import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

//import SubscriptionIdaDetails from './subscription-ida-details';
import InfoDialog from '../util/InfoDialog';
import { findCropType, findSoilType } from '../util/IdaData';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70, align:'center' },
  { field: 'active', headerName: 'Active', type: 'boolean', width: 100 },
  { field: 'user', headerName: 'Subscriber', width: 200,
    valueGetter: (value, row) => value.email },
  { field: 'title', headerName: 'Title', width: 200 },
  { field: 'latitude', headerName: 'Latitude', width: 130 },
  { field: 'longitude', headerName: 'Longitude', width: 130 },
  { field: 'crop_code', headerName: 'Crop Type', width: 130,
    valueGetter: (value, row) => findCropType(value) },
  { field: 'planting_date', headerName: 'Planting Date', width: 100 }
];


// ==============================================================
let SubscriptionsView = (props) => {

  const [rows, setRows] = useState([]);

  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [infoTitle, setInfoTitle] = useState('');
  const [infoMessage, setInfoMessage] = useState('');

  const navigate = useNavigate();

  // -----------------------------------------------
  useEffect( () => {
  
    console.log( "SubscriptionsView:", props );

    // if ( props.loginContext.userDetails == null || 
    //   props.loginContext.userDetails.roles.indexOf("ROLE_ADMIN") < 0 ) {
    
    //   console.log( "not admin account" );
    //   //navigate("/");
    // }

    fetchData();

  }, [props.viewName]);

  
  // -----------------------------------------------
  let openInfoDialog = ( title, message ) => {
    
    setInfoTitle(title);
    setInfoMessage(message);
    setInfoDialogOpen(true);
  };


  // -----------------------------------------------
  let fetchData = async () => {

    let urlStr = process.env.PUBLIC_URL + '/backend/subscriptions/ida';

    let response = await fetch(urlStr).catch( error => console.log(error) );
    if ( response === undefined ) {
      openInfoDialog( "Error', 'Cannot fetch subscriptions information" );
      return null;
    }

    let data = await response.json().catch( error => console.log(error) );

    console.log( "Data:", data.arrResponse );

    setRows( data.arrResponse );
  };



  // -----------------------------------------------
  return (

    <Box
      component="main"
      sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, '& p': {marginBottom:0} }}
    >
      <div style={{ marginTop:50, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            }
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true
            },
          }}          
          pageSizeOptions={[5, 10, 25, 50, 100]}
          //checkboxSelection
          hideFooterSelectedRowCount={true}
          autoHeight={true}
          //editMode={'row'}
          onRowClick={(e)=>{
            console.log(e);
            openInfoDialog( "Clicked", `id: ${e.id}` );
          }}
        />
      </div>

      <InfoDialog
        open={infoDialogOpen}
        title={infoTitle}
        message={infoMessage}
        onClose={()=>{
          setInfoDialogOpen(false);
        }}
      />


      {
      //  <SubscriptionIdaDetails
      //   open={userDetailsOpen}
      //   user={row}
      //   onClose={()=>{
      //     setUserDetailsOpen(false);
      //   }}
      //   onUpdate={(info)=>{
      //     onUpdate(info);
      //   }}
      //   onDelete={(info)=>{
      //     onDelete( info );
      //   }}
      // />
      }

    </Box>
  );

}



export default SubscriptionsView;

