import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@mui/material/Alert';



export const Message = (props) => {
  return (
    <Snackbar open={props.open} autoHideDuration={props.duration} onClose={() => props.setOpen(false)}> 
        <Alert variant="filled" severity={props.success ? "success" : "error"} >{props.message}</Alert>
    </Snackbar>
  )
}

export default Message;