
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export let updateTimeRange = ( c1, c2 ) => {  // (new, exist)

  if ( !c1.start.isSame(c2.start,"day") )
    return c1;

  if ( c1.irrigation_amount == null || c2.irrigation_amount == null )
    return c1;

  let diff = c2.end.diff(c2.start,"minute");
  if ( diff == 0 ) diff = 1;
  let apm = parseFloat(c2.irrigation_amount) / diff;

  //console.log( "updateTimeRange:", c1.start.toISOString(), c1.end.toISOString(), c2.start.toISOString(), c2.end.toISOString(), diff, apm );
  
  if ( c1.end.isBefore(c2.start,"minute") ) {
    c1.end = c2.end;
    c1.end_datetime = c2.end.toISOString();
    c1.irrigation_amount = (parseFloat( c1.irrigation_amount ) + parseFloat( c2.irrigation_amount )).toString()

    //console.log( "case 1: c1.end.isBefore(c2.start" );
  }
  else if ( c1.start.isAfter(c2.end,"minute") ) {
    c1.start = c2.start;
    c1.start_datetime = c2.start.toISOString();
    c1.irrigation_amount = (parseFloat( c1.irrigation_amount ) + parseFloat( c2.irrigation_amount )).toString()

    //console.log( "case 2: c1.start.isAfter(c2.end" );
  }
  else if ( c1.start.isSameOrBefore(c2.start,"minute") && c1.end.isSameOrAfter(c2.end,"minute") ) {

    // do nothing

    //console.log( "case 3: c1.start.isSameOrBefore(c2.start && c1.end.isSameOrAfter(c2.end" );
  }
  else if ( c1.start.isSameOrAfter(c2.start,"minute") && c1.end.isSameOrBefore(c2.end,"minute") ) {
    let d1 = c1.start.diff(c2.start,"minute");
    let d2 = c2.end.diff(c1.end,"minute");
    c1.start = c2.start;
    c1.start_datetime = c2.start.toISOString();
    c1.end = c2.end;
    c1.end_datetime = c2.end.toISOString();
    c1.irrigation_amount = (d1*apm + parseFloat(c1.irrigation_amount) + d2*apm).toString();

    //console.log( "case 4: c1.start.isSameOrAfter(c2.start && c1.end.isSameOrBefore(c2.end", d1, d2 );
  }
  else if ( c1.start.isSameOrBefore(c2.start,"minute") && !c1.end.isSameOrBefore(c2.end,"minute") ) {
    let d1 = c2.end.diff(c1.end,"minute");
    c1.end = c2.end;
    c1.end_datetime = c2.end.toISOString();
    c1.irrigation_amount = (parseFloat( c1.irrigation_amount ) + d1*apm).toString();
    
    //console.log( "case 5: c1.start.isSameOrBefore(c2.start && !c1.end.isSameOrBefore(c2.end", d1 );
  }
  else if ( c1.start.isSameOrAfter(c2.start,"minute") && c1.end.isSameOrAfter(c2.end,"minute") ) {
    let d1 = c1.start.diff(c2.start,"minute");
    c1.start = c2.start;
    c1.start_datetime = c2.start.toISOString();
    c1.irrigation_amount = (d1*apm + parseFloat( c1.irrigation_amount )).toString();
    
    //console.log( "case 6: c1.start.isSameOrAfter(c2.start && c1.end.isSameOrAfter(c2.end", d1 );
  }
  else {
    console.log( "case 7 : else ??" );
  }

  return c1;
};

