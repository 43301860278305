import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIcon from '@mui/icons-material/Assignment';

import UsersView from './users-view';
import SubscriptionsView from './subscriptions-view';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TaskIcon from '@mui/icons-material/Task';

import { WSConsumer } from "../context/WSContext";
import LoginContext from "../account/login-context";
import TasksView from './tasks-view';
import LogsView from './logs-view';


const drawerWidth = 240;

// ==============================================================
let AdminView = (props) => {

  const [ viewName, setViewName ] = useState('');

  const navigate = useNavigate();
  const loginContext = useContext(LoginContext);  


  // -----------------------------------------------
  useEffect( () => {
  
    console.log( "AdminView:", props, loginContext );

    if ( loginContext.userDetails == null || 
      loginContext.userDetails.roles.indexOf("ROLE_ADMIN") < 0 ) {
    
      console.log( "not admin account" );
      //navigate(-1);
    }
  }, []);


  // -----------------------------------------------
  useEffect( () => {
    setViewName( props.view );
  }, [props.view]);


  // -----------------------------------------------
  return (

    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          backgroundColor:'#343a40'
        }}
      >
        <Toolbar style={{minHeight:42}}>
          <Typography variant="h6" noWrap component="div">
            { 'Administration - ' + viewName }
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          }
        }}
        variant="permanent"
        anchor="left"
      >
        <List
          style={{color:'white', backgroundColor:'#545a60'}}
        >
          <ListItem key="Home" disablePadding
              onClick={()=>{
                navigate("/");
              }}   
          >
              <ListItemButton>
              <ListItemIcon
                  style={{color:'white'}}
              >
                  <HomeIcon />
              </ListItemIcon>
              <ListItemText primary='Back Home'/>
              </ListItemButton>
          </ListItem>
        </List>

        <Divider
          style={{backgroundColor:'#343a40'}}
        />
        <List
          style={{color:'white', backgroundColor:'#545a60'}}
        >
          {['Users', 'Subscriptions', 'Task Status', 'System Logs'].map((text, index) => (
            <ListItem key={text} disablePadding
                onClick={() => setViewName(text)}
            >
              <ListItemButton>
                <ListItemIcon style={{color:'white'}}>
                  {index === 0 ? <PersonIcon /> :
                   index === 1 ? <NotificationsIcon /> :
                   index === 2 ? <TaskIcon /> : 
                   index === 3 ? <AssignmentIcon /> : 
                   ''}
                </ListItemIcon>
                <ListItemText primary={text}/>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <div
          style={{backgroundColor:'#545a60', height:'100vh'}}
        >
        </div>
      </Drawer>

      {viewName === 'Users' && <UsersView viewName={viewName} loginContext={loginContext}/>}
      {viewName === 'Subscriptions' && <SubscriptionsView viewName={viewName} loginContext={loginContext}/>}
      {viewName === 'Task Status' && <TasksView viewName={viewName} loginContext={loginContext}/>}
      {viewName === 'System Logs' && <LogsView viewName={viewName} loginContext={loginContext}/>}

    </Box>

  );
}

// const AdminViewFunc = props => {

//     const navigate = useNavigate();
//     const loginContext = useContext(LoginContext);
  
//     return (
//       <WSConsumer>
//         {context => <AdminView {...props} context={context} navigate={navigate} loginContext={loginContext} />}
//       </WSConsumer>
//     )
//   };
  
export default AdminView;
