import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const columns = [
  { id: 'date', label: 'Date', minWidth: 120 },
  { id: 'title', label: 'Site Name', minWidth: 200 },
  //{ id: 'location', label: 'Location (Lon, Lat)', minWidth: 200 },
  { id: 'type', label: 'Corp Type', minWidth: 130 },
  { id: 'status', label: 'Status', minWidth: 80 }
];

function createData(id, date, title, type, active) {
  let status = (active) ? 'Activated' : 'Deactivated';
  return { id, date, title, type, status };
}

// const data = [ [1, '08/12/2023', '42.12265', '-100.58203', 'Corn'] ];

// const rows = [
//   createData(data[0][0], data[0][1], data[0][2], data[0][3], data[0][4] )
// ];

const createRows = (props) => {

    let data = props.data;
    let findCropType = props.findCropType;
    let list = [];

    for (let i=0; i < data.length; i++ ) {
        let date = data[i].created_at.split('T')[0].split('-');
        let newDate = date[1].padStart(2,'0') + "/" + date[2].padStart(2,'0') + "/" + date[0];

        list.push( createData(data[i].id, newDate, data[i].title, findCropType(data[i].crop_code).name, data[i].active ) );
    }

    return list;
};


export default function WSIdaSubscribeTable( props ) {
  
  const [rows, setRows] = React.useState(() => createRows( props ));

  const onEdit = (value) => {

    //console.log( "Table:", value );

    props.onEdit( value );
  };


  useEffect( () => {
    setRows( createRows( props ) );
  }, [props.data]); 


  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 260 }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight:"bold" }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .map((row) => {

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}
                   //onClick={(e)=>{console.log("low clicked: ", e.target); }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id}>
                          {value}
                        </TableCell>
                      );
                    })}
                    <TableCell
                      style={{width:210}}                    
                    >
                      <button className="btn btn-success btn-sm"
                        onClick={()=>{onEdit(row.id);}}
                      >Details...</button>
                      <button className="btn btn-info btn-sm"
                        style={{marginLeft:10}}
                        onClick={()=>{props.onFeedback && props.onFeedback(row.id);}}
                      >Feedback...</button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
