
const dateToString=(date)=>{
  var y = date.getFullYear();
  var m = date.getMonth()+1;
  m = m<10?('0'+m):m;
  var d = date.getDate();
  d = d<10?('0'+d):d;
  return y+'.'+m+'.'+d;
};
const dateHourToString=(date)=>{
  var y = date.getFullYear();
  var m = date.getMonth()+1;
  m = m<10?('0'+m):m;
  var d = date.getDate();
  var h = date.getHours()
  h = h<10?('0'+h):h
  d = d<10?('0'+d):d;
  return y+'-'+m+'-'+d+' '+h+':00';
};

const stringToDate=(str)=>{//2019.07.19 to Date
  var dateStr = str.split('.');
  var date = new Date(dateStr[0], dateStr[1]-1, dateStr[2])
  return date
}

const dateToHour=(date)=>{
  var h = date.getHours()
  var hh = Math.floor(h/3)*3+1
  return hh<10?('0'+hh+'3000'):(hh+'3000')
}

const getWeekFromDate=(date)=>{//for SMAP  format:50(12.13_12.19)_2016
  const dayMS = 24*60*60*1000
  const weekMS = 6*dayMS
  let weekNum = 1
  const y = date.getFullYear()
  let d = new Date(y,'0','1')
  if(y<2019){
    while (d.getDay()!==2) {//FIND THE FIRST TUESDAY OF THE GIVEN YEAR
      d = new Date(d.getTime()+dayMS)
    }
  }
  else{
    while (d.getDay()!==0) {//FIND THE FIRST SUNDAY OF THE GIVEN YEAR
      d = new Date(d.getTime()+dayMS)
    }
    d = new Date(d.getTime()-7*dayMS)// START FROM SOMEDAY OF LAST YEAR, TRUE BEFORE 2024
  }
  while (d.getTime()<=date.getTime()-7*dayMS) {
    weekNum = weekNum+1
    d = new Date(d.getTime()+7*dayMS)
  }
  //console.log(d)
  const weekEnd = new Date(d.getTime()+weekMS)
  // const weekString=getNumString(weekNum, 2)+'('+getNumString(d.getMonth()+1, 2)+'.'+getNumString(d.getDate(), 2)+'_'
  // +getNumString(weekEnd.getMonth()+1, 2)+'.'+getNumString(weekEnd.getDate(), 2)+')'+'_'+y;
  const weekString = getNumString(weekNum, 2)+'_'+dateToString(d)+'_'+dateToString(weekEnd)
  return weekString
}

const getWeekFromDate2=(date)=>{//for NDVI  format: 50(12.13_12.19)_2016
  const dayMS = 24*60*60*1000
  const weekMS = 6*dayMS
  const y = date.getFullYear()
  let weekNum = 1
  let d = new Date(y,'0','1')
  if(y<2020){
    while (d.getDay()!==2) {//FIND THE FIRST TUESDAY OF THE GIVEN YEAR
      d = new Date(d.getTime()+dayMS)
    }
  }
  else{
    while (d.getDay()!==0) {//FIND THE FIRST SUNDAY OF THE GIVEN YEAR
      d = new Date(d.getTime()+dayMS)
    }
    d = new Date(d.getTime()-7*dayMS)
  }
  while (d.getTime()<=date.getTime()-7*dayMS) {
    weekNum = weekNum+1
    d = new Date(d.getTime()+7*dayMS)
  }
  //console.log(d)
  const weekEnd = new Date(d.getTime()+weekMS)
  // const weekString=getNumString(weekNum, 2)+'('+getNumString(d.getMonth()+1, 2)+'.'+getNumString(d.getDate(), 2)+'_'
  // +getNumString(weekEnd.getMonth()+1, 2)+'.'+getNumString(weekEnd.getDate(), 2)+')'+'_'+y;
  const weekString = getNumString(weekNum, 2)+'_'+dateToString(d)+'_'+dateToString(weekEnd)
  return weekString
}

const getWeekFromDate3=(date)=>{//for NDVI  format: 50(12.13_12.19)_2016
  const dayMS = 24*60*60*1000
  const y = date.getFullYear()
  let weekNum = 1
  let d = new Date(y,'0','1')
  //if(y<2020){
    while (d.getDay()!==4) {//FIND THE FIRST THURSDAY OF THE GIVEN YEAR
      d = new Date(d.getTime()+dayMS)
    }
  while (d.getTime()-3*dayMS<=date.getTime()-7*dayMS) {//
    weekNum = weekNum+1
    d = new Date(d.getTime()+7*dayMS)
  }
  //console.log(d)
  const weekEnd = new Date(d.getTime()+2*dayMS)
  const weekStart = new Date(d.getTime()-4*dayMS) //start from sunday
  const weekString=getNumString(weekNum, 2)+'_'+dateToString(weekStart)+'_'+dateToString(weekEnd)
  return weekString
}


const getBiweekFromDate=(date)=>{
  const dayMS = 24*60*60*1000
  const biweekMS = 13*dayMS
  const y = date.getFullYear()
  let weekNum = 1
  let d = new Date(y,'0','1')
  //if(y<2020){
    while (d.getDay()!==2) {//FIND THE FIRST TUESDAY OF THE GIVEN YEAR
      d = new Date(d.getTime()+dayMS)
    }
  // if (d.getTime()>date.getTime()){
  //   d = new Date(d.getTime()-7*dayMS)
  // }
  while (d.getTime()<=date.getTime()-7*dayMS) {
    weekNum = weekNum+1
    d = new Date(d.getTime()+7*dayMS)
  }
  //console.log(d)
  const biweekEnd = new Date(d.getTime()+biweekMS)
  const biweekString=getNumString(weekNum, 2)+'('+getNumString(d.getMonth()+1, 2)+'.'+getNumString(d.getDate(), 2)+'_'
  +getNumString(biweekEnd.getMonth()+1, 2)+'.'+getNumString(biweekEnd.getDate(), 2)+')'+'_'+y;
  return biweekString
}

const getLatestWeek=()=>{
  //week starts from the first Tuesday in the year before 2019
  let weekNum = 2
  const dayMS = 24*60*60*1000
  const weekMS = 6*dayMS

  const today = new Date()
  const maxDay =new Date(today.getTime()-28*dayMS)
  const year = maxDay.getFullYear()
  let date = new Date(year, "0", "1")
  while (date.getDay()!==0) {//FIND THE FIRST SUNDAY OF THE GIVEN YEAR
    date = new Date(date.getTime()+dayMS)
  }
  while (date.getTime()<=maxDay.getTime()) {
    weekNum = weekNum+1
    date = new Date(date.getTime()+7*dayMS)
  }
  const weekEnd = new Date(date.getTime()+weekMS)
  const latestWeek=getNumString(weekNum,2)+'('+getNumString(date.getMonth()+1,2)+'.'+getNumString(date.getDate(), 2)+'_'
  +getNumString(weekEnd.getMonth()+1, 2)+'.'+getNumString(weekEnd.getDate(), 2)+')'+'_'+year;
  return latestWeek
}

const getDayList=(day,num)=>{
  let dayList = []
  for(let i = 0; i<num; i++){

    dayList.push(dateToString(new Date(day.getTime()-i*24*3600*1000)))
  }
  return dayList
}

const getDuration = (type,start,stop) => {
  let array = new Array();
  var current = new Date(start);
  stop  = new Date(stop);
  while (current <= stop) {
      array.push( new Date (current) );
      if(type == 'hour'){//小时
           current.setHours(current.getHours() + 1);
      }else if(type == 'day'){//天
          current.setDate(current.getDate() + 1);
      }else if(type == 'week'){//周
          current.setDate(current.getDate() + 7);
      }else if(type == 'month'){//月
          current.setMonth(current.getMonth() + 1);
      }else{//默认天
          current.setDate(current.getDate() + 1);
      }
  }
  return array;
}


const capitalize = ([first, ...rest], lowerRest = false) => 
  first.toUpperCase()+(lowerRest?rest.join('').toLowerCase():rest.join(''));

  // const getNumString = (num) =>{
  //   return num = num<10?'0'+num:num.toString();
  // }
  function getNumString(num, length) {
    if((num + "").length >= length) {
        return num;
    }
    return getNumString("0" + num, length)
}

  const findIndexOfArray = (arr, item)=>{
    let i=0
    for(i=0; i<arr.length; i++){
      if(arr[i]===item)return i
    }
    return -1
  }


  const get3DaysFromDate=(date)=>{
    const dayMS = 24*60*60*1000
    const y = date.getFullYear()
    //let weekNum = 1
    let d = new Date(y,'0','1')
    while (d.getTime()<=date.getTime()-3*dayMS) {//
      //weekNum = weekNum+1
      d = new Date(d.getTime()+3*dayMS)
    }
    const endDay = new Date(d.getTime()+2*dayMS)
    const dayStr = dateToString(d)+'_'+dateToString(endDay)
    //let dayStr = '2020.04.06_2020.04.08'
    return dayStr
  }

  export {capitalize, getLatestWeek, getNumString, dateToString, dateToHour,
    getWeekFromDate, getWeekFromDate2, getBiweekFromDate, stringToDate, findIndexOfArray,
    getDayList, getWeekFromDate3, getDuration, get3DaysFromDate, dateHourToString
  }
