import React, { useContext, useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Dialog, Snackbar, Toolbar } from '@material-ui/core';

import {Link, useNavigate} from "react-router-dom";

import LoginContext from "../account/login-context";
import Copyright from "../account/copyright";
import Message from "../account/feedback-message";

const MESSAGE_DURATION = 2000;
const successMessage = `You are logged in! Closing dialog in ${MESSAGE_DURATION/1000} seconds`;
const failMessage = "Sorry, your credentials are incorrect.";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

// timeout
let redirectTimeout = null;

export default function LoginInPage(props) {
  const classes = useStyles();

  const loginContext = useContext(LoginContext);

  const navigate = useNavigate();
//  const location = useLocation();


  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const [messageOpen, setMessageOpen] = useState(false);
  const [success, setSuccess] = useState(true);
  const [message, setMessage] = useState(successMessage);

  useEffect(() => {

    if ( messageOpen && success ) {
      console.log ("issued -- before");
      redirectTimeout = setTimeout(() => {
        console.log ("issued", MESSAGE_DURATION );
        navigate('/');
      }, MESSAGE_DURATION );
    }

    return () => clearTimeout(redirectTimeout);
  }, [messageOpen, success]);

  // callback function for submitting login credentials
  const handleSubmit = async (e) => {
    e.preventDefault();
    let response = await fetch( process.env.PUBLIC_URL + "/backend/login", {
      method: "POST",
      header: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: new URLSearchParams({
        "username": username,
        "password": password
      })
    })
    
    if (response.status === 200) {
      // log-in success

      response.text().then( txt => {
        if ( !txt.includes( "Login failed" ) ) {

          fetch(process.env.PUBLIC_URL + "/backend/user_details")
          .then(response => {
            if (response.ok && response.headers.get("Content-Type") === "application/json" )
              return response.json();
            else
              throw new Error();
          })
          .then(data => {

            console.log( "signin-page:", data  );

            loginContext.updateUserId(data.id);
            loginContext.updateUsername(username);
            loginContext.updateUserDetails(data);
            loginContext.updateLoggedIn(true);
            setMessageOpen(true);
            setSuccess(true);
            setMessage(successMessage);
            
            // console.log ("issued -- before");
            // redirectTimeout = setTimeout(() => {
            //   console.log ("issued", MESSAGE_DURATION );
            //   navigate('/');
            // }, MESSAGE_DURATION );
//            redirectTimeout = setTimeout(() => navigate(-1), MESSAGE_DURATION)
          })
          .catch(err => console.info(err, "no login session found."))
        } else {
          // failed
          loginContext.updateLoggedIn(false);
          setMessageOpen(true);
          setSuccess(false);
          setMessage(failMessage);
        }
      });
    } else {
      // failed
      loginContext.updateLoggedIn(false);
      setMessageOpen(true);
      setSuccess(false);
      setMessage(failMessage);
    }
    
  }

  return (
    // <Dialog open={props.open} onClose={props.handleClose} maxWidth='sm' >
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Toolbar />
      <Grid className={classes.paper}>
        <Avatar className={classes.avatar} >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="username"
            autoComplete="email"
            autoFocus
            onChange={(e) => setUsername(e.target.value) }
          />
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container spacing={1} direction="column" justify="flex-end">
            {/* <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid> */}
            <Grid item>
              <Link to="/signup" >
                Don't have an account? Sign Up here.
              </Link>
            </Grid>
            <Grid item>
              <Link to="/forgot_password" >
                Forgot password? Reset it here.
              </Link>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Box mt={4}>
        <Copyright />
      </Box>

      <Message 
        open={messageOpen} 
        setOpen={setMessageOpen} 
        success={success} 
        message={message}
      />

    </Container>
  );
}