import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

import InfoDialog from '../util/InfoDialog';
import UserDetails from './user-details';


const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70, align:'center' },
  { field: 'active', headerName: 'Active User', type: 'boolean', width: 100 },
  { field: 'email', headerName: 'E-mail', width: 200 },
  { field: 'firstName', headerName: 'First name', width: 130 },
    //valueGetter: (value, row) => `${value || ''}` }, 
  { field: 'lastName', headerName: 'Last name', width: 130 },
    //valueGetter: (value, row) => `${value || ''}` }, 
  { field: 'phoneNumber', headerName: 'Phones', width: 130 },
    //valueGetter: (value, row) => `${value || ''}` },
  { field: 'roles', headerName: 'Role', width: 130,
    valueGetter: (value, row) => (value?value.split("_")[1]:'') }
];



// ==============================================================
const UsersView = (props) => {

  const [rows, setRows] = useState([]);

  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [infoTitle, setInfoTitle] = useState('');
  const [infoMessage, setInfoMessage] = useState('');

  const [row, setRow] = useState({});
  const [userDetailsOpen, setUserDetailsOpen] = useState(false);
  
  // -----------------------------------------------
  useEffect( () => {
  
    console.log( "UsersView:", props );

    // if ( props.loginContext.userDetails == null || 
    //   props.loginContext.userDetails.roles.indexOf("ROLE_ADMIN") < 0 ) {
    
    //   console.log( "not admin account" );
    //   //navigate("/");
    // } else
      fetchData();

  }, [props.viewName]);

  
  // -----------------------------------------------
  let openInfoDialog = ( title, message ) => {
    
    setInfoTitle(title);
    setInfoMessage(message);
    setInfoDialogOpen(true);
  };


  // -----------------------------------------------
  let fetchData = async () => {

    let urlStr = process.env.PUBLIC_URL + '/backend/users';

    let response = await fetch(urlStr).catch( error => console.log(error) );
    if ( response === undefined ) {
      openInfoDialog( "Error', 'Cannot fetch users information" );
      return null;
    }

    let data = await response.json().catch( error => console.log(error) );

    console.log( "UsersView.fetchData:", data );

    setRows( data );
  };


  // -----------------------------------------------
  let onUpdate = async (info) => {

    let payload = {...info};

    payload.geofairyPushToken = ( info.resetGeofairy ) ? null : row.geofairyPushToken;
    delete payload.resetGeofairy;

    console.log( "UsersView.onUpdate:", info, payload );

    let response = await fetch(process.env.PUBLIC_URL + "/backend/users/" + payload.id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
      });

    let data = await response.json().catch( (e) => console.log(e) );
    if (data.status == "Success" ) {
      openInfoDialog( 'Info', `Updated successfully: ${info.email}` );
      fetchData();
    }
    else 
      openInfoDialog( 'Error', `Updating failed: ${info.email}` );
  }


  // -----------------------------------------------
  let onDelete = async ( info ) => {

    console.log( "UsersView.onDelete:", info );

    let response = await fetch(process.env.PUBLIC_URL + "/backend/users/" + info.id, {
      method: "DELETE"
    });

    let data = await response.json().catch( (e) => console.log(e) );
    if (data.status == "Success" ) {
      openInfoDialog( 'Info', `Deleted successfully: ${info.email}` );
      fetchData();
    }
    else 
      openInfoDialog( 'Error', `Deleting failed: ${info.email}` );
  }

  
  // -----------------------------------------------
  return (

    <Box
      component="main"
      sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, '& p': {marginBottom:0} }}
    >
      <div style={{ marginTop:50, width: '90%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}          
          pageSizeOptions={[5, 10, 25, 50, 100]}
          //checkboxSelection
          hideFooterSelectedRowCount={true}
          autoHeight={true}
          //editMode={'row'}
          onRowClick={(e)=>{
            console.log(e);
            //openInfoDialog( "Clicked", `id: ${e.id}` );
            setRow( e.row );
            setUserDetailsOpen(true);

          }}
        />
      </div>

      <InfoDialog
        open={infoDialogOpen}
        title={infoTitle}
        message={infoMessage}
        onClose={()=>{
          setInfoDialogOpen(false);
        }}
      />

      <UserDetails
        open={userDetailsOpen}
        user={row}
        onClose={()=>{
          setUserDetailsOpen(false);
        }}
        onUpdate={(info)=>{
          onUpdate(info);
        }}
        onDelete={(info)=>{
          onDelete( info );
        }}
      />

    </Box>
  );

}



export default UsersView;

