import React, { Component, useRef } from "react";
//import {Link} from 'react-router'
//import {Link} from 'react-router-dom'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import ReactModal from 'react-modal-resizable-draggable'
import '@fortawesome/fontawesome-free'
import '@fortawesome/fontawesome-free/css/all.css'
import Media from 'react-media';

// ============================================================
// Added by Gil Heo (July 26, 2023)
import { useContext } from "react";
import {useNavigate} from "react-router-dom";
import LoginContext from "../account/login-context";
import { WSConsumer } from "../context/WSContext";
import WSIrrigationSchedulingModal from "./WSIrrigationSchedulingModal";
// ============================================================


// ============================================================
// Modified by Gil Heo (July 26, 2023)
//export default class WSNavbar extends Component {
class WSNavbar extends Component {
// ============================================================

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      aboutVisible: false,
      newsVisible: false,
      publicationVisible: false,
      userguideVisible: false,
      isOpen: false,
      DRISVisible:false,
      DASVisible:false,
      WIVisible:false,
      // ============================================================
      // Modified by Gil Heo (Sep 27, 2023)
      irrigationSchedulingVisible: false,
      AccVisible:false,
      // ============================================================
      HelpVisible:false
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  close(){

    this.setState({

    });

  }

  collapseAll = () => {
    this.setState({ DRISVisible: false });
    this.setState({ DASVisible: false });
    this.setState({ WIVisible: false });
    this.setState({ AccVisible: false });
    this.setState({ HelpVisible: false });
  }


  // ======================================================  
  // Logout onClick event handler
  // Added by Gil Heo (Sep 29, 2023)  */}

  componentDidMount() {
    //this.fetchUserDetails();
  }

  fetchUserDetails = () => {

    fetch(process.env.PUBLIC_URL + "/backend/user_details")
    .then(response => {
      if (response.ok && response.headers.get("Content-Type") === "application/json" )
        return response.json();
      else
        throw new Error();
    })
    .then(data => {

      console.log( data );

      this.props.loginContext.updateUserId(data.id);
      this.props.loginContext.updateUsername(data.email);
      this.props.loginContext.updateUserDetails( data );
      this.props.loginContext.updateLoggedIn(true);
    })
    .catch(err => {
      console.info(err, "no login session found.")
    })
  }
 

  onClickLogout = () => {

    console.log( "onClickLogout: called" ) ;

    fetch(process.env.PUBLIC_URL + '/backend/logout')
    .then(response => {

      this.props.loginContext.updateLoggedIn(false);
      this.props.loginContext.updateUserId(0);
      this.props.loginContext.updateUsername('');
      this.props.loginContext.updateUserDetails(null);

      this.collapseAll();
      this.props.navigate("/");
      window.location.reload();
    })
  }
  // End of Logout handler
  // ======================================================  


  render() {
    return (
      <div style={{'padding':0, 'margin':0, 'height': '42px',minHeight: '42px'  }}>
        <Navbar className="py-0 bg-dark" style={{'padding':0, 'margin':0, 'height': '42px',"minHeight": '42px' }} dark expand="md">
          <NavbarBrand style={{ width: '40px', padding: 0, margin:0, height: '42px', minHeight: '42px' }} > </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="py-0 bg-dark" style={{'padding':0, 'margin':0, 'height': '42px',"minHeight": '42px' }}  navbar>
              <NavItem >
                {/*<NavLink href="https://cat.csiss.gmu.edu/CropSmart/" style={{ fontWeight: 'bold', color:'white'}} >*/}
                <NavLink href="#nolink"
                  style={{ fontWeight: 'bold', color:'white'}}
                  onClick={() => {
                    this.collapseAll();
                    this.props.context.state.collapseSidebar();
                    this.props.context.state.setShowProductBar();
                  }}
                >
                      Decision Ready Information
                    {/* <i className="fas fa-home" style={{ fontSize: '2em', color:'white'}} /> */}
                </NavLink>
              </NavItem>
              <NavItem >
                <NavLink href="#nolink" 
                   onClick={() => {
                    this.collapseAll();
                    this.props.context.state.collapseSidebar();
                    this.setState({ DASVisible: !this.state.DASVisible });
                    }} style={{ fontWeight: 'bold', color:'white'}} >
                        Decision Advice Services
                {/* <i className="fas fa-comments" style={{ fontSize: '2em', color:'white'}} /> */}
                </NavLink>
              </NavItem>
              <NavItem >
                <NavLink href="#nolink" 
                    onClick={() => {
                      this.collapseAll();
                      this.props.context.state.collapseSidebar();
                      this.setState({ WIVisible: !this.state.WIVisible });
                      }}  style={{ fontWeight: 'bold', color:'white'}} >
                        Trade-off Services
                {/* <i className="fas fa-balance-scale" style={{ fontSize: '2em', color:'white'}} /> */}
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>

          <Nav className="ml-auto py-0 bg-dark" style={{'padding':0, 'margin':0, 'height': '42px',"minHeight": '42px' }} >
                
              {/* ======================================================  
               // Account item
               // Added by Gil Heo (Sep 27, 2023)  */}
                <NavItem id={'elAccount'}>
                  <NavLink href="#nolink"
                    style={{ fontWeight: 'bold', color:'white'}}
                    onClick={() => {
                      this.collapseAll();
                      this.setState({ AccVisible: !this.state.AccVisible });
                    }}
                  >
                    { !this.props.loginContext.loggedIn ? 'Account' : this.props.loginContext.username }
                  </NavLink>
                </NavItem>
              {/* End of Account item
               // ======================================================  */}

                <NavItem id={'elHelp'}>
                  <NavLink href="#nolink"
                    style={{ fontWeight: 'bold', color:'white'}}
                    onClick={() => {
                      this.collapseAll();
                      this.setState({ HelpVisible: !this.state.HelpVisible });
                    }}
                  >
                   Help
                  </NavLink>
                </NavItem>

                {/*
                <NavItem >
                <NavLink href="https://drive.google.com/file/d/1kpezAXq8lA2qWnayt5pBkz3iBai0-TBI/view" style={{ fontWeight: 'bold', color:'white'}} >

                          Video
                  </NavLink>
                </NavItem>
                <NavItem >
                  <NavLink href="#nolink" 
                      onClick={() => {
                        this.collapseAll();
                        this.setState({ aboutVisible: !this.state.aboutVisible });
                        }}
                      style={{ fontWeight: 'bold', color:'white'}}>
                          About
                  </NavLink>
                </NavItem>
                */}
              <a href="https://cropsmart.org" target="_blank" rel="noreferrer">
                  <img
                    className='logo'
                    src={process.env.PUBLIC_URL + "/image/CropSmart-Logo-Original.png"}
                    alt="CSISS"
                    hspace="10"
                  />
                </a>
              <a href="https://www.nsf.gov/" target="_blank" rel="noreferrer">
                  <img
                    className='logo'
                    src={process.env.PUBLIC_URL + "/image/NSF_4-Color_bitmap_Logo.png"}
                    alt="CSISS"
                    hspace="10"
                  />
                </a>
              
              </Nav>
        </Navbar>

{/*         {this.state.DRISVisible ? 
        <div id='DRIS'  style = {{ 
          color:'white', position:'absolute', top:'42px',
           left:'40px' , zIndex:'100', background: '#0000008f',
           width: '430px', marginLeft:'0'
           }}>
              {/* <a href="/watersmartport/test" >DIP</a> */}
  {/*            <a href="" style = {{ color:'white',}}>
              CropSmart DRIS - Decision Ready Information Service 1
              </a> 
              <br/>
              <a href="" style = {{ color:'white',}}>
              CropSmart DRIS -  Decision Ready Information Service 2
              </a>
              <br/>
              <a href="" style = {{ color:'white',}}>
              CropSmart DRIS -  Decision Ready Information Service 3
              </a>
        </div>:null} */}
          
          {this.state.DASVisible?
          <div id='DAS'style = {{ 
          color:'white', position:'absolute', top:'42px',
           left:'200px' , zIndex:'100',background: '#0000008f',
           width: '300px', marginLeft:'0'
           }}>
              {/* <a href="/watersmartport/test" >DIP</a> */}
              {/* <a href="https://geobrain.csiss.gmu.edu/ids2/" style = {{ color:'white',}}>
              Irrigation Scheduling Subscription Service
              </a> 
              <br/> */}              
              <a href="#nolink" style={{ color:'white',}}
                onClick={event => {
                  this.setState( {DASVisible:false} );
                  this.setState( {irrigationSchedulingVisible: true } );
                }}
              >
              {/*Irrigation Decision Advice Subscription Service  */}
              Irrigation Scheduling Service
              </a>              
              <br/>
              <a href="#nolink" style={{ color:'#C0C0C0', pointerEvents:'none', cursor:'default'}}>
              {/* Fertilize Scheduling Subscription Service */}
              Fertilize Scheduling Service
              </a>
              <br/>
              <a href="#nolink" style = {{ color:'#C0C0C0', pointerEvents:'none', cursor:'default'}}>
              {/* Harvest Subscription Service */}
              Harvest Scheduling Service
              </a>
          </div>:null}

           {this.state.WIVisible?
          <div id='WI' style = {{ 
          color:'white', position:'absolute', top:'42px',
           left:'420px' , zIndex:'100',background: '#0000008f',
           width: '300px', marginLeft:'0'
           }}>
              {/* <a href="/watersmartport/test" >DIP</a> */}
              {/* ======================================================  
               // WS Irigation Scheduling 
               // Added by Gil Heo (July 25, 2023)  */}
              <a href="#nolink" style={{ color:'#C0C0C0', pointerEvents:'none', cursor:'default'}}
                // onClick={event => {
                //   this.setState( {WIVisible:false} );
                //   this.setState( {irrigationSchedulingVisible: true } );
                // }}
              >
              Irrigation Trade off Analysis Service
              </a> 
              {/* End of WS Irrigation Scheduling  
               // ======================================================  */}
              <br/>
              <a href="#nolink" style = {{ color:'#C0C0C0', pointerEvents:'none', cursor:'default'}}>
              Pest Control Analysis Service
              </a>
          </div>:null}

        {/* ======================================================  
         // Account sub-menu
         // Added by Gil Heo (Sep 27, 2023)  */}
        {this.state.AccVisible?
          <div id='ACC' style = {{ 
          color:'white', position:'absolute', top:'42px',
           right:'240px' , zIndex:'100',background: '#0000008f',
           width: document.getElementById('elAccount').offsetWidth,
           marginRight:'0'
           }}>
              {this.props.loginContext.loggedIn ? 
              <div>
              {this.props.loginContext.userDetails && this.props.loginContext.userDetails.roles === 'ROLE_ADMIN' && <div>
              <a href={process.env.PUBLIC_URL + "/admin"} style={{ color:'white',}}
              >
              Administration Menu
              </a> 
              <br/>
              </div>}
              <a href={process.env.PUBLIC_URL + "/account"} style={{ color:'white',}}
              >
              Edit User Profile
              </a> 
              <br/>
              <a href="#nolink" style = {{ color:'white',}}
                onClick={()=>this.onClickLogout()}
              >
              Logout
              </a>
              </div> : <div>
             <a href={process.env.PUBLIC_URL + "/signin"} style={{ color:'white',}}
             >
             Log in
             </a> 
             <br/>
             <a href={process.env.PUBLIC_URL + "/signup"} style = {{ color:'white',}}>
             Sign Up
             </a>
             </div> }
          </div>:null}
          {/* End of  Account sub-menu
            // ======================================================  */}

        {this.state.HelpVisible?
          <div id='HE' style = {{ 
           color:'white', position:'absolute', top:'42px',
           right:'145px' , zIndex:'100',background: '#0000008f',
           //width: document.getElementById('elHelp').offsetWidth,
           width: 130,
           marginRight:'0'
           }}>
            <div>
              <a href="https://drive.google.com/file/d/1kpezAXq8lA2qWnayt5pBkz3iBai0-TBI/view" target="_blank" rel="noreferrer" style = {{ color:'white'}} >
                Video
              </a>
              <br/>
              <a href="#nolink" style = {{ color:'#C0C0C0', pointerEvents:'none', cursor:'default'}}>
              User's Guide
              </a>
              <br/>
              <a href="#nolink" style = {{ color:'white',}}
                onClick={()=>{
                  this.collapseAll();
                  this.setState({ aboutVisible: !this.state.aboutVisible });
                }}                  
              >
              About
              </a>
            </div>
          </div>:null}


        <ReactModal initWidth={700} initHeight={500} 
            // style={{ top: '40px' }}
            onFocus={() => {}}
            className={"my-modal-custom-class"}
            onRequestClose={()=>{this.setState({aboutVisible:false})}} 
            disableResize={'false'}
            isOpen={this.state.aboutVisible}> 
            <h5 className='flexible-modal-title'>About</h5>
            <div className="body" style={{fontSize:'0.8em'}}>
                <p style={{ textAlign: 'left', padding: '10px' }}>
                {/* Crop-CASMA (Crop Condition and Soil Moisture Analytics) is a web-based geospatial application.
                 This application is designed for users to utilize the remotely sensed geospatial soil moisture and vegetation index data 
                 derived from <a target="_blank" href="https://smap.jpl.nasa.gov/">NASA SMAP</a> and MODIS missions to
                  assess conterminous U.S. crop vegetation conditions and soil moisture condition. */}

                  This system is mainly designed, developed and maintained by Center for Spatial Information Science and System, George Mason University. This project is primarily funded by National Science Foundation <a href="https://www.nsf.gov/awardsearch/showAward?AWD_ID=2236137&HistoricalAwards=false">#2236137</a>.

                </p>
                {/* <p style={{ textAlign: 'left', padding: '10px' }}>
                  The SMAP data is produced by <a href="https://www.jpl.nasa.gov/">NASA JPL</a>. The LDAS model results are generated by UCAR HRLDAS model in a near-real-time manner. The Soil Porperties data in this portal (including 'Soil Color','Chemical Soil Properties','Physical Soil Properties','Soil Land Use' data) comes from
                  the App 'Soil Porperties' developed by the <a target="_blank" href="https://casoilresource.lawr.ucdavis.edu/">California Soil Resource Lab</a> at UC Davis 
                  and <a target="_blank" href="https://ucanr.edu/">UC-ANR</a> in 
                  collaboration with the <a target="_blank" href="https://www.nrcs.usda.gov/wps/portal/nrcs/site/national/home/">USDA Natural Resources Conservation Service.</a>

                </p> */}
                <p style={{ textAlign: 'left', padding: '10px' }}>

                  PIs: Dr. Liping Di, Dr. Fei Chen, Dr. Juan Sesmero, Dr. Qian Du, Dr. Haishun Yang.

                </p>
                
                <p style={{ textAlign: 'left', padding: '10px' }}>

                  Point of Contact for Techinical Issues and New Feature Requests: Dr. Gil Heo <a href="mailto:gheo@gmu.edu">gheo@gmu.edu</a>; Dr. Li Lin <a href="mailto:llin2@gmu.edu">llin2@gmu.edu</a>; Mr. Haoteng Zhao <a href="mailto:hzhao22@masonlive.gmu.edu">hzhao22@masonlive.gmu.edu</a>.

                </p>

                <p style={{ textAlign: 'left', padding: '10px' }}>

                  Last modified date: <i>December 12, 2024</i>

                </p>                

                <p style={{ textAlign: 'left', padding: '10px' }}>

                {/* This application is funded by NASA and USDA-NASS. 
                It is designed and developed by <a href="https://www.nass.usda.gov/">USDA-NASS</a> 
                and <a href="http://csiss.gmu.edu">the Center for Spatial Information Science and Systems</a> at George Mason University. */}

                </p>
{/* 
                <p style={{ textAlign: 'left', padding: '10px' }}>

                  Point of Contact: Dr. Zhengwei Yang <a href="mailto: zhengwei.yang@usda.gov">zhengwei.yang@usda.gov</a>

                </p> */}

            </div>
            
            <button className='flexible-modal-clsBtn'
                onClick={()=>{this.setState({aboutVisible:false})}}
            >
                &times;
            </button>
        </ReactModal>

        <ReactModal initWidth={700} initHeight={500} 
            onFocus={() => {}}
            className={"my-modal-custom-class"}
            onRequestClose={()=>{this.setState({newsVisible:false})}} 
            disableResize={'true'}
            isOpen={this.state.newsVisible}> 
            <h5 className='flexible-modal-title'>News</h5>
            <div className="body">
              {/* <p style={{ textAlign: 'left', padding: '10px' }}>
                <b>March 19, 2021 </b> The alpha version of WaterSmart Data and Information Portal is released online for internal testing. 
                The system address: <a  href="https://geobrain.csiss.gmu.edu/watersmartport/web/">https://geobrain.csiss.gmu.edu/watersmartport/web/</a>
              </p> */}
            </div>
            
            <button className='flexible-modal-clsBtn'
                onClick={()=>{this.setState({newsVisible:false})}}
            >
                &times;
            </button>
        </ReactModal>

        <ReactModal initWidth={700} initHeight={500} 
            onFocus={() => {}}
            className={"my-modal-custom-class"}
            onRequestClose={()=>{this.setState({publicationVisible:false})}} 
            disableResize={'true'}
            isOpen={this.state.publicationVisible}> 
            <h5 className='flexible-modal-title'>Publication</h5>
            <div className="body" style={{fontSize:'0.7em'}}>
                
{/*                 
                <p style={{ textAlign: 'left', padding: '10px' }}>Sun, Ziheng, Liping Di, Hui Fang, Liying Guo, Eugene Yu, Junmei Tang, Haoteng Zhao et al. "Advanced Cyberinfrastructure for Agricultural Drought Monitoring." In 2019 8th International Conference on Agro-Geoinformatics (Agro-Geoinformatics), pp. 1-5. IEEE, 2019.<a href = "https://doi.org/10.1109/Agro-Geoinformatics.2019.8820694">https://doi.org/10.1109/Agro-Geoinformatics.2019.8820694</a> </p>

                <p style={{ textAlign: 'left', padding: '10px' }}>Sun, Ziheng, Liping Di, Hui Fang, Annie Bryant Burgess, and Navya Singh. "Deep Learning Cyberinfrastructure for Crop Semantic Segmentation." AGUFM 2019 (2019): IN32B-04.<a href = "https://ui.adsabs.harvard.edu/abs/2019AGUFMIN32B..04S/abstract">https://ui.adsabs.harvard.edu/abs/2019AGUFMIN32B..04S/abstract</a></p>

                <p style={{ textAlign: 'left', padding: '10px' }}>Sun, Jie, Zulong Lai, Liping Di, Ziheng Sun, Jianbin Tao, and Yonglin Shen. "Multilevel Deep Learning Network for County-Level Corn Yield Estimation in the US Corn Belt." IEEE Journal of Selected Topics in Applied Earth Observations and Remote Sensing 13 (2020): 5048-5060.<a href = "https://doi.org/10.1109/JSTARS.2020.3019046">https://doi.org/10.1109/JSTARS.2020.3019046</a> </p>
                
                <p style={{ textAlign: 'left', padding: '10px' }}>Sun, Ziheng, Liping Di, Hui Fang, and Annie Burgess. "Deep Learning Classification for Crop Types in North Dakota." IEEE Journal of Selected Topics in Applied Earth Observations and Remote Sensing (2020).<a href = "https://doi.org/10.1109/JSTARS.2020.2990104">https://doi.org/10.1109/JSTARS.2020.2990104</a> </p>
            
                <p style={{ textAlign: 'left', padding: '10px' }}>Pengyu, Hao, Di Liping, Zhang Chen, and Guo Liying. "Transfer learning for crop classification with Cropland Data Layer data (CDL) as training samples." Science of The Total Environment (2020): 138869.<a href = "https://doi.org/10.1016/j.scitotenv.2020.138869">https://doi.org/10.1016/j.scitotenv.2020.138869</a></p>

                 */}

            </div>
            
            <button className='flexible-modal-clsBtn'
                onClick={()=>{this.setState({publicationVisible:false})}}
            >
                &times;
            </button>
        </ReactModal>

        <ReactModal initWidth={700} initHeight={600} 
            onFocus={() => {}}
            className={"my-modal-custom-class"}
            
            onRequestClose={()=>{this.setState({userguideVisible:false})}} 
            disableResize={'true'}
            isOpen={this.state.userguideVisible}> 
            <h5 className='flexible-modal-title'>User Guide</h5>
            <div className="body">
                <p style={{ textAlign: 'left', padding: '10px' }}>

                  <b>Left Menu Panel</b> contains eight function modules: (from top to bottom) catalog, layer manager, 
                  comparison, statistics (aoi-based), profile (point-based), document generator, data download, and animation. 

                </p>
                <p style={{ textAlign: 'left', padding: '10px' }}>

                  <b>Top-Central Tool Icon Bar</b> contains three button groups (from left to right): Select County/States, One-Click for 
                  All information panel, and AOI selector. The select country/states function provides a list of all
                   the counties in the CONUS and will draw the boundary once user selects one. The One-Click for All
                   button is used to instantly get all the information about one place, e.g., the entire California, 
                   at minimum action cost. The AOI selector allows users to draw points, circles, rectangles, and polygons
                   as AOI (Area of Interest).  

                </p>
                <p style={{ textAlign: 'left', padding: '10px' }}>

                  <b>Top-RIght Tool Icon Bar</b> contains three buttons: Get Pixel, Open Legend, and Background Layers. 
                  Get Pixel button could get the layer value by clicking on the map. It returns the value of the top layer.
                  The Open Legend button will close/open legend window. The legend changes along with the top layer.
                  The background layer button could show/hide some background layers, like states, ASD, county, rivers, lakes, 
                  global land cover, highway, freeway, etc. 

                </p>
                <p style={{ textAlign: 'left', padding: '10px' }}>

                  <b>Navigation Bar</b> will show information like the developers, sponsorship, version, news, publications, 
                  users guide and developer guide

                </p>
            </div>
            
            <button className='flexible-modal-clsBtn'
                onClick={()=>{this.setState({userguideVisible:false})}}
            >
                &times;
            </button>
        </ReactModal>

        {/* ====================================================== 
          // WS Irigation Scheduling  
          // Added by Gil Heo (July 25, 2023) 
          // ====================================================== */}
          <WSIrrigationSchedulingModal 
            visible={this.state.irrigationSchedulingVisible}
            //context={this.props.context}
            onClose={() => this.setState({ irrigationSchedulingVisible:false })}
          />
        {/* ====================================================== */}

        </div>
    );
  }
}

// ============================================================
// Added by Gil Heo (July 26, 2023)

//export default WSNavbar;
export default props => {
  
  const loginContext = useContext(LoginContext);
  const navigate = useNavigate();

  return (
  <WSConsumer>
    {context => <WSNavbar {...props} context={context} loginContext={loginContext} navigate={navigate}/>}
  </WSConsumer>
  );
};
// ============================================================
