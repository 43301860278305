import React, {useContext, useEffect, useState} from 'react';
import {Container, Grid, Drawer, makeStyles, Toolbar, Snackbar} from '@material-ui/core';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Alert from '@mui/material/Alert';

import {useNavigate, Link} from 'react-router-dom';

import Copyright from "../account/copyright";
import Message from "../account/feedback-message";

const MESSAGE_DURATION = 2000;
const successMessage = `You are all set! Thanks for signing up! Redirecting to the login page in ${MESSAGE_DURATION/1000} seconds.`;
const failMessage = "This user already exists, if you have signed up already, please sign in.";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

let redirectTimeout = null;

export function SignUpPage() {
  const classes = useStyles();

  const navigate = useNavigate();

  const [lastName, setLastName] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);

  const [messageOpen, setMessageOpen] = useState(false);
  const [success, setSuccess] = useState(true);
  const [message, setMessage] = useState(successMessage);

  // clear the timeout callback if user click other links before time comes
  useEffect(() => {
    return () => {
      //clearTimeout(redirectTimeout);
    }
  })

  // controled form submit function
  const handleSubmit = (e) => {
    const request = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      password: password,
      phoneNumber: phoneNumber
    };

    fetch(process.env.PUBLIC_URL + "/backend/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(request)
    }).then(response => {

      if (response.status === 200) {
        response.json()
       .then(data => {
          console.log( data );
          if ( data.status == "Success" ) {
            setSuccess(true);
            setMessageOpen(true);
            setMessage(successMessage);
            redirectTimeout = setTimeout(() => navigate("/signin"), MESSAGE_DURATION);
          }
          else {
            setSuccess(false);
            setMessageOpen(true);
            setMessage(failMessage);            
          }
        });
      } else {
        setSuccess(false);
        setMessageOpen(true);
        setMessage(failMessage);
      }
    });

    e.preventDefault();
  }

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Toolbar />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} method="POST" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
          <Grid item xs={12}>
              <TextField
                variant="filled"
                required
                fullWidth
                id="email"
                label="Username / Email"
                name="email"
                type="email"
                autoComplete="email"
                autoFocus                
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="filled"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="filled"
                fullWidth
                id="firstName"
                label="First Name"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="filled"
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="filled"
                fullWidth
                id="phoneNumber"
                label="Phone Number"
                name="phoneNumber"
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Grid>
            
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link to="/signin#signup" >
                Already have an account? Sign in here.
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
      
      <Message 
        open={messageOpen} 
        setOpen={setMessageOpen} 
        success={success}
        duration={MESSAGE_DURATION} 
        message={message}
      /> 

    </Container>
  );
}


export default SignUpPage;