import React, { Component } from "react";
import {DropdownList, DateTimePicker} from "react-widgets";
import Moment, { weekdays } from 'moment';
import momentLocalizer from 'react-widgets-moment';
import "react-widgets/dist/css/react-widgets.css";
import '../style/WSProductsBar.css'

import {stringToDate, dateHourToString} from '../functions/dateFunc'

import { WSConsumer } from '../context/WSContext'

Moment.locale('en')
momentLocalizer()


class WSProductsBarNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datatypes: [], 
      data: [{ id: 0, types: "flood",  periods: "monthly", years: "2015", dates: "Oct20"},
            {id: 1, types: "NDVI",  periods: "daily", years: "2016", dates: "Jun23"}],
      types: 
        //'NWM',
        //'GFS',
        //'ET',
        {
        'ET':'ET LDASOUT',      //GeoBrain
        'Soil Moisture': 'SM LDASOUT',        //GeoBrain
        //'Total Amount of Available Water':'AWC LDASOUT',
        'Available Water for Crop':'AWC LDASOUT',        //GeoBrain
        'ET Forecast': 'ET FORECAST',        //GeoBrain
        'Soil Moisture Forecast': 'SM FORECAST',        //GeoBrain
        'Global Forecast System (GFS)': 'GFS LDASIN',        //GeoBrain
        'National Water Model (NWM)': 'NWM LDASIN',        //GeoBrain
        'NWM Precipitation': 'PREP NWM',        //GeoBrain
        'GFS Precipitation': 'PREP GFS',        //GeoBrain
        'ET Machine Learning': 'ET RF',        //GeoBrain
        //'ET Machine Learning(cloud free)':'ET RF(cf)'
        'Soil Moisture Machine Learning': 'SM RF',        //GeoBrain
        //'Soil Moisture Machine Learning(cloud free)':'SM RF(cf)',
        'ET MODIS': 'ET MODIS',        //GeoBrain
        //GeoBrain - others   'Soil Color':'Soil Color',        //GeoBrain
        //GeoBrain - others   'Chemical Soil Properties': 'Chemical Soil Properties',        //GeoBrain
        //GeoBrain - others   'Physical Soil Properties': 'Physical Soil Properties',        //GeoBrain
        //GeoBrain - others   'Soil Land Use': 'Soil Land Use',        //GeoBrain
        //'Wilting Point',
        'SMAPTop 9km': 'SMAPTop 9km',
        'SMAPSub 9km': 'SMAPSub 9km',
        'SMAPTop 9km Anomaly': 'SMAPTop 9km-Anomaly',
        'SMAPSub 9km Anomaly': 'SMAPSub 9km-Anomaly',
        'SMAPTop 9km Categorical': 'SMAPTop 9km-Categorical',
        'SMAPSub 9km Categorical': 'SMAPSub 9km-Categorical',
        'SMAPTop 1km': 'SMAPTop 1km',
        //'SMAPSub 1km',
        'SMAPTop 1km Anomaly': 'SMAPTop 1km-Anomaly',
        //'SMAPSub 1km-Anomaly',
        'SMAPTop 1km Categorical': 'SMAPTop 1km-Categorical',
        //'SMAPSub 1km-Categorical',
        // 'SMAPSurface-Multi-year-Average',
        // 'SMAPRootzone-Multi-year-Average',
        'NDVI':'NDVI',
        'Vegetation Condition Index (VCI)':'VCI',
        'Mean Referenced VCI':'MVCI',
        'Cropland Data Layers':'CDL',
        // ============================================================
        // NDVI/VCI/Drought 16Days products from GADMFS portal
        // Added by Gil Heo (Feb 1, 2024)
        //'NDVI (16Days)':'GADMFS_NDVI16',
        //'VCI (16Days)':'GADMFS_VCI16',
        //'Drought (16Days)':'GADMFS_Drought16',
        'Drought':'Drought',
        // End of NDVI/VCI/Drought 16Days products from GADMFS portal
        // ============================================================
        'Niche Crops':'Niche_Crops'
      }
        // 'RVCI',
        // 'RMVCI',
      ,//'NWM','GFS'],
      periods: [
        'Daily', 
        'Weekly',
        '3Days',
        'Annual',
        // 'Biweekly',
      ],      
      periodsET: [
        'Daily', 
        'Hourly',
        // 'Biweekly',
      ],
      periodsCDL: [
        'Annual',
        'In-season'
      ],
      periodsDrought:[
        '16Days',
        'Weekly'
      ],
      inSeason: [
        //'inseason',
        '2024_07',
        '2024_06',
        '2024_05',
        '2024_pre',
        '2023_07',
        '2023_06',
        '2023_05',
        '2023_pre',
        '2022_07',
        '2022_06',
        '2022_05',
        '2022_pre'
      ],
      depth:['0-10','10-40','40-100','100-200'],
      depthAWC:['0-10','10-40','40-100','100-200', '0-100'],
      depthRF:['0-10', '10-50','50-100'],
      periods1km: ['AM', 'PM'],
      // time: ['AllTimeAverage','AllTimeMax', '013000','043000', '073000', 
      // '103000', '133000', '163000', '193000', '223000'],//for daily SMAP
      resolution: ['9km','1km'],
      //year: ['2015', '2016', '2017', '2018', '2019'], //for weekly
      method: ['Max', 'Average', '3hours'], // for weekly SMAP
      NWMlayers:['T2D','LWDOWN','Q2D','U2D','V2D','RAINRATE','SWDOWN','PSFC'],
      GFSlayers:['PRES_surface', 'TMP_surface','TMP_2maboveground','SPFH_2maboveground','UGRD_10maboveground',
      'VGRD_10maboveground','PRATE_surface','DSWRF_surface','DLWRF_surface'],
      GFS_LDASINlayers:[ 'Long-wave radiation flux',
      'Surface Pressure',
       'Humidity',
      'Precipitation',
      'Short-wave radiation flux', 
      'Temperature', 
      'U-component of wind', 
      'V-component of wind',],
      NWM_LDASINlayers:['Long-wave radiation flux',
      'Surface Pressure',
      'Humidity',
      'Precipitation',
      'Short-wave radiation flux', 
      'Temperature', 
      'U-component of wind', 
      'V-component of wind',],
      Soil_Colorlayers:['Soil Color (10cm)','Soil Color (25cm)', 'Soil Color (75cm)', 'Soil Color (125cm)'],
      Chemical_Soil_Propertieslayers:['Calcium Carbonate', 'Cation Exchange Capacity', 'Cation Exchange Cap. (0 - 5 cm)', 'Cation Exchange Cap. (0 - 25 cm)',
        'Cation Exchange Cap. (0 - 50 cm)', 'Electrical Conductivity', 'Electrical Conductivity (0 - 5 cm)', 'Electrical Conductivity (0 - 25 cm)',
        'pH', 'pH (0 - 5 cm)', 'pH (0 - 25 cm)', 'pH (25 - 50 cm)', 'Sodium Adsorption Ratio', 'Soil Organic Matter', 'Soil Organic Matter - Max'],
      Physical_Soil_Propertieslayers:['Avail. Water Holding Capacity', 'Avail. Water Holding Cap. (0 - 25 cm)', 'Avail. Water Holding Cap. (0 - 50 cm)',
        'Wilting Point',
        'Bulk Density', 'Drainage Class', 'Rock Fragments (0 - 25 cm)', 'Sat. Hyd. Conductivity (Ksat) - Mean', 'Sat. Hyd. Conductivity (Ksat) - Min',
        'Sat. Hyd. Conductivity (Ksat) - Max', 'Sat. Hyd. Conductivity (Ksat) (0 - 5 cm)', 'Sand', 'Silt', 'Clay', 'Clay (0 - 5 cm)', 'Clay (0 - 25 cm)', 
        'Clay (25 - 50 cm)', 'Soil Texture (0 - 5 cm)', 'Soil Texture (0 - 25 cm)', 'Soil Texture (25 - 50 cm)'],
        Soil_Land_Uselayers:['Depth to Restrictive Layer', 'Hydrologic Group', 'Kw Factor (0 - 25 cm)', 'Land Capability Class - Non-Irrigated',
          'Land Capability Class - Irrigated', 'Soil Depth', 'Soil Order', 'Soil Temperature Regime', 'Wind Erodibility Group', 'Wind Erodibility Index',
          'Survey Type'],
      yearState:'',
      nicheYear:['2023','2024'],
      nicheCropType:['Kernza ®','Sainfoin'],

    };

    this.soilDict = this.props.context.state.soilDict

    this.handleSubmit = this.handleSubmit.bind(this);

  }
  handleSubmit(event) {
    event.preventDefault();
    const {selOptions} = this.props.context.state
    let method = selOptions.selMethod
    const per = selOptions.selPeriod
    const type = this.state.types[selOptions.selType]
    let time = selOptions.selDate
    const week = selOptions.selWeek
    const variable = selOptions.selVar
    const timeUTC = selOptions.selTime
    const pTime = selOptions.selPTime
    const depth = selOptions.selDepth
    const id = selOptions.selId;

    if(type.includes('Soil')){
      this.props.context.updateContextState('showAnimationBar', false)
    }
    if(
      (type.slice(0,3)==='NWM' && (!variable || !timeUTC))
      ||(type.slice(0,3)==='GFS' && (!variable || !timeUTC || !pTime))
      ||(type.includes('PREP GFS') && ( !timeUTC || !pTime))
      ||(type.includes('ET LDASOUT') && (!timeUTC || !per))
      ||((type==='SM LDASOUT'||type === 'AWC LDASOUT') && (!timeUTC || !depth))
      ||(per === 'Daily' && !time && type !== 'ET LDASOUT')||(['Weekly', '3Days'].indexOf(per) !== -1 && !week)
      ||(type.includes('Soil') && !variable)
      //||(type.includes('Niche_Crops') && !id) 
    ){
      alert('Error: You must select all required parameters to add layer!')
      return; 
    }
    if(['SMAPTop 9km-Multi-year-Average',
        'SMAPSub 9km-Multi-year-Average',
        'SMAPTop 9km-Anomaly',
        'SMAPSub 9km-Anomaly',
        'SMAPTop 1km-Anomaly',
        'SMAPTop 1km-Categorical',
        'SMAPTop 9km-Categorical',
        'SMAPSub 9km-Categorical',].indexOf(type)!==-1){
          method = type.split('-').slice(1).join('-')
        }
        // else if([
        // 'SMAPTop-Categorical',
        // 'SMAPSub-Categorical',].indexOf(type)!==-1){
        //   method = 'Categorical-'+method
        // }
    let period=  method==='3hours'?['3hours']:[per,method]

    if(type === 'CDL'){period = [per, selOptions.selYear, selOptions.selInSeason]}
     
    if (type.slice(0,3)==='NWM'){
      period = [per, variable]
      time = timeUTC
    }
    else if (type.slice(0,3) === 'GFS'){
      period = [per, variable, pTime]
      time = timeUTC
    // }else if (type === 'ET'){
    //   period = []
    }
    else if (type.includes('PREP GFS')){
      period = [per, 'PREP GFS',pTime]
      time = timeUTC
    }
    else if (type.includes('PREP NWM')){
      period = [per, 'PREP NWM']
    }else if(type.includes('LDASOUT')||type.includes('FORECAST')){
      let depthD = {'0-10':0,'10-40':1,'40-100':2, '100-200':3, '0-100':4}
      period = [per, type.split(' ')[0], depthD[depth], pTime]
      time = timeUTC
    }else if(type.includes('RF')){
      let depthD = {'0-10':1,'10-50':2,'50-100':3, }
      period = [per, type.split(' ')[0], depthD[depth]]
    }
    // ============================================================
    // NDVI/VCI/Drought 16Days products from GADMFS portal
    // Added by Gil Heo (Feb 26, 2024)
    else if(type.startsWith('Drought')){
      period = [per, selOptions.selYear, selOptions.selDate];
    }
    // End of NDVI/VCI/Drought 16Days products from GADMFS portal
    // ============================================================
    else if(type.startsWith('Niche_Crops')){
      period = ['Year', selOptions.selYear];
    }

    let newLayer
    if (type.includes('Soil')){
      newLayer = this.props.context.defNewTMSLayer(type,this.soilDict[variable],variable)
    }else{
      newLayer = this.props.context.defNewLayer(period, type, time, week)
    }
    //const filePath = 'https://cloud.csiss.gmu.edu/smap_server/cgi-bin/mapserv'
    //newLayer = this.props.context.defNewLayer(period, type, time, week)
    if (newLayer){
      const layer = newLayer.layer
      this.props.context.state.olmap.addLayer(layer)
      this.props.openLayerBar()
      // this.props.context.clipLayer(layer)
      this.props.context.updateLegend( type.includes('Soil')?variable:type, layer.get('title'), layer.get('period'))
      this.props.context.updateLayerState()
    }

  }

  componentDidMount(){
    // const {selOptions,latestSMAPDay} = this.props.context.state
    // let newOptions = {...selOptions,selDate:stringToDate(latestSMAPDay)}
    //this.props.context.updateContextState('selOptions',newOptions);
  }

  render() {
    const { RenderField, state, handleSubmit } = this;
    const {selOptions} = this.props.context.state
   
    return (
      <div>
      <form >
        {RenderField(state,'Type')}
        {selOptions.selType && state.types[selOptions.selType].slice(0,3)==='NWM'?(
          <div>
            {RenderField(state, 'Variable')}
            {RenderField(state,'Time (Universal Time Coordinated)')}

          </div>
        ):(selOptions.selType && state.types[selOptions.selType].slice(0,3)==='GFS'?(
            <div>
              {RenderField(state, 'Variable')}
              {RenderField(state,'Time (Universal Time Coordinated)')}
              {RenderField(state,'Predict Time')}
            </div>
          ):(selOptions.selType && state.types[selOptions.selType].slice(0,3)==='CDL'?(
            <div>
              {RenderField(state,"Period")}

              {selOptions.selPeriod === 'Annual'? 
                <div>
                  {RenderField(state,'Year')}
                </div>
              :
                <div>
                  {RenderField(state,'InSeason', "In-season")}
                </div>
              }
            </div>
          ):(selOptions.selType && state.types[selOptions.selType].includes('Soil')?(
            <div>
              {RenderField(state, 'Variable')}
            </div>
          ):
            (selOptions.selType && state.types[selOptions.selType].includes('ET LDASOUT')?(
              <div>
                {RenderField(state,"Period")}
                {RenderField(state, 'Time (Universal Time Coordinated)')}
              </div>
            ):     
            (selOptions.selType && (state.types[selOptions.selType].includes('SM LDASOUT')||state.types[selOptions.selType]==='AWC LDASOUT')?(
              <div>
                {RenderField(state, 'Depth(cm)')}
                {RenderField(state, 'Time (Universal Time Coordinated)')}
              </div>
            ):
            (selOptions.selType && state.types[selOptions.selType].includes('FORECAST')?(
              <div>
                {selOptions.selType && state.types[selOptions.selType].includes('SM ')?(
                  <div>{RenderField(state, 'Depth(cm)')}</div>):null}
                
                {RenderField(state, 'Time (Universal Time Coordinated)')}
                {RenderField(state,'Predict Time')}
              </div>
            ):
            (selOptions.selType && state.types[selOptions.selType].includes('PREP GFS')?(
              <div>
                {RenderField(state, 'Time (Universal Time Coordinated)')}
                {RenderField(state,'Predict Time')}
              </div>
            ):
            // ============================================================
            // NDVI/VCI/Drought 16Days products from GADMFS portal
            // Added by Gil Heo (Feb 1, 2024)
            (selOptions.selType && state.types[selOptions.selType].includes('Drought')?(
              <div>
                {RenderField(state, 'Period')}
                {RenderField(state, 'Year')}
                {RenderField(state, 'Drought_Date', "Date")}
              </div>
            ):
            // End of NDVI/VCI/Drought 16Days products from GADMFS portal
            // ============================================================
            (selOptions.selType && state.types[selOptions.selType].includes('Niche_Crops')?(
              <div>
                {RenderField(state, 'Year', 'Year Data')}
                {/* 
                {RenderField(state, 'Niche_CropType', "Crop Type")}
                {RenderField(state, 'Niche_Id', "ID")}
                */}
              </div>
            ):
          (
            <div>
             
            <div value={selOptions.selPeriod}/>
            {selOptions.selType && state.types[selOptions.selType].slice(0,4)==='SMAP'?
              //['SMAPSurface','SMAPRootzone'].indexOf(selOptions.selType)!==-1?
              (
              <div>
                {/* {RenderField(state,"Resolution")} */}
           
                    {RenderField(state,"Period")}
              
              
                {selOptions.selType && ['9km'].indexOf(state.types[selOptions.selType].split(/\s+/)[1])!==-1? (
                <div>
                  {RenderField(state,'Method')}
                </div>)
                :
                null
              }
                {['Weekly', '3Days'].indexOf(selOptions.selPeriod)!==-1? (
                  <div>
                    {selOptions.selType && state.types[selOptions.selType].split(/\s+|-/)[2] === 'Multi'?null:
                    <div>
                      {RenderField(state,'Year')}
                    </div>
                  }
                    {RenderField(state, 'Week')}
                  </div>)
                  :
                  <div>
                    {RenderField(state,'Date')}
                  </div>
                }
                
             
               
              </div>
            ):
            (
              <div>
                {selOptions.selType && state.types[selOptions.selType].includes('PREP NWM')? null:
                (
                  <div>
                    {RenderField(state,"Period")}
                  </div>
                )}
                {['Weekly', 'Biweekly'].indexOf(selOptions.selPeriod)!==-1? (
                  <div>
                    {RenderField(state,'Year')}
                    {RenderField(state, 'Week')}
                  </div>)
                  :(
                    <div>
                      {selOptions.selType && state.types[selOptions.selType].includes('SM ')?
                        (<div>{RenderField(state, 'Depth(cm)')}</div>):null}
                      {RenderField(state,'Date')}
                    </div>)
                }
              </div>
            )
            }
            </div>
          )
          ))
        )))))))}
        {this.RenderTime(selOptions.selTime)}
        <button onClick={handleSubmit}            
          type="button" className="btn btn-danger btn-sm" 
          style={{ 'width': '100px', 'borderRadius': '0',outline:'none', marginTop: '5px'}} >Add</button> 
      </form>

      <br/>
      {this.RenderDesc(selOptions.selType, selOptions.selVar)}
      <br/>
      <small className='form-text text-light'>Note: You can also hover over the options of Type and Variable to see their descriptions.</small>
      </div>
    );
  }

  RenderDesc=(type, vari)=>{
    const {descriVar} = this.props.context.state
    return(
      <div style={{ padding:'0 4px'}}>
        {type === 'Soil Color'?(
        <small className='form-text text-light'>{type}:
          Data for this property were aggregated using:Dominant condition of each map unit & Dominant condition among map units in each grid cell<br/>
          Source Data: Moist colors from Official Series Descriptions.<br/>
          More information is available at the <a href="https://www.nrcs.usda.gov/wps/portal/nrcs/detail/soils/survey/geo/?cid=nrcseprd1423827">NRCS Soil Colors page</a>.
        </small>):
        (<small className='form-text text-light'>{type}: {descriVar[type]?descriVar[type]:type}</small>)}

      <br/>
      {vari?(<small className='form-text text-light'>{vari}: {descriVar[vari]?descriVar[vari]:vari}</small>
      ):null}
      </div>
    )
  }

  
  RenderTime=(time)=>{
    let cdt, mdt
    if(time){
      cdt = dateHourToString(new Date(time.getTime()-1000*60*60*5))
      mdt = dateHourToString(new Date(time.getTime()-1000*60*60*6))
    }
    return(
      <div>
      {time?
        (
        <div style={{ padding:'0 4px'}}>
          <small className='form-text text-light'>Mountain Daylight Time (MDT):{mdt}</small>
          <small className='form-text text-light'>Central Daylight Time (CDT):{cdt}</small>
        </div>)
        :null}
      </div>
    )
  }

  RenderField=(state, name, label = name, type = "text")=> {
      // const { selType,  selPeriod, selTime, selDate,} = this.props.selOptions;
      const labelStyle = {  marginBottom:'0', color:'white'};
      const {selOptions,SMAPYear, VIYear, SMAPWeekly, VIWeekly,latestNDVIDay, latestSMAPDay, 
        SMAPWeekly1km, SMAPWeekly1kmAnomaly, SMAPWeekly1kmCategory, SMAP3Days1km, latestSMAPDay1km, latestSMAPAnomalyDay,
        ETList, startNDVIDay, startSMAPDay, startSMAPAnomalyDay, startSMAPDay1km, descriVar, latestETDay,latestANATime,
        latestPREPNWMDay, latestGFSTime, latestNWMTime, latestFORECASTTime, latestPREPGFSTime, latestANAAWCTime,
        // ============================================================
        // NDVI/VCI/Drought 16Days products from GADMFS portal
        // Added by Gil Heo (Feb 1, 2024)
        GADMFSNDVI16days, GADMFSVCI16days, GADMFSDrought16days,
        // End of NDVI/VCI/Drought 16Days products from GADMFS portal
        // ============================================================
        NicheCrops
      } = this.props.context.state
      const cState =  this.props.context.state
      let ListItem = ({ item }) => (
        <span data-toggle = 'tooltip' title = {descriVar[item]}>{item}</span>
      );
     // const label = props.name;
       if (name === "Type") {
         return (
           <div className='comboBox' >
             <label style={labelStyle}>
               {label}  </label>
               <DropdownList
                 data={Object.keys(state.types)}
                 disabled={[        
                  //'SMAPTop 1km-Anomaly',
                  'SMAPSub 1km',
                  'SMAPSub 1km-Anomaly',
                  //'SMAPTop 1km-Categorical',
                  'SMAPSub 1km-Categorical',]}
                 value={
                   selOptions.selType 
                 }
                 itemComponent={ListItem}
                 //disabled={['NDVI','VCI','RVCI','RMVCI','MVCI']}
                 onChange={e=>{
                   let newOptions
                   let ec = state.types[e]
                   if(
                      (['RVCI','RMVCI', 'MVCI', 'VCI','NDVI', 'SMAPTop 9km-Multi-year-Average','SMAPSub 9km-Multi-year-Average',
                      'SMAPTop 1km-Categorical','SMAPTop 1km-Anomaly','SMAPTop 1km' ].indexOf(ec) !== -1)
                    ) {//default period is weekly
                      newOptions = {...selOptions,selType:e, selPeriod:'Weekly', selWeek:'',selYear:'2024'}
                    }else if([ 'ET MODIS' ].indexOf(ec) !== -1){
                      newOptions = {...selOptions,selType:e, selPeriod:'Weekly', selWeek:'', selYear:'2020'}
                    }else if([ 'SMAPTop 9km-Categorical', 'SMAPSub 9km-Categorical'].indexOf(ec) !== -1){
                      newOptions = {...selOptions,selType:e, selPeriod:'Weekly', selWeek:'', selYear:'2024'}
                    }else if(['SMAPTop 9km-Multi-year-Average','SMAPSub 9km-Multi-year-Average'].indexOf(ec) === -1 
                      && ['SMAPTop 9km-Multi-year-Average','SMAPSub 9km-Multi-year-Average'].indexOf(state.types[selOptions.selType]) !== -1){
                        newOptions = {...selOptions,selType:e, selWeek:''}
                    }else if([ 'NWM LDASIN', 'GFS LDASIN', 'PREP GFS', 'SM LDASOUT', 'Soil Color','Chemical Soil Properties', 'Physical Soil Properties',
                    'Soil Land Use','ET FORECAST', 'SM FORECAST', 'AWC LDASOUT'].indexOf(ec) !== -1){
                      newOptions = {...selOptions,selType:e, selVar:'',selPeriod:'Hourly', selTime:null, selPTime:null, selDepth:'0-10'}
                    }else if(['ET RF', 'PREP NWM','ET RF(cf)', 'SM RF', 'SM RF(cf)'].indexOf(ec) !== -1){
                      newOptions = {...selOptions,selType:e, selPeriod:'Daily', selDepth:'0-10'}
                    }else if(['ET LDASOUT'].indexOf(ec) !== -1){
                      newOptions = {...selOptions,selType:e, selPeriod:undefined}
                    }else if(['CDL'].indexOf(ec) !== -1){
                      newOptions = {...selOptions,selType:e, selPeriod:state.periodsCDL[0], selYear:'2023', selInSeason:state.inSeason[0]}
                    }
                  // ============================================================
                  // NDVI/VCI/Drought 16Days products from GADMFS portal
                  // Added by Gil Heo (Feb 1, 2024)
                    else if(['GADMFS_NDVI16', 'GADMFS_VCI16', 'GADMFS_Drought16', 'Drought' ].indexOf(ec) !== -1){
                      let lastYear, lastDoy;
                      let doy2str = (year, doy) => {
                        let date = new Date( parseInt(year), 0, 1 )
                        date.setDate( parseInt(doy) );
                        return date.toLocaleString('en-us',{month:'short', day:'numeric'} );
                      }                      
                      if ( ['GADMFS_NDVI16'].indexOf(ec) !== -1 && GADMFSNDVI16days != null ) {
                        lastYear = Object.keys( GADMFSNDVI16days ).sort().reverse()[0];
                        lastDoy = GADMFSNDVI16days[lastYear][0];
                      }
                      else if ( ['GADMFS_VCI16'].indexOf(ec) !== -1 && GADMFSVCI16days != null ) {
                        lastYear = Object.keys( GADMFSVCI16days ).sort().reverse()[0];
                        lastDoy = GADMFSVCI16days[lastYear][0];
                      }
                      else if ( ['GADMFS_Drought16'].indexOf(ec) !== -1 && GADMFSDrought16days != null ) {
                        lastYear = Object.keys( GADMFSDrought16days ).sort().reverse()[0];
                        lastDoy = GADMFSDrought16days[lastYear][0];
                      }
                      else if ( ['Drought'].indexOf(ec) !== -1 && GADMFSDrought16days != null ) {
                        lastYear = Object.keys( GADMFSDrought16days ).sort().reverse()[0];
                        lastDoy = GADMFSDrought16days[lastYear][0];
                      }
                      //newOptions = {...selOptions,selType:e, selPeriod:'Annual', selYear:lastYear, selDOY:doy2str(lastYear,lastDoy) };
                      //newOptions = {...selOptions,selType:e, selPeriod:'Annual', selYear:lastYear, selDOY:lastDoy };
                      newOptions = {...selOptions,selType:e, selPeriod:state.periodsDrought[0], selYear:lastYear, selDate:lastDoy };
                    }
                  // End of NDVI/VCI/Drought 16Days products from GADMFS portal
                  // ============================================================
                    else if (['Niche_Crops'].indexOf(ec) !== -1) {
                      //newOptions = {...selOptions,selType:e, selYear:'2023', selCropType:"Kernza ®", selId:null };
                      newOptions = {...selOptions,selType:e, selYear:'2023' };
                    } else {
                      newOptions = {...selOptions,selType:e, selWeek:'', selPeriod:'Daily'}
                    }
               
                  this.props.context.updateContextState('selOptions',newOptions);
                  //this.props.onSMAPChange(e==='SMAP');
                }}
                 //value={selType}
                 
               />
           
           </div>
         );
       }
       else if (name === "Variable") {
        
        return(
          <div className='comboBox' >
          <label style={labelStyle}>{label} </label>
            <DropdownList
              data={state[state.types[selOptions.selType].replace(/\s+/gi, '_')+'layers']}
              value={
                selOptions.selVar
              }
              itemComponent = {ListItem}
              onChange={e=>{
                //let defaultMethod = (e==='Weekly')
                const newOptions = {...selOptions, selVar:e,}
               this.props.context.updateContextState('selOptions', newOptions);
               //e==='weekly'?(this.props.onWeekChange(true)):(this.props.onWeekChange(false));
            }}
              
            />
         
        </div>
        )

       }
       
       else if (name === "Period") {
         let p = state.types[selOptions.selType]==='ET LDASOUT'?state.periodsET
                : state.types[selOptions.selType]==='CDL'?state.periodsCDL
                : state.types[selOptions.selType].startsWith( 'Drought' ) ? state.periodsDrought
                : state.periods;
         return (
           <div className='comboBox' >
             <label style={labelStyle}>
               {label} </label>
               <DropdownList
                 data={p}
                 value={
                   selOptions.selPeriod
                 }
                 disabled={['RVCI','RMVCI', 'MVCI', 'VCI', 'SMAPTop 9km-Categorical','SMAPTop 1km-Categorical',
                 'SMAPTop 1km-Anomaly','SMAPSub 9km-Categorical', 'ET MODIS'].indexOf(state.types[selOptions.selType]) !== -1 ?
                    ['Daily', '3Days', 'Annual']:
                  (['ET RF', 'PREP NWM', 'PREP GFS', 'ET RF(cf)', 'SM RF', 'SM RF(cf)'].indexOf(state.types[selOptions.selType]) !==-1 ?
                    ['Weekly', '3Days','Annual']:
                  (state.types[selOptions.selType]==='SMAPTop 1km'?
                    ['Annual']:
                  (state.types[selOptions.selType]==='CDL'?
                    []:
                  state.types[selOptions.selType].startsWith('Drought')?
                    ['Weekly']:
                  ['3Days','Annual'])))}
                 onChange={e=>{
                   //let defaultMethod = (e==='Weekly')
                   const newOptions = {...selOptions, selPeriod:e, selMethod:'Average'}
                  this.props.context.updateContextState('selOptions', newOptions);
                  //e==='weekly'?(this.props.onWeekChange(true)):(this.props.onWeekChange(false));
               }}
                 
               />
            
           </div>
         );
       }
       else if (name === "InSeason") {
        
        return(
          <div className='comboBox' >
          <label style={labelStyle}>{label} </label>
            <DropdownList
              data={state.inSeason}
              value={
                selOptions.selInSeason
              }
              itemComponent = {ListItem}
              onChange={e=>{
                const newOptions = {...selOptions, selInSeason:e,}
                this.props.context.updateContextState('selOptions', newOptions);
              }}
            />
        </div>
        )

       }
       else if (name === "Depth(cm)") {
         return (
           <div className='comboBox' >
             <label style={labelStyle}>
               {label}  </label>
               <DropdownList
                 data={state.types[selOptions.selType].includes('AWC')?state.depthAWC
                 :state.types[selOptions.selType].includes('RF')?state.depthRF:state.depth}
                 value={
                   selOptions.selDepth 
                 }
                 onChange={e=>{ 
                  const newOptions = {...selOptions, selDepth:e, }
                  this.props.context.updateContextState('selOptions', newOptions);
                 }
                }
                
               />
           
           </div>
         );
        } else if (name === "Resolution") {
          return (
            <div className='comboBox' >
              <label style={labelStyle}>
                {label}  </label>
                <DropdownList
                  data={state.resolution}
                  value={
                    selOptions.selResolution 
                  }
                  onChange={e=>{ 
                   const newOptions = {...selOptions, selResolution:e, 
                     selDate:e==='1km'?stringToDate('2019.03.15'):new Date(new Date().getTime()-3*24*60*60*1000), selPeriod:''}
                   this.props.context.updateContextState('selOptions', newOptions);
                  }
                 }
                 
                />
            
            </div>
          );
       } else if (name === "Date") {
        let maxDate = state.types[selOptions.selType] === 'NDVI'?
        stringToDate(latestNDVIDay)
        :
        state.types[selOptions.selType] === 'SMAPTop 1km'?
          stringToDate(latestSMAPDay1km)
          :
          selOptions.selType && state.types[selOptions.selType].split(' ')[1] === '9km-Anomaly'?
            stringToDate(latestSMAPAnomalyDay)
            :
            state.types[selOptions.selType].includes('RF')?
              //stringToDate('2020.12.31')
              //new Date(new Date().getTime()-1000*3600*24*4)
              stringToDate('2021.09.18')
              :   
              state.types[selOptions.selType] === 'PREP NWM'?
              stringToDate(latestPREPNWMDay)
                :
                stringToDate(latestSMAPDay)
        maxDate = new Date(maxDate.getTime()+1000*3600*23)
        let minDate =   state.types[selOptions.selType].includes('RF')?
          stringToDate('2019.01.01')
          :
          (state.types[selOptions.selType] === 'NDVI'?
          stringToDate(startNDVIDay)
          :
          state.types[selOptions.selType] === 'SMAPTop 1km'?
            stringToDate(startSMAPDay1km)
            :
            selOptions.selType && state.types[selOptions.selType].split(' ')[1] === '9km-Anomaly'?
              stringToDate(startSMAPAnomalyDay)
              :
              state.types[selOptions.selType] === 'PREP NWM'?
                stringToDate('2018.11.14')
                :
                stringToDate(startSMAPDay)
          )

          return (
           <div className='comboBox'>
             <label style={labelStyle}>
               {label}  </label>
               <DateTimePicker
                time={['SMAPTop 9km', 'SMAPSub 9km'].indexOf(state.types[selOptions.selType]) !== -1 && selOptions.selMethod==='3hours'}
                format={['SMAPTop 9km', 'SMAPSub 9km'].indexOf(state.types[selOptions.selType]) !== -1 &&selOptions.selMethod==='3hours'?'MM/DD/YYYY hh:mm A':'MM/DD/YYYY'}
                step={90}
                max={maxDate}
                min={minDate}
                defaultCurrentDate={selOptions.selDate?selOptions.selDate:maxDate}
                value={selOptions.selDate}
                onChange={e=> {
                  const newOptions = {...selOptions, selDate:e}
                  this.props.context.updateContextState('selOptions', newOptions);
                }}
                 
               />
           
           </div>
         );
        } else if (name === "Time (Universal Time Coordinated)") {
          //let maxDate = selOptions.selType === 'NDVI'?stringToDate(latestNDVIDay):stringToDate(latestSMAPDay)
          let maxDate = state.types[selOptions.selType] === 'NWM LDASIN'?latestNWMTime:
          state.types[selOptions.selType] === 'GFS LDASIN'?latestGFSTime:
          (selOptions.selPeriod === 'Daily' && state.types[selOptions.selType] === 'ET LDASOUT')?stringToDate(latestETDay):
          state.types[selOptions.selType] === 'AWC LDASOUT'?latestANAAWCTime:
          state.types[selOptions.selType].includes('LDASOUT')?latestANATime:
          state.types[selOptions.selType].includes('PREP GFS')?latestPREPGFSTime:
          state.types[selOptions.selType].includes('FORECAST')?latestFORECASTTime:
          new Date()
          let step = (state.types[selOptions.selType].includes('GFS') || state.types[selOptions.selType].includes('FORECAST'))?6:1
          let labelName = (state.types[selOptions.selType].includes('GFS')|| state.types[selOptions.selType].includes('FORECAST'))?'Initialize Time':label


          console.log( "maxDate:", maxDate );

          return (
           <div className='comboBox'>
             <label style={labelStyle}>
               {labelName}  </label>
               <DateTimePicker
                time={(selOptions.selPeriod === 'Daily' && state.types[selOptions.selType] === 'ET LDASOUT')||state.types[selOptions.selType].includes('FORECAST')?
                false:true}
                step={60*step}
                max={maxDate}
                format={selOptions.selPeriod === 'Daily'?'MM/DD/YYYY':'MM/DD/YYYY hh:mm A'}
                min={selOptions.selType && (state.types[selOptions.selType].includes('GFS')|| state.types[selOptions.selType].includes('FORECAST'))?
                new Date('2019-01-01T00:00:00'):new Date('2018-11-14T22:00:00')}
                value={selOptions.selTime}
                onChange={e=>    { 

                  console.log("===", e);

                    e.setMinutes(0)
                  if ((selOptions.selType && state.types[selOptions.selType].includes('GFS'))){
                    let h = e.getHours()
                    e.setHours(h-h%6)
                  }
                  if (state.types[selOptions.selType].includes('FORECAST')){
                    e.setHours(0)
                  }
                  const newOptions = {...selOptions, selTime:e, selPTime:undefined}
                this.props.context.updateContextState('selOptions', newOptions);
                }}
                 
               />
           
           </div>
         );
        } else if (name === "Predict Time") {
          //let maxDate = selOptions.selType === 'NDVI'?stringToDate(latestNDVIDay):stringToDate(latestSMAPDay)
          let dayNum = selOptions.selType && state.types[selOptions.selType].includes('PREP GFS')?4:5
          let maxDate = selOptions.selTime && new Date(selOptions.selTime.getTime()+1000*3600*24*dayNum)
          return (
           <div className='comboBox'>
             <label style={labelStyle}>
               {label}  </label>
               <DateTimePicker
                time={selOptions.selType && state.types[selOptions.selType].includes('PREP GFS')?false:true}
                step={60}
                max={maxDate}
                format={selOptions.selType && state.types[selOptions.selType].includes('PREP GFS')?'MM/DD/YYYY':'MM/DD/YYYY hh:mm A'}
                min={selOptions.selTime}
                currentDate={selOptions.selTime}
                disabled={!selOptions.selTime}
                value={selOptions.selPTime}
                onChange={e=> {
                  
                  console.log("===", e);

                  e.setMinutes(0)
                  const newOptions = {...selOptions, selPTime:e}
                this.props.context.updateContextState('selOptions', newOptions);
                }}
                 
               />
           
           </div>
         );
       } else if (name === "Year") {
         let yearList
         if (state.types[selOptions.selType] === 'CDL'){
           let date = new Date();
           yearList = Array(date.getFullYear()-1997).fill().map((d,i) => i +1997).reverse()
         }
        // ============================================================
        // NDVI/VCI/Drought 16Days products from GADMFS portal
        // Added by Gil Heo (Feb 1, 2024)
        // else if (state.types[selOptions.selType].includes( 'GADMFS_') ){
        //   if ( state.types[selOptions.selType] == "GADMFS_NDVI16" ) {
        //     yearList = (GADMFSNDVI16days != null ) ? Object.keys( GADMFSNDVI16days ).sort().reverse() : [];
        //   } else if ( state.types[selOptions.selType] == "GADMFS_VCI16" ) {
        //     yearList = (GADMFSVCI16days != null ) ? Object.keys( GADMFSVCI16days ).sort().reverse() : [];
        //   } else if ( state.types[selOptions.selType] == "GADMFS_Drought16" ) {
        //     yearList = (GADMFSDrought16days != null ) ? Object.keys( GADMFSDrought16days ).sort().reverse() : [];
        //   }
        // }
        else if ( state.types[selOptions.selType].startsWith('Drought') && selOptions.selPeriod=='16Days' ) {
            yearList = (GADMFSDrought16days != null ) ? Object.keys( GADMFSDrought16days ).sort().reverse() : [];
        }        
        // End of NDVI/VCI/Drought 16Days products from GADMFS portal
        // ============================================================
        else if (state.types[selOptions.selType].includes( 'Niche_Crops') ){
          if ( NicheCrops != null )  {
            let yList = [];
            for( let el in NicheCrops ) { 
              let year = el.split('_')[0];
              if ( yList.indexOf(year) < 0 ) yList.push(year);
            };
            yearList = yList.sort();
          }
          else
            yearList = this.nicheYear;
        }        
        else{
          if (state.types[selOptions.selType] === 'ET MODIS'){
            let yList = ETList
            yList = yList.map(a=>a[3])
            yearList = [...new Set(yList)]
          }else{
            let fileIndex = state.types[selOptions.selType].replace(/Top |Sub |\-/gi, '')+'Weekly'
            if(selOptions.selPeriod === '3Days'){
              let yList = SMAP3Days1km.map(a=>(a[1].split(/\./)[0]))
              yearList = [...new Set(yList)]
            }else{
              let yList = cState[fileIndex]
              //let d = {'SMAPTop':'SMAPSurface', 'SMAPSub':'SMAPRootzone', 'NDVI':'NDVI', 'VCI':'VCI','MVCI':'MVCI'}
              //yearList = yearList.filter(a=>(a[1]===selOptions.selPeriod && a[2]===d[selOptions.selType.split(/\s+|-/)[0]])).map(a=>a[0])
              yList = yList.map(a=>a[1])
              yearList = [...new Set(yList)]
            }
          }
        }
        return (
          <div className='comboBox' >
            <label style={labelStyle}>
              {label} </label>
              <DropdownList
                data={yearList}
                value={
                  selOptions.selYear
                }
                // disabled={[ 'SMAPSurface-Categorical',
                // 'SMAPRootzone-Categorical'].indexOf(selOptions.selType) !== -1 ?
                //  ["2020"]:[]}
                onChange={e=>{
                  const newOptions = {...selOptions, selYear:e, selWeek:'', selDate:''}
                  this.props.context.updateContextState('selOptions', newOptions);
                  this.setState({yearState:e});
              }}
               
              />
           
          </div>
        );
      } else if (name === "Week") {
        let weekData
        let fileIndex = state.types[selOptions.selType].replace(/Top |Sub |\-/gi, '')+'Weekly'
        let multiYearWeeks = [...Array(53).keys()].map(a=>(Array(2).join('0') + a).slice(-2)).slice(1)
        let wData
        if(selOptions.selPeriod === '3Days'){//SMAP-HYB-1KM-3DAYS_2020.03.19_2020.03.21_PM.map
          let weekListCut = SMAP3Days1km.filter(a=>(a[1].split(/\./)[0] === selOptions.selYear)).map(a=>(a[1]+'_'+a[2]))
          weekData = [...new Set(weekListCut)]
          weekData.sort((a,b)=>b.split(/\./)[1]*50+b.split(/\./)[2]-a.split(/\./)[1]*50-a.split(/\./)[2])
          wData = weekData
        }
        else if(state.types[selOptions.selType].includes('Multi')){
          wData=multiYearWeeks
        }else if(state.types[selOptions.selType] === 'ET MODIS'){
          let weekListCut = ETList.filter(a=>(a[3] === selOptions.selYear)).map(a=>a.slice(3,6).join('.'))
          weekData = [...new Set(weekListCut)]
          weekData.sort((a,b)=>b.split(/\./)[1]*50+b.split(/\./)[2]-a.split(/\./)[1]*50-a.split(/\./)[2])
          wData = weekData
        }
        else{
          //SMAP-HYB-1KM-WEEKLY_2015_44_2015.10.26_2015.10.31_PM.map
            let weekListCut = cState[fileIndex].filter(a=>(a[1] === selOptions.selYear)).map(a=>(a[2]+'_'+a[3]+'_'+a[4].replace('.map', '')))
            weekData = [...new Set(weekListCut)]
            weekData.sort((a,b)=>b.split(/\_/)[0]-a.split(/\_/)[0])
            wData = weekData
        }
        return (
          <div className='comboBox' >
            <label style={labelStyle}>
              {selOptions.selPeriod === '3Days'?'3Days':label}   </label>
              <DropdownList
                data={wData}
                value={
                  selOptions.selWeek 
                }
                onChange={e=>{const newOptions = {...selOptions, selWeek:e}
                this.props.context.updateContextState('selOptions', newOptions);}}
               
              />
          
          </div>
        );
      } else if (name === "Method") {
        let disableList = selOptions.selPeriod==='Daily'?['Multi-year Average']:['3hours','Category']
        return (
          <div className='comboBox' >
            <label style={labelStyle}>
              {label}    </label>
              <DropdownList
                data={state.method}
                value={
                  selOptions.selMethod 
                }
                disabled={disableList}
                onChange={e=>{const newOptions = {...selOptions, selMethod:e, selWeek:''}
                this.props.context.updateContextState('selOptions', newOptions);}}
               
              />
        
          </div>
        );
      }
      // ============================================================
      // NDVI/VCI/Drought 16Days products from GADMFS portal
      // Added by Gil Heo (Feb 1, 2024)
      else if (name === "Drought_Date") {
        let dateList = [];
        let doy2str = (year, doy) => {
          if ( doy == '' || doy == null ) return '';
          let date = new Date( parseInt(year), 0, 1 )
          date.setDate( parseInt(doy) );
          return date.toLocaleString('en-us',{month:'short', day:'numeric'} );
        }
        if ( state.types[selOptions.selType] == "GADMFS_NDVI16" ) {
          dateList = (GADMFSNDVI16days != null) ? GADMFSNDVI16days[selOptions.selYear].map(i=>({date:i, label:doy2str(selOptions.selYear, i)})) : [];
        } else if ( state.types[selOptions.selType] == "GADMFS_VCI16" ) {
          dateList = (GADMFSVCI16days != null ) ? GADMFSVCI16days[selOptions.selYear].map(i=>({date:i, label:doy2str(selOptions.selYear, i)})) : [];
        } else if ( state.types[selOptions.selType] == "GADMFS_Drought16" ) {
          dateList = (GADMFSDrought16days != null ) ? GADMFSDrought16days[selOptions.selYear].map(i=>({date:i, label:doy2str(selOptions.selYear, i)})) : [];
        } else if ( selOptions.selPeriod == "16Days" ) {
          dateList = (GADMFSDrought16days != null ) ? GADMFSDrought16days[selOptions.selYear].map(i=>({date:i, label:doy2str(selOptions.selYear, i)})) : [];
        }        
      return (
          <div className='comboBox' >
            <label style={labelStyle}>
              {label}    </label>
              <DropdownList
                data={dateList}
                dataKey="date"
                textField="label"
                value={doy2str(selOptions.selYear, selOptions.selDate)}
                // defaultValue={
                //   doy2str(selOptions.selYear, selOptions.selDate)
                // }
                //disabled={disableList}
                onChange={e=>{
                  const newOptions = {...selOptions, selDate:e.date};
                  this.props.context.updateContextState('selOptions', newOptions);
                }}
              />
        
          </div>
        );
      }
      // End of NDVI/VCI/Drought 16Days products from GADMFS portal
      // ============================================================

      // else if (name === "Niche_CropType") {
      //   let cropList = [];
      //   if ( NicheCrops != null )  {
      //     let cList = [];
      //     for( let el in NicheCrops ) { 
      //       let crop = NicheCrops[el].features[0].properties.Crop;
      //       if ( cList.indexOf(crop) < 0 ) cList.push(crop);
      //     };
      //     cropList = cList.sort();
      //   }
      //   else
      //     cropList = this.nicheCropType;

      //   return (
      //     <div className='comboBox' >
      //       <label style={labelStyle}>
      //         {label}    </label>
      //         <DropdownList
      //           data={cropList}
      //           //dataKey="doy"
      //           //textField="label"
      //           // defaultValue={
      //           //   doy2str(selOptions.selYear, selOptions.selDOY)
      //           // }
      //           //disabled={disableList}
      //           value={selOptions.selCropType}
      //           onChange={e=>{
      //             const newOptions = {...selOptions, selCropType:e, selId:null};
      //             this.props.context.updateContextState('selOptions', newOptions);
      //           }}
      //         />
        
      //     </div>
      //   );
      // }
      // else if (name === "Niche_Id") {
      //   let idList = [];
      //   if ( NicheCrops != null )  {
      //     for( let el in NicheCrops ) { 
      //       let year = el.split('_')[0];
      //       if ( year !== selOptions.selYear )
      //         continue;
      //       let crop = NicheCrops[el].features[0].properties.Crop;
      //       if ( crop !== selOptions.selCropType )
      //         continue;
      //       NicheCrops[el].features.forEach( item => {
      //         idList.push( item.properties.fid );
      //       });
      //     };
      //   }

      //   return (
      //     <div className='comboBox' >
      //       <label style={labelStyle}>
      //         {label}    </label>
      //         <DropdownList
      //           data={idList}
      //           // dataKey="doy"
      //           // textField="label"
      //           // defaultValue={
      //           //   doy2str(selOptions.selYear, selOptions.selDOY)
      //           // }
      //           //disabled={disableList}
      //           value={selOptions.selId}
      //           onChange={e=>{
      //             const newOptions = {...selOptions, selId:e};
      //             this.props.context.updateContextState('selOptions', newOptions);
      //           }}
      //         />
        
      //     </div>
      //   );
      // }
      else return null;
     }
}

export default props => (
  <WSConsumer>
    {context => <WSProductsBarNew {...props} context={context} />}
  </WSConsumer>
)
// const ProductsForm = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ConnectedProductsForm);
// export default ProductsForm;
