import React, { useContext, useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Dialog, Snackbar, Toolbar } from '@material-ui/core';

import {useNavigate} from "react-router-dom";

import LoginContext from "../account/login-context";
import Copyright from "../account/copyright";
import Message from "../account/feedback-message";

const MESSAGE_DURATION = 2000;
const successMessage = `You are logged in! Closing dialog in ${MESSAGE_DURATION/1000} seconds`;
const failMessage = "Sorry, your credentials are incorrect.";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

// timeout
let redirectTimeout = null;

export default function ResetPasswordPage(props) {
  const classes = useStyles();

  const loginContext = useContext(LoginContext);

  const navigate = useNavigate();

  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordConfirmed, setPasswordConfirmed] = useState(null);

  const [messageOpen, setMessageOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState(successMessage);
  const [token, setToken] = useState()


  useEffect(()=>{
    return ()=> setToken(window.location.href.split("=")[1])
  })

  // callback function for submitting login credentials
  const handleSendResetPasswordLink = async (e) => {
    e.preventDefault();
      var resetPasswordURL = encodeURI(process.env.PUBLIC_URL + "/backend/reset_password")
      var requestBody = {
        token: token,
        password: password
      }
      if(password === passwordConfirmed){
        fetch(resetPasswordURL,
          {
              method: "POST",
              headers: {
                  "Accept": "application/json; charset=UTF-8",
                  "Content-Type": "application/json"
              },
              body:JSON.stringify(requestBody)
          })
          .then(res => res.json())
          .then(data => {
            console.log(data)
            if (data.status === "Success") {
              setMessage(data.message);
              setMessageOpen(true);
              setSuccess(true);

              redirectTimeout = setTimeout(() => navigate('/'), MESSAGE_DURATION);
            }
            else{
              setMessage(data.message + " The token may not be valid.")
              setMessageOpen(true)
              setSuccess(false)
            }
          })
      }else{
        setMessage("The passwords don't match.")
        setMessageOpen(true)
        setSuccess(false)
      }
       
  }

  return (
    // <Dialog open={props.open} onClose={props.handleClose} maxWidth='sm' >
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Toolbar />
      <Grid className={classes.paper}>
        <Avatar className={classes.avatar} >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset password
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSendResetPasswordLink}>
          <TextField
            type="password"
            variant="filled"
            margin="normal"
            required
            fullWidth
            id="new_password"
            label="Enter New Password"
            name="new_password"
            autoFocus
            onChange={(e) => setPassword(e.target.value) }
          />
          <TextField
            type="password"
            variant="filled"
            margin="normal"
            required
            fullWidth
            id="new_password_confirmed"
            label="Confirm New Password"
            name="new_password_confirmed"
            onChange={(e) => setPasswordConfirmed(e.target.value) }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Change Password
          </Button>
        </form>
      </Grid>
      <Box mt={4}>
        <Copyright />
      </Box>

      <Message 
        open={messageOpen} 
        setOpen={setMessageOpen} 
        success={success} 
        message={message}
      />
        
    </Container>
  );
}
