
import React, { useState, useEffect } from 'react'

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const boxStyle = {display:"flex", marginLeft:30};
const labelStyle = {fontWeight:"bold", marginTop:15 };


// ===================================================
let PromptDialog = (props) => {

    const [ modalOpen, setModalOpen ] = useState(false);
    const [ title, setTitle ] = useState('Prompt');
    const [ promptMessage, setPromptMessage ] = useState('');
    const [ okButtonText, setOkButtonText ] = useState('OK');
    const [ cancelButtonText, setCancelButtonText ] = useState('Cancel');
    const [ inputContent, setInputContent ] = useState('');
    const [ placeholder, setPlaceholder ] = useState('');

    // -------------------------------------
    useEffect( () => {
        
        if ( props.open ) {
          setModalOpen(true);

          if ( props.title !== undefined )
            setTitle( props.title );
          if ( props.promptMessage !== undefined )
            setPromptMessage( props.promptMessage + ":" );
          if ( props.initValue !== undefined )
            setInputContent( props.initValue );
          if ( props.cancelButtonText !== undefined )
            setCancelButtonText( props.cancelButtonText );
          if ( props.okButtonText !== undefined )
            setOkButtonText( props.okButtonText );
          if ( props.value !== undefined )
            setInputContent( props.value );
          if ( props.placeholder !== undefined )
            setPlaceholder( props.placeholder );
          }

    }, [props]);


    // -------------------------------------
    return (<div>


      <Modal 
        //onFocus={() => {console.log("Modal clicked")}}
        className={"my-modal-custom-class"}
        open={modalOpen}
        onKeyDown={(event) => {
          if (event.key === 'Escape') {
            setModalOpen(false);
            props.onCancel && props.onCancel(); 
          }          
        }}
      >
      <Box style={{
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 550,
        initHeight: 200,
        backgroundColor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,                  
      }}>
        <h5 className='flexible-modal-title'>{title}</h5>
        <div className="body">
          <div style={boxStyle}>
            <label style={{...labelStyle}}>{promptMessage}</label>
          </div>
          <div style={boxStyle}>
            <TextField
                //className={'inputClass'}
                style={{width:490}}
                placeholder={placeholder}
                value={inputContent}
                autoFocus
                onChange={event=>{
                  setInputContent( event.target.value );
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    if ( props.checkValidity && !props.checkValidity(inputContent) )
                      return;
                    setModalOpen(false);
                    props.onOk && props.onOk(inputContent);                       
                    event.preventDefault();
                  }
                  else if (event.key === 'Escape') {
                    setModalOpen(false);
                    props.onCancel && props.onCancel(); 
                  }
                }}
            />
          </div>

          <div style={{marginTop:20, marginRight:30, marginBottom:20, float:"right"}}>
            <Button 
              variant="outlined"
              style={{textTransform:'none', marginRight:20}}
              onClick={()=>{
                setModalOpen(false);
                props.onCancel && props.onCancel();                
              }}
            >{cancelButtonText}
            </Button>
            <Button
              variant="contained"
              color="primary"            
              style={{textTransform:'none'}}
              onClick={()=>{
                if ( props.checkValidity && !props.checkValidity(inputContent) )
                  return;

                setModalOpen(false);
                props.onOk && props.onOk(inputContent);   
              }}
            >{okButtonText}
            </Button> 
          </div>
        </div>
        <button
          className='flexible-modal-clsBtn' 
          data-dismiss="modal" 
          onClick={() => {
            setModalOpen(false);
            props.onCancel && props.onCancel();                
          }}
        >
          &times;
        </button>
      </Box>
    </Modal> 


{/*
      <Dialog
          open={dialogOpen}
          maxWidth={(thirdButtonText)?'md':'sm'}
          onClose={()=>{
            setDialogOpen(false);
            if ( props.onClose !== undefined )
                props.onClose();
        }}
        //PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          { confirmDialog && thirdButtonText && (
          <Button
            autoFocus
            variant="contained"
            color="primary"
            style={{textTransform:'none'}}
            onClick={()=>{
              setDialogOpen(false);
              props.onThirdClicked && props.onThirdClicked();
            }}
          >{thirdButtonText}</Button>)}

          { confirmDialog && (
          <Button
            autoFocus
            variant="contained"
            color="primary"
            style={{textTransform:'none'}}
            onClick={()=>{
              setDialogOpen(false);
              props.onYes && props.onYes();
            }}
          >{yesButtonText}</Button>)}

          <Button
            autoFocus
            variant="outlined"
            style={{textTransform:'none'}}
            onClick={()=>{
              setDialogOpen(false);
              if ( props.onClose !== undefined )
                  props.onClose();
            }}
          >{closeButtonText}</Button>

        </DialogActions>
      </Dialog>
*/} 

  </div>     
    );
}


export default PromptDialog;
