import React, { useState, useEffect } from 'react'
import { Chart } from 'react-google-charts'

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';

import InfoDialog from '../util/InfoDialog';

const options = {
  chart: {
    title: "Average Temperatures and Daylight in Iceland Throughout the Year",
  },
  width: 900,
  height: 500,
  series: {
    // Gives each series an axis name that matches the Y-axis below.
    0: { axis: "Temps" },
    1: { axis: "Daylight" },
  },
  axes: {
    // Adds labels to each axis; they don't have to match the axis names.
    y: {
      Temps: { label: "Temps (Celsius)" },
      Daylight: { label: "Daylight" },
    },
  },
};


// ==============================================================
const WSCvrStatChart = (props) => {

  const [openChart, setOpenChart] = useState(false);
  const [cropType, setCropType] = useState('');
  const [lineData, setLineData] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [candlestickData, setCandlestickData] = useState([]);


  // -----------------------------------------------
  useEffect( () => {
  
    //console.log( "UserDetails:", props );

    setOpenChart( props.open );
    if ( props.open == true )
      initData();

  }, [props.open]);


  // ---------------------------------------------------------
  let initData = () => {
    setCropType( props.data[1][0] );

    let row = props.data[1];
    setLineData([
      [ "Percentile", row[0], "mean" ],
      [ "0", row[3], row[2] ],
      [ "25", row[4], row[2] ],
      [ "50", row[5], row[2] ],
      [ "75", row[6], row[2] ],
      [ "100", row[7], row[2] ]
    ]);

    setPieData([
      [ "Range", "Ratio" ],
      [ "min - 25", row[4]-row[3]+0.0 ],
      [ "25 - 50", row[5]-row[4]+0.0 ],
      [ "50 - 75", row[6]-row[5]+0.0 ],
      [ "75 - max", row[7]-row[6]+0.0 ]
    ]);

    console.log( row );

    setCandlestickData([
      ["Crop Type", "", "", "", ""],
      [ row[0], row[3], row[4], row[6], row[7] ]
    ]);
  };


  // ---------------------------------------------------------
  const boxStyle = {display:"flex", width:900, padding:'0.4em', margin: 'auto'};
  const labelStyle = {textAlign:'right', width:230, marginRight:'10px', fontWeight:"bold", paddingTop:7};
  const inputStyle = {width:140, paddingLeft:'.857em'};
  const inputClass = 'rw-widget-picker rw-widget rw-input, rw-widget-container';

  return (

    <div>

      <Modal
        onClose={(event, reason) => {
          setOpenChart(false);
          props.onClose && props.onClose(reason === "escapeKeyDown");
        }} 
        open={openChart}
        style={{zIndex:11000}}
      >
        <Box
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 930,
            //initHeight: 700,
            backgroundColor: 'white',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
          }}
        >
          <h5 className='flexible-modal-title' style={{paddingLeft:30}}>Statistics per CropType : {cropType}</h5>
          <div className="body" style={{float:"left", paddingLeft:50, width:900, maxHeight:650, overflow:"hidden", overflowY:"auto" }}>
                            
          <div style={{...boxStyle, paddingTop:10}}>
            <Chart
                    // width={'500'}
                    // height={'500'}
                    chartType="Table"
                    loader={<div>Loading Chart</div>}
                    data={props.data}
                    
                    // onResize={()=> console.log("Modal is resized")}
                    options={{
                        showRowNumber: false,
                        width: '95%',
                        // height: '95%' 
                        // chartArea: {width:'500',height:'500'}
                        cssClassNames:{tableCell:'table-cell'}
                    }}
                    rootProps={{'data-tested':'1'}}
                    style={{textTransform:'capitalize'}}
                />
            </div>

            <div style={{...boxStyle, paddingTop:50}}>
              <div style={{float:'left'}}>
              <Chart
                chartType="Line"
                width={400}
                height={370}
                data={lineData}
                options={{
                  chart: {
                    title: "Value-Percentile Graph",
                    legend: "none"
                  }
                }}
              />
              </div>
              
              {false && <div style={{float:'left', marginLeft:50}}>
              <Chart
                chartType="PieChart"
                width={400}
                height={400}
                data={pieData}
                options={{
                  //legend: "none",
                  //pieSliceText: "label",
                  title: "Percentile Increase Ratio",
                  subtitle: "Percentage",
                }}
              />
              </div>}

              {true && <div style={{float:'left',  marginLeft:50 }}>
              <Chart
                chartType="CandlestickChart"
                width={400}
                height={400}
                data={candlestickData}
                options={{
                  legend: "none",
                  title: "Percentile Increase Ratio",
                  subtitle: "Percentage",
                  vAxis:{title:'Value'}
                }}
              />
              </div>}

            </div>

            <div style={{...boxStyle, width:'auto', marginTop:10, marginRight:20, marginBottom:15, float:"right"}}>

              <button
                onClick={()=>{
                  setOpenChart(false);
                  props.onClose && props.onClose();
                }}
                type="button"
                className="btn btn-secondary btn-sm" 
                style={{ width:110, marginLeft:20, borderRadius:0, outline:'none', marginTop:5 }}
              >Close
              </button> 
            </div>

          </div>
          <button
              className='flexible-modal-clsBtn' 
              data-dismiss="modal"
              onClick={ () => {
                setOpenChart(false);
                props.onClose && props.onClose();
              }}
          >&times;
          </button>

        </Box>
      </Modal> 


    </div>

  );
};

export default WSCvrStatChart;
