import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.jsx";
import * as serviceWorker from "./serviceWorker";

import TagManager from 'react-gtm-module';
 
const tagManagerArgs = {
    gtmId: 'G-TQQ89DLSYZ'
};
TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.unregister();
