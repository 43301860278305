export const FipsCodes = [
    { "code":"01", "state":"Alabama", "abbr":"AL", "area":135767},
    { "code":"02", "state":"Alaska", "abbr":"AK", "area":1723337 },
    { "code":"04", "state":"Arizona", "abbr":"AZ", "area":295234 },
    { "code":"05", "state":"Arkansas", "abbr":"AR", "area":137732 },
    { "code":"06", "state":"California", "abbr":"CA", "area":423967	},
    { "code":"08", "state":"Colorado", "abbr":"CO", "area":269601 },
    { "code":"09", "state":"Connecticut", "abbr":"CT", "area":14357 },
    { "code":"10", "state":"Delaware", "abbr":"DE", "area":6446 },
    { "code":"11", "state":"District of Columbia", "abbr":"DC", "area":177 },
    { "code":"12", "state":"Florida", "abbr":"FL", "area":170312 },
    { "code":"13", "state":"Georgia", "abbr":"GA", "area":153910 },
    { "code":"15", "state":"Hawaii", "abbr":"HI", "area":28313 },
    { "code":"16", "state":"Idaho", "abbr":"ID", "area":216443 },
    { "code":"17", "state":"Illinois", "abbr":"IL", "area":149995 },
    { "code":"18", "state":"Indiana", "abbr":"IN", "area":94326 },
    { "code":"19", "state":"Iowa", "abbr":"IA", "area":145746 },
    { "code":"20", "state":"Kansas", "abbr":"KS", "area":213100 },
    { "code":"21", "state":"Kentucky", "abbr":"KY", "area":104656	},
    { "code":"22", "state":"Louisiana", "abbr":"LA", "area":135659 },
    { "code":"23", "state":"Maine", "abbr":"ME", "area":91633 },
    { "code":"24", "state":"Maryland", "abbr":"MD", "area":32131 },
    { "code":"25", "state":"Massachusetts", "abbr":"MA", "area":27336 },
    { "code":"26", "state":"Michigan", "abbr":"MI", "area":250487 },
    { "code":"27", "state":"Minnesota", "abbr":"MN", "area":225163 },
    { "code":"28", "state":"Mississippi", "abbr":"MS", "area":125438 },
    { "code":"29", "state":"Missouri", "abbr":"MO", "area":180540 },
    { "code":"30", "state":"Montana", "abbr":"MT", "area":380831 },
    { "code":"31", "state":"Nebraska", "abbr":"NE", "area":200330 },
    { "code":"32", "state":"Nevada", "abbr":"NV", "area":286380 },
    { "code":"33", "state":"New Hampshire", "abbr":"NH", "area":24214 },
    { "code":"34", "state":"New Jersey", "abbr":"NJ", "area":22591 },
    { "code":"35", "state":"New Mexico", "abbr":"NM", "area":314917 },
    { "code":"36", "state":"New York", "abbr":"NY", "area":141297 },
    { "code":"37", "state":"North Carolina", "abbr":"NC", "area":139391 },
    { "code":"38", "state":"North Dakota", "abbr":"ND", "area":183108 },
    { "code":"39", "state":"Ohio", "abbr":"OH", "area":116098 },
    { "code":"40", "state":"Oklahoma", "abbr":"OK", "area":181037 },
    { "code":"41", "state":"Oregon", "abbr":"OR", "area":254799 },
    { "code":"42", "state":"Pennsylvania", "abbr":"PA", "area":119280 },
    { "code":"44", "state":"Rhode Island", "abbr":"RI", "area":4001 },
    { "code":"45", "state":"South Carolina", "abbr":"SC", "area":82933 },
    { "code":"46", "state":"South Dakota", "abbr":"SD", "area":199729 },
    { "code":"47", "state":"Tennessee", "abbr":"TN", "area":109153 },
    { "code":"48", "state":"Texas", "abbr":"TX", "area":695662 },
    { "code":"49", "state":"Utah", "abbr":"UT", "area":219882 },
    { "code":"50", "state":"Vermont", "abbr":"VT", "area":24906 },
    { "code":"51", "state":"Virginia", "abbr":"VA", "area":110787 },
    { "code":"53", "state":"Washington", "abbr":"WA", "area":184661 },
    { "code":"54", "state":"West Virginia", "abbr":"WV", "area":62756 },
    { "code":"55", "state":"Wisconsin", "abbr":"WI", "area":169635 },
    { "code":"56", "state":"Wyoming", "abbr":"WY", "area":253335 }
  ];

export const FipsCountyCodes = [
  {"code":"01000", "name":"Alabama"},
  {"code":"01001", "name":"Autauga County"},
  {"code":"01003", "name":"Baldwin County"},
  {"code":"01005", "name":"Barbour County"},
  {"code":"01007", "name":"Bibb County"},
  {"code":"01009", "name":"Blount County"},
  {"code":"01011", "name":"Bullock County"},
  {"code":"01013", "name":"Butler County"},
  {"code":"01015", "name":"Calhoun County"},
  {"code":"01017", "name":"Chambers County"},
  {"code":"01019", "name":"Cherokee County"},
  {"code":"01021", "name":"Chilton County"},
  {"code":"01023", "name":"Choctaw County"},
  {"code":"01025", "name":"Clarke County"},
  {"code":"01027", "name":"Clay County"},
  {"code":"01029", "name":"Cleburne County"},
  {"code":"01031", "name":"Coffee County"},
  {"code":"01033", "name":"Colbert County"},
  {"code":"01035", "name":"Conecuh County"},
  {"code":"01037", "name":"Coosa County"},
  {"code":"01039", "name":"Covington County"},
  {"code":"01041", "name":"Crenshaw County"},
  {"code":"01043", "name":"Cullman County"},
  {"code":"01045", "name":"Dale County"},
  {"code":"01047", "name":"Dallas County"},
  {"code":"01049", "name":"DeKalb County"},
  {"code":"01051", "name":"Elmore County"},
  {"code":"01053", "name":"Escambia County"},
  {"code":"01055", "name":"Etowah County"},
  {"code":"01057", "name":"Fayette County"},
  {"code":"01059", "name":"Franklin County"},
  {"code":"01061", "name":"Geneva County"},
  {"code":"01063", "name":"Greene County"},
  {"code":"01065", "name":"Hale County"},
  {"code":"01067", "name":"Henry County"},
  {"code":"01069", "name":"Houston County"},
  {"code":"01071", "name":"Jackson County"},
  {"code":"01073", "name":"Jefferson County"},
  {"code":"01075", "name":"Lamar County"},
  {"code":"01077", "name":"Lauderdale County"},
  {"code":"01079", "name":"Lawrence County"},
  {"code":"01081", "name":"Lee County"},
  {"code":"01083", "name":"Limestone County"},
  {"code":"01085", "name":"Lowndes County"},
  {"code":"01087", "name":"Macon County"},
  {"code":"01089", "name":"Madison County"},
  {"code":"01091", "name":"Marengo County"},
  {"code":"01093", "name":"Marion County"},
  {"code":"01095", "name":"Marshall County"},
  {"code":"01097", "name":"Mobile County"},
  {"code":"01099", "name":"Monroe County"},
  {"code":"01101", "name":"Montgomery County"},
  {"code":"01103", "name":"Morgan County"},
  {"code":"01105", "name":"Perry County"},
  {"code":"01107", "name":"Pickens County"},
  {"code":"01109", "name":"Pike County"},
  {"code":"01111", "name":"Randolph County"},
  {"code":"01113", "name":"Russell County"},
  {"code":"01115", "name":"St. Clair County"},
  {"code":"01117", "name":"Shelby County"},
  {"code":"01119", "name":"Sumter County"},
  {"code":"01121", "name":"Talladega County"},
  {"code":"01123", "name":"Tallapoosa County"},
  {"code":"01125", "name":"Tuscaloosa County"},
  {"code":"01127", "name":"Walker County"},
  {"code":"01129", "name":"Washington County"},
  {"code":"01131", "name":"Wilcox County"},
  {"code":"01133", "name":"Winston County"},
  {"code":"02000", "name":"Alaska"},
  {"code":"02013", "name":"Aleutians East Borough"},
  {"code":"02016", "name":"Aleutians West Census Area"},
  {"code":"02020", "name":"Anchorage Borough"},
  {"code":"02050", "name":"Bethel Census Area"},
  {"code":"02060", "name":"Bristol Bay Borough"},
  {"code":"02068", "name":"Denali Borough"},
  {"code":"02070", "name":"Dillingham Census Area"},
  {"code":"02090", "name":"Fairbanks North Star Borough"},
  {"code":"02100", "name":"Haines Borough"},
  {"code":"02110", "name":"Juneau Borough"},
  {"code":"02122", "name":"Kenai Peninsula Borough"},
  {"code":"02130", "name":"Ketchikan Gateway Borough"},
  {"code":"02150", "name":"Kodiak Island Borough"},
  {"code":"02164", "name":"Lake and Peninsula Borough"},
  {"code":"02170", "name":"Matanuska-Susitna Borough"},
  {"code":"02180", "name":"Nome Census Area"},
  {"code":"02185", "name":"North Slope Borough"},
  {"code":"02188", "name":"Northwest Arctic Borough"},
  {"code":"02201", "name":"Prince of Wales-Outer Ketchikan Census Area"},
  {"code":"02220", "name":"Sitka Borough"},
  {"code":"02231", "name":"Skagway-Yakutat-Angoon Census Area"},
  {"code":"02232", "name":"Skagway-Hoonah-Angoon Census Area"},
  {"code":"02240", "name":"Southeast Fairbanks Census Area"},
  {"code":"02261", "name":"Valdez-Cordova Census Area"},
  {"code":"02270", "name":"Wade Hampton Census Area"},
  {"code":"02280", "name":"Wrangell-Petersburg Census Area"},
  {"code":"02282", "name":"Yakutat Borough"},
  {"code":"02290", "name":"Yukon-Koyukuk Census Area"},
  {"code":"04000", "name":"Arizona"},
  {"code":"04001", "name":"Apache County"},
  {"code":"04003", "name":"Cochise County"},
  {"code":"04005", "name":"Coconino County"},
  {"code":"04007", "name":"Gila County"},
  {"code":"04009", "name":"Graham County"},
  {"code":"04011", "name":"Greenlee County"},
  {"code":"04012", "name":"La Paz County"},
  {"code":"04013", "name":"Maricopa County"},
  {"code":"04015", "name":"Mohave County"},
  {"code":"04017", "name":"Navajo County"},
  {"code":"04019", "name":"Pima County"},
  {"code":"04021", "name":"Pinal County"},
  {"code":"04023", "name":"Santa Cruz County"},
  {"code":"04025", "name":"Yavapai County"},
  {"code":"04027", "name":"Yuma County"},
  {"code":"05000", "name":"Arkansas"},
  {"code":"05001", "name":"Arkansas County"},
  {"code":"05003", "name":"Ashley County"},
  {"code":"05005", "name":"Baxter County"},
  {"code":"05007", "name":"Benton County"},
  {"code":"05009", "name":"Boone County"},
  {"code":"05011", "name":"Bradley County"},
  {"code":"05013", "name":"Calhoun County"},
  {"code":"05015", "name":"Carroll County"},
  {"code":"05017", "name":"Chicot County"},
  {"code":"05019", "name":"Clark County"},
  {"code":"05021", "name":"Clay County"},
  {"code":"05023", "name":"Cleburne County"},
  {"code":"05025", "name":"Cleveland County"},
  {"code":"05027", "name":"Columbia County"},
  {"code":"05029", "name":"Conway County"},
  {"code":"05031", "name":"Craighead County"},
  {"code":"05033", "name":"Crawford County"},
  {"code":"05035", "name":"Crittenden County"},
  {"code":"05037", "name":"Cross County"},
  {"code":"05039", "name":"Dallas County"},
  {"code":"05041", "name":"Desha County"},
  {"code":"05043", "name":"Drew County"},
  {"code":"05045", "name":"Faulkner County"},
  {"code":"05047", "name":"Franklin County"},
  {"code":"05049", "name":"Fulton County"},
  {"code":"05051", "name":"Garland County"},
  {"code":"05053", "name":"Grant County"},
  {"code":"05055", "name":"Greene County"},
  {"code":"05057", "name":"Hempstead County"},
  {"code":"05059", "name":"Hot Spring County"},
  {"code":"05061", "name":"Howard County"},
  {"code":"05063", "name":"Independence County"},
  {"code":"05065", "name":"Izard County"},
  {"code":"05067", "name":"Jackson County"},
  {"code":"05069", "name":"Jefferson County"},
  {"code":"05071", "name":"Johnson County"},
  {"code":"05073", "name":"Lafayette County"},
  {"code":"05075", "name":"Lawrence County"},
  {"code":"05077", "name":"Lee County"},
  {"code":"05079", "name":"Lincoln County"},
  {"code":"05081", "name":"Little River County"},
  {"code":"05083", "name":"Logan County"},
  {"code":"05085", "name":"Lonoke County"},
  {"code":"05087", "name":"Madison County"},
  {"code":"05089", "name":"Marion County"},
  {"code":"05091", "name":"Miller County"},
  {"code":"05093", "name":"Mississippi County"},
  {"code":"05095", "name":"Monroe County"},
  {"code":"05097", "name":"Montgomery County"},
  {"code":"05099", "name":"Nevada County"},
  {"code":"05101", "name":"Newton County"},
  {"code":"05103", "name":"Ouachita County"},
  {"code":"05105", "name":"Perry County"},
  {"code":"05107", "name":"Phillips County"},
  {"code":"05109", "name":"Pike County"},
  {"code":"05111", "name":"Poinsett County"},
  {"code":"05113", "name":"Polk County"},
  {"code":"05115", "name":"Pope County"},
  {"code":"05117", "name":"Prairie County"},
  {"code":"05119", "name":"Pulaski County"},
  {"code":"05121", "name":"Randolph County"},
  {"code":"05123", "name":"St. Francis County"},
  {"code":"05125", "name":"Saline County"},
  {"code":"05127", "name":"Scott County"},
  {"code":"05129", "name":"Searcy County"},
  {"code":"05131", "name":"Sebastian County"},
  {"code":"05133", "name":"Sevier County"},
  {"code":"05135", "name":"Sharp County"},
  {"code":"05137", "name":"Stone County"},
  {"code":"05139", "name":"Union County"},
  {"code":"05141", "name":"Van Buren County"},
  {"code":"05143", "name":"Washington County"},
  {"code":"05145", "name":"White County"},
  {"code":"05147", "name":"Woodruff County"},
  {"code":"05149", "name":"Yell County"},
  {"code":"06000", "name":"California"},
  {"code":"06001", "name":"Alameda County"},
  {"code":"06003", "name":"Alpine County"},
  {"code":"06005", "name":"Amador County"},
  {"code":"06007", "name":"Butte County"},
  {"code":"06009", "name":"Calaveras County"},
  {"code":"06011", "name":"Colusa County"},
  {"code":"06013", "name":"Contra Costa County"},
  {"code":"06015", "name":"Del Norte County"},
  {"code":"06017", "name":"El Dorado County"},
  {"code":"06019", "name":"Fresno County"},
  {"code":"06021", "name":"Glenn County"},
  {"code":"06023", "name":"Humboldt County"},
  {"code":"06025", "name":"Imperial County"},
  {"code":"06027", "name":"Inyo County"},
  {"code":"06029", "name":"Kern County"},
  {"code":"06031", "name":"Kings County"},
  {"code":"06033", "name":"Lake County"},
  {"code":"06035", "name":"Lassen County"},
  {"code":"06037", "name":"Los Angeles County"},
  {"code":"06039", "name":"Madera County"},
  {"code":"06041", "name":"Marin County"},
  {"code":"06043", "name":"Mariposa County"},
  {"code":"06045", "name":"Mendocino County"},
  {"code":"06047", "name":"Merced County"},
  {"code":"06049", "name":"Modoc County"},
  {"code":"06051", "name":"Mono County"},
  {"code":"06053", "name":"Monterey County"},
  {"code":"06055", "name":"Napa County"},
  {"code":"06057", "name":"Nevada County"},
  {"code":"06059", "name":"Orange County"},
  {"code":"06061", "name":"Placer County"},
  {"code":"06063", "name":"Plumas County"},
  {"code":"06065", "name":"Riverside County"},
  {"code":"06067", "name":"Sacramento County"},
  {"code":"06069", "name":"San Benito County"},
  {"code":"06071", "name":"San Bernardino County"},
  {"code":"06073", "name":"San Diego County"},
  {"code":"06075", "name":"San Francisco County"},
  {"code":"06077", "name":"San Joaquin County"},
  {"code":"06079", "name":"San Luis Obispo County"},
  {"code":"06081", "name":"San Mateo County"},
  {"code":"06083", "name":"Santa Barbara County"},
  {"code":"06085", "name":"Santa Clara County"},
  {"code":"06087", "name":"Santa Cruz County"},
  {"code":"06089", "name":"Shasta County"},
  {"code":"06091", "name":"Sierra County"},
  {"code":"06093", "name":"Siskiyou County"},
  {"code":"06095", "name":"Solano County"},
  {"code":"06097", "name":"Sonoma County"},
  {"code":"06099", "name":"Stanislaus County"},
  {"code":"06101", "name":"Sutter County"},
  {"code":"06103", "name":"Tehama County"},
  {"code":"06105", "name":"Trinity County"},
  {"code":"06107", "name":"Tulare County"},
  {"code":"06109", "name":"Tuolumne County"},
  {"code":"06111", "name":"Ventura County"},
  {"code":"06113", "name":"Yolo County"},
  {"code":"06115", "name":"Yuba County"},
  {"code":"08000", "name":"Colorado"},
  {"code":"08001", "name":"Adams County"},
  {"code":"08003", "name":"Alamosa County"},
  {"code":"08005", "name":"Arapahoe County"},
  {"code":"08007", "name":"Archuleta County"},
  {"code":"08009", "name":"Baca County"},
  {"code":"08011", "name":"Bent County"},
  {"code":"08013", "name":"Boulder County"},
  {"code":"08015", "name":"Chaffee County"},
  {"code":"08017", "name":"Cheyenne County"},
  {"code":"08019", "name":"Clear Creek County"},
  {"code":"08021", "name":"Conejos County"},
  {"code":"08023", "name":"Costilla County"},
  {"code":"08025", "name":"Crowley County"},
  {"code":"08027", "name":"Custer County"},
  {"code":"08029", "name":"Delta County"},
  {"code":"08031", "name":"Denver County"},
  {"code":"08033", "name":"Dolores County"},
  {"code":"08035", "name":"Douglas County"},
  {"code":"08037", "name":"Eagle County"},
  {"code":"08039", "name":"Elbert County"},
  {"code":"08041", "name":"El Paso County"},
  {"code":"08043", "name":"Fremont County"},
  {"code":"08045", "name":"Garfield County"},
  {"code":"08047", "name":"Gilpin County"},
  {"code":"08049", "name":"Grand County"},
  {"code":"08051", "name":"Gunnison County"},
  {"code":"08053", "name":"Hinsdale County"},
  {"code":"08055", "name":"Huerfano County"},
  {"code":"08057", "name":"Jackson County"},
  {"code":"08059", "name":"Jefferson County"},
  {"code":"08061", "name":"Kiowa County"},
  {"code":"08063", "name":"Kit Carson County"},
  {"code":"08065", "name":"Lake County"},
  {"code":"08067", "name":"La Plata County"},
  {"code":"08069", "name":"Larimer County"},
  {"code":"08071", "name":"Las Animas County"},
  {"code":"08073", "name":"Lincoln County"},
  {"code":"08075", "name":"Logan County"},
  {"code":"08077", "name":"Mesa County"},
  {"code":"08079", "name":"Mineral County"},
  {"code":"08081", "name":"Moffat County"},
  {"code":"08083", "name":"Montezuma County"},
  {"code":"08085", "name":"Montrose County"},
  {"code":"08087", "name":"Morgan County"},
  {"code":"08089", "name":"Otero County"},
  {"code":"08091", "name":"Ouray County"},
  {"code":"08093", "name":"Park County"},
  {"code":"08095", "name":"Phillips County"},
  {"code":"08097", "name":"Pitkin County"},
  {"code":"08099", "name":"Prowers County"},
  {"code":"08101", "name":"Pueblo County"},
  {"code":"08103", "name":"Rio Blanco County"},
  {"code":"08105", "name":"Rio Grande County"},
  {"code":"08107", "name":"Routt County"},
  {"code":"08109", "name":"Saguache County"},
  {"code":"08111", "name":"San Juan County"},
  {"code":"08113", "name":"San Miguel County"},
  {"code":"08115", "name":"Sedgwick County"},
  {"code":"08117", "name":"Summit County"},
  {"code":"08119", "name":"Teller County"},
  {"code":"08121", "name":"Washington County"},
  {"code":"08123", "name":"Weld County"},
  {"code":"08125", "name":"Yuma County"},
  {"code":"09000", "name":"Connecticut"},
  {"code":"09001", "name":"Fairfield County"},
  {"code":"09003", "name":"Hartford County"},
  {"code":"09005", "name":"Litchfield County"},
  {"code":"09007", "name":"Middlesex County"},
  {"code":"09009", "name":"New Haven County"},
  {"code":"09011", "name":"New London County"},
  {"code":"09013", "name":"Tolland County"},
  {"code":"09015", "name":"Windham County"},
  {"code":"10000", "name":"Delaware"},
  {"code":"10001", "name":"Kent County"},
  {"code":"10003", "name":"New Castle County"},
  {"code":"10005", "name":"Sussex County"},
  {"code":"11000", "name":"District of Columbia"},
  {"code":"11001", "name":"District of Columbia"},
  {"code":"12000", "name":"Florida"},
  {"code":"12001", "name":"Alachua County"},
  {"code":"12003", "name":"Baker County"},
  {"code":"12005", "name":"Bay County"},
  {"code":"12007", "name":"Bradford County"},
  {"code":"12009", "name":"Brevard County"},
  {"code":"12011", "name":"Broward County"},
  {"code":"12013", "name":"Calhoun County"},
  {"code":"12015", "name":"Charlotte County"},
  {"code":"12017", "name":"Citrus County"},
  {"code":"12019", "name":"Clay County"},
  {"code":"12021", "name":"Collier County"},
  {"code":"12023", "name":"Columbia County"},
  {"code":"12025", "name":"Dade County"},
  {"code":"12027", "name":"DeSoto County"},
  {"code":"12029", "name":"Dixie County"},
  {"code":"12031", "name":"Duval County"},
  {"code":"12033", "name":"Escambia County"},
  {"code":"12035", "name":"Flagler County"},
  {"code":"12037", "name":"Franklin County"},
  {"code":"12039", "name":"Gadsden County"},
  {"code":"12041", "name":"Gilchrist County"},
  {"code":"12043", "name":"Glades County"},
  {"code":"12045", "name":"Gulf County"},
  {"code":"12047", "name":"Hamilton County"},
  {"code":"12049", "name":"Hardee County"},
  {"code":"12051", "name":"Hendry County"},
  {"code":"12053", "name":"Hernando County"},
  {"code":"12055", "name":"Highlands County"},
  {"code":"12057", "name":"Hillsborough County"},
  {"code":"12059", "name":"Holmes County"},
  {"code":"12061", "name":"Indian River County"},
  {"code":"12063", "name":"Jackson County"},
  {"code":"12065", "name":"Jefferson County"},
  {"code":"12067", "name":"Lafayette County"},
  {"code":"12069", "name":"Lake County"},
  {"code":"12071", "name":"Lee County"},
  {"code":"12073", "name":"Leon County"},
  {"code":"12075", "name":"Levy County"},
  {"code":"12077", "name":"Liberty County"},
  {"code":"12079", "name":"Madison County"},
  {"code":"12081", "name":"Manatee County"},
  {"code":"12083", "name":"Marion County"},
  {"code":"12085", "name":"Martin County"},
  {"code":"12087", "name":"Monroe County"},
  {"code":"12089", "name":"Nassau County"},
  {"code":"12091", "name":"Okaloosa County"},
  {"code":"12093", "name":"Okeechobee County"},
  {"code":"12095", "name":"Orange County"},
  {"code":"12097", "name":"Osceola County"},
  {"code":"12099", "name":"Palm Beach County"},
  {"code":"12101", "name":"Pasco County"},
  {"code":"12103", "name":"Pinellas County"},
  {"code":"12105", "name":"Polk County"},
  {"code":"12107", "name":"Putnam County"},
  {"code":"12109", "name":"St. Johns County"},
  {"code":"12111", "name":"St. Lucie County"},
  {"code":"12113", "name":"Santa Rosa County"},
  {"code":"12115", "name":"Sarasota County"},
  {"code":"12117", "name":"Seminole County"},
  {"code":"12119", "name":"Sumter County"},
  {"code":"12121", "name":"Suwannee County"},
  {"code":"12123", "name":"Taylor County"},
  {"code":"12125", "name":"Union County"},
  {"code":"12127", "name":"Volusia County"},
  {"code":"12129", "name":"Wakulla County"},
  {"code":"12131", "name":"Walton County"},
  {"code":"12133", "name":"Washington County"},
  {"code":"13000", "name":"Georgia"},
  {"code":"13001", "name":"Appling County"},
  {"code":"13003", "name":"Atkinson County"},
  {"code":"13005", "name":"Bacon County"},
  {"code":"13007", "name":"Baker County"},
  {"code":"13009", "name":"Baldwin County"},
  {"code":"13011", "name":"Banks County"},
  {"code":"13013", "name":"Barrow County"},
  {"code":"13015", "name":"Bartow County"},
  {"code":"13017", "name":"Ben Hill County"},
  {"code":"13019", "name":"Berrien County"},
  {"code":"13021", "name":"Bibb County"},
  {"code":"13023", "name":"Bleckley County"},
  {"code":"13025", "name":"Brantley County"},
  {"code":"13027", "name":"Brooks County"},
  {"code":"13029", "name":"Bryan County"},
  {"code":"13031", "name":"Bulloch County"},
  {"code":"13033", "name":"Burke County"},
  {"code":"13035", "name":"Butts County"},
  {"code":"13037", "name":"Calhoun County"},
  {"code":"13039", "name":"Camden County"},
  {"code":"13043", "name":"Candler County"},
  {"code":"13045", "name":"Carroll County"},
  {"code":"13047", "name":"Catoosa County"},
  {"code":"13049", "name":"Charlton County"},
  {"code":"13051", "name":"Chatham County"},
  {"code":"13053", "name":"Chattahoochee County"},
  {"code":"13055", "name":"Chattooga County"},
  {"code":"13057", "name":"Cherokee County"},
  {"code":"13059", "name":"Clarke County"},
  {"code":"13061", "name":"Clay County"},
  {"code":"13063", "name":"Clayton County"},
  {"code":"13065", "name":"Clinch County"},
  {"code":"13067", "name":"Cobb County"},
  {"code":"13069", "name":"Coffee County"},
  {"code":"13071", "name":"Colquitt County"},
  {"code":"13073", "name":"Columbia County"},
  {"code":"13075", "name":"Cook County"},
  {"code":"13077", "name":"Coweta County"},
  {"code":"13079", "name":"Crawford County"},
  {"code":"13081", "name":"Crisp County"},
  {"code":"13083", "name":"Dade County"},
  {"code":"13085", "name":"Dawson County"},
  {"code":"13087", "name":"Decatur County"},
  {"code":"13089", "name":"DeKalb County"},
  {"code":"13091", "name":"Dodge County"},
  {"code":"13093", "name":"Dooly County"},
  {"code":"13095", "name":"Dougherty County"},
  {"code":"13097", "name":"Douglas County"},
  {"code":"13099", "name":"Early County"},
  {"code":"13101", "name":"Echols County"},
  {"code":"13103", "name":"Effingham County"},
  {"code":"13105", "name":"Elbert County"},
  {"code":"13107", "name":"Emanuel County"},
  {"code":"13109", "name":"Evans County"},
  {"code":"13111", "name":"Fannin County"},
  {"code":"13113", "name":"Fayette County"},
  {"code":"13115", "name":"Floyd County"},
  {"code":"13117", "name":"Forsyth County"},
  {"code":"13119", "name":"Franklin County"},
  {"code":"13121", "name":"Fulton County"},
  {"code":"13123", "name":"Gilmer County"},
  {"code":"13125", "name":"Glascock County"},
  {"code":"13127", "name":"Glynn County"},
  {"code":"13129", "name":"Gordon County"},
  {"code":"13131", "name":"Grady County"},
  {"code":"13133", "name":"Greene County"},
  {"code":"13135", "name":"Gwinnett County"},
  {"code":"13137", "name":"Habersham County"},
  {"code":"13139", "name":"Hall County"},
  {"code":"13141", "name":"Hancock County"},
  {"code":"13143", "name":"Haralson County"},
  {"code":"13145", "name":"Harris County"},
  {"code":"13147", "name":"Hart County"},
  {"code":"13149", "name":"Heard County"},
  {"code":"13151", "name":"Henry County"},
  {"code":"13153", "name":"Houston County"},
  {"code":"13155", "name":"Irwin County"},
  {"code":"13157", "name":"Jackson County"},
  {"code":"13159", "name":"Jasper County"},
  {"code":"13161", "name":"Jeff Davis County"},
  {"code":"13163", "name":"Jefferson County"},
  {"code":"13165", "name":"Jenkins County"},
  {"code":"13167", "name":"Johnson County"},
  {"code":"13169", "name":"Jones County"},
  {"code":"13171", "name":"Lamar County"},
  {"code":"13173", "name":"Lanier County"},
  {"code":"13175", "name":"Laurens County"},
  {"code":"13177", "name":"Lee County"},
  {"code":"13179", "name":"Liberty County"},
  {"code":"13181", "name":"Lincoln County"},
  {"code":"13183", "name":"Long County"},
  {"code":"13185", "name":"Lowndes County"},
  {"code":"13187", "name":"Lumpkin County"},
  {"code":"13189", "name":"McDuffie County"},
  {"code":"13191", "name":"McIntosh County"},
  {"code":"13193", "name":"Macon County"},
  {"code":"13195", "name":"Madison County"},
  {"code":"13197", "name":"Marion County"},
  {"code":"13199", "name":"Meriwether County"},
  {"code":"13201", "name":"Miller County"},
  {"code":"13205", "name":"Mitchell County"},
  {"code":"13207", "name":"Monroe County"},
  {"code":"13209", "name":"Montgomery County"},
  {"code":"13211", "name":"Morgan County"},
  {"code":"13213", "name":"Murray County"},
  {"code":"13215", "name":"Muscogee County"},
  {"code":"13217", "name":"Newton County"},
  {"code":"13219", "name":"Oconee County"},
  {"code":"13221", "name":"Oglethorpe County"},
  {"code":"13223", "name":"Paulding County"},
  {"code":"13225", "name":"Peach County"},
  {"code":"13227", "name":"Pickens County"},
  {"code":"13229", "name":"Pierce County"},
  {"code":"13231", "name":"Pike County"},
  {"code":"13233", "name":"Polk County"},
  {"code":"13235", "name":"Pulaski County"},
  {"code":"13237", "name":"Putnam County"},
  {"code":"13239", "name":"Quitman County"},
  {"code":"13241", "name":"Rabun County"},
  {"code":"13243", "name":"Randolph County"},
  {"code":"13245", "name":"Richmond County"},
  {"code":"13247", "name":"Rockdale County"},
  {"code":"13249", "name":"Schley County"},
  {"code":"13251", "name":"Screven County"},
  {"code":"13253", "name":"Seminole County"},
  {"code":"13255", "name":"Spalding County"},
  {"code":"13257", "name":"Stephens County"},
  {"code":"13259", "name":"Stewart County"},
  {"code":"13261", "name":"Sumter County"},
  {"code":"13263", "name":"Talbot County"},
  {"code":"13265", "name":"Taliaferro County"},
  {"code":"13267", "name":"Tattnall County"},
  {"code":"13269", "name":"Taylor County"},
  {"code":"13271", "name":"Telfair County"},
  {"code":"13273", "name":"Terrell County"},
  {"code":"13275", "name":"Thomas County"},
  {"code":"13277", "name":"Tift County"},
  {"code":"13279", "name":"Toombs County"},
  {"code":"13281", "name":"Towns County"},
  {"code":"13283", "name":"Treutlen County"},
  {"code":"13285", "name":"Troup County"},
  {"code":"13287", "name":"Turner County"},
  {"code":"13289", "name":"Twiggs County"},
  {"code":"13291", "name":"Union County"},
  {"code":"13293", "name":"Upson County"},
  {"code":"13295", "name":"Walker County"},
  {"code":"13297", "name":"Walton County"},
  {"code":"13299", "name":"Ware County"},
  {"code":"13301", "name":"Warren County"},
  {"code":"13303", "name":"Washington County"},
  {"code":"13305", "name":"Wayne County"},
  {"code":"13307", "name":"Webster County"},
  {"code":"13309", "name":"Wheeler County"},
  {"code":"13311", "name":"White County"},
  {"code":"13313", "name":"Whitfield County"},
  {"code":"13315", "name":"Wilcox County"},
  {"code":"13317", "name":"Wilkes County"},
  {"code":"13319", "name":"Wilkinson County"},
  {"code":"13321", "name":"Worth County"},
  {"code":"15000", "name":"Hawaii"},
  {"code":"15001", "name":"Hawaii County"},
  {"code":"15003", "name":"Honolulu County"},
  {"code":"15005", "name":"Kalawao County"},
  {"code":"15007", "name":"Kauai County"},
  {"code":"15009", "name":"Maui County"},
  {"code":"16000", "name":"Idaho"},
  {"code":"16001", "name":"Ada County"},
  {"code":"16003", "name":"Adams County"},
  {"code":"16005", "name":"Bannock County"},
  {"code":"16007", "name":"Bear Lake County"},
  {"code":"16009", "name":"Benewah County"},
  {"code":"16011", "name":"Bingham County"},
  {"code":"16013", "name":"Blaine County"},
  {"code":"16015", "name":"Boise County"},
  {"code":"16017", "name":"Bonner County"},
  {"code":"16019", "name":"Bonneville County"},
  {"code":"16021", "name":"Boundary County"},
  {"code":"16023", "name":"Butte County"},
  {"code":"16025", "name":"Camas County"},
  {"code":"16027", "name":"Canyon County"},
  {"code":"16029", "name":"Caribou County"},
  {"code":"16031", "name":"Cassia County"},
  {"code":"16033", "name":"Clark County"},
  {"code":"16035", "name":"Clearwater County"},
  {"code":"16037", "name":"Custer County"},
  {"code":"16039", "name":"Elmore County"},
  {"code":"16041", "name":"Franklin County"},
  {"code":"16043", "name":"Fremont County"},
  {"code":"16045", "name":"Gem County"},
  {"code":"16047", "name":"Gooding County"},
  {"code":"16049", "name":"Idaho County"},
  {"code":"16051", "name":"Jefferson County"},
  {"code":"16053", "name":"Jerome County"},
  {"code":"16055", "name":"Kootenai County"},
  {"code":"16057", "name":"Latah County"},
  {"code":"16059", "name":"Lemhi County"},
  {"code":"16061", "name":"Lewis County"},
  {"code":"16063", "name":"Lincoln County"},
  {"code":"16065", "name":"Madison County"},
  {"code":"16067", "name":"Minidoka County"},
  {"code":"16069", "name":"Nez Perce County"},
  {"code":"16071", "name":"Oneida County"},
  {"code":"16073", "name":"Owyhee County"},
  {"code":"16075", "name":"Payette County"},
  {"code":"16077", "name":"Power County"},
  {"code":"16079", "name":"Shoshone County"},
  {"code":"16081", "name":"Teton County"},
  {"code":"16083", "name":"Twin Falls County"},
  {"code":"16085", "name":"Valley County"},
  {"code":"16087", "name":"Washington County"},
  {"code":"17000", "name":"Illinois"},
  {"code":"17001", "name":"Adams County"},
  {"code":"17003", "name":"Alexander County"},
  {"code":"17005", "name":"Bond County"},
  {"code":"17007", "name":"Boone County"},
  {"code":"17009", "name":"Brown County"},
  {"code":"17011", "name":"Bureau County"},
  {"code":"17013", "name":"Calhoun County"},
  {"code":"17015", "name":"Carroll County"},
  {"code":"17017", "name":"Cass County"},
  {"code":"17019", "name":"Champaign County"},
  {"code":"17021", "name":"Christian County"},
  {"code":"17023", "name":"Clark County"},
  {"code":"17025", "name":"Clay County"},
  {"code":"17027", "name":"Clinton County"},
  {"code":"17029", "name":"Coles County"},
  {"code":"17031", "name":"Cook County"},
  {"code":"17033", "name":"Crawford County"},
  {"code":"17035", "name":"Cumberland County"},
  {"code":"17037", "name":"DeKalb County"},
  {"code":"17039", "name":"De Witt County"},
  {"code":"17041", "name":"Douglas County"},
  {"code":"17043", "name":"DuPage County"},
  {"code":"17045", "name":"Edgar County"},
  {"code":"17047", "name":"Edwards County"},
  {"code":"17049", "name":"Effingham County"},
  {"code":"17051", "name":"Fayette County"},
  {"code":"17053", "name":"Ford County"},
  {"code":"17055", "name":"Franklin County"},
  {"code":"17057", "name":"Fulton County"},
  {"code":"17059", "name":"Gallatin County"},
  {"code":"17061", "name":"Greene County"},
  {"code":"17063", "name":"Grundy County"},
  {"code":"17065", "name":"Hamilton County"},
  {"code":"17067", "name":"Hancock County"},
  {"code":"17069", "name":"Hardin County"},
  {"code":"17071", "name":"Henderson County"},
  {"code":"17073", "name":"Henry County"},
  {"code":"17075", "name":"Iroquois County"},
  {"code":"17077", "name":"Jackson County"},
  {"code":"17079", "name":"Jasper County"},
  {"code":"17081", "name":"Jefferson County"},
  {"code":"17083", "name":"Jersey County"},
  {"code":"17085", "name":"Jo Daviess County"},
  {"code":"17087", "name":"Johnson County"},
  {"code":"17089", "name":"Kane County"},
  {"code":"17091", "name":"Kankakee County"},
  {"code":"17093", "name":"Kendall County"},
  {"code":"17095", "name":"Knox County"},
  {"code":"17097", "name":"Lake County"},
  {"code":"17099", "name":"La Salle County"},
  {"code":"17101", "name":"Lawrence County"},
  {"code":"17103", "name":"Lee County"},
  {"code":"17105", "name":"Livingston County"},
  {"code":"17107", "name":"Logan County"},
  {"code":"17109", "name":"McDonough County"},
  {"code":"17111", "name":"McHenry County"},
  {"code":"17113", "name":"McLean County"},
  {"code":"17115", "name":"Macon County"},
  {"code":"17117", "name":"Macoupin County"},
  {"code":"17119", "name":"Madison County"},
  {"code":"17121", "name":"Marion County"},
  {"code":"17123", "name":"Marshall County"},
  {"code":"17125", "name":"Mason County"},
  {"code":"17127", "name":"Massac County"},
  {"code":"17129", "name":"Menard County"},
  {"code":"17131", "name":"Mercer County"},
  {"code":"17133", "name":"Monroe County"},
  {"code":"17135", "name":"Montgomery County"},
  {"code":"17137", "name":"Morgan County"},
  {"code":"17139", "name":"Moultrie County"},
  {"code":"17141", "name":"Ogle County"},
  {"code":"17143", "name":"Peoria County"},
  {"code":"17145", "name":"Perry County"},
  {"code":"17147", "name":"Piatt County"},
  {"code":"17149", "name":"Pike County"},
  {"code":"17151", "name":"Pope County"},
  {"code":"17153", "name":"Pulaski County"},
  {"code":"17155", "name":"Putnam County"},
  {"code":"17157", "name":"Randolph County"},
  {"code":"17159", "name":"Richland County"},
  {"code":"17161", "name":"Rock Island County"},
  {"code":"17163", "name":"St. Clair County"},
  {"code":"17165", "name":"Saline County"},
  {"code":"17167", "name":"Sangamon County"},
  {"code":"17169", "name":"Schuyler County"},
  {"code":"17171", "name":"Scott County"},
  {"code":"17173", "name":"Shelby County"},
  {"code":"17175", "name":"Stark County"},
  {"code":"17177", "name":"Stephenson County"},
  {"code":"17179", "name":"Tazewell County"},
  {"code":"17181", "name":"Union County"},
  {"code":"17183", "name":"Vermilion County"},
  {"code":"17185", "name":"Wabash County"},
  {"code":"17187", "name":"Warren County"},
  {"code":"17189", "name":"Washington County"},
  {"code":"17191", "name":"Wayne County"},
  {"code":"17193", "name":"White County"},
  {"code":"17195", "name":"Whiteside County"},
  {"code":"17197", "name":"Will County"},
  {"code":"17199", "name":"Williamson County"},
  {"code":"17201", "name":"Winnebago County"},
  {"code":"17203", "name":"Woodford County"},
  {"code":"18000", "name":"Indiana"},
  {"code":"18001", "name":"Adams County"},
  {"code":"18003", "name":"Allen County"},
  {"code":"18005", "name":"Bartholomew County"},
  {"code":"18007", "name":"Benton County"},
  {"code":"18009", "name":"Blackford County"},
  {"code":"18011", "name":"Boone County"},
  {"code":"18013", "name":"Brown County"},
  {"code":"18015", "name":"Carroll County"},
  {"code":"18017", "name":"Cass County"},
  {"code":"18019", "name":"Clark County"},
  {"code":"18021", "name":"Clay County"},
  {"code":"18023", "name":"Clinton County"},
  {"code":"18025", "name":"Crawford County"},
  {"code":"18027", "name":"Daviess County"},
  {"code":"18029", "name":"Dearborn County"},
  {"code":"18031", "name":"Decatur County"},
  {"code":"18033", "name":"De Kalb County"},
  {"code":"18035", "name":"Delaware County"},
  {"code":"18037", "name":"Dubois County"},
  {"code":"18039", "name":"Elkhart County"},
  {"code":"18041", "name":"Fayette County"},
  {"code":"18043", "name":"Floyd County"},
  {"code":"18045", "name":"Fountain County"},
  {"code":"18047", "name":"Franklin County"},
  {"code":"18049", "name":"Fulton County"},
  {"code":"18051", "name":"Gibson County"},
  {"code":"18053", "name":"Grant County"},
  {"code":"18055", "name":"Greene County"},
  {"code":"18057", "name":"Hamilton County"},
  {"code":"18059", "name":"Hancock County"},
  {"code":"18061", "name":"Harrison County"},
  {"code":"18063", "name":"Hendricks County"},
  {"code":"18065", "name":"Henry County"},
  {"code":"18067", "name":"Howard County"},
  {"code":"18069", "name":"Huntington County"},
  {"code":"18071", "name":"Jackson County"},
  {"code":"18073", "name":"Jasper County"},
  {"code":"18075", "name":"Jay County"},
  {"code":"18077", "name":"Jefferson County"},
  {"code":"18079", "name":"Jennings County"},
  {"code":"18081", "name":"Johnson County"},
  {"code":"18083", "name":"Knox County"},
  {"code":"18085", "name":"Kosciusko County"},
  {"code":"18087", "name":"Lagrange County"},
  {"code":"18089", "name":"Lake County"},
  {"code":"18091", "name":"La Porte County"},
  {"code":"18093", "name":"Lawrence County"},
  {"code":"18095", "name":"Madison County"},
  {"code":"18097", "name":"Marion County"},
  {"code":"18099", "name":"Marshall County"},
  {"code":"18101", "name":"Martin County"},
  {"code":"18103", "name":"Miami County"},
  {"code":"18105", "name":"Monroe County"},
  {"code":"18107", "name":"Montgomery County"},
  {"code":"18109", "name":"Morgan County"},
  {"code":"18111", "name":"Newton County"},
  {"code":"18113", "name":"Noble County"},
  {"code":"18115", "name":"Ohio County"},
  {"code":"18117", "name":"Orange County"},
  {"code":"18119", "name":"Owen County"},
  {"code":"18121", "name":"Parke County"},
  {"code":"18123", "name":"Perry County"},
  {"code":"18125", "name":"Pike County"},
  {"code":"18127", "name":"Porter County"},
  {"code":"18129", "name":"Posey County"},
  {"code":"18131", "name":"Pulaski County"},
  {"code":"18133", "name":"Putnam County"},
  {"code":"18135", "name":"Randolph County"},
  {"code":"18137", "name":"Ripley County"},
  {"code":"18139", "name":"Rush County"},
  {"code":"18141", "name":"St. Joseph County"},
  {"code":"18143", "name":"Scott County"},
  {"code":"18145", "name":"Shelby County"},
  {"code":"18147", "name":"Spencer County"},
  {"code":"18149", "name":"Starke County"},
  {"code":"18151", "name":"Steuben County"},
  {"code":"18153", "name":"Sullivan County"},
  {"code":"18155", "name":"Switzerland County"},
  {"code":"18157", "name":"Tippecanoe County"},
  {"code":"18159", "name":"Tipton County"},
  {"code":"18161", "name":"Union County"},
  {"code":"18163", "name":"Vanderburgh County"},
  {"code":"18165", "name":"Vermillion County"},
  {"code":"18167", "name":"Vigo County"},
  {"code":"18169", "name":"Wabash County"},
  {"code":"18171", "name":"Warren County"},
  {"code":"18173", "name":"Warrick County"},
  {"code":"18175", "name":"Washington County"},
  {"code":"18177", "name":"Wayne County"},
  {"code":"18179", "name":"Wells County"},
  {"code":"18181", "name":"White County"},
  {"code":"18183", "name":"Whitley County"},
  {"code":"19000", "name":"Iowa"},
  {"code":"19001", "name":"Adair County"},
  {"code":"19003", "name":"Adams County"},
  {"code":"19005", "name":"Allamakee County"},
  {"code":"19007", "name":"Appanoose County"},
  {"code":"19009", "name":"Audubon County"},
  {"code":"19011", "name":"Benton County"},
  {"code":"19013", "name":"Black Hawk County"},
  {"code":"19015", "name":"Boone County"},
  {"code":"19017", "name":"Bremer County"},
  {"code":"19019", "name":"Buchanan County"},
  {"code":"19021", "name":"Buena Vista County"},
  {"code":"19023", "name":"Butler County"},
  {"code":"19025", "name":"Calhoun County"},
  {"code":"19027", "name":"Carroll County"},
  {"code":"19029", "name":"Cass County"},
  {"code":"19031", "name":"Cedar County"},
  {"code":"19033", "name":"Cerro Gordo County"},
  {"code":"19035", "name":"Cherokee County"},
  {"code":"19037", "name":"Chickasaw County"},
  {"code":"19039", "name":"Clarke County"},
  {"code":"19041", "name":"Clay County"},
  {"code":"19043", "name":"Clayton County"},
  {"code":"19045", "name":"Clinton County"},
  {"code":"19047", "name":"Crawford County"},
  {"code":"19049", "name":"Dallas County"},
  {"code":"19051", "name":"Davis County"},
  {"code":"19053", "name":"Decatur County"},
  {"code":"19055", "name":"Delaware County"},
  {"code":"19057", "name":"Des Moines County"},
  {"code":"19059", "name":"Dickinson County"},
  {"code":"19061", "name":"Dubuque County"},
  {"code":"19063", "name":"Emmet County"},
  {"code":"19065", "name":"Fayette County"},
  {"code":"19067", "name":"Floyd County"},
  {"code":"19069", "name":"Franklin County"},
  {"code":"19071", "name":"Fremont County"},
  {"code":"19073", "name":"Greene County"},
  {"code":"19075", "name":"Grundy County"},
  {"code":"19077", "name":"Guthrie County"},
  {"code":"19079", "name":"Hamilton County"},
  {"code":"19081", "name":"Hancock County"},
  {"code":"19083", "name":"Hardin County"},
  {"code":"19085", "name":"Harrison County"},
  {"code":"19087", "name":"Henry County"},
  {"code":"19089", "name":"Howard County"},
  {"code":"19091", "name":"Humboldt County"},
  {"code":"19093", "name":"Ida County"},
  {"code":"19095", "name":"Iowa County"},
  {"code":"19097", "name":"Jackson County"},
  {"code":"19099", "name":"Jasper County"},
  {"code":"19101", "name":"Jefferson County"},
  {"code":"19103", "name":"Johnson County"},
  {"code":"19105", "name":"Jones County"},
  {"code":"19107", "name":"Keokuk County"},
  {"code":"19109", "name":"Kossuth County"},
  {"code":"19111", "name":"Lee County"},
  {"code":"19113", "name":"Linn County"},
  {"code":"19115", "name":"Louisa County"},
  {"code":"19117", "name":"Lucas County"},
  {"code":"19119", "name":"Lyon County"},
  {"code":"19121", "name":"Madison County"},
  {"code":"19123", "name":"Mahaska County"},
  {"code":"19125", "name":"Marion County"},
  {"code":"19127", "name":"Marshall County"},
  {"code":"19129", "name":"Mills County"},
  {"code":"19131", "name":"Mitchell County"},
  {"code":"19133", "name":"Monona County"},
  {"code":"19135", "name":"Monroe County"},
  {"code":"19137", "name":"Montgomery County"},
  {"code":"19139", "name":"Muscatine County"},
  {"code":"19141", "name":"O"},
  {"code":"19143", "name":"Osceola County"},
  {"code":"19145", "name":"Page County"},
  {"code":"19147", "name":"Palo Alto County"},
  {"code":"19149", "name":"Plymouth County"},
  {"code":"19151", "name":"Pocahontas County"},
  {"code":"19153", "name":"Polk County"},
  {"code":"19155", "name":"Pottawattamie County"},
  {"code":"19157", "name":"Poweshiek County"},
  {"code":"19159", "name":"Ringgold County"},
  {"code":"19161", "name":"Sac County"},
  {"code":"19163", "name":"Scott County"},
  {"code":"19165", "name":"Shelby County"},
  {"code":"19167", "name":"Sioux County"},
  {"code":"19169", "name":"Story County"},
  {"code":"19171", "name":"Tama County"},
  {"code":"19173", "name":"Taylor County"},
  {"code":"19175", "name":"Union County"},
  {"code":"19177", "name":"Van Buren County"},
  {"code":"19179", "name":"Wapello County"},
  {"code":"19181", "name":"Warren County"},
  {"code":"19183", "name":"Washington County"},
  {"code":"19185", "name":"Wayne County"},
  {"code":"19187", "name":"Webster County"},
  {"code":"19189", "name":"Winnebago County"},
  {"code":"19191", "name":"Winneshiek County"},
  {"code":"19193", "name":"Woodbury County"},
  {"code":"19195", "name":"Worth County"},
  {"code":"19197", "name":"Wright County"},
  {"code":"20000", "name":"Kansas"},
  {"code":"20001", "name":"Allen County"},
  {"code":"20003", "name":"Anderson County"},
  {"code":"20005", "name":"Atchison County"},
  {"code":"20007", "name":"Barber County"},
  {"code":"20009", "name":"Barton County"},
  {"code":"20011", "name":"Bourbon County"},
  {"code":"20013", "name":"Brown County"},
  {"code":"20015", "name":"Butler County"},
  {"code":"20017", "name":"Chase County"},
  {"code":"20019", "name":"Chautauqua County"},
  {"code":"20021", "name":"Cherokee County"},
  {"code":"20023", "name":"Cheyenne County"},
  {"code":"20025", "name":"Clark County"},
  {"code":"20027", "name":"Clay County"},
  {"code":"20029", "name":"Cloud County"},
  {"code":"20031", "name":"Coffey County"},
  {"code":"20033", "name":"Comanche County"},
  {"code":"20035", "name":"Cowley County"},
  {"code":"20037", "name":"Crawford County"},
  {"code":"20039", "name":"Decatur County"},
  {"code":"20041", "name":"Dickinson County"},
  {"code":"20043", "name":"Doniphan County"},
  {"code":"20045", "name":"Douglas County"},
  {"code":"20047", "name":"Edwards County"},
  {"code":"20049", "name":"Elk County"},
  {"code":"20051", "name":"Ellis County"},
  {"code":"20053", "name":"Ellsworth County"},
  {"code":"20055", "name":"Finney County"},
  {"code":"20057", "name":"Ford County"},
  {"code":"20059", "name":"Franklin County"},
  {"code":"20061", "name":"Geary County"},
  {"code":"20063", "name":"Gove County"},
  {"code":"20065", "name":"Graham County"},
  {"code":"20067", "name":"Grant County"},
  {"code":"20069", "name":"Gray County"},
  {"code":"20071", "name":"Greeley County"},
  {"code":"20073", "name":"Greenwood County"},
  {"code":"20075", "name":"Hamilton County"},
  {"code":"20077", "name":"Harper County"},
  {"code":"20079", "name":"Harvey County"},
  {"code":"20081", "name":"Haskell County"},
  {"code":"20083", "name":"Hodgeman County"},
  {"code":"20085", "name":"Jackson County"},
  {"code":"20087", "name":"Jefferson County"},
  {"code":"20089", "name":"Jewell County"},
  {"code":"20091", "name":"Johnson County"},
  {"code":"20093", "name":"Kearny County"},
  {"code":"20095", "name":"Kingman County"},
  {"code":"20097", "name":"Kiowa County"},
  {"code":"20099", "name":"Labette County"},
  {"code":"20101", "name":"Lane County"},
  {"code":"20103", "name":"Leavenworth County"},
  {"code":"20105", "name":"Lincoln County"},
  {"code":"20107", "name":"Linn County"},
  {"code":"20109", "name":"Logan County"},
  {"code":"20111", "name":"Lyon County"},
  {"code":"20113", "name":"McPherson County"},
  {"code":"20115", "name":"Marion County"},
  {"code":"20117", "name":"Marshall County"},
  {"code":"20119", "name":"Meade County"},
  {"code":"20121", "name":"Miami County"},
  {"code":"20123", "name":"Mitchell County"},
  {"code":"20125", "name":"Montgomery County"},
  {"code":"20127", "name":"Morris County"},
  {"code":"20129", "name":"Morton County"},
  {"code":"20131", "name":"Nemaha County"},
  {"code":"20133", "name":"Neosho County"},
  {"code":"20135", "name":"Ness County"},
  {"code":"20137", "name":"Norton County"},
  {"code":"20139", "name":"Osage County"},
  {"code":"20141", "name":"Osborne County"},
  {"code":"20143", "name":"Ottawa County"},
  {"code":"20145", "name":"Pawnee County"},
  {"code":"20147", "name":"Phillips County"},
  {"code":"20149", "name":"Pottawatomie County"},
  {"code":"20151", "name":"Pratt County"},
  {"code":"20153", "name":"Rawlins County"},
  {"code":"20155", "name":"Reno County"},
  {"code":"20157", "name":"Republic County"},
  {"code":"20159", "name":"Rice County"},
  {"code":"20161", "name":"Riley County"},
  {"code":"20163", "name":"Rooks County"},
  {"code":"20165", "name":"Rush County"},
  {"code":"20167", "name":"Russell County"},
  {"code":"20169", "name":"Saline County"},
  {"code":"20171", "name":"Scott County"},
  {"code":"20173", "name":"Sedgwick County"},
  {"code":"20175", "name":"Seward County"},
  {"code":"20177", "name":"Shawnee County"},
  {"code":"20179", "name":"Sheridan County"},
  {"code":"20181", "name":"Sherman County"},
  {"code":"20183", "name":"Smith County"},
  {"code":"20185", "name":"Stafford County"},
  {"code":"20187", "name":"Stanton County"},
  {"code":"20189", "name":"Stevens County"},
  {"code":"20191", "name":"Sumner County"},
  {"code":"20193", "name":"Thomas County"},
  {"code":"20195", "name":"Trego County"},
  {"code":"20197", "name":"Wabaunsee County"},
  {"code":"20199", "name":"Wallace County"},
  {"code":"20201", "name":"Washington County"},
  {"code":"20203", "name":"Wichita County"},
  {"code":"20205", "name":"Wilson County"},
  {"code":"20207", "name":"Woodson County"},
  {"code":"20209", "name":"Wyandotte County"},
  {"code":"21000", "name":"Kentucky"},
  {"code":"21001", "name":"Adair County"},
  {"code":"21003", "name":"Allen County"},
  {"code":"21005", "name":"Anderson County"},
  {"code":"21007", "name":"Ballard County"},
  {"code":"21009", "name":"Barren County"},
  {"code":"21011", "name":"Bath County"},
  {"code":"21013", "name":"Bell County"},
  {"code":"21015", "name":"Boone County"},
  {"code":"21017", "name":"Bourbon County"},
  {"code":"21019", "name":"Boyd County"},
  {"code":"21021", "name":"Boyle County"},
  {"code":"21023", "name":"Bracken County"},
  {"code":"21025", "name":"Breathitt County"},
  {"code":"21027", "name":"Breckinridge County"},
  {"code":"21029", "name":"Bullitt County"},
  {"code":"21031", "name":"Butler County"},
  {"code":"21033", "name":"Caldwell County"},
  {"code":"21035", "name":"Calloway County"},
  {"code":"21037", "name":"Campbell County"},
  {"code":"21039", "name":"Carlisle County"},
  {"code":"21041", "name":"Carroll County"},
  {"code":"21043", "name":"Carter County"},
  {"code":"21045", "name":"Casey County"},
  {"code":"21047", "name":"Christian County"},
  {"code":"21049", "name":"Clark County"},
  {"code":"21051", "name":"Clay County"},
  {"code":"21053", "name":"Clinton County"},
  {"code":"21055", "name":"Crittenden County"},
  {"code":"21057", "name":"Cumberland County"},
  {"code":"21059", "name":"Daviess County"},
  {"code":"21061", "name":"Edmonson County"},
  {"code":"21063", "name":"Elliott County"},
  {"code":"21065", "name":"Estill County"},
  {"code":"21067", "name":"Fayette County"},
  {"code":"21069", "name":"Fleming County"},
  {"code":"21071", "name":"Floyd County"},
  {"code":"21073", "name":"Franklin County"},
  {"code":"21075", "name":"Fulton County"},
  {"code":"21077", "name":"Gallatin County"},
  {"code":"21079", "name":"Garrard County"},
  {"code":"21081", "name":"Grant County"},
  {"code":"21083", "name":"Graves County"},
  {"code":"21085", "name":"Grayson County"},
  {"code":"21087", "name":"Green County"},
  {"code":"21089", "name":"Greenup County"},
  {"code":"21091", "name":"Hancock County"},
  {"code":"21093", "name":"Hardin County"},
  {"code":"21095", "name":"Harlan County"},
  {"code":"21097", "name":"Harrison County"},
  {"code":"21099", "name":"Hart County"},
  {"code":"21101", "name":"Henderson County"},
  {"code":"21103", "name":"Henry County"},
  {"code":"21105", "name":"Hickman County"},
  {"code":"21107", "name":"Hopkins County"},
  {"code":"21109", "name":"Jackson County"},
  {"code":"21111", "name":"Jefferson County"},
  {"code":"21113", "name":"Jessamine County"},
  {"code":"21115", "name":"Johnson County"},
  {"code":"21117", "name":"Kenton County"},
  {"code":"21119", "name":"Knott County"},
  {"code":"21121", "name":"Knox County"},
  {"code":"21123", "name":"Larue County"},
  {"code":"21125", "name":"Laurel County"},
  {"code":"21127", "name":"Lawrence County"},
  {"code":"21129", "name":"Lee County"},
  {"code":"21131", "name":"Leslie County"},
  {"code":"21133", "name":"Letcher County"},
  {"code":"21135", "name":"Lewis County"},
  {"code":"21137", "name":"Lincoln County"},
  {"code":"21139", "name":"Livingston County"},
  {"code":"21141", "name":"Logan County"},
  {"code":"21143", "name":"Lyon County"},
  {"code":"21145", "name":"McCracken County"},
  {"code":"21147", "name":"McCreary County"},
  {"code":"21149", "name":"McLean County"},
  {"code":"21151", "name":"Madison County"},
  {"code":"21153", "name":"Magoffin County"},
  {"code":"21155", "name":"Marion County"},
  {"code":"21157", "name":"Marshall County"},
  {"code":"21159", "name":"Martin County"},
  {"code":"21161", "name":"Mason County"},
  {"code":"21163", "name":"Meade County"},
  {"code":"21165", "name":"Menifee County"},
  {"code":"21167", "name":"Mercer County"},
  {"code":"21169", "name":"Metcalfe County"},
  {"code":"21171", "name":"Monroe County"},
  {"code":"21173", "name":"Montgomery County"},
  {"code":"21175", "name":"Morgan County"},
  {"code":"21177", "name":"Muhlenberg County"},
  {"code":"21179", "name":"Nelson County"},
  {"code":"21181", "name":"Nicholas County"},
  {"code":"21183", "name":"Ohio County"},
  {"code":"21185", "name":"Oldham County"},
  {"code":"21187", "name":"Owen County"},
  {"code":"21189", "name":"Owsley County"},
  {"code":"21191", "name":"Pendleton County"},
  {"code":"21193", "name":"Perry County"},
  {"code":"21195", "name":"Pike County"},
  {"code":"21197", "name":"Powell County"},
  {"code":"21199", "name":"Pulaski County"},
  {"code":"21201", "name":"Robertson County"},
  {"code":"21203", "name":"Rockcastle County"},
  {"code":"21205", "name":"Rowan County"},
  {"code":"21207", "name":"Russell County"},
  {"code":"21209", "name":"Scott County"},
  {"code":"21211", "name":"Shelby County"},
  {"code":"21213", "name":"Simpson County"},
  {"code":"21215", "name":"Spencer County"},
  {"code":"21217", "name":"Taylor County"},
  {"code":"21219", "name":"Todd County"},
  {"code":"21221", "name":"Trigg County"},
  {"code":"21223", "name":"Trimble County"},
  {"code":"21225", "name":"Union County"},
  {"code":"21227", "name":"Warren County"},
  {"code":"21229", "name":"Washington County"},
  {"code":"21231", "name":"Wayne County"},
  {"code":"21233", "name":"Webster County"},
  {"code":"21235", "name":"Whitley County"},
  {"code":"21237", "name":"Wolfe County"},
  {"code":"21239", "name":"Woodford County"},
  {"code":"22000", "name":"Louisiana"},
  {"code":"22001", "name":"Acadia Parish"},
  {"code":"22003", "name":"Allen Parish"},
  {"code":"22005", "name":"Ascension Parish"},
  {"code":"22007", "name":"Assumption Parish"},
  {"code":"22009", "name":"Avoyelles Parish"},
  {"code":"22011", "name":"Beauregard Parish"},
  {"code":"22013", "name":"Bienville Parish"},
  {"code":"22015", "name":"Bossier Parish"},
  {"code":"22017", "name":"Caddo Parish"},
  {"code":"22019", "name":"Calcasieu Parish"},
  {"code":"22021", "name":"Caldwell Parish"},
  {"code":"22023", "name":"Cameron Parish"},
  {"code":"22025", "name":"Catahoula Parish"},
  {"code":"22027", "name":"Claiborne Parish"},
  {"code":"22029", "name":"Concordia Parish"},
  {"code":"22031", "name":"De Soto Parish"},
  {"code":"22033", "name":"East Baton Rouge Parish"},
  {"code":"22035", "name":"East Carroll Parish"},
  {"code":"22037", "name":"East Feliciana Parish"},
  {"code":"22039", "name":"Evangeline Parish"},
  {"code":"22041", "name":"Franklin Parish"},
  {"code":"22043", "name":"Grant Parish"},
  {"code":"22045", "name":"Iberia Parish"},
  {"code":"22047", "name":"Iberville Parish"},
  {"code":"22049", "name":"Jackson Parish"},
  {"code":"22051", "name":"Jefferson Parish"},
  {"code":"22053", "name":"Jefferson Davis Parish"},
  {"code":"22055", "name":"Lafayette Parish"},
  {"code":"22057", "name":"Lafourche Parish"},
  {"code":"22059", "name":"La Salle Parish"},
  {"code":"22061", "name":"Lincoln Parish"},
  {"code":"22063", "name":"Livingston Parish"},
  {"code":"22065", "name":"Madison Parish"},
  {"code":"22067", "name":"Morehouse Parish"},
  {"code":"22069", "name":"Natchitoches Parish"},
  {"code":"22071", "name":"Orleans Parish"},
  {"code":"22073", "name":"Ouachita Parish"},
  {"code":"22075", "name":"Plaquemines Parish"},
  {"code":"22077", "name":"Pointe Coupee Parish"},
  {"code":"22079", "name":"Rapides Parish"},
  {"code":"22081", "name":"Red River Parish"},
  {"code":"22083", "name":"Richland Parish"},
  {"code":"22085", "name":"Sabine Parish"},
  {"code":"22087", "name":"St. Bernard Parish"},
  {"code":"22089", "name":"St. Charles Parish"},
  {"code":"22091", "name":"St. Helena Parish"},
  {"code":"22093", "name":"St. James Parish"},
  {"code":"22095", "name":"St. John the Baptist Parish"},
  {"code":"22097", "name":"St. Landry Parish"},
  {"code":"22099", "name":"St. Martin Parish"},
  {"code":"22101", "name":"St. Mary Parish"},
  {"code":"22103", "name":"St. Tammany Parish"},
  {"code":"22105", "name":"Tangipahoa Parish"},
  {"code":"22107", "name":"Tensas Parish"},
  {"code":"22109", "name":"Terrebonne Parish"},
  {"code":"22111", "name":"Union Parish"},
  {"code":"22113", "name":"Vermilion Parish"},
  {"code":"22115", "name":"Vernon Parish"},
  {"code":"22117", "name":"Washington Parish"},
  {"code":"22119", "name":"Webster Parish"},
  {"code":"22121", "name":"West Baton Rouge Parish"},
  {"code":"22123", "name":"West Carroll Parish"},
  {"code":"22125", "name":"West Feliciana Parish"},
  {"code":"22127", "name":"Winn Parish"},
  {"code":"23000", "name":"Maine"},
  {"code":"23001", "name":"Androscoggin County"},
  {"code":"23003", "name":"Aroostook County"},
  {"code":"23005", "name":"Cumberland County"},
  {"code":"23007", "name":"Franklin County"},
  {"code":"23009", "name":"Hancock County"},
  {"code":"23011", "name":"Kennebec County"},
  {"code":"23013", "name":"Knox County"},
  {"code":"23015", "name":"Lincoln County"},
  {"code":"23017", "name":"Oxford County"},
  {"code":"23019", "name":"Penobscot County"},
  {"code":"23021", "name":"Piscataquis County"},
  {"code":"23023", "name":"Sagadahoc County"},
  {"code":"23025", "name":"Somerset County"},
  {"code":"23027", "name":"Waldo County"},
  {"code":"23029", "name":"Washington County"},
  {"code":"23031", "name":"York County"},
  {"code":"24000", "name":"Maryland"},
  {"code":"24001", "name":"Allegany County"},
  {"code":"24003", "name":"Anne Arundel County"},
  {"code":"24005", "name":"Baltimore County"},
  {"code":"24009", "name":"Calvert County"},
  {"code":"24011", "name":"Caroline County"},
  {"code":"24013", "name":"Carroll County"},
  {"code":"24015", "name":"Cecil County"},
  {"code":"24017", "name":"Charles County"},
  {"code":"24019", "name":"Dorchester County"},
  {"code":"24021", "name":"Frederick County"},
  {"code":"24023", "name":"Garrett County"},
  {"code":"24025", "name":"Harford County"},
  {"code":"24027", "name":"Howard County"},
  {"code":"24029", "name":"Kent County"},
  {"code":"24031", "name":"Montgomery County"},
  {"code":"24033", "name":"Prince George"},
  {"code":"24035", "name":"Queen Anne"},
  {"code":"24037", "name":"St. Mary"},
  {"code":"24039", "name":"Somerset County"},
  {"code":"24041", "name":"Talbot County"},
  {"code":"24043", "name":"Washington County"},
  {"code":"24045", "name":"Wicomico County"},
  {"code":"24047", "name":"Worcester County"},
  {"code":"24510", "name":"Baltimore city"},
  {"code":"25000", "name":"Massachusetts"},
  {"code":"25001", "name":"Barnstable County"},
  {"code":"25003", "name":"Berkshire County"},
  {"code":"25005", "name":"Bristol County"},
  {"code":"25007", "name":"Dukes County"},
  {"code":"25009", "name":"Essex County"},
  {"code":"25011", "name":"Franklin County"},
  {"code":"25013", "name":"Hampden County"},
  {"code":"25015", "name":"Hampshire County"},
  {"code":"25017", "name":"Middlesex County"},
  {"code":"25019", "name":"Nantucket County"},
  {"code":"25021", "name":"Norfolk County"},
  {"code":"25023", "name":"Plymouth County"},
  {"code":"25025", "name":"Suffolk County"},
  {"code":"25027", "name":"Worcester County"},
  {"code":"26000", "name":"Michigan"},
  {"code":"26001", "name":"Alcona County"},
  {"code":"26003", "name":"Alger County"},
  {"code":"26005", "name":"Allegan County"},
  {"code":"26007", "name":"Alpena County"},
  {"code":"26009", "name":"Antrim County"},
  {"code":"26011", "name":"Arenac County"},
  {"code":"26013", "name":"Baraga County"},
  {"code":"26015", "name":"Barry County"},
  {"code":"26017", "name":"Bay County"},
  {"code":"26019", "name":"Benzie County"},
  {"code":"26021", "name":"Berrien County"},
  {"code":"26023", "name":"Branch County"},
  {"code":"26025", "name":"Calhoun County"},
  {"code":"26027", "name":"Cass County"},
  {"code":"26029", "name":"Charlevoix County"},
  {"code":"26031", "name":"Cheboygan County"},
  {"code":"26033", "name":"Chippewa County"},
  {"code":"26035", "name":"Clare County"},
  {"code":"26037", "name":"Clinton County"},
  {"code":"26039", "name":"Crawford County"},
  {"code":"26041", "name":"Delta County"},
  {"code":"26043", "name":"Dickinson County"},
  {"code":"26045", "name":"Eaton County"},
  {"code":"26047", "name":"Emmet County"},
  {"code":"26049", "name":"Genesee County"},
  {"code":"26051", "name":"Gladwin County"},
  {"code":"26053", "name":"Gogebic County"},
  {"code":"26055", "name":"Grand Traverse County"},
  {"code":"26057", "name":"Gratiot County"},
  {"code":"26059", "name":"Hillsdale County"},
  {"code":"26061", "name":"Houghton County"},
  {"code":"26063", "name":"Huron County"},
  {"code":"26065", "name":"Ingham County"},
  {"code":"26067", "name":"Ionia County"},
  {"code":"26069", "name":"Iosco County"},
  {"code":"26071", "name":"Iron County"},
  {"code":"26073", "name":"Isabella County"},
  {"code":"26075", "name":"Jackson County"},
  {"code":"26077", "name":"Kalamazoo County"},
  {"code":"26079", "name":"Kalkaska County"},
  {"code":"26081", "name":"Kent County"},
  {"code":"26083", "name":"Keweenaw County"},
  {"code":"26085", "name":"Lake County"},
  {"code":"26087", "name":"Lapeer County"},
  {"code":"26089", "name":"Leelanau County"},
  {"code":"26091", "name":"Lenawee County"},
  {"code":"26093", "name":"Livingston County"},
  {"code":"26095", "name":"Luce County"},
  {"code":"26097", "name":"Mackinac County"},
  {"code":"26099", "name":"Macomb County"},
  {"code":"26101", "name":"Manistee County"},
  {"code":"26103", "name":"Marquette County"},
  {"code":"26105", "name":"Mason County"},
  {"code":"26107", "name":"Mecosta County"},
  {"code":"26109", "name":"Menominee County"},
  {"code":"26111", "name":"Midland County"},
  {"code":"26113", "name":"Missaukee County"},
  {"code":"26115", "name":"Monroe County"},
  {"code":"26117", "name":"Montcalm County"},
  {"code":"26119", "name":"Montmorency County"},
  {"code":"26121", "name":"Muskegon County"},
  {"code":"26123", "name":"Newaygo County"},
  {"code":"26125", "name":"Oakland County"},
  {"code":"26127", "name":"Oceana County"},
  {"code":"26129", "name":"Ogemaw County"},
  {"code":"26131", "name":"Ontonagon County"},
  {"code":"26133", "name":"Osceola County"},
  {"code":"26135", "name":"Oscoda County"},
  {"code":"26137", "name":"Otsego County"},
  {"code":"26139", "name":"Ottawa County"},
  {"code":"26141", "name":"Presque Isle County"},
  {"code":"26143", "name":"Roscommon County"},
  {"code":"26145", "name":"Saginaw County"},
  {"code":"26147", "name":"St. Clair County"},
  {"code":"26149", "name":"St. Joseph County"},
  {"code":"26151", "name":"Sanilac County"},
  {"code":"26153", "name":"Schoolcraft County"},
  {"code":"26155", "name":"Shiawassee County"},
  {"code":"26157", "name":"Tuscola County"},
  {"code":"26159", "name":"Van Buren County"},
  {"code":"26161", "name":"Washtenaw County"},
  {"code":"26163", "name":"Wayne County"},
  {"code":"26165", "name":"Wexford County"},
  {"code":"27000", "name":"Minnesota"},
  {"code":"27001", "name":"Aitkin County"},
  {"code":"27003", "name":"Anoka County"},
  {"code":"27005", "name":"Becker County"},
  {"code":"27007", "name":"Beltrami County"},
  {"code":"27009", "name":"Benton County"},
  {"code":"27011", "name":"Big Stone County"},
  {"code":"27013", "name":"Blue Earth County"},
  {"code":"27015", "name":"Brown County"},
  {"code":"27017", "name":"Carlton County"},
  {"code":"27019", "name":"Carver County"},
  {"code":"27021", "name":"Cass County"},
  {"code":"27023", "name":"Chippewa County"},
  {"code":"27025", "name":"Chisago County"},
  {"code":"27027", "name":"Clay County"},
  {"code":"27029", "name":"Clearwater County"},
  {"code":"27031", "name":"Cook County"},
  {"code":"27033", "name":"Cottonwood County"},
  {"code":"27035", "name":"Crow Wing County"},
  {"code":"27037", "name":"Dakota County"},
  {"code":"27039", "name":"Dodge County"},
  {"code":"27041", "name":"Douglas County"},
  {"code":"27043", "name":"Faribault County"},
  {"code":"27045", "name":"Fillmore County"},
  {"code":"27047", "name":"Freeborn County"},
  {"code":"27049", "name":"Goodhue County"},
  {"code":"27051", "name":"Grant County"},
  {"code":"27053", "name":"Hennepin County"},
  {"code":"27055", "name":"Houston County"},
  {"code":"27057", "name":"Hubbard County"},
  {"code":"27059", "name":"Isanti County"},
  {"code":"27061", "name":"Itasca County"},
  {"code":"27063", "name":"Jackson County"},
  {"code":"27065", "name":"Kanabec County"},
  {"code":"27067", "name":"Kandiyohi County"},
  {"code":"27069", "name":"Kittson County"},
  {"code":"27071", "name":"Koochiching County"},
  {"code":"27073", "name":"Lac qui Parle County"},
  {"code":"27075", "name":"Lake County"},
  {"code":"27077", "name":"Lake of the Woods County"},
  {"code":"27079", "name":"Le Sueur County"},
  {"code":"27081", "name":"Lincoln County"},
  {"code":"27083", "name":"Lyon County"},
  {"code":"27085", "name":"McLeod County"},
  {"code":"27087", "name":"Mahnomen County"},
  {"code":"27089", "name":"Marshall County"},
  {"code":"27091", "name":"Martin County"},
  {"code":"27093", "name":"Meeker County"},
  {"code":"27095", "name":"Mille Lacs County"},
  {"code":"27097", "name":"Morrison County"},
  {"code":"27099", "name":"Mower County"},
  {"code":"27101", "name":"Murray County"},
  {"code":"27103", "name":"Nicollet County"},
  {"code":"27105", "name":"Nobles County"},
  {"code":"27107", "name":"Norman County"},
  {"code":"27109", "name":"Olmsted County"},
  {"code":"27111", "name":"Otter Tail County"},
  {"code":"27113", "name":"Pennington County"},
  {"code":"27115", "name":"Pine County"},
  {"code":"27117", "name":"Pipestone County"},
  {"code":"27119", "name":"Polk County"},
  {"code":"27121", "name":"Pope County"},
  {"code":"27123", "name":"Ramsey County"},
  {"code":"27125", "name":"Red Lake County"},
  {"code":"27127", "name":"Redwood County"},
  {"code":"27129", "name":"Renville County"},
  {"code":"27131", "name":"Rice County"},
  {"code":"27133", "name":"Rock County"},
  {"code":"27135", "name":"Roseau County"},
  {"code":"27137", "name":"St. Louis County"},
  {"code":"27139", "name":"Scott County"},
  {"code":"27141", "name":"Sherburne County"},
  {"code":"27143", "name":"Sibley County"},
  {"code":"27145", "name":"Stearns County"},
  {"code":"27147", "name":"Steele County"},
  {"code":"27149", "name":"Stevens County"},
  {"code":"27151", "name":"Swift County"},
  {"code":"27153", "name":"Todd County"},
  {"code":"27155", "name":"Traverse County"},
  {"code":"27157", "name":"Wabasha County"},
  {"code":"27159", "name":"Wadena County"},
  {"code":"27161", "name":"Waseca County"},
  {"code":"27163", "name":"Washington County"},
  {"code":"27165", "name":"Watonwan County"},
  {"code":"27167", "name":"Wilkin County"},
  {"code":"27169", "name":"Winona County"},
  {"code":"27171", "name":"Wright County"},
  {"code":"27173", "name":"Yellow Medicine County"},
  {"code":"28000", "name":"Mississippi"},
  {"code":"28001", "name":"Adams County"},
  {"code":"28003", "name":"Alcorn County"},
  {"code":"28005", "name":"Amite County"},
  {"code":"28007", "name":"Attala County"},
  {"code":"28009", "name":"Benton County"},
  {"code":"28011", "name":"Bolivar County"},
  {"code":"28013", "name":"Calhoun County"},
  {"code":"28015", "name":"Carroll County"},
  {"code":"28017", "name":"Chickasaw County"},
  {"code":"28019", "name":"Choctaw County"},
  {"code":"28021", "name":"Claiborne County"},
  {"code":"28023", "name":"Clarke County"},
  {"code":"28025", "name":"Clay County"},
  {"code":"28027", "name":"Coahoma County"},
  {"code":"28029", "name":"Copiah County"},
  {"code":"28031", "name":"Covington County"},
  {"code":"28033", "name":"DeSoto County"},
  {"code":"28035", "name":"Forrest County"},
  {"code":"28037", "name":"Franklin County"},
  {"code":"28039", "name":"George County"},
  {"code":"28041", "name":"Greene County"},
  {"code":"28043", "name":"Grenada County"},
  {"code":"28045", "name":"Hancock County"},
  {"code":"28047", "name":"Harrison County"},
  {"code":"28049", "name":"Hinds County"},
  {"code":"28051", "name":"Holmes County"},
  {"code":"28053", "name":"Humphreys County"},
  {"code":"28055", "name":"Issaquena County"},
  {"code":"28057", "name":"Itawamba County"},
  {"code":"28059", "name":"Jackson County"},
  {"code":"28061", "name":"Jasper County"},
  {"code":"28063", "name":"Jefferson County"},
  {"code":"28065", "name":"Jefferson Davis County"},
  {"code":"28067", "name":"Jones County"},
  {"code":"28069", "name":"Kemper County"},
  {"code":"28071", "name":"Lafayette County"},
  {"code":"28073", "name":"Lamar County"},
  {"code":"28075", "name":"Lauderdale County"},
  {"code":"28077", "name":"Lawrence County"},
  {"code":"28079", "name":"Leake County"},
  {"code":"28081", "name":"Lee County"},
  {"code":"28083", "name":"Leflore County"},
  {"code":"28085", "name":"Lincoln County"},
  {"code":"28087", "name":"Lowndes County"},
  {"code":"28089", "name":"Madison County"},
  {"code":"28091", "name":"Marion County"},
  {"code":"28093", "name":"Marshall County"},
  {"code":"28095", "name":"Monroe County"},
  {"code":"28097", "name":"Montgomery County"},
  {"code":"28099", "name":"Neshoba County"},
  {"code":"28101", "name":"Newton County"},
  {"code":"28103", "name":"Noxubee County"},
  {"code":"28105", "name":"Oktibbeha County"},
  {"code":"28107", "name":"Panola County"},
  {"code":"28109", "name":"Pearl River County"},
  {"code":"28111", "name":"Perry County"},
  {"code":"28113", "name":"Pike County"},
  {"code":"28115", "name":"Pontotoc County"},
  {"code":"28117", "name":"Prentiss County"},
  {"code":"28119", "name":"Quitman County"},
  {"code":"28121", "name":"Rankin County"},
  {"code":"28123", "name":"Scott County"},
  {"code":"28125", "name":"Sharkey County"},
  {"code":"28127", "name":"Simpson County"},
  {"code":"28129", "name":"Smith County"},
  {"code":"28131", "name":"Stone County"},
  {"code":"28133", "name":"Sunflower County"},
  {"code":"28135", "name":"Tallahatchie County"},
  {"code":"28137", "name":"Tate County"},
  {"code":"28139", "name":"Tippah County"},
  {"code":"28141", "name":"Tishomingo County"},
  {"code":"28143", "name":"Tunica County"},
  {"code":"28145", "name":"Union County"},
  {"code":"28147", "name":"Walthall County"},
  {"code":"28149", "name":"Warren County"},
  {"code":"28151", "name":"Washington County"},
  {"code":"28153", "name":"Wayne County"},
  {"code":"28155", "name":"Webster County"},
  {"code":"28157", "name":"Wilkinson County"},
  {"code":"28159", "name":"Winston County"},
  {"code":"28161", "name":"Yalobusha County"},
  {"code":"28163", "name":"Yazoo County"},
  {"code":"29000", "name":"Missouri"},
  {"code":"29001", "name":"Adair County"},
  {"code":"29003", "name":"Andrew County"},
  {"code":"29005", "name":"Atchison County"},
  {"code":"29007", "name":"Audrain County"},
  {"code":"29009", "name":"Barry County"},
  {"code":"29011", "name":"Barton County"},
  {"code":"29013", "name":"Bates County"},
  {"code":"29015", "name":"Benton County"},
  {"code":"29017", "name":"Bollinger County"},
  {"code":"29019", "name":"Boone County"},
  {"code":"29021", "name":"Buchanan County"},
  {"code":"29023", "name":"Butler County"},
  {"code":"29025", "name":"Caldwell County"},
  {"code":"29027", "name":"Callaway County"},
  {"code":"29029", "name":"Camden County"},
  {"code":"29031", "name":"Cape Girardeau County"},
  {"code":"29033", "name":"Carroll County"},
  {"code":"29035", "name":"Carter County"},
  {"code":"29037", "name":"Cass County"},
  {"code":"29039", "name":"Cedar County"},
  {"code":"29041", "name":"Chariton County"},
  {"code":"29043", "name":"Christian County"},
  {"code":"29045", "name":"Clark County"},
  {"code":"29047", "name":"Clay County"},
  {"code":"29049", "name":"Clinton County"},
  {"code":"29051", "name":"Cole County"},
  {"code":"29053", "name":"Cooper County"},
  {"code":"29055", "name":"Crawford County"},
  {"code":"29057", "name":"Dade County"},
  {"code":"29059", "name":"Dallas County"},
  {"code":"29061", "name":"Daviess County"},
  {"code":"29063", "name":"DeKalb County"},
  {"code":"29065", "name":"Dent County"},
  {"code":"29067", "name":"Douglas County"},
  {"code":"29069", "name":"Dunklin County"},
  {"code":"29071", "name":"Franklin County"},
  {"code":"29073", "name":"Gasconade County"},
  {"code":"29075", "name":"Gentry County"},
  {"code":"29077", "name":"Greene County"},
  {"code":"29079", "name":"Grundy County"},
  {"code":"29081", "name":"Harrison County"},
  {"code":"29083", "name":"Henry County"},
  {"code":"29085", "name":"Hickory County"},
  {"code":"29087", "name":"Holt County"},
  {"code":"29089", "name":"Howard County"},
  {"code":"29091", "name":"Howell County"},
  {"code":"29093", "name":"Iron County"},
  {"code":"29095", "name":"Jackson County"},
  {"code":"29097", "name":"Jasper County"},
  {"code":"29099", "name":"Jefferson County"},
  {"code":"29101", "name":"Johnson County"},
  {"code":"29103", "name":"Knox County"},
  {"code":"29105", "name":"Laclede County"},
  {"code":"29107", "name":"Lafayette County"},
  {"code":"29109", "name":"Lawrence County"},
  {"code":"29111", "name":"Lewis County"},
  {"code":"29113", "name":"Lincoln County"},
  {"code":"29115", "name":"Linn County"},
  {"code":"29117", "name":"Livingston County"},
  {"code":"29119", "name":"McDonald County"},
  {"code":"29121", "name":"Macon County"},
  {"code":"29123", "name":"Madison County"},
  {"code":"29125", "name":"Maries County"},
  {"code":"29127", "name":"Marion County"},
  {"code":"29129", "name":"Mercer County"},
  {"code":"29131", "name":"Miller County"},
  {"code":"29133", "name":"Mississippi County"},
  {"code":"29135", "name":"Moniteau County"},
  {"code":"29137", "name":"Monroe County"},
  {"code":"29139", "name":"Montgomery County"},
  {"code":"29141", "name":"Morgan County"},
  {"code":"29143", "name":"New Madrid County"},
  {"code":"29145", "name":"Newton County"},
  {"code":"29147", "name":"Nodaway County"},
  {"code":"29149", "name":"Oregon County"},
  {"code":"29151", "name":"Osage County"},
  {"code":"29153", "name":"Ozark County"},
  {"code":"29155", "name":"Pemiscot County"},
  {"code":"29157", "name":"Perry County"},
  {"code":"29159", "name":"Pettis County"},
  {"code":"29161", "name":"Phelps County"},
  {"code":"29163", "name":"Pike County"},
  {"code":"29165", "name":"Platte County"},
  {"code":"29167", "name":"Polk County"},
  {"code":"29169", "name":"Pulaski County"},
  {"code":"29171", "name":"Putnam County"},
  {"code":"29173", "name":"Ralls County"},
  {"code":"29175", "name":"Randolph County"},
  {"code":"29177", "name":"Ray County"},
  {"code":"29179", "name":"Reynolds County"},
  {"code":"29181", "name":"Ripley County"},
  {"code":"29183", "name":"St. Charles County"},
  {"code":"29185", "name":"St. Clair County"},
  {"code":"29186", "name":"Ste. Genevieve County"},
  {"code":"29187", "name":"St. Francois County"},
  {"code":"29189", "name":"St. Louis County"},
  {"code":"29195", "name":"Saline County"},
  {"code":"29197", "name":"Schuyler County"},
  {"code":"29199", "name":"Scotland County"},
  {"code":"29201", "name":"Scott County"},
  {"code":"29203", "name":"Shannon County"},
  {"code":"29205", "name":"Shelby County"},
  {"code":"29207", "name":"Stoddard County"},
  {"code":"29209", "name":"Stone County"},
  {"code":"29211", "name":"Sullivan County"},
  {"code":"29213", "name":"Taney County"},
  {"code":"29215", "name":"Texas County"},
  {"code":"29217", "name":"Vernon County"},
  {"code":"29219", "name":"Warren County"},
  {"code":"29221", "name":"Washington County"},
  {"code":"29223", "name":"Wayne County"},
  {"code":"29225", "name":"Webster County"},
  {"code":"29227", "name":"Worth County"},
  {"code":"29229", "name":"Wright County"},
  {"code":"29510", "name":"St. Louis city"},
  {"code":"30000", "name":"Montana"},
  {"code":"30001", "name":"Beaverhead County"},
  {"code":"30003", "name":"Big Horn County"},
  {"code":"30005", "name":"Blaine County"},
  {"code":"30007", "name":"Broadwater County"},
  {"code":"30009", "name":"Carbon County"},
  {"code":"30011", "name":"Carter County"},
  {"code":"30013", "name":"Cascade County"},
  {"code":"30015", "name":"Chouteau County"},
  {"code":"30017", "name":"Custer County"},
  {"code":"30019", "name":"Daniels County"},
  {"code":"30021", "name":"Dawson County"},
  {"code":"30023", "name":"Deer Lodge County"},
  {"code":"30025", "name":"Fallon County"},
  {"code":"30027", "name":"Fergus County"},
  {"code":"30029", "name":"Flathead County"},
  {"code":"30031", "name":"Gallatin County"},
  {"code":"30033", "name":"Garfield County"},
  {"code":"30035", "name":"Glacier County"},
  {"code":"30037", "name":"Golden Valley County"},
  {"code":"30039", "name":"Granite County"},
  {"code":"30041", "name":"Hill County"},
  {"code":"30043", "name":"Jefferson County"},
  {"code":"30045", "name":"Judith Basin County"},
  {"code":"30047", "name":"Lake County"},
  {"code":"30049", "name":"Lewis and Clark County"},
  {"code":"30051", "name":"Liberty County"},
  {"code":"30053", "name":"Lincoln County"},
  {"code":"30055", "name":"McCone County"},
  {"code":"30057", "name":"Madison County"},
  {"code":"30059", "name":"Meagher County"},
  {"code":"30061", "name":"Mineral County"},
  {"code":"30063", "name":"Missoula County"},
  {"code":"30065", "name":"Musselshell County"},
  {"code":"30067", "name":"Park County"},
  {"code":"30069", "name":"Petroleum County"},
  {"code":"30071", "name":"Phillips County"},
  {"code":"30073", "name":"Pondera County"},
  {"code":"30075", "name":"Powder River County"},
  {"code":"30077", "name":"Powell County"},
  {"code":"30079", "name":"Prairie County"},
  {"code":"30081", "name":"Ravalli County"},
  {"code":"30083", "name":"Richland County"},
  {"code":"30085", "name":"Roosevelt County"},
  {"code":"30087", "name":"Rosebud County"},
  {"code":"30089", "name":"Sanders County"},
  {"code":"30091", "name":"Sheridan County"},
  {"code":"30093", "name":"Silver Bow County"},
  {"code":"30095", "name":"Stillwater County"},
  {"code":"30097", "name":"Sweet Grass County"},
  {"code":"30099", "name":"Teton County"},
  {"code":"30101", "name":"Toole County"},
  {"code":"30103", "name":"Treasure County"},
  {"code":"30105", "name":"Valley County"},
  {"code":"30107", "name":"Wheatland County"},
  {"code":"30109", "name":"Wibaux County"},
  {"code":"30111", "name":"Yellowstone County"},
  {"code":"30113", "name":"Yellowstone National Park"},
  {"code":"31000", "name":"Nebraska"},
  {"code":"31001", "name":"Adams County"},
  {"code":"31003", "name":"Antelope County"},
  {"code":"31005", "name":"Arthur County"},
  {"code":"31007", "name":"Banner County"},
  {"code":"31009", "name":"Blaine County"},
  {"code":"31011", "name":"Boone County"},
  {"code":"31013", "name":"Box Butte County"},
  {"code":"31015", "name":"Boyd County"},
  {"code":"31017", "name":"Brown County"},
  {"code":"31019", "name":"Buffalo County"},
  {"code":"31021", "name":"Burt County"},
  {"code":"31023", "name":"Butler County"},
  {"code":"31025", "name":"Cass County"},
  {"code":"31027", "name":"Cedar County"},
  {"code":"31029", "name":"Chase County"},
  {"code":"31031", "name":"Cherry County"},
  {"code":"31033", "name":"Cheyenne County"},
  {"code":"31035", "name":"Clay County"},
  {"code":"31037", "name":"Colfax County"},
  {"code":"31039", "name":"Cuming County"},
  {"code":"31041", "name":"Custer County"},
  {"code":"31043", "name":"Dakota County"},
  {"code":"31045", "name":"Dawes County"},
  {"code":"31047", "name":"Dawson County"},
  {"code":"31049", "name":"Deuel County"},
  {"code":"31051", "name":"Dixon County"},
  {"code":"31053", "name":"Dodge County"},
  {"code":"31055", "name":"Douglas County"},
  {"code":"31057", "name":"Dundy County"},
  {"code":"31059", "name":"Fillmore County"},
  {"code":"31061", "name":"Franklin County"},
  {"code":"31063", "name":"Frontier County"},
  {"code":"31065", "name":"Furnas County"},
  {"code":"31067", "name":"Gage County"},
  {"code":"31069", "name":"Garden County"},
  {"code":"31071", "name":"Garfield County"},
  {"code":"31073", "name":"Gosper County"},
  {"code":"31075", "name":"Grant County"},
  {"code":"31077", "name":"Greeley County"},
  {"code":"31079", "name":"Hall County"},
  {"code":"31081", "name":"Hamilton County"},
  {"code":"31083", "name":"Harlan County"},
  {"code":"31085", "name":"Hayes County"},
  {"code":"31087", "name":"Hitchcock County"},
  {"code":"31089", "name":"Holt County"},
  {"code":"31091", "name":"Hooker County"},
  {"code":"31093", "name":"Howard County"},
  {"code":"31095", "name":"Jefferson County"},
  {"code":"31097", "name":"Johnson County"},
  {"code":"31099", "name":"Kearney County"},
  {"code":"31101", "name":"Keith County"},
  {"code":"31103", "name":"Keya Paha County"},
  {"code":"31105", "name":"Kimball County"},
  {"code":"31107", "name":"Knox County"},
  {"code":"31109", "name":"Lancaster County"},
  {"code":"31111", "name":"Lincoln County"},
  {"code":"31113", "name":"Logan County"},
  {"code":"31115", "name":"Loup County"},
  {"code":"31117", "name":"McPherson County"},
  {"code":"31119", "name":"Madison County"},
  {"code":"31121", "name":"Merrick County"},
  {"code":"31123", "name":"Morrill County"},
  {"code":"31125", "name":"Nance County"},
  {"code":"31127", "name":"Nemaha County"},
  {"code":"31129", "name":"Nuckolls County"},
  {"code":"31131", "name":"Otoe County"},
  {"code":"31133", "name":"Pawnee County"},
  {"code":"31135", "name":"Perkins County"},
  {"code":"31137", "name":"Phelps County"},
  {"code":"31139", "name":"Pierce County"},
  {"code":"31141", "name":"Platte County"},
  {"code":"31143", "name":"Polk County"},
  {"code":"31145", "name":"Red Willow County"},
  {"code":"31147", "name":"Richardson County"},
  {"code":"31149", "name":"Rock County"},
  {"code":"31151", "name":"Saline County"},
  {"code":"31153", "name":"Sarpy County"},
  {"code":"31155", "name":"Saunders County"},
  {"code":"31157", "name":"Scotts Bluff County"},
  {"code":"31159", "name":"Seward County"},
  {"code":"31161", "name":"Sheridan County"},
  {"code":"31163", "name":"Sherman County"},
  {"code":"31165", "name":"Sioux County"},
  {"code":"31167", "name":"Stanton County"},
  {"code":"31169", "name":"Thayer County"},
  {"code":"31171", "name":"Thomas County"},
  {"code":"31173", "name":"Thurston County"},
  {"code":"31175", "name":"Valley County"},
  {"code":"31177", "name":"Washington County"},
  {"code":"31179", "name":"Wayne County"},
  {"code":"31181", "name":"Webster County"},
  {"code":"31183", "name":"Wheeler County"},
  {"code":"31185", "name":"York County"},
  {"code":"32000", "name":"Nevada"},
  {"code":"32001", "name":"Churchill County"},
  {"code":"32003", "name":"Clark County"},
  {"code":"32005", "name":"Douglas County"},
  {"code":"32007", "name":"Elko County"},
  {"code":"32009", "name":"Esmeralda County"},
  {"code":"32011", "name":"Eureka County"},
  {"code":"32013", "name":"Humboldt County"},
  {"code":"32015", "name":"Lander County"},
  {"code":"32017", "name":"Lincoln County"},
  {"code":"32019", "name":"Lyon County"},
  {"code":"32021", "name":"Mineral County"},
  {"code":"32023", "name":"Nye County"},
  {"code":"32027", "name":"Pershing County"},
  {"code":"32029", "name":"Storey County"},
  {"code":"32031", "name":"Washoe County"},
  {"code":"32033", "name":"White Pine County"},
  {"code":"32510", "name":"Carson City"},
  {"code":"33000", "name":"New Hampshire"},
  {"code":"33001", "name":"Belknap County"},
  {"code":"33003", "name":"Carroll County"},
  {"code":"33005", "name":"Cheshire County"},
  {"code":"33007", "name":"Coos County"},
  {"code":"33009", "name":"Grafton County"},
  {"code":"33011", "name":"Hillsborough County"},
  {"code":"33013", "name":"Merrimack County"},
  {"code":"33015", "name":"Rockingham County"},
  {"code":"33017", "name":"Strafford County"},
  {"code":"33019", "name":"Sullivan County"},
  {"code":"34000", "name":"New Jersey"},
  {"code":"34001", "name":"Atlantic County"},
  {"code":"34003", "name":"Bergen County"},
  {"code":"34005", "name":"Burlington County"},
  {"code":"34007", "name":"Camden County"},
  {"code":"34009", "name":"Cape May County"},
  {"code":"34011", "name":"Cumberland County"},
  {"code":"34013", "name":"Essex County"},
  {"code":"34015", "name":"Gloucester County"},
  {"code":"34017", "name":"Hudson County"},
  {"code":"34019", "name":"Hunterdon County"},
  {"code":"34021", "name":"Mercer County"},
  {"code":"34023", "name":"Middlesex County"},
  {"code":"34025", "name":"Monmouth County"},
  {"code":"34027", "name":"Morris County"},
  {"code":"34029", "name":"Ocean County"},
  {"code":"34031", "name":"Passaic County"},
  {"code":"34033", "name":"Salem County"},
  {"code":"34035", "name":"Somerset County"},
  {"code":"34037", "name":"Sussex County"},
  {"code":"34039", "name":"Union County"},
  {"code":"34041", "name":"Warren County"},
  {"code":"35000", "name":"New Mexico"},
  {"code":"35001", "name":"Bernalillo County"},
  {"code":"35003", "name":"Catron County"},
  {"code":"35005", "name":"Chaves County"},
  {"code":"35006", "name":"Cibola County"},
  {"code":"35007", "name":"Colfax County"},
  {"code":"35009", "name":"Curry County"},
  {"code":"35011", "name":"DeBaca County"},
  {"code":"35013", "name":"Dona Ana County"},
  {"code":"35015", "name":"Eddy County"},
  {"code":"35017", "name":"Grant County"},
  {"code":"35019", "name":"Guadalupe County"},
  {"code":"35021", "name":"Harding County"},
  {"code":"35023", "name":"Hidalgo County"},
  {"code":"35025", "name":"Lea County"},
  {"code":"35027", "name":"Lincoln County"},
  {"code":"35028", "name":"Los Alamos County"},
  {"code":"35029", "name":"Luna County"},
  {"code":"35031", "name":"McKinley County"},
  {"code":"35033", "name":"Mora County"},
  {"code":"35035", "name":"Otero County"},
  {"code":"35037", "name":"Quay County"},
  {"code":"35039", "name":"Rio Arriba County"},
  {"code":"35041", "name":"Roosevelt County"},
  {"code":"35043", "name":"Sandoval County"},
  {"code":"35045", "name":"San Juan County"},
  {"code":"35047", "name":"San Miguel County"},
  {"code":"35049", "name":"Santa Fe County"},
  {"code":"35051", "name":"Sierra County"},
  {"code":"35053", "name":"Socorro County"},
  {"code":"35055", "name":"Taos County"},
  {"code":"35057", "name":"Torrance County"},
  {"code":"35059", "name":"Union County"},
  {"code":"35061", "name":"Valencia County"},
  {"code":"36000", "name":"New York"},
  {"code":"36001", "name":"Albany County"},
  {"code":"36003", "name":"Allegany County"},
  {"code":"36005", "name":"Bronx County"},
  {"code":"36007", "name":"Broome County"},
  {"code":"36009", "name":"Cattaraugus County"},
  {"code":"36011", "name":"Cayuga County"},
  {"code":"36013", "name":"Chautauqua County"},
  {"code":"36015", "name":"Chemung County"},
  {"code":"36017", "name":"Chenango County"},
  {"code":"36019", "name":"Clinton County"},
  {"code":"36021", "name":"Columbia County"},
  {"code":"36023", "name":"Cortland County"},
  {"code":"36025", "name":"Delaware County"},
  {"code":"36027", "name":"Dutchess County"},
  {"code":"36029", "name":"Erie County"},
  {"code":"36031", "name":"Essex County"},
  {"code":"36033", "name":"Franklin County"},
  {"code":"36035", "name":"Fulton County"},
  {"code":"36037", "name":"Genesee County"},
  {"code":"36039", "name":"Greene County"},
  {"code":"36041", "name":"Hamilton County"},
  {"code":"36043", "name":"Herkimer County"},
  {"code":"36045", "name":"Jefferson County"},
  {"code":"36047", "name":"Kings County"},
  {"code":"36049", "name":"Lewis County"},
  {"code":"36051", "name":"Livingston County"},
  {"code":"36053", "name":"Madison County"},
  {"code":"36055", "name":"Monroe County"},
  {"code":"36057", "name":"Montgomery County"},
  {"code":"36059", "name":"Nassau County"},
  {"code":"36061", "name":"New York County"},
  {"code":"36063", "name":"Niagara County"},
  {"code":"36065", "name":"Oneida County"},
  {"code":"36067", "name":"Onondaga County"},
  {"code":"36069", "name":"Ontario County"},
  {"code":"36071", "name":"Orange County"},
  {"code":"36073", "name":"Orleans County"},
  {"code":"36075", "name":"Oswego County"},
  {"code":"36077", "name":"Otsego County"},
  {"code":"36079", "name":"Putnam County"},
  {"code":"36081", "name":"Queens County"},
  {"code":"36083", "name":"Rensselaer County"},
  {"code":"36085", "name":"Richmond County"},
  {"code":"36087", "name":"Rockland County"},
  {"code":"36089", "name":"St. Lawrence County"},
  {"code":"36091", "name":"Saratoga County"},
  {"code":"36093", "name":"Schenectady County"},
  {"code":"36095", "name":"Schoharie County"},
  {"code":"36097", "name":"Schuyler County"},
  {"code":"36099", "name":"Seneca County"},
  {"code":"36101", "name":"Steuben County"},
  {"code":"36103", "name":"Suffolk County"},
  {"code":"36105", "name":"Sullivan County"},
  {"code":"36107", "name":"Tioga County"},
  {"code":"36109", "name":"Tompkins County"},
  {"code":"36111", "name":"Ulster County"},
  {"code":"36113", "name":"Warren County"},
  {"code":"36115", "name":"Washington County"},
  {"code":"36117", "name":"Wayne County"},
  {"code":"36119", "name":"Westchester County"},
  {"code":"36121", "name":"Wyoming County"},
  {"code":"36123", "name":"Yates County"},
  {"code":"37000", "name":"North Carolina"},
  {"code":"37001", "name":"Alamance County"},
  {"code":"37003", "name":"Alexander County"},
  {"code":"37005", "name":"Alleghany County"},
  {"code":"37007", "name":"Anson County"},
  {"code":"37009", "name":"Ashe County"},
  {"code":"37011", "name":"Avery County"},
  {"code":"37013", "name":"Beaufort County"},
  {"code":"37015", "name":"Bertie County"},
  {"code":"37017", "name":"Bladen County"},
  {"code":"37019", "name":"Brunswick County"},
  {"code":"37021", "name":"Buncombe County"},
  {"code":"37023", "name":"Burke County"},
  {"code":"37025", "name":"Cabarrus County"},
  {"code":"37027", "name":"Caldwell County"},
  {"code":"37029", "name":"Camden County"},
  {"code":"37031", "name":"Carteret County"},
  {"code":"37033", "name":"Caswell County"},
  {"code":"37035", "name":"Catawba County"},
  {"code":"37037", "name":"Chatham County"},
  {"code":"37039", "name":"Cherokee County"},
  {"code":"37041", "name":"Chowan County"},
  {"code":"37043", "name":"Clay County"},
  {"code":"37045", "name":"Cleveland County"},
  {"code":"37047", "name":"Columbus County"},
  {"code":"37049", "name":"Craven County"},
  {"code":"37051", "name":"Cumberland County"},
  {"code":"37053", "name":"Currituck County"},
  {"code":"37055", "name":"Dare County"},
  {"code":"37057", "name":"Davidson County"},
  {"code":"37059", "name":"Davie County"},
  {"code":"37061", "name":"Duplin County"},
  {"code":"37063", "name":"Durham County"},
  {"code":"37065", "name":"Edgecombe County"},
  {"code":"37067", "name":"Forsyth County"},
  {"code":"37069", "name":"Franklin County"},
  {"code":"37071", "name":"Gaston County"},
  {"code":"37073", "name":"Gates County"},
  {"code":"37075", "name":"Graham County"},
  {"code":"37077", "name":"Granville County"},
  {"code":"37079", "name":"Greene County"},
  {"code":"37081", "name":"Guilford County"},
  {"code":"37083", "name":"Halifax County"},
  {"code":"37085", "name":"Harnett County"},
  {"code":"37087", "name":"Haywood County"},
  {"code":"37089", "name":"Henderson County"},
  {"code":"37091", "name":"Hertford County"},
  {"code":"37093", "name":"Hoke County"},
  {"code":"37095", "name":"Hyde County"},
  {"code":"37097", "name":"Iredell County"},
  {"code":"37099", "name":"Jackson County"},
  {"code":"37101", "name":"Johnston County"},
  {"code":"37103", "name":"Jones County"},
  {"code":"37105", "name":"Lee County"},
  {"code":"37107", "name":"Lenoir County"},
  {"code":"37109", "name":"Lincoln County"},
  {"code":"37111", "name":"McDowell County"},
  {"code":"37113", "name":"Macon County"},
  {"code":"37115", "name":"Madison County"},
  {"code":"37117", "name":"Martin County"},
  {"code":"37119", "name":"Mecklenburg County"},
  {"code":"37121", "name":"Mitchell County"},
  {"code":"37123", "name":"Montgomery County"},
  {"code":"37125", "name":"Moore County"},
  {"code":"37127", "name":"Nash County"},
  {"code":"37129", "name":"New Hanover County"},
  {"code":"37131", "name":"Northampton County"},
  {"code":"37133", "name":"Onslow County"},
  {"code":"37135", "name":"Orange County"},
  {"code":"37137", "name":"Pamlico County"},
  {"code":"37139", "name":"Pasquotank County"},
  {"code":"37141", "name":"Pender County"},
  {"code":"37143", "name":"Perquimans County"},
  {"code":"37145", "name":"Person County"},
  {"code":"37147", "name":"Pitt County"},
  {"code":"37149", "name":"Polk County"},
  {"code":"37151", "name":"Randolph County"},
  {"code":"37153", "name":"Richmond County"},
  {"code":"37155", "name":"Robeson County"},
  {"code":"37157", "name":"Rockingham County"},
  {"code":"37159", "name":"Rowan County"},
  {"code":"37161", "name":"Rutherford County"},
  {"code":"37163", "name":"Sampson County"},
  {"code":"37165", "name":"Scotland County"},
  {"code":"37167", "name":"Stanly County"},
  {"code":"37169", "name":"Stokes County"},
  {"code":"37171", "name":"Surry County"},
  {"code":"37173", "name":"Swain County"},
  {"code":"37175", "name":"Transylvania County"},
  {"code":"37177", "name":"Tyrrell County"},
  {"code":"37179", "name":"Union County"},
  {"code":"37181", "name":"Vance County"},
  {"code":"37183", "name":"Wake County"},
  {"code":"37185", "name":"Warren County"},
  {"code":"37187", "name":"Washington County"},
  {"code":"37189", "name":"Watauga County"},
  {"code":"37191", "name":"Wayne County"},
  {"code":"37193", "name":"Wilkes County"},
  {"code":"37195", "name":"Wilson County"},
  {"code":"37197", "name":"Yadkin County"},
  {"code":"37199", "name":"Yancey County"},
  {"code":"38000", "name":"North Dakota"},
  {"code":"38001", "name":"Adams County"},
  {"code":"38003", "name":"Barnes County"},
  {"code":"38005", "name":"Benson County"},
  {"code":"38007", "name":"Billings County"},
  {"code":"38009", "name":"Bottineau County"},
  {"code":"38011", "name":"Bowman County"},
  {"code":"38013", "name":"Burke County"},
  {"code":"38015", "name":"Burleigh County"},
  {"code":"38017", "name":"Cass County"},
  {"code":"38019", "name":"Cavalier County"},
  {"code":"38021", "name":"Dickey County"},
  {"code":"38023", "name":"Divide County"},
  {"code":"38025", "name":"Dunn County"},
  {"code":"38027", "name":"Eddy County"},
  {"code":"38029", "name":"Emmons County"},
  {"code":"38031", "name":"Foster County"},
  {"code":"38033", "name":"Golden Valley County"},
  {"code":"38035", "name":"Grand Forks County"},
  {"code":"38037", "name":"Grant County"},
  {"code":"38039", "name":"Griggs County"},
  {"code":"38041", "name":"Hettinger County"},
  {"code":"38043", "name":"Kidder County"},
  {"code":"38045", "name":"LaMoure County"},
  {"code":"38047", "name":"Logan County"},
  {"code":"38049", "name":"McHenry County"},
  {"code":"38051", "name":"McIntosh County"},
  {"code":"38053", "name":"McKenzie County"},
  {"code":"38055", "name":"McLean County"},
  {"code":"38057", "name":"Mercer County"},
  {"code":"38059", "name":"Morton County"},
  {"code":"38061", "name":"Mountrail County"},
  {"code":"38063", "name":"Nelson County"},
  {"code":"38065", "name":"Oliver County"},
  {"code":"38067", "name":"Pembina County"},
  {"code":"38069", "name":"Pierce County"},
  {"code":"38071", "name":"Ramsey County"},
  {"code":"38073", "name":"Ransom County"},
  {"code":"38075", "name":"Renville County"},
  {"code":"38077", "name":"Richland County"},
  {"code":"38079", "name":"Rolette County"},
  {"code":"38081", "name":"Sargent County"},
  {"code":"38083", "name":"Sheridan County"},
  {"code":"38085", "name":"Sioux County"},
  {"code":"38087", "name":"Slope County"},
  {"code":"38089", "name":"Stark County"},
  {"code":"38091", "name":"Steele County"},
  {"code":"38093", "name":"Stutsman County"},
  {"code":"38095", "name":"Towner County"},
  {"code":"38097", "name":"Traill County"},
  {"code":"38099", "name":"Walsh County"},
  {"code":"38101", "name":"Ward County"},
  {"code":"38103", "name":"Wells County"},
  {"code":"38105", "name":"Williams County"},
  {"code":"39000", "name":"Ohio"},
  {"code":"39001", "name":"Adams County"},
  {"code":"39003", "name":"Allen County"},
  {"code":"39005", "name":"Ashland County"},
  {"code":"39007", "name":"Ashtabula County"},
  {"code":"39009", "name":"Athens County"},
  {"code":"39011", "name":"Auglaize County"},
  {"code":"39013", "name":"Belmont County"},
  {"code":"39015", "name":"Brown County"},
  {"code":"39017", "name":"Butler County"},
  {"code":"39019", "name":"Carroll County"},
  {"code":"39021", "name":"Champaign County"},
  {"code":"39023", "name":"Clark County"},
  {"code":"39025", "name":"Clermont County"},
  {"code":"39027", "name":"Clinton County"},
  {"code":"39029", "name":"Columbiana County"},
  {"code":"39031", "name":"Coshocton County"},
  {"code":"39033", "name":"Crawford County"},
  {"code":"39035", "name":"Cuyahoga County"},
  {"code":"39037", "name":"Darke County"},
  {"code":"39039", "name":"Defiance County"},
  {"code":"39041", "name":"Delaware County"},
  {"code":"39043", "name":"Erie County"},
  {"code":"39045", "name":"Fairfield County"},
  {"code":"39047", "name":"Fayette County"},
  {"code":"39049", "name":"Franklin County"},
  {"code":"39051", "name":"Fulton County"},
  {"code":"39053", "name":"Gallia County"},
  {"code":"39055", "name":"Geauga County"},
  {"code":"39057", "name":"Greene County"},
  {"code":"39059", "name":"Guernsey County"},
  {"code":"39061", "name":"Hamilton County"},
  {"code":"39063", "name":"Hancock County"},
  {"code":"39065", "name":"Hardin County"},
  {"code":"39067", "name":"Harrison County"},
  {"code":"39069", "name":"Henry County"},
  {"code":"39071", "name":"Highland County"},
  {"code":"39073", "name":"Hocking County"},
  {"code":"39075", "name":"Holmes County"},
  {"code":"39077", "name":"Huron County"},
  {"code":"39079", "name":"Jackson County"},
  {"code":"39081", "name":"Jefferson County"},
  {"code":"39083", "name":"Knox County"},
  {"code":"39085", "name":"Lake County"},
  {"code":"39087", "name":"Lawrence County"},
  {"code":"39089", "name":"Licking County"},
  {"code":"39091", "name":"Logan County"},
  {"code":"39093", "name":"Lorain County"},
  {"code":"39095", "name":"Lucas County"},
  {"code":"39097", "name":"Madison County"},
  {"code":"39099", "name":"Mahoning County"},
  {"code":"39101", "name":"Marion County"},
  {"code":"39103", "name":"Medina County"},
  {"code":"39105", "name":"Meigs County"},
  {"code":"39107", "name":"Mercer County"},
  {"code":"39109", "name":"Miami County"},
  {"code":"39111", "name":"Monroe County"},
  {"code":"39113", "name":"Montgomery County"},
  {"code":"39115", "name":"Morgan County"},
  {"code":"39117", "name":"Morrow County"},
  {"code":"39119", "name":"Muskingum County"},
  {"code":"39121", "name":"Noble County"},
  {"code":"39123", "name":"Ottawa County"},
  {"code":"39125", "name":"Paulding County"},
  {"code":"39127", "name":"Perry County"},
  {"code":"39129", "name":"Pickaway County"},
  {"code":"39131", "name":"Pike County"},
  {"code":"39133", "name":"Portage County"},
  {"code":"39135", "name":"Preble County"},
  {"code":"39137", "name":"Putnam County"},
  {"code":"39139", "name":"Richland County"},
  {"code":"39141", "name":"Ross County"},
  {"code":"39143", "name":"Sandusky County"},
  {"code":"39145", "name":"Scioto County"},
  {"code":"39147", "name":"Seneca County"},
  {"code":"39149", "name":"Shelby County"},
  {"code":"39151", "name":"Stark County"},
  {"code":"39153", "name":"Summit County"},
  {"code":"39155", "name":"Trumbull County"},
  {"code":"39157", "name":"Tuscarawas County"},
  {"code":"39159", "name":"Union County"},
  {"code":"39161", "name":"Van Wert County"},
  {"code":"39163", "name":"Vinton County"},
  {"code":"39165", "name":"Warren County"},
  {"code":"39167", "name":"Washington County"},
  {"code":"39169", "name":"Wayne County"},
  {"code":"39171", "name":"Williams County"},
  {"code":"39173", "name":"Wood County"},
  {"code":"39175", "name":"Wyandot County"},
  {"code":"40000", "name":"Oklahoma"},
  {"code":"40001", "name":"Adair County"},
  {"code":"40003", "name":"Alfalfa County"},
  {"code":"40005", "name":"Atoka County"},
  {"code":"40007", "name":"Beaver County"},
  {"code":"40009", "name":"Beckham County"},
  {"code":"40011", "name":"Blaine County"},
  {"code":"40013", "name":"Bryan County"},
  {"code":"40015", "name":"Caddo County"},
  {"code":"40017", "name":"Canadian County"},
  {"code":"40019", "name":"Carter County"},
  {"code":"40021", "name":"Cherokee County"},
  {"code":"40023", "name":"Choctaw County"},
  {"code":"40025", "name":"Cimarron County"},
  {"code":"40027", "name":"Cleveland County"},
  {"code":"40029", "name":"Coal County"},
  {"code":"40031", "name":"Comanche County"},
  {"code":"40033", "name":"Cotton County"},
  {"code":"40035", "name":"Craig County"},
  {"code":"40037", "name":"Creek County"},
  {"code":"40039", "name":"Custer County"},
  {"code":"40041", "name":"Delaware County"},
  {"code":"40043", "name":"Dewey County"},
  {"code":"40045", "name":"Ellis County"},
  {"code":"40047", "name":"Garfield County"},
  {"code":"40049", "name":"Garvin County"},
  {"code":"40051", "name":"Grady County"},
  {"code":"40053", "name":"Grant County"},
  {"code":"40055", "name":"Greer County"},
  {"code":"40057", "name":"Harmon County"},
  {"code":"40059", "name":"Harper County"},
  {"code":"40061", "name":"Haskell County"},
  {"code":"40063", "name":"Hughes County"},
  {"code":"40065", "name":"Jackson County"},
  {"code":"40067", "name":"Jefferson County"},
  {"code":"40069", "name":"Johnston County"},
  {"code":"40071", "name":"Kay County"},
  {"code":"40073", "name":"Kingfisher County"},
  {"code":"40075", "name":"Kiowa County"},
  {"code":"40077", "name":"Latimer County"},
  {"code":"40079", "name":"Le Flore County"},
  {"code":"40081", "name":"Lincoln County"},
  {"code":"40083", "name":"Logan County"},
  {"code":"40085", "name":"Love County"},
  {"code":"40087", "name":"McClain County"},
  {"code":"40089", "name":"McCurtain County"},
  {"code":"40091", "name":"McIntosh County"},
  {"code":"40093", "name":"Major County"},
  {"code":"40095", "name":"Marshall County"},
  {"code":"40097", "name":"Mayes County"},
  {"code":"40099", "name":"Murray County"},
  {"code":"40101", "name":"Muskogee County"},
  {"code":"40103", "name":"Noble County"},
  {"code":"40105", "name":"Nowata County"},
  {"code":"40107", "name":"Okfuskee County"},
  {"code":"40109", "name":"Oklahoma County"},
  {"code":"40111", "name":"Okmulgee County"},
  {"code":"40113", "name":"Osage County"},
  {"code":"40115", "name":"Ottawa County"},
  {"code":"40117", "name":"Pawnee County"},
  {"code":"40119", "name":"Payne County"},
  {"code":"40121", "name":"Pittsburg County"},
  {"code":"40123", "name":"Pontotoc County"},
  {"code":"40125", "name":"Pottawatomie County"},
  {"code":"40127", "name":"Pushmataha County"},
  {"code":"40129", "name":"Roger Mills County"},
  {"code":"40131", "name":"Rogers County"},
  {"code":"40133", "name":"Seminole County"},
  {"code":"40135", "name":"Sequoyah County"},
  {"code":"40137", "name":"Stephens County"},
  {"code":"40139", "name":"Texas County"},
  {"code":"40141", "name":"Tillman County"},
  {"code":"40143", "name":"Tulsa County"},
  {"code":"40145", "name":"Wagoner County"},
  {"code":"40147", "name":"Washington County"},
  {"code":"40149", "name":"Washita County"},
  {"code":"40151", "name":"Woods County"},
  {"code":"40153", "name":"Woodward County"},
  {"code":"41000", "name":"Oregon"},
  {"code":"41001", "name":"Baker County"},
  {"code":"41003", "name":"Benton County"},
  {"code":"41005", "name":"Clackamas County"},
  {"code":"41007", "name":"Clatsop County"},
  {"code":"41009", "name":"Columbia County"},
  {"code":"41011", "name":"Coos County"},
  {"code":"41013", "name":"Crook County"},
  {"code":"41015", "name":"Curry County"},
  {"code":"41017", "name":"Deschutes County"},
  {"code":"41019", "name":"Douglas County"},
  {"code":"41021", "name":"Gilliam County"},
  {"code":"41023", "name":"Grant County"},
  {"code":"41025", "name":"Harney County"},
  {"code":"41027", "name":"Hood River County"},
  {"code":"41029", "name":"Jackson County"},
  {"code":"41031", "name":"Jefferson County"},
  {"code":"41033", "name":"Josephine County"},
  {"code":"41035", "name":"Klamath County"},
  {"code":"41037", "name":"Lake County"},
  {"code":"41039", "name":"Lane County"},
  {"code":"41041", "name":"Lincoln County"},
  {"code":"41043", "name":"Linn County"},
  {"code":"41045", "name":"Malheur County"},
  {"code":"41047", "name":"Marion County"},
  {"code":"41049", "name":"Morrow County"},
  {"code":"41051", "name":"Multnomah County"},
  {"code":"41053", "name":"Polk County"},
  {"code":"41055", "name":"Sherman County"},
  {"code":"41057", "name":"Tillamook County"},
  {"code":"41059", "name":"Umatilla County"},
  {"code":"41061", "name":"Union County"},
  {"code":"41063", "name":"Wallowa County"},
  {"code":"41065", "name":"Wasco County"},
  {"code":"41067", "name":"Washington County"},
  {"code":"41069", "name":"Wheeler County"},
  {"code":"41071", "name":"Yamhill County"},
  {"code":"42000", "name":"Pennsylvania"},
  {"code":"42001", "name":"Adams County"},
  {"code":"42003", "name":"Allegheny County"},
  {"code":"42005", "name":"Armstrong County"},
  {"code":"42007", "name":"Beaver County"},
  {"code":"42009", "name":"Bedford County"},
  {"code":"42011", "name":"Berks County"},
  {"code":"42013", "name":"Blair County"},
  {"code":"42015", "name":"Bradford County"},
  {"code":"42017", "name":"Bucks County"},
  {"code":"42019", "name":"Butler County"},
  {"code":"42021", "name":"Cambria County"},
  {"code":"42023", "name":"Cameron County"},
  {"code":"42025", "name":"Carbon County"},
  {"code":"42027", "name":"Centre County"},
  {"code":"42029", "name":"Chester County"},
  {"code":"42031", "name":"Clarion County"},
  {"code":"42033", "name":"Clearfield County"},
  {"code":"42035", "name":"Clinton County"},
  {"code":"42037", "name":"Columbia County"},
  {"code":"42039", "name":"Crawford County"},
  {"code":"42041", "name":"Cumberland County"},
  {"code":"42043", "name":"Dauphin County"},
  {"code":"42045", "name":"Delaware County"},
  {"code":"42047", "name":"Elk County"},
  {"code":"42049", "name":"Erie County"},
  {"code":"42051", "name":"Fayette County"},
  {"code":"42053", "name":"Forest County"},
  {"code":"42055", "name":"Franklin County"},
  {"code":"42057", "name":"Fulton County"},
  {"code":"42059", "name":"Greene County"},
  {"code":"42061", "name":"Huntingdon County"},
  {"code":"42063", "name":"Indiana County"},
  {"code":"42065", "name":"Jefferson County"},
  {"code":"42067", "name":"Juniata County"},
  {"code":"42069", "name":"Lackawanna County"},
  {"code":"42071", "name":"Lancaster County"},
  {"code":"42073", "name":"Lawrence County"},
  {"code":"42075", "name":"Lebanon County"},
  {"code":"42077", "name":"Lehigh County"},
  {"code":"42079", "name":"Luzerne County"},
  {"code":"42081", "name":"Lycoming County"},
  {"code":"42083", "name":"Mc Kean County"},
  {"code":"42085", "name":"Mercer County"},
  {"code":"42087", "name":"Mifflin County"},
  {"code":"42089", "name":"Monroe County"},
  {"code":"42091", "name":"Montgomery County"},
  {"code":"42093", "name":"Montour County"},
  {"code":"42095", "name":"Northampton County"},
  {"code":"42097", "name":"Northumberland County"},
  {"code":"42099", "name":"Perry County"},
  {"code":"42101", "name":"Philadelphia County"},
  {"code":"42103", "name":"Pike County"},
  {"code":"42105", "name":"Potter County"},
  {"code":"42107", "name":"Schuylkill County"},
  {"code":"42109", "name":"Snyder County"},
  {"code":"42111", "name":"Somerset County"},
  {"code":"42113", "name":"Sullivan County"},
  {"code":"42115", "name":"Susquehanna County"},
  {"code":"42117", "name":"Tioga County"},
  {"code":"42119", "name":"Union County"},
  {"code":"42121", "name":"Venango County"},
  {"code":"42123", "name":"Warren County"},
  {"code":"42125", "name":"Washington County"},
  {"code":"42127", "name":"Wayne County"},
  {"code":"42129", "name":"Westmoreland County"},
  {"code":"42131", "name":"Wyoming County"},
  {"code":"42133", "name":"York County"},
  {"code":"44000", "name":"Rhode Island"},
  {"code":"44001", "name":"Bristol County"},
  {"code":"44003", "name":"Kent County"},
  {"code":"44005", "name":"Newport County"},
  {"code":"44007", "name":"Providence County"},
  {"code":"44009", "name":"Washington County"},
  {"code":"45000", "name":"South Carolina"},
  {"code":"45001", "name":"Abbeville County"},
  {"code":"45003", "name":"Aiken County"},
  {"code":"45005", "name":"Allendale County"},
  {"code":"45007", "name":"Anderson County"},
  {"code":"45009", "name":"Bamberg County"},
  {"code":"45011", "name":"Barnwell County"},
  {"code":"45013", "name":"Beaufort County"},
  {"code":"45015", "name":"Berkeley County"},
  {"code":"45017", "name":"Calhoun County"},
  {"code":"45019", "name":"Charleston County"},
  {"code":"45021", "name":"Cherokee County"},
  {"code":"45023", "name":"Chester County"},
  {"code":"45025", "name":"Chesterfield County"},
  {"code":"45027", "name":"Clarendon County"},
  {"code":"45029", "name":"Colleton County"},
  {"code":"45031", "name":"Darlington County"},
  {"code":"45033", "name":"Dillon County"},
  {"code":"45035", "name":"Dorchester County"},
  {"code":"45037", "name":"Edgefield County"},
  {"code":"45039", "name":"Fairfield County"},
  {"code":"45041", "name":"Florence County"},
  {"code":"45043", "name":"Georgetown County"},
  {"code":"45045", "name":"Greenville County"},
  {"code":"45047", "name":"Greenwood County"},
  {"code":"45049", "name":"Hampton County"},
  {"code":"45051", "name":"Horry County"},
  {"code":"45053", "name":"Jasper County"},
  {"code":"45055", "name":"Kershaw County"},
  {"code":"45057", "name":"Lancaster County"},
  {"code":"45059", "name":"Laurens County"},
  {"code":"45061", "name":"Lee County"},
  {"code":"45063", "name":"Lexington County"},
  {"code":"45065", "name":"McCormick County"},
  {"code":"45067", "name":"Marion County"},
  {"code":"45069", "name":"Marlboro County"},
  {"code":"45071", "name":"Newberry County"},
  {"code":"45073", "name":"Oconee County"},
  {"code":"45075", "name":"Orangeburg County"},
  {"code":"45077", "name":"Pickens County"},
  {"code":"45079", "name":"Richland County"},
  {"code":"45081", "name":"Saluda County"},
  {"code":"45083", "name":"Spartanburg County"},
  {"code":"45085", "name":"Sumter County"},
  {"code":"45087", "name":"Union County"},
  {"code":"45089", "name":"Williamsburg County"},
  {"code":"45091", "name":"York County"},
  {"code":"46000", "name":"South Dakota"},
  {"code":"46003", "name":"Aurora County"},
  {"code":"46005", "name":"Beadle County"},
  {"code":"46007", "name":"Bennett County"},
  {"code":"46009", "name":"Bon Homme County"},
  {"code":"46011", "name":"Brookings County"},
  {"code":"46013", "name":"Brown County"},
  {"code":"46015", "name":"Brule County"},
  {"code":"46017", "name":"Buffalo County"},
  {"code":"46019", "name":"Butte County"},
  {"code":"46021", "name":"Campbell County"},
  {"code":"46023", "name":"Charles Mix County"},
  {"code":"46025", "name":"Clark County"},
  {"code":"46027", "name":"Clay County"},
  {"code":"46029", "name":"Codington County"},
  {"code":"46031", "name":"Corson County"},
  {"code":"46033", "name":"Custer County"},
  {"code":"46035", "name":"Davison County"},
  {"code":"46037", "name":"Day County"},
  {"code":"46039", "name":"Deuel County"},
  {"code":"46041", "name":"Dewey County"},
  {"code":"46043", "name":"Douglas County"},
  {"code":"46045", "name":"Edmunds County"},
  {"code":"46047", "name":"Fall River County"},
  {"code":"46049", "name":"Faulk County"},
  {"code":"46051", "name":"Grant County"},
  {"code":"46053", "name":"Gregory County"},
  {"code":"46055", "name":"Haakon County"},
  {"code":"46057", "name":"Hamlin County"},
  {"code":"46059", "name":"Hand County"},
  {"code":"46061", "name":"Hanson County"},
  {"code":"46063", "name":"Harding County"},
  {"code":"46065", "name":"Hughes County"},
  {"code":"46067", "name":"Hutchinson County"},
  {"code":"46069", "name":"Hyde County"},
  {"code":"46071", "name":"Jackson County"},
  {"code":"46073", "name":"Jerauld County"},
  {"code":"46075", "name":"Jones County"},
  {"code":"46077", "name":"Kingsbury County"},
  {"code":"46079", "name":"Lake County"},
  {"code":"46081", "name":"Lawrence County"},
  {"code":"46083", "name":"Lincoln County"},
  {"code":"46085", "name":"Lyman County"},
  {"code":"46087", "name":"McCook County"},
  {"code":"46089", "name":"McPherson County"},
  {"code":"46091", "name":"Marshall County"},
  {"code":"46093", "name":"Meade County"},
  {"code":"46095", "name":"Mellette County"},
  {"code":"46097", "name":"Miner County"},
  {"code":"46099", "name":"Minnehaha County"},
  {"code":"46101", "name":"Moody County"},
  {"code":"46103", "name":"Pennington County"},
  {"code":"46105", "name":"Perkins County"},
  {"code":"46107", "name":"Potter County"},
  {"code":"46109", "name":"Roberts County"},
  {"code":"46111", "name":"Sanborn County"},
  {"code":"46113", "name":"Shannon County"},
  {"code":"46115", "name":"Spink County"},
  {"code":"46117", "name":"Stanley County"},
  {"code":"46119", "name":"Sully County"},
  {"code":"46121", "name":"Todd County"},
  {"code":"46123", "name":"Tripp County"},
  {"code":"46125", "name":"Turner County"},
  {"code":"46127", "name":"Union County"},
  {"code":"46129", "name":"Walworth County"},
  {"code":"46135", "name":"Yankton County"},
  {"code":"46137", "name":"Ziebach County"},
  {"code":"47000", "name":"Tennessee"},
  {"code":"47001", "name":"Anderson County"},
  {"code":"47003", "name":"Bedford County"},
  {"code":"47005", "name":"Benton County"},
  {"code":"47007", "name":"Bledsoe County"},
  {"code":"47009", "name":"Blount County"},
  {"code":"47011", "name":"Bradley County"},
  {"code":"47013", "name":"Campbell County"},
  {"code":"47015", "name":"Cannon County"},
  {"code":"47017", "name":"Carroll County"},
  {"code":"47019", "name":"Carter County"},
  {"code":"47021", "name":"Cheatham County"},
  {"code":"47023", "name":"Chester County"},
  {"code":"47025", "name":"Claiborne County"},
  {"code":"47027", "name":"Clay County"},
  {"code":"47029", "name":"Cocke County"},
  {"code":"47031", "name":"Coffee County"},
  {"code":"47033", "name":"Crockett County"},
  {"code":"47035", "name":"Cumberland County"},
  {"code":"47037", "name":"Davidson County"},
  {"code":"47039", "name":"Decatur County"},
  {"code":"47041", "name":"DeKalb County"},
  {"code":"47043", "name":"Dickson County"},
  {"code":"47045", "name":"Dyer County"},
  {"code":"47047", "name":"Fayette County"},
  {"code":"47049", "name":"Fentress County"},
  {"code":"47051", "name":"Franklin County"},
  {"code":"47053", "name":"Gibson County"},
  {"code":"47055", "name":"Giles County"},
  {"code":"47057", "name":"Grainger County"},
  {"code":"47059", "name":"Greene County"},
  {"code":"47061", "name":"Grundy County"},
  {"code":"47063", "name":"Hamblen County"},
  {"code":"47065", "name":"Hamilton County"},
  {"code":"47067", "name":"Hancock County"},
  {"code":"47069", "name":"Hardeman County"},
  {"code":"47071", "name":"Hardin County"},
  {"code":"47073", "name":"Hawkins County"},
  {"code":"47075", "name":"Haywood County"},
  {"code":"47077", "name":"Henderson County"},
  {"code":"47079", "name":"Henry County"},
  {"code":"47081", "name":"Hickman County"},
  {"code":"47083", "name":"Houston County"},
  {"code":"47085", "name":"Humphreys County"},
  {"code":"47087", "name":"Jackson County"},
  {"code":"47089", "name":"Jefferson County"},
  {"code":"47091", "name":"Johnson County"},
  {"code":"47093", "name":"Knox County"},
  {"code":"47095", "name":"Lake County"},
  {"code":"47097", "name":"Lauderdale County"},
  {"code":"47099", "name":"Lawrence County"},
  {"code":"47101", "name":"Lewis County"},
  {"code":"47103", "name":"Lincoln County"},
  {"code":"47105", "name":"Loudon County"},
  {"code":"47107", "name":"McMinn County"},
  {"code":"47109", "name":"McNairy County"},
  {"code":"47111", "name":"Macon County"},
  {"code":"47113", "name":"Madison County"},
  {"code":"47115", "name":"Marion County"},
  {"code":"47117", "name":"Marshall County"},
  {"code":"47119", "name":"Maury County"},
  {"code":"47121", "name":"Meigs County"},
  {"code":"47123", "name":"Monroe County"},
  {"code":"47125", "name":"Montgomery County"},
  {"code":"47127", "name":"Moore County"},
  {"code":"47129", "name":"Morgan County"},
  {"code":"47131", "name":"Obion County"},
  {"code":"47133", "name":"Overton County"},
  {"code":"47135", "name":"Perry County"},
  {"code":"47137", "name":"Pickett County"},
  {"code":"47139", "name":"Polk County"},
  {"code":"47141", "name":"Putnam County"},
  {"code":"47143", "name":"Rhea County"},
  {"code":"47145", "name":"Roane County"},
  {"code":"47147", "name":"Robertson County"},
  {"code":"47149", "name":"Rutherford County"},
  {"code":"47151", "name":"Scott County"},
  {"code":"47153", "name":"Sequatchie County"},
  {"code":"47155", "name":"Sevier County"},
  {"code":"47157", "name":"Shelby County"},
  {"code":"47159", "name":"Smith County"},
  {"code":"47161", "name":"Stewart County"},
  {"code":"47163", "name":"Sullivan County"},
  {"code":"47165", "name":"Sumner County"},
  {"code":"47167", "name":"Tipton County"},
  {"code":"47169", "name":"Trousdale County"},
  {"code":"47171", "name":"Unicoi County"},
  {"code":"47173", "name":"Union County"},
  {"code":"47175", "name":"Van Buren County"},
  {"code":"47177", "name":"Warren County"},
  {"code":"47179", "name":"Washington County"},
  {"code":"47181", "name":"Wayne County"},
  {"code":"47183", "name":"Weakley County"},
  {"code":"47185", "name":"White County"},
  {"code":"47187", "name":"Williamson County"},
  {"code":"47189", "name":"Wilson County"},
  {"code":"48000", "name":"Texas"},
  {"code":"48001", "name":"Anderson County"},
  {"code":"48003", "name":"Andrews County"},
  {"code":"48005", "name":"Angelina County"},
  {"code":"48007", "name":"Aransas County"},
  {"code":"48009", "name":"Archer County"},
  {"code":"48011", "name":"Armstrong County"},
  {"code":"48013", "name":"Atascosa County"},
  {"code":"48015", "name":"Austin County"},
  {"code":"48017", "name":"Bailey County"},
  {"code":"48019", "name":"Bandera County"},
  {"code":"48021", "name":"Bastrop County"},
  {"code":"48023", "name":"Baylor County"},
  {"code":"48025", "name":"Bee County"},
  {"code":"48027", "name":"Bell County"},
  {"code":"48029", "name":"Bexar County"},
  {"code":"48031", "name":"Blanco County"},
  {"code":"48033", "name":"Borden County"},
  {"code":"48035", "name":"Bosque County"},
  {"code":"48037", "name":"Bowie County"},
  {"code":"48039", "name":"Brazoria County"},
  {"code":"48041", "name":"Brazos County"},
  {"code":"48043", "name":"Brewster County"},
  {"code":"48045", "name":"Briscoe County"},
  {"code":"48047", "name":"Brooks County"},
  {"code":"48049", "name":"Brown County"},
  {"code":"48051", "name":"Burleson County"},
  {"code":"48053", "name":"Burnet County"},
  {"code":"48055", "name":"Caldwell County"},
  {"code":"48057", "name":"Calhoun County"},
  {"code":"48059", "name":"Callahan County"},
  {"code":"48061", "name":"Cameron County"},
  {"code":"48063", "name":"Camp County"},
  {"code":"48065", "name":"Carson County"},
  {"code":"48067", "name":"Cass County"},
  {"code":"48069", "name":"Castro County"},
  {"code":"48071", "name":"Chambers County"},
  {"code":"48073", "name":"Cherokee County"},
  {"code":"48075", "name":"Childress County"},
  {"code":"48077", "name":"Clay County"},
  {"code":"48079", "name":"Cochran County"},
  {"code":"48081", "name":"Coke County"},
  {"code":"48083", "name":"Coleman County"},
  {"code":"48085", "name":"Collin County"},
  {"code":"48087", "name":"Collingsworth County"},
  {"code":"48089", "name":"Colorado County"},
  {"code":"48091", "name":"Comal County"},
  {"code":"48093", "name":"Comanche County"},
  {"code":"48095", "name":"Concho County"},
  {"code":"48097", "name":"Cooke County"},
  {"code":"48099", "name":"Coryell County"},
  {"code":"48101", "name":"Cottle County"},
  {"code":"48103", "name":"Crane County"},
  {"code":"48105", "name":"Crockett County"},
  {"code":"48107", "name":"Crosby County"},
  {"code":"48109", "name":"Culberson County"},
  {"code":"48111", "name":"Dallam County"},
  {"code":"48113", "name":"Dallas County"},
  {"code":"48115", "name":"Dawson County"},
  {"code":"48117", "name":"Deaf Smith County"},
  {"code":"48119", "name":"Delta County"},
  {"code":"48121", "name":"Denton County"},
  {"code":"48123", "name":"DeWitt County"},
  {"code":"48125", "name":"Dickens County"},
  {"code":"48127", "name":"Dimmit County"},
  {"code":"48129", "name":"Donley County"},
  {"code":"48131", "name":"Duval County"},
  {"code":"48133", "name":"Eastland County"},
  {"code":"48135", "name":"Ector County"},
  {"code":"48137", "name":"Edwards County"},
  {"code":"48139", "name":"Ellis County"},
  {"code":"48141", "name":"El Paso County"},
  {"code":"48143", "name":"Erath County"},
  {"code":"48145", "name":"Falls County"},
  {"code":"48147", "name":"Fannin County"},
  {"code":"48149", "name":"Fayette County"},
  {"code":"48151", "name":"Fisher County"},
  {"code":"48153", "name":"Floyd County"},
  {"code":"48155", "name":"Foard County"},
  {"code":"48157", "name":"Fort Bend County"},
  {"code":"48159", "name":"Franklin County"},
  {"code":"48161", "name":"Freestone County"},
  {"code":"48163", "name":"Frio County"},
  {"code":"48165", "name":"Gaines County"},
  {"code":"48167", "name":"Galveston County"},
  {"code":"48169", "name":"Garza County"},
  {"code":"48171", "name":"Gillespie County"},
  {"code":"48173", "name":"Glasscock County"},
  {"code":"48175", "name":"Goliad County"},
  {"code":"48177", "name":"Gonzales County"},
  {"code":"48179", "name":"Gray County"},
  {"code":"48181", "name":"Grayson County"},
  {"code":"48183", "name":"Gregg County"},
  {"code":"48185", "name":"Grimes County"},
  {"code":"48187", "name":"Guadalupe County"},
  {"code":"48189", "name":"Hale County"},
  {"code":"48191", "name":"Hall County"},
  {"code":"48193", "name":"Hamilton County"},
  {"code":"48195", "name":"Hansford County"},
  {"code":"48197", "name":"Hardeman County"},
  {"code":"48199", "name":"Hardin County"},
  {"code":"48201", "name":"Harris County"},
  {"code":"48203", "name":"Harrison County"},
  {"code":"48205", "name":"Hartley County"},
  {"code":"48207", "name":"Haskell County"},
  {"code":"48209", "name":"Hays County"},
  {"code":"48211", "name":"Hemphill County"},
  {"code":"48213", "name":"Henderson County"},
  {"code":"48215", "name":"Hidalgo County"},
  {"code":"48217", "name":"Hill County"},
  {"code":"48219", "name":"Hockley County"},
  {"code":"48221", "name":"Hood County"},
  {"code":"48223", "name":"Hopkins County"},
  {"code":"48225", "name":"Houston County"},
  {"code":"48227", "name":"Howard County"},
  {"code":"48229", "name":"Hudspeth County"},
  {"code":"48231", "name":"Hunt County"},
  {"code":"48233", "name":"Hutchinson County"},
  {"code":"48235", "name":"Irion County"},
  {"code":"48237", "name":"Jack County"},
  {"code":"48239", "name":"Jackson County"},
  {"code":"48241", "name":"Jasper County"},
  {"code":"48243", "name":"Jeff Davis County"},
  {"code":"48245", "name":"Jefferson County"},
  {"code":"48247", "name":"Jim Hogg County"},
  {"code":"48249", "name":"Jim Wells County"},
  {"code":"48251", "name":"Johnson County"},
  {"code":"48253", "name":"Jones County"},
  {"code":"48255", "name":"Karnes County"},
  {"code":"48257", "name":"Kaufman County"},
  {"code":"48259", "name":"Kendall County"},
  {"code":"48261", "name":"Kenedy County"},
  {"code":"48263", "name":"Kent County"},
  {"code":"48265", "name":"Kerr County"},
  {"code":"48267", "name":"Kimble County"},
  {"code":"48269", "name":"King County"},
  {"code":"48271", "name":"Kinney County"},
  {"code":"48273", "name":"Kleberg County"},
  {"code":"48275", "name":"Knox County"},
  {"code":"48277", "name":"Lamar County"},
  {"code":"48279", "name":"Lamb County"},
  {"code":"48281", "name":"Lampasas County"},
  {"code":"48283", "name":"La Salle County"},
  {"code":"48285", "name":"Lavaca County"},
  {"code":"48287", "name":"Lee County"},
  {"code":"48289", "name":"Leon County"},
  {"code":"48291", "name":"Liberty County"},
  {"code":"48293", "name":"Limestone County"},
  {"code":"48295", "name":"Lipscomb County"},
  {"code":"48297", "name":"Live Oak County"},
  {"code":"48299", "name":"Llano County"},
  {"code":"48301", "name":"Loving County"},
  {"code":"48303", "name":"Lubbock County"},
  {"code":"48305", "name":"Lynn County"},
  {"code":"48307", "name":"McCulloch County"},
  {"code":"48309", "name":"McLennan County"},
  {"code":"48311", "name":"McMullen County"},
  {"code":"48313", "name":"Madison County"},
  {"code":"48315", "name":"Marion County"},
  {"code":"48317", "name":"Martin County"},
  {"code":"48319", "name":"Mason County"},
  {"code":"48321", "name":"Matagorda County"},
  {"code":"48323", "name":"Maverick County"},
  {"code":"48325", "name":"Medina County"},
  {"code":"48327", "name":"Menard County"},
  {"code":"48329", "name":"Midland County"},
  {"code":"48331", "name":"Milam County"},
  {"code":"48333", "name":"Mills County"},
  {"code":"48335", "name":"Mitchell County"},
  {"code":"48337", "name":"Montague County"},
  {"code":"48339", "name":"Montgomery County"},
  {"code":"48341", "name":"Moore County"},
  {"code":"48343", "name":"Morris County"},
  {"code":"48345", "name":"Motley County"},
  {"code":"48347", "name":"Nacogdoches County"},
  {"code":"48349", "name":"Navarro County"},
  {"code":"48351", "name":"Newton County"},
  {"code":"48353", "name":"Nolan County"},
  {"code":"48355", "name":"Nueces County"},
  {"code":"48357", "name":"Ochiltree County"},
  {"code":"48359", "name":"Oldham County"},
  {"code":"48361", "name":"Orange County"},
  {"code":"48363", "name":"Palo Pinto County"},
  {"code":"48365", "name":"Panola County"},
  {"code":"48367", "name":"Parker County"},
  {"code":"48369", "name":"Parmer County"},
  {"code":"48371", "name":"Pecos County"},
  {"code":"48373", "name":"Polk County"},
  {"code":"48375", "name":"Potter County"},
  {"code":"48377", "name":"Presidio County"},
  {"code":"48379", "name":"Rains County"},
  {"code":"48381", "name":"Randall County"},
  {"code":"48383", "name":"Reagan County"},
  {"code":"48385", "name":"Real County"},
  {"code":"48387", "name":"Red River County"},
  {"code":"48389", "name":"Reeves County"},
  {"code":"48391", "name":"Refugio County"},
  {"code":"48393", "name":"Roberts County"},
  {"code":"48395", "name":"Robertson County"},
  {"code":"48397", "name":"Rockwall County"},
  {"code":"48399", "name":"Runnels County"},
  {"code":"48401", "name":"Rusk County"},
  {"code":"48403", "name":"Sabine County"},
  {"code":"48405", "name":"San Augustine County"},
  {"code":"48407", "name":"San Jacinto County"},
  {"code":"48409", "name":"San Patricio County"},
  {"code":"48411", "name":"San Saba County"},
  {"code":"48413", "name":"Schleicher County"},
  {"code":"48415", "name":"Scurry County"},
  {"code":"48417", "name":"Shackelford County"},
  {"code":"48419", "name":"Shelby County"},
  {"code":"48421", "name":"Sherman County"},
  {"code":"48423", "name":"Smith County"},
  {"code":"48425", "name":"Somervell County"},
  {"code":"48427", "name":"Starr County"},
  {"code":"48429", "name":"Stephens County"},
  {"code":"48431", "name":"Sterling County"},
  {"code":"48433", "name":"Stonewall County"},
  {"code":"48435", "name":"Sutton County"},
  {"code":"48437", "name":"Swisher County"},
  {"code":"48439", "name":"Tarrant County"},
  {"code":"48441", "name":"Taylor County"},
  {"code":"48443", "name":"Terrell County"},
  {"code":"48445", "name":"Terry County"},
  {"code":"48447", "name":"Throckmorton County"},
  {"code":"48449", "name":"Titus County"},
  {"code":"48451", "name":"Tom Green County"},
  {"code":"48453", "name":"Travis County"},
  {"code":"48455", "name":"Trinity County"},
  {"code":"48457", "name":"Tyler County"},
  {"code":"48459", "name":"Upshur County"},
  {"code":"48461", "name":"Upton County"},
  {"code":"48463", "name":"Uvalde County"},
  {"code":"48465", "name":"Val Verde County"},
  {"code":"48467", "name":"Van Zandt County"},
  {"code":"48469", "name":"Victoria County"},
  {"code":"48471", "name":"Walker County"},
  {"code":"48473", "name":"Waller County"},
  {"code":"48475", "name":"Ward County"},
  {"code":"48477", "name":"Washington County"},
  {"code":"48479", "name":"Webb County"},
  {"code":"48481", "name":"Wharton County"},
  {"code":"48483", "name":"Wheeler County"},
  {"code":"48485", "name":"Wichita County"},
  {"code":"48487", "name":"Wilbarger County"},
  {"code":"48489", "name":"Willacy County"},
  {"code":"48491", "name":"Williamson County"},
  {"code":"48493", "name":"Wilson County"},
  {"code":"48495", "name":"Winkler County"},
  {"code":"48497", "name":"Wise County"},
  {"code":"48499", "name":"Wood County"},
  {"code":"48501", "name":"Yoakum County"},
  {"code":"48503", "name":"Young County"},
  {"code":"48505", "name":"Zapata County"},
  {"code":"48507", "name":"Zavala County"},
  {"code":"49000", "name":"Utah"},
  {"code":"49001", "name":"Beaver County"},
  {"code":"49003", "name":"Box Elder County"},
  {"code":"49005", "name":"Cache County"},
  {"code":"49007", "name":"Carbon County"},
  {"code":"49009", "name":"Daggett County"},
  {"code":"49011", "name":"Davis County"},
  {"code":"49013", "name":"Duchesne County"},
  {"code":"49015", "name":"Emery County"},
  {"code":"49017", "name":"Garfield County"},
  {"code":"49019", "name":"Grand County"},
  {"code":"49021", "name":"Iron County"},
  {"code":"49023", "name":"Juab County"},
  {"code":"49025", "name":"Kane County"},
  {"code":"49027", "name":"Millard County"},
  {"code":"49029", "name":"Morgan County"},
  {"code":"49031", "name":"Piute County"},
  {"code":"49033", "name":"Rich County"},
  {"code":"49035", "name":"Salt Lake County"},
  {"code":"49037", "name":"San Juan County"},
  {"code":"49039", "name":"Sanpete County"},
  {"code":"49041", "name":"Sevier County"},
  {"code":"49043", "name":"Summit County"},
  {"code":"49045", "name":"Tooele County"},
  {"code":"49047", "name":"Uintah County"},
  {"code":"49049", "name":"Utah County"},
  {"code":"49051", "name":"Wasatch County"},
  {"code":"49053", "name":"Washington County"},
  {"code":"49055", "name":"Wayne County"},
  {"code":"49057", "name":"Weber County"},
  {"code":"50000", "name":"Vermont"},
  {"code":"50001", "name":"Addison County"},
  {"code":"50003", "name":"Bennington County"},
  {"code":"50005", "name":"Caledonia County"},
  {"code":"50007", "name":"Chittenden County"},
  {"code":"50009", "name":"Essex County"},
  {"code":"50011", "name":"Franklin County"},
  {"code":"50013", "name":"Grand Isle County"},
  {"code":"50015", "name":"Lamoille County"},
  {"code":"50017", "name":"Orange County"},
  {"code":"50019", "name":"Orleans County"},
  {"code":"50021", "name":"Rutland County"},
  {"code":"50023", "name":"Washington County"},
  {"code":"50025", "name":"Windham County"},
  {"code":"50027", "name":"Windsor County"},
  {"code":"51000", "name":"Virginia"},
  {"code":"51001", "name":"Accomack County"},
  {"code":"51003", "name":"Albemarle County"},
  {"code":"51005", "name":"Alleghany County"},
  {"code":"51007", "name":"Amelia County"},
  {"code":"51009", "name":"Amherst County"},
  {"code":"51011", "name":"Appomattox County"},
  {"code":"51013", "name":"Arlington County"},
  {"code":"51015", "name":"Augusta County"},
  {"code":"51017", "name":"Bath County"},
  {"code":"51019", "name":"Bedford County"},
  {"code":"51021", "name":"Bland County"},
  {"code":"51023", "name":"Botetourt County"},
  {"code":"51025", "name":"Brunswick County"},
  {"code":"51027", "name":"Buchanan County"},
  {"code":"51029", "name":"Buckingham County"},
  {"code":"51031", "name":"Campbell County"},
  {"code":"51033", "name":"Caroline County"},
  {"code":"51035", "name":"Carroll County"},
  {"code":"51036", "name":"Charles City County"},
  {"code":"51037", "name":"Charlotte County"},
  {"code":"51041", "name":"Chesterfield County"},
  {"code":"51043", "name":"Clarke County"},
  {"code":"51045", "name":"Craig County"},
  {"code":"51047", "name":"Culpeper County"},
  {"code":"51049", "name":"Cumberland County"},
  {"code":"51051", "name":"Dickenson County"},
  {"code":"51053", "name":"Dinwiddie County"},
  {"code":"51057", "name":"Essex County"},
  {"code":"51059", "name":"Fairfax County"},
  {"code":"51061", "name":"Fauquier County"},
  {"code":"51063", "name":"Floyd County"},
  {"code":"51065", "name":"Fluvanna County"},
  {"code":"51067", "name":"Franklin County"},
  {"code":"51069", "name":"Frederick County"},
  {"code":"51071", "name":"Giles County"},
  {"code":"51073", "name":"Gloucester County"},
  {"code":"51075", "name":"Goochland County"},
  {"code":"51077", "name":"Grayson County"},
  {"code":"51079", "name":"Greene County"},
  {"code":"51081", "name":"Greensville County"},
  {"code":"51083", "name":"Halifax County"},
  {"code":"51085", "name":"Hanover County"},
  {"code":"51087", "name":"Henrico County"},
  {"code":"51089", "name":"Henry County"},
  {"code":"51091", "name":"Highland County"},
  {"code":"51093", "name":"Isle of Wight County"},
  {"code":"51095", "name":"James City County"},
  {"code":"51097", "name":"King and Queen County"},
  {"code":"51099", "name":"King George County"},
  {"code":"51101", "name":"King William County"},
  {"code":"51103", "name":"Lancaster County"},
  {"code":"51105", "name":"Lee County"},
  {"code":"51107", "name":"Loudoun County"},
  {"code":"51109", "name":"Louisa County"},
  {"code":"51111", "name":"Lunenburg County"},
  {"code":"51113", "name":"Madison County"},
  {"code":"51115", "name":"Mathews County"},
  {"code":"51117", "name":"Mecklenburg County"},
  {"code":"51119", "name":"Middlesex County"},
  {"code":"51121", "name":"Montgomery County"},
  {"code":"51125", "name":"Nelson County"},
  {"code":"51127", "name":"New Kent County"},
  {"code":"51131", "name":"Northampton County"},
  {"code":"51133", "name":"Northumberland County"},
  {"code":"51135", "name":"Nottoway County"},
  {"code":"51137", "name":"Orange County"},
  {"code":"51139", "name":"Page County"},
  {"code":"51141", "name":"Patrick County"},
  {"code":"51143", "name":"Pittsylvania County"},
  {"code":"51145", "name":"Powhatan County"},
  {"code":"51147", "name":"Prince Edward County"},
  {"code":"51149", "name":"Prince George County"},
  {"code":"51153", "name":"Prince William County"},
  {"code":"51155", "name":"Pulaski County"},
  {"code":"51157", "name":"Rappahannock County"},
  {"code":"51159", "name":"Richmond County"},
  {"code":"51161", "name":"Roanoke County"},
  {"code":"51163", "name":"Rockbridge County"},
  {"code":"51165", "name":"Rockingham County"},
  {"code":"51167", "name":"Russell County"},
  {"code":"51169", "name":"Scott County"},
  {"code":"51171", "name":"Shenandoah County"},
  {"code":"51173", "name":"Smyth County"},
  {"code":"51175", "name":"Southampton County"},
  {"code":"51177", "name":"Spotsylvania County"},
  {"code":"51179", "name":"Stafford County"},
  {"code":"51181", "name":"Surry County"},
  {"code":"51183", "name":"Sussex County"},
  {"code":"51185", "name":"Tazewell County"},
  {"code":"51187", "name":"Warren County"},
  {"code":"51191", "name":"Washington County"},
  {"code":"51193", "name":"Westmoreland County"},
  {"code":"51195", "name":"Wise County"},
  {"code":"51197", "name":"Wythe County"},
  {"code":"51199", "name":"York County"},
  {"code":"51510", "name":"Alexandria city"},
  {"code":"51515", "name":"Bedford city"},
  {"code":"51520", "name":"Bristol city"},
  {"code":"51530", "name":"Buena Vista city"},
  {"code":"51540", "name":"Charlottesville city"},
  {"code":"51550", "name":"Chesapeake city"},
  {"code":"51560", "name":"Clifton Forge city"},
  {"code":"51570", "name":"Colonial Heights city"},
  {"code":"51580", "name":"Covington city"},
  {"code":"51590", "name":"Danville city"},
  {"code":"51595", "name":"Emporia city"},
  {"code":"51600", "name":"Fairfax city"},
  {"code":"51610", "name":"Falls Church city"},
  {"code":"51620", "name":"Franklin city"},
  {"code":"51630", "name":"Fredericksburg city"},
  {"code":"51640", "name":"Galax city"},
  {"code":"51650", "name":"Hampton city"},
  {"code":"51660", "name":"Harrisonburg city"},
  {"code":"51670", "name":"Hopewell city"},
  {"code":"51678", "name":"Lexington city"},
  {"code":"51680", "name":"Lynchburg city"},
  {"code":"51683", "name":"Manassas city"},
  {"code":"51685", "name":"Manassas Park city"},
  {"code":"51690", "name":"Martinsville city"},
  {"code":"51700", "name":"Newport News city"},
  {"code":"51710", "name":"Norfolk city"},
  {"code":"51720", "name":"Norton city"},
  {"code":"51730", "name":"Petersburg city"},
  {"code":"51735", "name":"Poquoson city"},
  {"code":"51740", "name":"Portsmouth city"},
  {"code":"51750", "name":"Radford city"},
  {"code":"51760", "name":"Richmond city"},
  {"code":"51770", "name":"Roanoke city"},
  {"code":"51775", "name":"Salem city"},
  {"code":"51780", "name":"South Boston city"},
  {"code":"51790", "name":"Staunton city"},
  {"code":"51800", "name":"Suffolk city"},
  {"code":"51810", "name":"Virginia Beach city"},
  {"code":"51820", "name":"Waynesboro city"},
  {"code":"51830", "name":"Williamsburg city"},
  {"code":"51840", "name":"Winchester city"},
  {"code":"53000", "name":"Washington"},
  {"code":"53001", "name":"Adams County"},
  {"code":"53003", "name":"Asotin County"},
  {"code":"53005", "name":"Benton County"},
  {"code":"53007", "name":"Chelan County"},
  {"code":"53009", "name":"Clallam County"},
  {"code":"53011", "name":"Clark County"},
  {"code":"53013", "name":"Columbia County"},
  {"code":"53015", "name":"Cowlitz County"},
  {"code":"53017", "name":"Douglas County"},
  {"code":"53019", "name":"Ferry County"},
  {"code":"53021", "name":"Franklin County"},
  {"code":"53023", "name":"Garfield County"},
  {"code":"53025", "name":"Grant County"},
  {"code":"53027", "name":"Grays Harbor County"},
  {"code":"53029", "name":"Island County"},
  {"code":"53031", "name":"Jefferson County"},
  {"code":"53033", "name":"King County"},
  {"code":"53035", "name":"Kitsap County"},
  {"code":"53037", "name":"Kittitas County"},
  {"code":"53039", "name":"Klickitat County"},
  {"code":"53041", "name":"Lewis County"},
  {"code":"53043", "name":"Lincoln County"},
  {"code":"53045", "name":"Mason County"},
  {"code":"53047", "name":"Okanogan County"},
  {"code":"53049", "name":"Pacific County"},
  {"code":"53051", "name":"Pend Oreille County"},
  {"code":"53053", "name":"Pierce County"},
  {"code":"53055", "name":"San Juan County"},
  {"code":"53057", "name":"Skagit County"},
  {"code":"53059", "name":"Skamania County"},
  {"code":"53061", "name":"Snohomish County"},
  {"code":"53063", "name":"Spokane County"},
  {"code":"53065", "name":"Stevens County"},
  {"code":"53067", "name":"Thurston County"},
  {"code":"53069", "name":"Wahkiakum County"},
  {"code":"53071", "name":"Walla Walla County"},
  {"code":"53073", "name":"Whatcom County"},
  {"code":"53075", "name":"Whitman County"},
  {"code":"53077", "name":"Yakima County"},
  {"code":"54000", "name":"West Virginia"},
  {"code":"54001", "name":"Barbour County"},
  {"code":"54003", "name":"Berkeley County"},
  {"code":"54005", "name":"Boone County"},
  {"code":"54007", "name":"Braxton County"},
  {"code":"54009", "name":"Brooke County"},
  {"code":"54011", "name":"Cabell County"},
  {"code":"54013", "name":"Calhoun County"},
  {"code":"54015", "name":"Clay County"},
  {"code":"54017", "name":"Doddridge County"},
  {"code":"54019", "name":"Fayette County"},
  {"code":"54021", "name":"Gilmer County"},
  {"code":"54023", "name":"Grant County"},
  {"code":"54025", "name":"Greenbrier County"},
  {"code":"54027", "name":"Hampshire County"},
  {"code":"54029", "name":"Hancock County"},
  {"code":"54031", "name":"Hardy County"},
  {"code":"54033", "name":"Harrison County"},
  {"code":"54035", "name":"Jackson County"},
  {"code":"54037", "name":"Jefferson County"},
  {"code":"54039", "name":"Kanawha County"},
  {"code":"54041", "name":"Lewis County"},
  {"code":"54043", "name":"Lincoln County"},
  {"code":"54045", "name":"Logan County"},
  {"code":"54047", "name":"McDowell County"},
  {"code":"54049", "name":"Marion County"},
  {"code":"54051", "name":"Marshall County"},
  {"code":"54053", "name":"Mason County"},
  {"code":"54055", "name":"Mercer County"},
  {"code":"54057", "name":"Mineral County"},
  {"code":"54059", "name":"Mingo County"},
  {"code":"54061", "name":"Monongalia County"},
  {"code":"54063", "name":"Monroe County"},
  {"code":"54065", "name":"Morgan County"},
  {"code":"54067", "name":"Nicholas County"},
  {"code":"54069", "name":"Ohio County"},
  {"code":"54071", "name":"Pendleton County"},
  {"code":"54073", "name":"Pleasants County"},
  {"code":"54075", "name":"Pocahontas County"},
  {"code":"54077", "name":"Preston County"},
  {"code":"54079", "name":"Putnam County"},
  {"code":"54081", "name":"Raleigh County"},
  {"code":"54083", "name":"Randolph County"},
  {"code":"54085", "name":"Ritchie County"},
  {"code":"54087", "name":"Roane County"},
  {"code":"54089", "name":"Summers County"},
  {"code":"54091", "name":"Taylor County"},
  {"code":"54093", "name":"Tucker County"},
  {"code":"54095", "name":"Tyler County"},
  {"code":"54097", "name":"Upshur County"},
  {"code":"54099", "name":"Wayne County"},
  {"code":"54101", "name":"Webster County"},
  {"code":"54103", "name":"Wetzel County"},
  {"code":"54105", "name":"Wirt County"},
  {"code":"54107", "name":"Wood County"},
  {"code":"54109", "name":"Wyoming County"},
  {"code":"55000", "name":"Wisconsin"},
  {"code":"55001", "name":"Adams County"},
  {"code":"55003", "name":"Ashland County"},
  {"code":"55005", "name":"Barron County"},
  {"code":"55007", "name":"Bayfield County"},
  {"code":"55009", "name":"Brown County"},
  {"code":"55011", "name":"Buffalo County"},
  {"code":"55013", "name":"Burnett County"},
  {"code":"55015", "name":"Calumet County"},
  {"code":"55017", "name":"Chippewa County"},
  {"code":"55019", "name":"Clark County"},
  {"code":"55021", "name":"Columbia County"},
  {"code":"55023", "name":"Crawford County"},
  {"code":"55025", "name":"Dane County"},
  {"code":"55027", "name":"Dodge County"},
  {"code":"55029", "name":"Door County"},
  {"code":"55031", "name":"Douglas County"},
  {"code":"55033", "name":"Dunn County"},
  {"code":"55035", "name":"Eau Claire County"},
  {"code":"55037", "name":"Florence County"},
  {"code":"55039", "name":"Fond du Lac County"},
  {"code":"55041", "name":"Forest County"},
  {"code":"55043", "name":"Grant County"},
  {"code":"55045", "name":"Green County"},
  {"code":"55047", "name":"Green Lake County"},
  {"code":"55049", "name":"Iowa County"},
  {"code":"55051", "name":"Iron County"},
  {"code":"55053", "name":"Jackson County"},
  {"code":"55055", "name":"Jefferson County"},
  {"code":"55057", "name":"Juneau County"},
  {"code":"55059", "name":"Kenosha County"},
  {"code":"55061", "name":"Kewaunee County"},
  {"code":"55063", "name":"La Crosse County"},
  {"code":"55065", "name":"Lafayette County"},
  {"code":"55067", "name":"Langlade County"},
  {"code":"55069", "name":"Lincoln County"},
  {"code":"55071", "name":"Manitowoc County"},
  {"code":"55073", "name":"Marathon County"},
  {"code":"55075", "name":"Marinette County"},
  {"code":"55077", "name":"Marquette County"},
  {"code":"55078", "name":"Menominee County"},
  {"code":"55079", "name":"Milwaukee County"},
  {"code":"55081", "name":"Monroe County"},
  {"code":"55083", "name":"Oconto County"},
  {"code":"55085", "name":"Oneida County"},
  {"code":"55087", "name":"Outagamie County"},
  {"code":"55089", "name":"Ozaukee County"},
  {"code":"55091", "name":"Pepin County"},
  {"code":"55093", "name":"Pierce County"},
  {"code":"55095", "name":"Polk County"},
  {"code":"55097", "name":"Portage County"},
  {"code":"55099", "name":"Price County"},
  {"code":"55101", "name":"Racine County"},
  {"code":"55103", "name":"Richland County"},
  {"code":"55105", "name":"Rock County"},
  {"code":"55107", "name":"Rusk County"},
  {"code":"55109", "name":"St. Croix County"},
  {"code":"55111", "name":"Sauk County"},
  {"code":"55113", "name":"Sawyer County"},
  {"code":"55115", "name":"Shawano County"},
  {"code":"55117", "name":"Sheboygan County"},
  {"code":"55119", "name":"Taylor County"},
  {"code":"55121", "name":"Trempealeau County"},
  {"code":"55123", "name":"Vernon County"},
  {"code":"55125", "name":"Vilas County"},
  {"code":"55127", "name":"Walworth County"},
  {"code":"55129", "name":"Washburn County"},
  {"code":"55131", "name":"Washington County"},
  {"code":"55133", "name":"Waukesha County"},
  {"code":"55135", "name":"Waupaca County"},
  {"code":"55137", "name":"Waushara County"},
  {"code":"55139", "name":"Winnebago County"},
  {"code":"55141", "name":"Wood County"},
  {"code":"56000", "name":"Wyoming"},
  {"code":"56001", "name":"Albany County"},
  {"code":"56003", "name":"Big Horn County"},
  {"code":"56005", "name":"Campbell County"},
  {"code":"56007", "name":"Carbon County"},
  {"code":"56009", "name":"Converse County"},
  {"code":"56011", "name":"Crook County"},
  {"code":"56013", "name":"Fremont County"},
  {"code":"56015", "name":"Goshen County"},
  {"code":"56017", "name":"Hot Springs County"},
  {"code":"56019", "name":"Johnson County"},
  {"code":"56021", "name":"Laramie County"},
  {"code":"56023", "name":"Lincoln County"},
  {"code":"56025", "name":"Natrona County"},
  {"code":"56027", "name":"Niobrara County"},
  {"code":"56029", "name":"Park County"},
  {"code":"56031", "name":"Platte County"},
  {"code":"56033", "name":"Sheridan County"},
  {"code":"56035", "name":"Sublette County"},
  {"code":"56037", "name":"Sweetwater County"},
  {"code":"56039", "name":"Teton County"},
  {"code":"56041", "name":"Uinta County"},
  {"code":"56043", "name":"Washakie County"},
  {"code":"56045", "name":"Weston County"}
];


export const CropTypeData = [
    { "code": 1, "name": "Corn", "color": "#FFD400" },
    { "code": 2, "name": "Cotton", "color": "#FF2626" },
    { "code": 3, "name": "Rice", "color": "#00A9E6" },
    { "code": 4, "name": "Sorghum", "color": "#FF9E0F" },
    { "code": 5, "name": "Soybeans", "color": "#267300" },
    { "code": 6, "name": "Sunflower", "color": "#FFFF00" },
    { "code": 10, "name": "Peanuts", "color": "#70A800" },
    { "code": 11, "name": "Tobacco", "color": "#00AF4D" },
    { "code": 12, "name": "Sweet Corn", "color": "#E0A60F" },
    { "code": 13, "name": "Pop or Orn Corn", "color": "#E0A60F" },
    { "code": 14, "name": "Mint", "color": "#80D4FF" },
    { "code": 21, "name": "Barley", "color": "#E2007F" },
    { "code": 22, "name": "Durum Wheat", "color": "#8A6453" },
    { "code": 23, "name": "Spring Wheat", "color": "#D9B56C" },
    { "code": 24, "name": "Winter Wheat", "color": "#A87000" },
    { "code": 25, "name": "Other Small Grains", "color": "#D69DBC" },
    { "code": 26, "name": "Dbl Crop WinWht/Soybeans", "color": "#737300" },
    { "code": 27, "name": "Rye", "color": "#AE017E" },
    { "code": 28, "name": "Oats", "color": "#A15889" },
    { "code": 29, "name": "Millet", "color": "#73004C" },
    { "code": 30, "name": "Speltz", "color": "#D69DBC" },
    { "code": 31, "name": "Canola", "color": "#D1FF00" },
    { "code": 32, "name": "Flaxseed", "color": "#8099FF" },
    { "code": 33, "name": "Safflower", "color": "#D6D600" },
    { "code": 34, "name": "Rape Seed", "color": "#D1FF00" },
    { "code": 35, "name": "Mustard", "color": "#00AF4D" },
    { "code": 36, "name": "Alfalfa", "color": "#FFA8E3" },
    { "code": 37, "name": "Other Hay/Non Alfalfa", "color": "#A5F58D" },
    { "code": 38, "name": "Camelina", "color": "#00AF4D" },
    { "code": 39, "name": "Buckwheat", "color": "#D69DBC" },
    { "code": 41, "name": "Sugarbeets", "color": "#A900E6" },
    { "code": 42, "name": "Dry Beans", "color": "#A80000" },
    { "code": 43, "name": "Potatoes", "color": "#732600" },
    { "code": 44, "name": "Other Crops", "color": "#00AF4D" },
    { "code": 45, "name": "Sugarcane", "color": "#B380FF" },
    { "code": 46, "name": "Sweet Potatoes", "color": "#732600" },
    { "code": 47, "name": "Misc Vegs & Fruits", "color": "#FF6666" },
    { "code": 48, "name": "Watermelons", "color": "#FF6666" },
    { "code": 49, "name": "Onions", "color": "#FFCC66" },
    { "code": 50, "name": "Cucumbers", "color": "#FF6666" },
    { "code": 51, "name": "Chick Peas", "color": "#00AF4D" },
    { "code": 52, "name": "Lentils", "color": "#00DEB0" },
    { "code": 53, "name": "Peas", "color": "#55FF00" },
    { "code": 54, "name": "Tomatoes", "color": "#F5A27A" },
    { "code": 55, "name": "Caneberries", "color": "#FF6666" },
    { "code": 56, "name": "Hops", "color": "#00AF4D" },
    { "code": 57, "name": "Herbs", "color": "#80D4FF" },
    { "code": 58, "name": "Clover/Wildflowers", "color": "#E8BEFF" },
    { "code": 59, "name": "Sod/Grass Seed", "color": "#B2FFDE" },
    { "code": 60, "name": "Switchgrass", "color": "#00AF4D" },
    { "code": 61, "name": "Fallow/Idle Cropland", "color": "#BFBF7A" },
    { "code": 63, "name": "Forest", "color": "#95CE93" },
    { "code": 64, "name": "Shrubland", "color": "#C7D79E" },
    { "code": 65, "name": "Barren", "color": "#CCBFA3" },
    { "code": 66, "name": "Cherries", "color": "#FF00FF" },
    { "code": 67, "name": "Peaches", "color": "#FF91AB" },
    { "code": 68, "name": "Apples", "color": "#B90050" },
    { "code": 69, "name": "Grapes", "color": "#704489" },
    { "code": 70, "name": "Christmas Trees", "color": "#007878" },
    { "code": 71, "name": "Other Tree Crops", "color": "#B39C70" },
    { "code": 72, "name": "Citrus", "color": "#FFFF80" },
    { "code": 74, "name": "Pecans", "color": "#B6705C" },
    { "code": 75, "name": "Almonds", "color": "#00A884" },
    { "code": 76, "name": "Walnuts", "color": "#EBD6B0" },
    { "code": 77, "name": "Pears", "color": "#B39C70" },
    { "code": 81, "name": "Clouds/No Data", "color": "#F7F7F7" },
    { "code": 82, "name": "Developed", "color": "#9C9C9C" },
    { "code": 83, "name": "Water", "color": "#4D70A3" },
    { "code": 87, "name": "Wetlands", "color": "#80B3B3" },
    { "code": 88, "name": "Nonag/Undefined", "color": "#E9FFBE" },
    { "code": 92, "name": "Aquaculture", "color": "#00FFFF" },
    { "code": 111, "name": "Open Water", "color": "#4D70A3" },
    { "code": 112, "name": "Perennial Ice/Snow ", "color": "#D4E3FC" },
    { "code": 121, "name": "Developed/Open Space", "color": "#9C9C9C" },
    { "code": 122, "name": "Developed/Low Intensity", "color": "#9C9C9C" },
    { "code": 123, "name": "Developed/Med Intensity", "color": "#9C9C9C" },
    { "code": 124, "name": "Developed/High Intensity", "color": "#9C9C9C" },
    { "code": 131, "name": "Barren", "color": "#CCBFA3" },
    { "code": 141, "name": "Deciduous Forest", "color": "#95CE93" },
    { "code": 142, "name": "Evergreen Forest", "color": "#95CE93" },
    { "code": 143, "name": "Mixed Forest", "color": "#95CE93" },
    { "code": 152, "name": "Shrubland", "color": "#C7D79E" },
    { "code": 176, "name": "Grass/Pasture", "color": "#E9FFBE" },
    { "code": 190, "name": "Woody Wetlands", "color": "#80B3B3" },
    { "code": 195, "name": "Herbaceous Wetlands", "color": "#80B3B3" },
    { "code": 204, "name": "Pistachios", "color": "#00FF8C" },
    { "code": 205, "name": "Triticale", "color": "#D69DBC" },
    { "code": 206, "name": "Carrots", "color": "#FF6666" },
    { "code": 207, "name": "Asparagus", "color": "#FF6666" },
    { "code": 208, "name": "Garlic", "color": "#FF6666" },
    { "code": 209, "name": "Cantaloupes", "color": "#FF6666" },
    { "code": 210, "name": "Prunes", "color": "#FF91AB" },
    { "code": 211, "name": "Olives", "color": "#344A34" },
    { "code": 212, "name": "Oranges", "color": "#E67525" },
    { "code": 213, "name": "Honeydew Melons", "color": "#FF6666" },
    { "code": 214, "name": "Broccoli", "color": "#FF6666" },
    { "code": 215, "name": "Avocados", "color": "#66994D" },
    { "code": 216, "name": "Peppers", "color": "#FF6666" },
    { "code": 217, "name": "Pomegranates", "color": "#B39C70" },
    { "code": 218, "name": "Nectarines", "color": "#FF91AB" },
    { "code": 219, "name": "Greens", "color": "#FF6666" },
    { "code": 220, "name": "Plums", "color": "#FF91AB" },
    { "code": 221, "name": "Strawberries", "color": "#FF6666" },
    { "code": 222, "name": "Squash", "color": "#FF6666" },
    { "code": 223, "name": "Apricots", "color": "#FF91AB" },
    { "code": 224, "name": "Vetch", "color": "#00AF4D" },
    { "code": 225, "name": "Dbl Crop WinWht/Corn", "color": "#FFD400" },
    { "code": 226, "name": "Dbl Crop Oats/Corn", "color": "#FFD400" },
    { "code": 227, "name": "Lettuce", "color": "#FF6666" },
    { "code": 228, "name": "Dbl Crop Triticale/Corn", "color": "#FFD400" },
    { "code": 229, "name": "Pumpkins", "color": "#FF6666" },
    { "code": 230, "name": "Dbl Crop Lettuce/Durum Wht", "color": "#8A6453" },
    { "code": 231, "name": "Dbl Crop Lettuce/Cantaloupe", "color": "#FF6666" },
    { "code": 232, "name": "Dbl Crop Lettuce/Cotton", "color": "#FF2626" },
    { "code": 233, "name": "Dbl Crop Lettuce/Barley", "color": "#E2007F" },
    { "code": 234, "name": "Dbl Crop Durum Wht/Sorghum", "color": "#FF9E0F" },
    { "code": 235, "name": "Dbl Crop Barley/Sorghum", "color": "#FF9E0F" },
    { "code": 236, "name": "Dbl Crop WinWht/Sorghum", "color": "#A87000" },
    { "code": 237, "name": "Dbl Crop Barley/Corn", "color": "#FFD400" },
    { "code": 238, "name": "Dbl Crop WinWht/Cotton", "color": "#A87000" },
    { "code": 239, "name": "Dbl Crop Soybeans/Cotton", "color": "#267300" },
    { "code": 240, "name": "Dbl Crop Soybeans/Oats", "color": "#267300" },
    { "code": 241, "name": "Dbl Crop Corn/Soybeans", "color": "#FFD400" },
    { "code": 242, "name": "Blueberries", "color": "#000099" },
    { "code": 243, "name": "Cabbage", "color": "#FF6666" },
    { "code": 244, "name": "Cauliflower", "color": "#FF6666" },
    { "code": 245, "name": "Celery", "color": "#FF6666" },
    { "code": 246, "name": "Radishes", "color": "#FF6666" },
    { "code": 247, "name": "Turnips", "color": "#FF6666" },
    { "code": 248, "name": "Eggplants", "color": "#FF6666" },
    { "code": 249, "name": "Gourds", "color": "#FF6666" },
    { "code": 250, "name": "Cranberries", "color": "#FF6666" },
    { "code": 254, "name": "Dbl Crop Barley/Soybeans", "color": "#267300" }
  ];
  
export const MethodData = [
    {key:'ET', name:'Evapotranspiration (ET)'},
    {key:'SM', name:'Soil Moisture (SM)'}
  ];
  
export const SoilTextureData = [
      { "code": 1, "name": "sand", "color": "#BEBEBE" },
      { "code": 2, "name": "loamy sand", "color": "#FDFD9E" },
      { "code": 3, "name": "sandy loam", "color": "#EBD834" },
      { "code": 4, "name": "loam", "color": "#307431"},
      { "code": 5, "name": "silt loam", "color": "#CD94EA" },
      { "code": 6, "name": "silt", "color": "#546BC3" },
      { "code": 7, "name": "sandy clay loam", "color": "#92C158" },
      { "code": 8, "name": "clay loam", "color": "#EA6996" },
      { "code": 9, "name": "silty clay loam", "color": "#6D94E5" },
      { "code": 10, "name": "sandy clay", "color": "#4C5323" },
      { "code": 11, "name": "silty clay", "color": "#E93F4A" },
      { "code": 12, "name": "clay", "color": "#AF4732" }
  ];
  
export const IrrTypeData = [
    { "type": "sprinkler", "name": "Sprinkler" },
    { "type": "flooding", "name": "Flooding" },
    { "type": "dripping", "name": "Dripping" }
  ];
  
export const IrrTypeSubData = [
    { "type": "above_canopy", "name": "Above Canopy" },
    { "type": "under_canopy", "name": "Under Canopy" }
  ];
  
export const PowerSourceData = [
    // { "type": "electric_motors", "name": "Electric Motors" },
    // { "type": "diesel_gas_engines", "name": "Diesel/Gas Engines" }
    { "type": "electricity", "name": "Electricity" },
    { "type": "diesel_gas", "name": "Diesel/Gas" }
  ];
  
export const IrrGoalData = [
    { "type": "max_yield", "name": "Maximizing Yield" },
    { "type": "max_income", "name": "Maximizing Income" },
    { "type": "max_efficiency", "name": "Maximizing Irrigation Efficiency and Water Conservation" }
  ];
  
// --------------------------
export let findStateByFips = (fips) => {

  for ( let i=0; i < FipsCodes.length; i++ ) {
    if ( FipsCodes[i].code === fips )
      return FipsCodes[i];
  }

  return null;
};


const findDataElement = (code, dataset, key, defaultIndex ) => {
    
  if ( code == null )
    return dataset[defaultIndex];

    for ( let i=0; i < dataset.length; i++ ) {
      if ( dataset[i][key] === code )
        return dataset[i];
    }

    return dataset[defaultIndex];
};


export let findCropType = (code, defaultIndex) => {
  
  if ( code == null )
    return CropTypeData[(defaultIndex!=null)?defaultIndex:0].name;

  for ( let i=0; i < CropTypeData.length; i++ ) {
    if ( CropTypeData[i].code === code )
      return CropTypeData[i].name;
  }

  if ( defaultIndex !=null && defaultIndex >= 0 )
    return CropTypeData[defaultIndex].name;
  else
    return code.toString();
};


export let findSoilType = (code) => {
    return findDataElement( code, SoilTextureData, 'code', 0 ).name;
};

