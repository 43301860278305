import React, { Component } from 'react'
import {Document,Page,pdfjs} from 'react-pdf'

class WSPdfForm extends Component {
  constructor(props){
    super(props)

    this.state={
      pageNumber:1,
      numPages:null,
    }
  }
  onDocumentLoadSuccess = ({numPages}) =>{
    this.setState({numPages})
  }


  componentDidMount() {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }
  render() {
    const {pageNumber,numPages} = this.state
    const {pdfUrl, height} = this.props
      return (
          <div className='embed-responsive embed-responsive-1by1' style={{height:height+'px', zIndex:'10000'}}>
            <iframe className='embed-responsive-item' src={pdfUrl} allowfullscreen style={{paddingLeft:'5px', paddingRight:'5px'}}/>
          </div>
      )
  }
}

export default WSPdfForm