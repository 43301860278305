import React, { Component, useContext } from "react";

// openlayer
import Map from 'ol/Map';
import View from 'ol/View';
import TileWMS from 'ol/source/TileWMS';
import TileLayer from 'ol/layer/Tile';
import { register } from "ol/proj/proj4.js";
import { defaults as defaultControls, OverviewMap } from "ol/control";
import {MousePosition, ScaleLine} from 'ol/control'
import {createStringXY,toStringHDMS} from 'ol/coordinate'
import { transformExtent, get as getProjection, fromLonLat, toLonLat } from "ol/proj.js";
import VectorSource from 'ol/source/Vector';
import Polygon from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Draw, {createRegularPolygon, createBox} from 'ol/interaction/Draw';
import DragZoom from 'ol/interaction/DragZoom';
import DragAndDrop from 'ol/interaction/DragAndDrop';
import {GPX, GeoJSON, IGC, KML, TopoJSON} from 'ol/format';
import {Vector as VectorLayer} from 'ol/layer';
import {Circle, Point} from 'ol/geom'
import {Circle as CircleStyle, Fill, Stroke, Style, RegularShape} from 'ol/style'
import BingMaps from 'ol/source/BingMaps';
import OSM from 'ol/source/OSM';
import {getVectorContext} from 'ol/render';
//import GeoJSON from'ol/format/GeoJSON';
import ImageWMS from 'ol/source/ImageWMS.js';
import { Image, Tile } from 'ol/layer.js';
import GML2 from 'ol/format/GML2'
import Overlay from 'ol/Overlay'
import ImageLayer from "ol/layer/Image.js";
//import Stamen from 'ol/source/Stamen';
import StadiaMaps from 'ol/source/StadiaMaps';
import Geocoder from 'ol-geocoder';
import * as $ from 'jquery'
// proj4
import proj4 from "proj4";
// styles
import 'ol/ol.css';
import '../style/WSMap.css';
import 'ol-geocoder/dist/ol-geocoder.css';


// ====================================================== 
// Geocoder to Point
// Added by Gil Heo (August 18, 2023) 
// ======================================================
import Feature from 'ol/Feature';
// ======================================================

// Context
import { WSConsumer } from '../context/WSContext'

import {dateToString, getNumString, getDuration, } from '../functions/dateFunc'

import urlExistSync from 'url-exist-sync'


import 'bootstrap'
import { ifStatement } from "@babel/types";


//var theextent = [-2354935.721, 311822.402, 2256319.225, 3165592.366];
//var theextent = [-750000, 1837015, 118545, 2290435];
//var theextent = [-106.4134, 39.3508, -93.2754, 43.7463]
var draw; // global so we can remove it later
proj4.defs(
  "EPSG:102004",
  "+proj=aea +lat_1=29.5 +lat_2=45.5" +
  " +lat_0=23 +lon_0=-96 +x_0=0 +y_0=0 +ellps=GRS80 +datum=NAD83 +units=m +no_defs"
);
proj4.defs(
  "EPSG:5070",
  "+proj=aea +lat_1=29.5 +lat_2=45.5 +lat_0=23 +lon_0=-96" +
  " +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs"
);
proj4.defs(
  "EPSG:4326",
  "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs "
);
register(proj4);
//this.conusAEAProj = getProjection("EPSG:102004");
var geoProj = getProjection("EPSG:4326");
var conusAEAProj = getProjection("EPSG:102004");
var conusAEA5070 = getProjection("EPSG:5070");
class WSMap extends Component {

  constructor(props,context) {

    super(props,context);

    proj4.defs(
      "EPSG:102004",
      "+proj=aea +lat_1=29.5 +lat_2=45.5" +
      " +lat_0=23 +lon_0=-96 +x_0=0 +y_0=0 +ellps=GRS80 +datum=NAD83 +units=m +no_defs"
    );
    proj4.defs(
      "EPSG:5070",
      "+proj=aea +lat_1=29.5 +lat_2=45.5 +lat_0=23 +lon_0=-96" +
      " +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs"
    );
    proj4.defs(
      "EPSG:4326",
      "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs "
    );
    register(proj4);
    //this.conusAEAProj = getProjection("EPSG:102004");
    var geoProj = getProjection("EPSG:4326");
    var conusAEA5070 = getProjection("EPSG:5070");

    var centerp = fromLonLat([-97.86, 42.6], conusAEAProj);
    // console.log(centerp);
    centerp = fromLonLat([-97.8767, 42.6094], geoProj);
    // console.log(centerp);
    // console.log(toLonLat([-2354935.721, 311822.402],conusAEAProj))
    // console.log(toLonLat([2256319.225, 3165592.366], conusAEAProj))

    this.state = {
      zoom:5.10,
      center:fromLonLat([-96.00, 37.89],conusAEA5070),
      pdList:[],
      lat:null,
      lon:null,
      popup:null,
    };

    this.theextent = this.props.context.state.theextent

    this.boundarylayerlist = [
      // new Tile({title:'Cropland Data Layer',
      //   type:'CDL',
      //   source: new TileWMS({
      //     url: 'https://crop.csiss.gmu.edu/cgi-bin/wms_cdlall.cgi',
      //     params: {
      //       'LAYERS': 'cdl_2021',
      //       //'MAP': '/media/dssiv02/disk5/products/weekly/smap/2019/Weekly_SMAPSurface_16_2019.04.14_2019.04.20_Average.map',
      //       'SRS': 'EPSG:4326',
      //       'FORMAT': 'image/png'
      //     },
      //     serverType: 'mapserver',
      //     //crossOrigin:'anonymous',
      //     transition: 0
      //   }),
      //   visible: false,
      //   opacity: 1,
      //   zIndex:96,
      // }),

      // ====================================================== 
      // Replace CDL to In-season Crop Cover Layer
      // Added by Gil Heo (June 12, 2024) 
      // ======================================================
      new Tile({title:'In-season Crop Cover Layer',
        type:'In-season Crop Cover Layer',
        source: new TileWMS({
          url: 'https://nassgeodata.gmu.edu/CropScapeService/wms_cdlall_icrop.cgi',
          params: {
            'LAYERS': 'cdl_inseason',
            //'MAP': '/media/dssiv02/disk5/products/weekly/smap/2019/Weekly_SMAPSurface_16_2019.04.14_2019.04.20_Average.map',
            'SRS': 'EPSG:5070',
            'FORMAT': 'image/png'
          },
          serverType: 'mapserver',
          //crossOrigin:'anonymous',
          transition: 0
        }),
        visible: false,
        opacity: 1,
        zIndex:96,
      }),      
      // ======================================================
      new Tile({title:'Pre-season Crop Cover Layer',
        type:'Pre-season Crop Cover Layer',
        source: new TileWMS({
          url: 'https://nassgeodata.gmu.edu/CropScapeService/wms_cdlall_icrop.cgi',
          //projection:'EPSG:102004',
          params: {
            'LAYERS': 'cdl_2022',
            //'MAP': '/media/dssiv02/disk5/products/weekly/smap/2019/Weekly_SMAPSurface_16_2019.04.14_2019.04.20_Average.map',
            'SRS': 'EPSG:4326',
            'FORMAT': 'image/png'
          },
          serverType: 'mapserver',
          //crossOrigin:'anonymous',
          transition: 0
        }),
        visible: false,
        opacity: 1,
        zIndex:96,
      }),
      new Tile({
        title:'Crop Mask',
        source: new TileWMS({
          url: 'https://crop.csiss.gmu.edu/cgi-bin/wms_cropmask',
          params: {
            'LAYERS': 'crop_mask',
            //'MAP': '/media/dssiv02/disk5/products/weekly/smap/2019/Weekly_SMAPSurface_16_2019.04.14_2019.04.20_Average.map',
            'SRS': 'EPSG:4326',
            'FORMAT': 'image/png'
          },
          serverType: 'mapserver',
          //crossOrigin:'anonymous',
          transition: 0
        }),
        visible: false,
        opacity: 1,
        zIndex:97,
      }),
      new ImageLayer({
        displayOnMap: false,
        isBoundary:true,
        title: "Lakes",
        noTreeDragging: true,
        //extent: this.theextent,
        visible: false,
        source: new ImageWMS({
          url: this.props.context.state.hostwms+'?MAP=/SMAP_DATA/boundary/wms/water.map',
          params: {
            srs: "EPSG:4326",
            layers: "conus_major_lakes_national,conus_waterareas_regional",
            format: "image/png",
            transparent: true
          },
          serverType: "mapserver",
          //crossOrigin:'anonymous',
        }),
        zIndex:105,
      }), 
      new ImageLayer({
        title: "Rivers",
        //extent: this.theextent,
        visible: false,
        source: new ImageWMS({
          url: this.props.context.state.hostwms+'?MAP=/SMAP_DATA/boundary/wms/water.map',
          params: {
            srs: "EPSG:4326",
            layers: "conus_major_rivers_national,conus_rivers_regional",
            format: "image/png",
            transparent: true
          },
          serverType: "mapserver",
          //crossOrigin:'anonymous',
        }),
        zIndex:104,
      }),
        new ImageLayer({
        title: "Major Highways (Regional)",
        //extent: this.theextent,
        visible: false,
        source: new ImageWMS({
          url: this.props.context.state.hostwms+'?MAP=/SMAP_DATA/boundary/wms/highway.map',
          params: {
            srs: "EPSG:4326",
            layers:
              "conus_major_highways_regional,conus_major_highways_regional_label",
            format: "image/png",
            transparent: true
          },
          serverType: "mapserver",
          //crossOrigin:'anonymous',
        }),
        zIndex:103,
      }),
      new ImageLayer({
        title: "Freeway System (National)",
        //extent: this.theextent,
        visible: false,
        source: new ImageWMS({
          url: this.props.context.state.hostwms+'?MAP=/SMAP_DATA/boundary/wms/highway.map',
          params: {
            srs: "EPSG:4326",
            layers:
              "conus_freeway_system_national,conus_freeway_system_national_label",
            format: "image/png",
            transparent: true
          },
          serverType: "mapserver",
          // crossOrigin:'anonymous',
        }),
        zIndex:102,
      }), 
      new Image({
        title:'Region',
        source: new ImageWMS({
          url:  this.props.context.state.hostwms+'?MAP=/SMAP_DATA/boundary/wms/conus.map',
          params: {
            'LAYERS': 'conus_regions,conus_regions_d',
            //'MAP': '/media/dssiv02/disk5/products/weekly/smap/2019/Weekly_SMAPSurface_16_2019.04.14_2019.04.20_Average.map',
            'SRS': 'EPSG:4326',
            'FORMAT': 'image/png'
          },
          serverType: 'mapserver',
          //crossOrigin:'anonymous',
          transition: 0
        }),
        visible: false,
        opacity: 1,
        zIndex:101,
      }),
      new Image({
        title:'State',
        source: new ImageWMS({
          url:  this.props.context.state.hostwms+'?MAP=/SMAP_DATA/boundary/wms/conus.map',
          params: {
            'LAYERS': 'conus_states,conus_states_d',
            //'MAP': '/media/dssiv02/disk5/products/weekly/smap/2019/Weekly_SMAPSurface_16_2019.04.14_2019.04.20_Average.map',
            'SRS': 'EPSG:4326',
            'FORMAT': 'image/png'
          },
          serverType: 'mapserver',
          //crossOrigin:'anonymous',
          transition: 0
        }),
        visible: true,
        opacity: 1,
        zIndex:100,
      }),
      new Image({
        title:'ASD',
        source: new ImageWMS({
          url:  this.props.context.state.hostwms+'?MAP=/SMAP_DATA/boundary/wms/conus.map',
          params: {
            'LAYERS': 'usa_asds,usa_asds_d',
            //'MAP': '/media/dssiv02/disk5/products/weekly/smap/2019/Weekly_SMAPSurface_16_2019.04.14_2019.04.20_Average.map',
            'SRS': 'EPSG:4326',
            'FORMAT': 'image/png'
          },
          serverType: 'mapserver',
          //crossOrigin:'anonymous',
          transition: 0
        }),
        visible: false,
        opacity: 1,
        zIndex:99,
      }),
      new Image({
        title:'County',
        source: new ImageWMS({
          url:  this.props.context.state.hostwms+'?MAP=/SMAP_DATA/boundary/wms/conus.map',
          params: {
            'LAYERS': 'conus_counties,conus_counties_d',
            //'MAP': '/media/dssiv02/disk5/products/weekly/smap/2019/Weekly_SMAPSurface_16_2019.04.14_2019.04.20_Average.map',
            'SRS': 'EPSG:4326',
            'FORMAT': 'image/png'
          },
          serverType: 'mapserver',
          //crossOrigin:'anonymous',
          transition: 0
        }),
        visible: true,
        opacity: 1,
        zIndex:98,
      }),
    ];

    // https://crop.csiss.gmu.edu/cgi-bin/wms_landcover?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=Global_Land_Cover&SRS=EPSG%3A102004&WIDTH=256&HEIGHT=256&CRS=EPSG%3A102004&STYLES=&BBOX=2501884.6714053266%2C3752827.00710799%2C3752827.00710799%2C5003769.342810653
    this.backgroundLayer = new TileLayer({
      title: "Global_Land_Cover",
      subtitle: "Global_Land_Cover",
      source: new TileWMS({
        url: this.props.context.state.hostwms+'?MAP=/SMAP_DATA/boundary/wms/landcover.map',
        params: {
          'LAYERS': 'Global_Land_Cover',
          'SRS': 'EPSG:4326',
          'FORMAT': 'image/png'
        },
        serverType: 'mapserver',
        // crossOrigin:'anonymous',
        transition: 0
      }),
      visible: true,
      opacity: 0.5,
      // preload: Infinity,
      zIndex:0
    });

    this.BMap = new TileLayer({
      visible: false,
      title:'Bing_Map',

      preload: Infinity,
      source: new BingMaps({
        key: 'Apb7A0KDPJPFAsivWjkKb3_fFpLWRYt2JpvteKBBkYkEXqpLwzNq7ZDnp-S4WnR8',
        imagerySet: 'AerialWithLabelsOnDemand',
        // use maxZoom 19 to see stretched tiles instead of the BingMaps
        // "no photos at this zoom level" tiles
        // maxZoom: 19
      })
    })

    this.TonerMap = new TileLayer({
      title:'Stamen_Toner_Map',
      visible:false,
      // source:new Stamen({
      //   layer:'toner'
      // })
      source:new StadiaMaps({
        layer:'stamen_toner',
        apiKey:'41b5758b-4063-400a-af88-b29736df37a0'
      })
    })

    this.osm = new TileLayer({
      title:'Open_Street_Map',
      visible:false,
      source:new OSM()
    })

    this.geojson = new GeoJSON()

    this.gml = new GML2()

    this.source = new VectorSource({wrapX:false});

    this.vectorStyle = new Style({
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)'
      }),
      stroke: new Stroke({
        color: '#ffcc33',
       width:2        }),
      image: new CircleStyle({
        radius: 3,
        fill: new Fill({
          color:'#ffcc33'
        })
      })
    }) 
    this.vector = new VectorLayer({
      title: "AOI",
      zIndex:106,
      source: this.source,
      style: this.vectorStyle,
    })



    // http://dss.csiss.gmu.edu/cgi-bin/mapserv?SRS=EPSG%3A102004&LAYERS=Weekly_SMAPSurface_16_2019.04.14_2019.04.20_Average&MAP=%2Fmedia%2Fdssiv02%2Fdisk5%2Fproducts%2Fweekly%2Fsmap%2F2019%2FWeekly_SMAPSurface_16_2019.04.14_2019.04.20_Average.map&FORMAT=image%2Fpng&TRANSPARENT=true&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&EXCEPTIONS=application%2Fvnd.ogc.se_inimage&BBOX=289874.81320949,2831529.6099071,1233718.0165119,3775372.8132095&WIDTH=256&HEIGHT=256
    
    this.overviewMapControl = new OverviewMap({
      className: 'ol-overviewmap ol-custom-overviewmap',
      layers: [
         new Image({
          source: new ImageWMS({
            url: 'https://nassgeodata.gmu.edu/cgi-bin/wms_conustiger.cgi',
            params: {
              'LAYERS': 'conus_counties_overview,conus_states_overview',
              //'MAP': '/media/dssiv02/disk5/products/weekly/smap/2019/Weekly_SMAPSurface_16_2019.04.14_2019.04.20_Average.map',
              'SRS': 'EPSG:4326',
              'FORMAT': 'image/png',
              //'STYLES': 'us_conus_counties_overview_style,us_conus_states_style'
            },
            serverType: 'mapserver',
            transition: 0
          }),
          visible: true,
          opacity: 1
        })
      ],
      view: new View({
        projection: geoProj,
      }),
      collapseLabel: '\u00BB',
      label: '\u00AB',
      collapsed: true,
    });

    // let overviewmapctl = this.overviewMapControl

    // let overlayBox = overviewmapctl.boxOverlay_.getElement();

    // let ovmap = overviewmapctl.ovmap_;

    // overlayBox.addEventListener('mouseup', function(event){
    //   console.log("mouse up is triggered")
    //   var coordinates = ovmap.getEventCoordinateInternal(event);
    //   overviewmapctl.getMap().getView().setCenterInternal(coordinates);
    //   // window.removeEventListener('mousemove', move);
    //   // window.removeEventListener('mouseup', endMoving);
    // });

    // this.provider = OsOpenNamesSearch({
    //   url:'https://www.mapquestapi.com/geocoding/v1/address/',
    // })

    // ====================================================== 
    // Geocoder to Point
    // Added by Gil Heo (August 18, 2023) 
    // ======================================================

    this.geocoder = new Geocoder('nominatim',{
      //provider: 'photon',//'mapquest',
      provider: 'osm',
      // key: 'tZCxOxOh8i4T8Pfr50XsLCuf9tGM7rR4',
      lang: 'en-US', //en-US, fr-FR
      placeholder: 'Search for an address...',
      targetType: 'text-input',
      //autoComplete: true,
      //autoCompleteTimeout: 1000,
      countrycodes: 'US',
      limit: 7,
      keepOpen: false
    })
    this.geocoder.on('addresschosen', (evt)=>{
      //let feature = evt.feature
      let coord = evt.coordinate
      //let address = evt.address

      //console.log( evt );
      //console.log( this.props.context.state.aoi );

      let feature = new Feature({
        geometry: new Point( evt.coordinate ),
      });
      let geometry = feature.getGeometry()
      let encodedFeature = new GeoJSON().writeGeometryObject(geometry,{featureProjection:'EPSG:102004', dataProjection:'EPSG:4326'})
      this.props.context.updateContextState('aoi', {type:'geom', feature:encodedFeature, title:"Customized-AOI", address:evt.address})

      this.map.getView().setCenter(coord);
      this.map.getView().setZoom(10);
     
      // some popup solution
      // content.innerHTML = '<p>'+ address.formatted +'</p>';
      // overlay.setPosition(coord);
    })
    // End of Geocoder to Point
    // ======================================================

    this.mousePositionControl = new MousePosition({
      coordinateFormat:toStringHDMS,//createStringXY(4),
      projection: geoProj,
      //className:'custom-mouse-position',
      //target: document.getElementById('mouse-position'),
      //undefinedHTML: '<p />',  // replaced with 'placeholder'
      placeholder: ''
    })

    this.scaleLineControl = new ScaleLine({
      units: 'metric'
    })

    this.dragAndDrop = new DragAndDrop({
      formatConstructors:[
        GPX,
        GeoJSON,
        IGC,
        KML,
        TopoJSON,
        GML2,
      ]
    });


  }

  componentDidMount() {
    // console.log("the whole map is destroied and rebuilt");
    const boundaryLayers = this.boundarylayerlist
    const vectorLayer = [this.vector] 
    let {zoom, center} = this.state
    
    if (window.location.hash !== '') {
      // try to restore extend from the URL
      var hash = window.location.hash.replace('#map=', '');
      var parts = hash.split('/');
      if (parts.length === 3) {
        zoom = parseFloat(parts[0])
        center = fromLonLat([parseFloat(parts[1]), parseFloat(parts[2])], conusAEA5070)
        this.setState({zoom, center})
      }
    }

  
    //  // fetch('./SMAPdaily.txt')
    fetch(this.props.context.state.configuration_url_prefix + 
      "/smap_data/VCI-WEEKLY/VCI-WEEKLY_list.txt"
      //"/ws_model_data/analysis_AWC.txt"
      )
     .then(response => response.text())
     .then(data =>{
      data = data.replaceAll('\r','')
      let weeklyList = data.split('\n').filter(a=>!a.includes('*')).map(a=>a.split(/\_/))
      weeklyList.pop()
      //dailyList.pop()
      let latestWeek = weeklyList.pop()
      let year
      year = latestWeek[1]

      let layerName =  latestWeek.join('_').replace('.map','')
      let filePath =  this.props.context.state.resource_url_prefix + "/cgi-bin/mapserv?MAP=/SMAP_DATA/VCI-WEEKLY/"
        +year+"/"+layerName+'.map';

      let time = new Date()
      let titleName = layerName
      const layer2 = new TileLayer({
        title: layerName,
        realTitle: titleName,
        type: 'VCI',
        period: ['Weekly',''],
        time:time,
        visible: true,
        opacity: 0.7,
        extent: this.theextent,
        source: new TileWMS({
          url: filePath,
          projection:'EPSG:4326',
          params: {
            'LAYERS': layerName,
            'SRS': 'EPSG:4326',
            'FORMAT': 'image/png',
            'VERSION':'1.1.1',
          },
            serverType: 'mapserver',
        })
      })

      // this.props.context.clipLayer(layer2)

      this.map = new Map({
        target: 'map',
        layers: [
          this.backgroundLayer, 
          this.TonerMap,
          this.BMap,
          this.osm,
         
          ...boundaryLayers,
          layer2,
          ...vectorLayer,
          // clipL,
        ],
        view: new View({
          extent: this.theextent,
           //[-11800000, 4800000, -10500000, 5400000],
          //zoom: this.state.zoom,
          projection: conusAEA5070,
        }),
        // controls:defaultControls().extend([
        //   this.overviewMapControl
        // ]),
      });
      // this.props.context.clipLayer(boundaryLayers[0])
      // this.props.context.clipLayer(boundaryLayers[1])
      // this.map.getView().fit(
      //   //[-3500000, -200000, 3500000, 3500000]
      //   theextent
      //   //[-11800000, 4800000, -10500000, 5400000],
      //   );
      this.map.getView().setCenter(center);
      this.map.getView().setZoom(zoom);
      this.map.addControl(this.overviewMapControl);
      this.map.addControl(this.mousePositionControl)
      this.map.addControl(this.scaleLineControl)

      
      let popup = new Overlay({
        element:document.getElementById('pixelInfo')
      })
      this.setState({popup})

      this.addInteraction();
      this.map.addInteraction(new DragZoom())
      this.map.addOverlay(popup)

      //this.props.context.updateLegend('AWC LDASOUT', layerName, ['Hourly', 'AWC', 4])
      this.props.context.updateLegend('VCI', layerName, ['Weekly', ''])

      let addFeatures = (event)=>{
        //event是ol.interaction.DragAndDrop.Event事件类型
          //ol.interaction.DragAndDrop.Event成员：
          //features{Array.<ol.Feature>}{undefined} ——The features parsed from dropped data.
          //projection{ol.proj.Projection} {undefined} ——The feature projection.
          console.log(event.features)
        this.source.clear()
    
        this.source.addFeatures(event.features)

        let geometry = event.features[0].getGeometry() //Problem: only encoded the first feature
        let subset = geometry.getExtent()
        let encodedFeature = this.geojson.writeGeometryObject(geometry, {featureProjection:'EPSG:102004', dataProjection:'EPSG:4326'})
        console.log(encodedFeature)
        let encodedGeom, encodedGeometry
        if (geometry instanceof Polygon) {
          encodedGeom = 
          { "type":"FeatureCollection",
          "features":
            [
              {"type":"Feature",
              "properties":{},
              "geometry":
                {
                  type:encodedFeature.type, 
                  "coordinates":[encodedFeature.coordinates[0]]
                 }
               }
             ],
             "crs":{"type": "name","properties": {"name": "EPSG:4326"}}
           }
          encodedGeom = JSON.stringify(encodedGeom)//.slice(11).replace(/\r\n/g,'')
          encodedGeometry = {type:encodedFeature.type, coordinates:[encodedFeature.coordinates[0]]}
          this.props.context.updateContextState('aoi', {type:'geom', feature:encodedGeom, title: 'Customized-AOI',subset:subset, geometry: encodedGeometry})
        }else if (geometry instanceof MultiPolygon){
          alert('WARNING: MultiPolygon is not supported for statistic or other functions.')
          //encodedGeom = {type:encodedFeature.type, coordinates:encodedFeature.coordinates.map(a=>a.map(a=>a.map(a=>toLonLat([a[0],a[1]],conusAEAProj))))}
        }
        // console.log(encodedFeature)
        // console.log(encodedGeom)
        //this.props.context.updateContextState('aoi', {type:'geom', feature:encodedGeom})
        //获取地图的视图
        var view = this.map.getView();
        //自适应区域
        view.fit(this.source.getExtent(), this.map.getSize());
      }
      addFeatures = addFeatures.bind(this)
      this.dragAndDrop.on('addfeatures',addFeatures)
      this.map.addInteraction(this.dragAndDrop)
      var canvas = $(".ol-layer")//.getContext('2d')
      // console.log(canvas)
      // this.map.on('pointermove', (evt)=>{//hide cursor while darwing aoi
      //   // When user was dragging map, then coordinates didn't change and there's
      //   // no need to continue
      //   if (evt.dragging) {
      //     return;
      //   }
      //   const basicLayerList=['Global_Land_Cover', 'Lakes', 'Rivers', 'Major Highways (Regional)',
      //   'Freeway System (National)', 'Region', 'State', 'ASD', 'County', 'AOI']
      //   var inter = this.map.getInteractions()
      //   var hit = (inter.array_.slice(-1)[0] instanceof Draw)//|| this.props.context.state.getPixel
      //   this.map.getViewport().style.cursor =hit? 'none':''
      //   if(this.props.context.state.getPixel){
      //     //let pixel = this.map.getPixelFromCoordinate(evt.coordinate)
      //     //console.log(evt)
      //     //let features = this.map.getFeaturesAtPixel(pixel)


      //     var xy = evt.pixel
      //     //var canvasContext = canvas.getContext('2d')
      //     var canvasContext = $('canvas')[0].getContext('2d');   
      //     console.log(canvasContext)
      //     var pixelAtMove = canvasContext.getImageData(xy[0],xy[1],1,1).data;
      //     var red = pixelAtMove[0]
      //     console.log(red)

      //     this.map.forEachLayerAtPixel(evt.pixel, (layer, pixel)=>{
      //       console.log(pixel)
      //       console.log()
      //     },{layerFilter:(layer)=>{
      //       if(basicLayerList.indexOf(layer.get('title'))===-1)
      //       {
      //         return true
      //       }
      //       else{
      //         return false
      //       }
      //     }})
      //     //console.log(evt.pixel)
      //   }
      // })

      this.map.on('singleclick',(e)=>{
        // console.log("triggered");
        if(this.props.context.state.getPixel){
          const {olmap, basicLayerList} = this.props.context.state
          let listlayers = this.props.context.getVisibleLayers(olmap, basicLayerList)
          let lat, lon
          lat = Number.parseFloat(e.coordinate[1]).toFixed(4)
          lon = Number.parseFloat(e.coordinate[0]).toFixed(4)
          this.setState({lat,lon, pdList:[]})
          listlayers.map(a=>{
            let {title:layerName, period:period, type:type, property:property, realTitle:title} = a
            this.onGetPixelInfo(e,type,property,period, layerName, title )
          })
          popup.setPosition(e.coordinate)
         } else{
          popup.unset('position')
        }
      })


      let shouldUpdate = true
      let view = this.map.getView()
      let updatePermalink = () =>{
        if (!shouldUpdate) {
          // do not update the URL when the view was changed in the 'popstate' handler
          shouldUpdate = true;
          return;
        }
      
        var center = view.getCenter();
        var centerLatLon = toLonLat(center,conusAEA5070)
        var hash =
          '#map=' +
          view.getZoom().toFixed(2) +
          '/' +
          centerLatLon[0].toFixed(2) +
          '/' +
          centerLatLon[1].toFixed(2) 
        var state = {
          zoom: view.getZoom(),
          center: view.getCenter(),
        };
        this.setState({zoom:view.getZoom(), center:view.getCenter()})
        window.history.pushState(state, 'map', hash);
      }

      updatePermalink = updatePermalink.bind(this)
      this.map.on('moveend', updatePermalink);

      window.addEventListener('popstate', (event)=> {
        if (event.state === null) {
          return;
        }
        this.map.getView().setCenter(event.state.center);
        this.map.getView().setZoom(event.state.zoom);
        shouldUpdate = false;
      });
      
      this.props.context.state.olmap = this.map; //make other files able to operate the map

    })
    .catch(e => {
      console.log(e);
    });

  }

  async onGetPixelInfo(e,type,property,period, layerName,title){
    let pixelData
    let url = null
    let left = e.coordinate[0]-0.00005
    let bottom =  e.coordinate[1]-0.00005
    let right =  e.coordinate[0]+0.00005
    let top =  e.coordinate[1]+0.00005
    if (type.includes('Soil')){
      if(property === 'SoilRas_WC15Bar_WTA_0to200cm_500Meter1'){
        // url = this.props.context.state.mapserverHost +'?map=/WMS/WS-SOILTEXTURE.map'
        //   +'&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo&LAYERS=SoilRas_WC15Bar_WTA_0to200cm_500Meter1&BBOX='
        //   +bottom+','+left+','+top+','+right+'&HEIGHT=575&WIDTH=1001&FORMAT=image/png&INFO_FORMAT=text/plain&CRS=EPSG:4326&X=229&Y=280'
        //   +'&QUERY_LAYERS=SoilRas_WC15Bar_WTA_0to200cm_500Meter1'
        // pixelData = await this.getPlainPixelData(url)
        // pixelData = pixelData + ' mm'
        pixelData = "Point queries are currently not available for this property."
      }
      else if (property.includes('texture')){
          pixelData = "Point queries are currently not available for this property."
      }
      else{
        url = this.props.context.state.nc_url_prefix + '/soil-properties/query_grid.php?lat='
        +e.coordinate[1]+'&lon='+e.coordinate[0]+'&property='+property
        pixelData = await this.getHtmlPixelData(url)
        pixelData =pixelData+' '+ this.props.context.state.soilUnits[layerName]
      }
    }else if (type === 'ET MODIS'){

        url = this.props.context.state.nc_url_prefix + '/cgi-bin/mapserv?map=/var/www/html/MOD16A2.006.5070/'+layerName+
        '.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo&LAYERS='+layerName+'&BBOX='+
        bottom+','+left+','+top+','+right+'&HEIGHT=575&WIDTH=1001&FORMAT=image/png&INFO_FORMAT=text/plain&CRS=EPSG:4326&X=229&Y=280'+
        '&QUERY_LAYERS='+layerName

      pixelData = await this.getPlainPixelData(url)
      pixelData = (pixelData*0.125).toString()
      if(pixelData === '4095.625'){pixelData = '0'}
      pixelData = pixelData + ' mm'
    }else if (type ==='ET RF'){
      url = this.props.context.state.nc_url_prefix + '/cgi-bin/mapserv?map=/var/www/html/ETC/ML/ET_final/'+layerName+
      '.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo&LAYERS='+layerName+'&BBOX='+
      bottom+','+left+','+top+','+right+'&HEIGHT=575&WIDTH=1001&FORMAT=image/png&INFO_FORMAT=text/plain&CRS=EPSG:4326&X=229&Y=280'+
      '&QUERY_LAYERS='+layerName
      pixelData = await this.getPlainPixelData(url)
      pixelData = pixelData + ' mm'
    // }else if (type ==='ET RF(cf)'){
    //   url = this.props.context.state.nc_url_prefix + '/cgi-bin/mapserv?map=/var/www/html/ETC/ML/ET_clear/'+layerName+
    //   '.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo&LAYERS='+layerName+'&BBOX='+
    //   bottom+','+left+','+top+','+right+'&HEIGHT=575&WIDTH=1001&FORMAT=image/png&INFO_FORMAT=text/plain&CRS=EPSG:4326&X=229&Y=280'+
    //   '&QUERY_LAYERS='+layerName
    //   pixelData = await this.getPlainPixelData(url)
    //   pixelData = pixelData + ' mm'
    }else if (type ==='SM RF'){
      url = this.props.context.state.nc_url_prefix + '/cgi-bin/mapserv?map=/var/www/html/ETC/ML/SM_final/'+layerName+
      '.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo&LAYERS='+layerName+'&BBOX='+
      bottom+','+left+','+top+','+right+'&HEIGHT=575&WIDTH=1001&FORMAT=image/png&INFO_FORMAT=text/plain&CRS=EPSG:4326&X=229&Y=280'+
      '&QUERY_LAYERS='+layerName
      pixelData = await this.getPlainPixelData(url)
      pixelData = pixelData + ' m/m'
    // }else if (type ==='SM RF(cf)'){
    //   url = this.props.context.state.nc_url_prefix + '/cgi-bin/mapserv?map=/var/www/html/ETC/ML/SM_clear/'+layerName+
    //   '.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo&LAYERS='+layerName+'&BBOX='+
    //   bottom+','+left+','+top+','+right+'&HEIGHT=575&WIDTH=1001&FORMAT=image/png&INFO_FORMAT=text/plain&CRS=EPSG:4326&X=229&Y=280'+
    //   '&QUERY_LAYERS='+layerName
    //   pixelData = await this.getPlainPixelData(url)
    //   pixelData = pixelData + ' m/m'

    }else if((type === 'ET LDASOUT' && period[0] === 'Daily')||type === 'PREP NWM'){
      url = this.props.context.state.nc_url_prefix + '/cgi-bin/mapserv?map=/var/www/html/ETC/ET-500m-LDASOUT-DAILY/'+layerName+
      '.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo&LAYERS='+layerName+'&BBOX='+
      bottom+','+left+','+top+','+right+'&HEIGHT=575&WIDTH=1001&FORMAT=image/png&INFO_FORMAT=text/plain&CRS=EPSG:4326&X=229&Y=280'+
      '&QUERY_LAYERS='+layerName
      pixelData = await this.getPlainPixelData(url)
      pixelData = pixelData + ' mm'
    }else if(type === 'PREP GFS'){
      let subFolder = title.split('-')[0]
      url = this.props.context.state.nc_url_prefix + '/cgi-bin/mapserv?map=/var/www/html/ETC/ET-500m-LDASOUT-DAILY/'+subFolder+'/'+layerName+
      '.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo&LAYERS='+layerName+'&BBOX='+
      bottom+','+left+','+top+','+right+'&HEIGHT=575&WIDTH=1001&FORMAT=image/png&INFO_FORMAT=text/plain&CRS=EPSG:4326&X=229&Y=280'+
      '&QUERY_LAYERS='+layerName
      pixelData = await this.getPlainPixelData(url)
      pixelData = pixelData + ' mm'
    }else if(type==='AWC LDASOUT'){
      url = this.props.context.state.nc_url_prefix + '/cgi-bin/mapserv?map=/var/www/html/ETC/AWC/'+layerName+
      '.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo&LAYERS='+layerName+'&BBOX='+
      bottom+','+left+','+top+','+right+'&HEIGHT=575&WIDTH=1001&FORMAT=image/png&INFO_FORMAT=text/plain&CRS=EPSG:4326&X=229&Y=280'+
      '&QUERY_LAYERS='+layerName
      pixelData = await this.getPlainPixelData(url)
      pixelData = pixelData + ' mm'
    }else if(type.includes('LDAS')||type.includes('FORECAST')){
      layerName = layerName.split(' ')[0]
      let depth = type.includes('SM ')?period[2]:''
      url = this.props.context.state.nc_url_prefix +'/ncWMS2/wms?LAYERS='+layerName+'&QUERY_LAYERS='+layerName+'&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&BBOX='+
      left+','+bottom+','+right+','+top+'&FEATURE_COUNT=5&HEIGHT=600&WIDTH=750&FORMAT=image/png&INFO_FORMAT=text/xml&SRS=EPSG:4326&X=351&Y=420&ELEVATION='+depth
      pixelData = await this.getNcPixelData(url)
      pixelData = pixelData+' ' +this.props.context.state.soilUnits[layerName.split('/').pop().split('_')[0]] 
    }else
    {
      let coor = fromLonLat(e.coordinate, conusAEAProj);
      if(!this.props.context.state.istesting){
        url = this.props.context.state.nc_url_prefix + '/smap_service?service=WPS'+
          '&version=1.0.0&request=Execute&identifier=GetPixelValue&'+
          'DataInputs=layer='+layerName+';x='+coor[0]+';y='+coor[1]
      }else{
        url = "get_pixel_resp.xml"
      }
      pixelData = await this.getWpsPixelData(url)
      if (type === 'NDVI'){
        pixelData = (pixelData-125)/125
      }else if (type === 'VCI'){
        pixelData = pixelData/250
      }else if(type==='MVCI'){
        pixelData = (pixelData-125)/100
      }
    }
    let p = this.state.pdList
    this.setState({pdList:[...p,{pixelData, title}]})
  }

  getPlainPixelData(url){
    return new Promise((resolve, reject) => {
      fetch(url)
      .then(res => res.text())
      .then(data => {
          let pixelData
          if(data.includes('value_0')){
            pixelData= data.split(/value_0 = |value_list/)[1].replace(/\'/gi,'')//.getElementsByTagName("value")[0].childNodes[0].nodeValue
          }
          else{
            pixelData = NaN
          }
          resolve(pixelData)
      }).catch(e => {
        console.log('error:', e)
        reject(e)
      })
    })
  }

  getHtmlPixelData(url){
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(res => res.text())
        .then(str => (new window.DOMParser()).parseFromString(str,'text/html'))
        .then(data => {
          let pixelData
          if(!data.getElementsByClassName("property")[0]){
            pixelData = 'Point queries are not applicable for this property.'
          }else{
            pixelData = data.getElementsByClassName('value')[0].childNodes[0].nodeValue
          }
          resolve(pixelData)
      }).catch(e => {
        console.log('error:', e)
        reject(e)
      })
    })
  }

  getNcPixelData(url){
    return new Promise((resolve, reject) => {
      fetch(url, {
        mode: 'cors',
      })
      .then(res => res.text())
      .then(str => (new window.DOMParser()).parseFromString(str,'text/xml'))
      .then(data => {
        let pixelData
        if(!data.getElementsByTagName("value")[0]){
          pixelData = 'Failed'
        }else{
          pixelData = data.getElementsByTagName("value")[0].childNodes[0].nodeValue
        }
        resolve(pixelData)
      }).catch(e => {
        console.log('error:', e)
        reject(e)
      })
    })
  }

  getWpsPixelData(url){
    return new Promise((resolve, reject) => {
      fetch(url)
      .then(res => res.text())
      .then(str => (new window.DOMParser()).parseFromString(str,'text/xml'))
      .then(data => {
        let pixelData
        if(!data.getElementsByTagName("wps:LiteralData")[0]){
          //alert("ERROR: Process failed, please check server error log")
          pixelData = 'Failed'
        }else{
          pixelData = data.getElementsByTagName("wps:LiteralData")[1].childNodes[0].nodeValue
          resolve(pixelData)
        }
      }).catch(e => {
        console.log('error:', e)
        reject(e)
      })
    })
  }

  showGMLFeature=(fipCode)=>{
    let aoi = this.props.context.state.aoi
    if(fipCode!=='-1'){
      this.source.clear()
      fetch(this.props.context.state.configuration_url_prefix+'/smap_data/boundary/gml/'+fipCode+'.gml')
      .then(res=>res.text())
      .then(str => (new window.DOMParser()).parseFromString(str,'text/xml'))
      .then(data=>{
        let gmlFeature = this.gml.readFeature(data)
        console.log(gmlFeature)
        // let extent =  data.getElementsByTagName('gml:coordinates')[0].childNodes[0].nodeValue
        // extent = extent.split(/[ ,]/).map(a=>a*1)
        // console.log(extent)
        
        let extent = gmlFeature.getGeometry().getExtent()
        let center = [(extent[0]+extent[2])/2, (extent[1]+extent[3])/2]
        let extentBuffer = 0.05
        let newExtent = [extent[0]-extentBuffer,extent[1]-extentBuffer,extent[2]+extentBuffer,extent[3]+extentBuffer,]
        this.source.addFeature(gmlFeature)
        this.props.context.updateContextState('aoi', {...aoi, subset:extent})
        this.map.getView().fit(newExtent);
        this.map.getView().setCenter(center)//set center again because newview in openMap() would set center as unchanged
      })
      .catch(e => console.log('error:', e))
    }
  }

  addInteraction=()=> {
  var value = this.props.context.state.geoType;
  let geojson = this.geojson
  if (value === 'Stop'){
    this.map.removeInteraction(draw)
  }
  else if (value === 'Border') {}
  else if (value !== 'None') {
    var geometryFunction;
    let freehand = false
    if (value === 'Square') {
      value = 'Circle';
      geometryFunction = createRegularPolygon(4);
    } else if (value === 'Box') {
      value = 'Circle';
      geometryFunction = createBox();
    } else if (value === 'FreehandLineString') {
      value = 'LineString'
      freehand = true
    } else if (value === 'FreehandPolygon') {
      value = 'Polygon'
      freehand = true
    }
    draw = new Draw({
      source: this.source,
      type: value,
      geometryFunction: geometryFunction,
      freehand: freehand,
      style: new Style({
        stroke: new Stroke({
          color: "#FFF",
          width: 2
        }),
        fill: new Fill({
          color: [255, 255, 255, 0]
        }),
        image: new RegularShape({
          fill: new Fill({
              color: 'red'
          }),
          points: 4,
          radius1: 15,
          radius2: 1
        }),
      }),
    });
   
    let drawEnd=(evt)=>{
      this.source.clear()
      let feature = evt.feature
      let geometry = feature.getGeometry()
      let subset = geometry.getExtent()
      let encodedGeom, encodedGeometry
      let encodedFeature = geojson.writeGeometryObject(geometry,{featureProjection:'EPSG:102004', dataProjection:'EPSG:4326'})
      // featureList = [...featureList, encodedFeature]
      // console.log(encodedFeature)
      if(geometry instanceof Circle){
        encodedGeom = {type:'Circle',coordinates:[[toLonLat(geometry.getCenter(),geoProj),geometry.getRadius()]]}
       }
      else if(geometry instanceof Point){
        encodedGeom = encodedFeature
      }
       else{
         encodedGeom = 
         { "type":"FeatureCollection",
         "features":
           [
             {"type":"Feature",
             "properties":{},
             "geometry":
               {
                 type:encodedFeature.type, 
                 "coordinates":[encodedFeature.coordinates[0]]
                }
              }
            ],
            "crs":{"type": "name","properties": {"name": "EPSG:4326"}}
          }
          encodedGeom = JSON.stringify(encodedGeom)//.slice(11).replace(/\r\n/g,'')
          encodedGeometry =  {type:encodedFeature.type,coordinates:[encodedFeature.coordinates[0]]}
        }
      //console.log(encodedGeom)
      this.props.context.updateContextState('aoi', {type:'geom', feature:encodedGeom, title:"Customized-AOI", subset:subset, geometry:encodedGeometry})
    }
    drawEnd = drawEnd.bind(this)//makes 'this' inside drawEnd refer to SMAPMap class
    draw.on('drawend', drawEnd)
    this.map.addInteraction(draw);
  } else {
    this.source.clear()
    this.props.context.updateContextState('aoi',{type:'fip',feature:'-1', title:'U.S.'})
  }
}
 equal=(a, b)=> {
  if (a.length !== b.length) {
      return false
  } else {
      for (let i = 0; i < a.length; i++) {
          if (a[i] !== b[i]) {
              return false
          }
      }
      return true;
  }
} 

  componentDidUpdate(prevProps, prevState) {
    // console.log("Map Context Updater is called")
    const {geoType, aoi, selectSearch} = this.props.context.state
    //const activeIndex = flatData.findIndex(a=>a.node.active===true)
    if(prevProps.context.state.geoType!==geoType){
      this.map.removeInteraction(draw);
      this.addInteraction();
      //console.log(draw)
    }
    else if(aoi.type==='fip' && aoi.feature !== prevProps.context.state.aoi.feature){
      this.showGMLFeature(aoi.feature)
    }
    if(selectSearch !== prevProps.context.state.selectSearch){
      selectSearch?this.map.addControl(this.geocoder): this.map.removeControl(this.geocoder)
    }

  }

  onPopupClose=()=>{
    this.state.popup.unset('position')
  }


  render() {
    return (
      <div>
        <div id="map" className="map" ></div>

        <div style={{display:'none'}}>
          <div id="pixelInfo" >
            
            <span className='ol-popup' style={{backgroundColor:'white', textAlign:'left'}}>
            <button href='#' className="ol-popup-closer" onClick={this.onPopupClose}>&times;</button>
            <table className='table table-borderless m-0'>
            <tbody>
              {this.state.pdList && this.state.pdList.map(a=>{return (
                // <p style={{whiteSpace:'nowrap', marginBottom:'0'}}> {a.title + ': '+a.pixelData}</p>
                <tr>
                  <td className='p-0 pr-2'><p style={{whiteSpace:'nowrap', marginBottom:'0', fontWeight:'bold'}}> {a.title + ': '}</p></td>
                  <td className='p-0'><p style={{whiteSpace:'nowrap', marginBottom:'0'}}> {a.pixelData}</p></td>
                </tr>
              )
              })}
              <tr>
                <td className='p-0 pr-2'><p style={{whiteSpace:'nowrap', marginBottom:'0',fontWeight:'bold'}}> Latitude: </p></td>
                <td className='p-0'><p style={{whiteSpace:'nowrap', marginBottom:'0'}}>{this.state.lat} </p></td>
              </tr>
              <tr>
                <td className='p-0 pr-2'><p style={{whiteSpace:'nowrap', marginBottom:'0',fontWeight:'bold'}}> Longitude: </p></td>
                <td className='p-0'><p style={{whiteSpace:'nowrap', marginBottom:'0'}}>{this.state.lon} </p></td>
              </tr>
              </tbody>
            </table>

              {/* {this.state.currentLayer + ': '+this.state.pixelData} */}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

//export default WSMap;
//this makes context can be used outside render()
export default props => (
  <WSConsumer>
    {context => <WSMap {...props} context={context} />}
  </WSConsumer>
)