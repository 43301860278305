// Modules
import React, { Component } from "react";
//import {Router, Route, IndexRoute} from 'react-router'
//import {BrowserRouter, Route} from 'react-router-dom'
//import {browserHistory} from 'react-router'
import "bootstrap";
import "jquery";

// ======================================================  
// Router
// Added by Gil Heo (Sep 29, 2023)  */}

import ReactGA from "react-ga4";

import { useEffect, useState } from 'react';
import { Route, Routes, BrowserRouter as Router, Navigate } from 'react-router-dom';
import { LoginContextProvider } from "./account/login-context";

import SignInPage from "./account/signin-page";
import SignUpPage from "./account/signup-page";
import ProfilePage from './account/profile-page';
import ForgotPasswordPage from './account/forgot-password-page';
import ResetPasswordPage from './account/reset-password-page';
import AdminView from './admin/admin-page';
import IdaFeedbackView from './feedback/ida-feedback-view';
import CvrStatisticsPage from './statistics/cvr-statistics-view';


// End of Router
// ======================================================  

// Style
import "./style/App.css";
import 'bootstrap/dist/css/bootstrap.css';

import { WSProvider } from "./context/WSContext"

// Components
import WSNavbar from "./components/WSNavbar";
import WSMap from "./components/WSMap";
import WSSidebar from "./components/WSSidebar";
import RedirectPath from "./util/RedirectPath";

ReactGA.initialize('G-TQQ89DLSYZ');

function App() {

  // ======================================================  
  // Router
  // Added by Gil Heo (Sep 29, 2023)  */}

  // return (
  //  <div className="App">
  //     <WSProvider>
  //       <WSNavbar />
  //       <WSSidebar />
  //       <WSMap />
  //     </WSProvider>
  //  </div>
  // );

  const [loggedIn, setLoggedIn] = useState(false);
  const [userId, setUserId] = useState(-1);
  const [username, setUsername] = useState(null);
  const [userDetails, setUserDetails] = useState(null);

  
  const loginContextValue = {
    loggedIn: loggedIn, 
    updateLoggedIn: setLoggedIn,
    userId: userId,
    updateUserId: setUserId,
    username: username,
    updateUsername: setUsername,
    userDetails: userDetails,
    updateUserDetails: setUserDetails
  };


  const fetchUserDetails = async () => {

    let response = await fetch(process.env.PUBLIC_URL + "/backend/user_details").catch(err => {
      console.info(err, "Fetching user details failed");
    });

    if (!response.ok || response.headers.get("Content-Type") !== "application/json" )
      return;

    let data = await response.json().catch(err => {
      console.info(err, "Invalid user details information")
    });

    console.log( data );

    setUserId(data.id);
    setUsername(data.email);
    setUserDetails( data );
    setLoggedIn(true);
  };


  useEffect( () => {
    fetchUserDetails();
  },[] );
  

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <LoginContextProvider value={loginContextValue}>
        <div className="App">
          <WSProvider>

            <Routes>
              <Route path="/signin" element={<SignInPage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route path="/forgot_password" element={<ForgotPasswordPage />} />
              <Route path="/reset_password" element={<ResetPasswordPage />} />
              <Route path="/account" element={ loggedIn ? <ProfilePage /> : null} />
              <Route path="/admin" element={ (userDetails && userDetails.roles.indexOf("ROLE_ADMIN") >= 0) ? (<div><AdminView view="Users"/> </div>) : null } />
              <Route path="/feedback/ida" element={<IdaFeedbackView />  } />
              <Route path="/cvrstatistics" element={<CvrStatisticsPage />  } />
              <Route path="/" element={ <div><WSNavbar /><WSSidebar /><WSMap /> </div>} />
            </Routes>

          </WSProvider>
        </div>
      </LoginContextProvider>
    </Router>
  );

  // End of Router
  // ======================================================  
}

export default App;
