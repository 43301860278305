import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

import Map from 'ol/Map';
import View from 'ol/View';
import Overlay from 'ol/Overlay.js';
import TileWMS from 'ol/source/TileWMS';
import TileLayer from 'ol/layer/Tile';
import BingMaps from 'ol/source/BingMaps';
import { get as getProjection, fromLonLat, toLonLat } from "ol/proj.js";
import proj4 from "proj4";

import "ol/ol.css";

proj4.defs(
    "EPSG:5070",
    "+proj=aea +lat_1=29.5 +lat_2=45.5 +lat_0=23 +lon_0=-96" +
    " +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs"
);
const theextent = [-3500000, -200000, 3500000, 3500000];
const conusAEA5070 = getProjection("EPSG:5070");
const usCenter = fromLonLat([-96.00, 37.89], conusAEA5070);

//const resource_url_prefix = process.env.REACT_APP_API_URL
const crop_url_prefix = "https://crop.csiss.gmu.edu";
const soil_url_prefix = "https://cat.csiss.gmu.edu";
const mapserverHost = soil_url_prefix + '/cgi-bin/mapserv'

const cdlUrl = crop_url_prefix + '/cgi-bin/wms_cdlall.cgi';
const cdlLayerName = 'cdl_2023';
const soilUrl = mapserverHost+'?map=/WMS/WS-SOILTEXTURE.map';
const soilLayerName = 'texture_2550';



// --------------------------
let PaperComponent = (props) => {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
}

let map = null;
let overlay = null;
let cdlLayer = null;
let soilLayer = null;


// --------------------------
let WSMapViewDialog = ( props ) => {
  
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [lon, setLon] = useState(-96.00);
    const [lat, setLat] = useState(37.89);
    const [sLon, setSLon] = useState(-96.00);
    const [sLat, setSLat] = useState(37.89);
    const [type, setType] = useState('');
    const [updated, setUpdated] = useState(false);
    const [dataLayer, setDataLayer] = useState(null);
    const [pixelInfo, setPixelInfo] = useState({});

    // --------------------------
    useEffect( () => {

        //console.log( "called: []" );

        let bingMaps = new TileLayer({
            visible: true,
            title:'Bing_Map',
        
            preload: Infinity,
            source: new BingMaps({
                key: 'Apb7A0KDPJPFAsivWjkKb3_fFpLWRYt2JpvteKBBkYkEXqpLwzNq7ZDnp-S4WnR8',
                imagerySet: 'AerialWithLabelsOnDemand',
                // use maxZoom 19 to see stretched tiles instead of the BingMaps
                // "no photos at this zoom level" tiles
                // maxZoom: 19
            })
        });
  
        cdlLayer = new TileLayer({
            visible: false,
            title:'CDL',
        
            preload: Infinity,
            source: new TileWMS({
                url: cdlUrl,
                params: {
                'LAYERS': cdlLayerName,
                },
                serverType: 'mapserver',
            })
        });     

        soilLayer = new TileLayer({
            visible: false,
            title:'Soil Texture',
        
            preload: Infinity,
            source: new TileWMS({
                url: soilUrl,
                params: {
                'LAYERS': soilLayerName
                },
                serverType: 'mapserver',
            })
        });        
  

        // let vectorLayer = new ol.layer.Vector({
        //     source: vectorSource,
        //     style: new ol.style.Style({
        //       image: new ol.style.Circle({
        //         radius: 2,
        //         fill: new ol.style.Fill({color: 'red'})
        //       })
        //     })
        //   });

        overlay = new Overlay({
          element: null,
          autoPan: {
            animation: {
              duration: 250,
            },
          },
        });

        map = new Map({
          target: 'mapDialog',
          layers: [
            bingMaps,
            cdlLayer,
            soilLayer
          ],
          overlays: [overlay],
          view: new View({
            extent: theextent,
            projection: conusAEA5070,
            center: usCenter,
            zoom: 10,
            opacity: 0.7
          }),
          // controls:defaultControls().extend([
          //   this.overviewMapControl
          // ]),
        });

        map.on( 'pointermove', (evt) => {
          let coord = toLonLat( evt.coordinate, conusAEA5070);
          setLon( coord[0] );
          setLat( coord[1] );
        });

    }, []); 


    // --------------------------
    useEffect( () => {
        
        //console.log( "called: props.isOpen" );

        setIsOpen( props.isOpen );

        if ( props.isOpen === true ) {
          if ( props.type === 'cdl' )
            setTitle('Cropland Data Layer 2023');
          else if ( props.type === 'soil' )
            setTitle('Soil Texture (25-50cm)');
          else if ( props.type === null )
            setTitle('Location');
          setType('');
        }

    }, [props.isOpen, props.type]); 


    // --------------------------
    useEffect( () => {
    
        //console.log( "called: isOpen" );

        if ( isOpen === true ) {
          setTimeout(() => {
            initMap();
            setTimeout( () => {
              let latLon5070 = fromLonLat(props.center, conusAEA5070);
              let container = document.getElementById('popup');
              overlay.setElement(container);
              container.style.display='block';
              overlay.setPosition(latLon5070);
            }, 500 );
          }, 500);
        }

        return () => {
          onClose();
        }
    }, [isOpen]);
    

    // --------------------------
    let onClose = () => {

      map.setTarget( null );
      overlay.setElement(undefined);
      overlay.setPosition(undefined);
      map.un('singleclick', singleClickEventListener );
    };

    // --------------------------
    let singleClickEventListener = async (evt) => {

      const coord = toLonLat(evt.coordinate, conusAEA5070);
      setSLon( coord[0] );
      setSLat( coord[1] );
      overlay.setPosition(evt.coordinate);

      console.log( "singleClickEventListener:", coord[0], coord[1] );

      if ( props.type != null ) {
        let data = await props.fetchPixelInfo(coord[0], coord[1], (props.type=='cdl')?"crop":props.type);
        if ( data != null )
          setType( (props.type==='cdl')?data.crop_type:(props.type==='soil')?data.soil_type:'Unknown');

        setPixelInfo( data );
      }
      setUpdated(true);
    };


    // --------------------------
    let initMap = () => {

      //console.log( "called: initMap" );

      setUpdated(false);

      if ( props.type === 'cdl' ) 
      {
        cdlLayer.setVisible(true);
        soilLayer.setVisible(false);
        setDataLayer(cdlLayer);
      }
      else if ( props.type === 'soil' ) 
      {
        cdlLayer.setVisible(false);
        soilLayer.setVisible(true);
        setDataLayer(soilLayer);
      }
      else {
        cdlLayer.setVisible(false);
        soilLayer.setVisible(false);
        setDataLayer(null);
      }

      //map.setTarget( null );
      map.setTarget( 'mapDialog' );
      let latLon5070 = fromLonLat(props.center, conusAEA5070);
      map.getView().setCenter(latLon5070);
      map.getView().setZoom(10);

      //console.log( props.center, latLon5070, toLonLat(map.getView().getCenter(), conusAEA5070 ));

      setLon( props.center[0] );
      setLat( props.center[1] );
      setSLon( props.center[0] );
      setSLat( props.center[1] );

      // let container = document.getElementById('popup');

      // //overlay.setElement(null);
      // //overlay.setPosition(undefined);
      // overlay.setElement(container);
      // container.style.display='block';
      // overlay.setPosition(latLon5070);

      if ( props.type !== null ) {
        props.fetchPixelInfo(props.center[0], props.center[1], (props.type === 'cdl')?'crop':props.type)
        .then( data => {

          console.log( data );


          if ( data != null ) {
            setType( (props.type==='cdl')?data.crop_type:(props.type==='soil')?data.soil_type:'Unknown');
            setPixelInfo( data );
          }
        }).catch(e=>{console.log(e)});
      }

      map.on('singleclick', singleClickEventListener );
    };


    // --------------------------
    return (
      <Dialog
        open={isOpen}
        onClose={()=>{
          setIsOpen(false);
          onClose();
          props.onClose();
        }}
        PaperComponent={PaperComponent}
        fullWidth={true}
        maxWidth={'sm'}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent style={{height:400}}>
          <div
            id="mapDialog"
            className="map-container"
            style={{
                height:370, width:'100%'
            }}
            
          ></div>
        </DialogContent>
        <DialogActions>
          <Box sx={{ width: 450 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                {props.type != null &&
                <Typography style={{color:'#1976d2'}}>
                  Opacity (%)
                </Typography>}
              </Grid>
              <Grid item xs>            
                {props.type != null &&
                <Slider
                    defaultValue={80}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    aria-labelledby="opacity-slider"
                    onChange={ (event, newValue) => {
                      dataLayer.setOpacity( newValue / 100. );
                    }}
                />}
              </Grid>
              <Grid item>
                <Typography style={{marginLeft:20, fontSize:'90%', color:'#1976d2'}}>
                  Lat,Lon: ({lat.toFixed(4)}, {lon.toFixed(4)})
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <div style={{width:30}}></div>
          <Button autoFocus onClick={()=>{
            setIsOpen(false); 
            onClose();
            props.onClose();
          }}>
            Close
          </Button>
        </DialogActions>
 
        <div id="popup" className="ol-popup" style={{display:'none'}}>
          <a href="#nolink" id="popup-closer" className="ol-popup-closer"
            style={{
              textDecoration: 'none',
              position: 'absolute',
              top: 2,
              right: 8
            }}
            onClick={()=>{
              overlay.setPosition(undefined);
            }}
          >x</a>
          {updated && (<a href="#nolink" id="popup-closer" className="ol-popup-closer"
            style={{
              textDecoration: 'none',
              position: 'absolute',
              top:'auto',
              bottom: 4,
              right: 8
            }}
            onClick={()=>{
              setIsOpen(false); 
              onClose();
              props.onUpdate(sLon, sLat, pixelInfo);
            }}
          >Update</a> )}         
          <div id="popup-content" style={{width:200}}>
            {props.type != null &&
            <p>Type: <code>{type}</code></p>}
            Lat: <code>{sLat.toFixed(4)}</code><br />
            Lon: <code>{sLon.toFixed(4)}</code><br />
          </div>
        </div>

      </Dialog>
    );

};

export default WSMapViewDialog;