import React, { useContext, useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Dialog, Snackbar, Toolbar } from '@material-ui/core';

import {useNavigate} from "react-router-dom";

import LoginContext from "../account/login-context";
import Copyright from "../account/copyright";
import Message from "../account/feedback-message";

const MESSAGE_DURATION = 2000;
const successMessage = `You are logged in! Closing dialog in ${MESSAGE_DURATION/1000} seconds`;
const failMessage = "Sorry, your credentials are incorrect.";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

// timeout
let redirectTimeout = null;

export default function ForgotPasswordPage(props) {
  const classes = useStyles();

  const loginContext = useContext(LoginContext);
  const navigate = useNavigate();

  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const [messageOpen, setMessageOpen] = useState(false);
  const [success, setSuccess] = useState(true);
  const [message, setMessage] = useState(successMessage);

  useEffect(() => {
    return () => clearTimeout(redirectTimeout);
  })

  // callback function for submitting login credentials
  const handleSendResetPasswordLink = async (e) => {
    e.preventDefault();
      var sendResetPasswordURL = encodeURI(process.env.PUBLIC_URL + "/backend/forgotten_password/"+username)
      fetch(sendResetPasswordURL,
      {
          method: "GET",
          headers: {
              "Accept": "application/json; charset=UTF-8",
              "Content-Type": "application/json"
          }
      })
      .then(res => res.json())
      .then(data => {
        if (data.status === "Success") {

          console.log(data)

          setMessage(data.message)
          setMessageOpen(true)

          setTimeout(() => navigate('/'), MESSAGE_DURATION);
        }
      })
       
  }

  return (
    // <Dialog open={props.open} onClose={props.handleClose} maxWidth='sm' >
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Toolbar />
      <Grid className={classes.paper}>
        <Avatar className={classes.avatar} >
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Send password reset link
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSendResetPasswordLink}>
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="username"
            autoComplete="email"
            autoFocus
            onChange={(e) => setUsername(e.target.value) }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Send password reset link
          </Button>
        </form>
      </Grid>
      <Box mt={4}>
        <Copyright />
      </Box>

      <Message 
        open={messageOpen} 
        setOpen={setMessageOpen} 
        success={success} 
        message={message}
      />
        
    </Container>
  );
}
