import React, { Component } from 'react'
import { WSConsumer } from "../context/WSContext";
import {DropdownList , DateTimePicker, Multiselect} from "react-widgets";
import Chart from 'react-google-charts'
import {capitalize, dateHourToString, getNumString, dateToString, dateToHour, getDuration} from '../functions/dateFunc'
import ReactModal from 'react-modal-resizable-draggable'
import {toStringHDMS} from 'ol/coordinate'
import {toLonLat, fromLonLat } from "ol/proj.js";
import {stringToDate} from '../functions/dateFunc'
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import '../style/WSProfileBar.css'

Moment.locale('en')
momentLocalizer()

class WSProfileBar extends Component {
  constructor(props){
    super(props)

    this.state={
      startDate:null,
      endDate:null,
      plantDate:stringToDate('2020.04.20'),
      startHour:null,
      endhour:null,
      startWeek:null,
      endWeek:null,
      period:'Daily',
      statUrl:'',
      //complexData:'',
      csvList:'',
      isLoading: false,
      modalVisible:false,
      loader:'Loading Chart...',
      point:'',
      title:"U.S.",
      product:null,
      cropType:'Corn',
    }

    this.closeModal = this.closeModal.bind(this)
    this.openModal = this.openModal.bind(this)
    this.handleExportProfile = this.handleExportProfile.bind(this)
    

  }

  closeModal() {
    this.setState({modalVisible:false})
    this.setState({isLoading:false})
    this.stopCountDown()
    // console.log("onclose callback")
  }

  openModal() {
    this.setState({modalVisible:true})
  }

  handleDateChange(e,ifend){
    //let date = dateToString(e)
    if(ifend){
      this.setState({endDate:e, csvList:''})
    }else{
      this.setState({startDate:e, csvList:''})
    }
  }
  handleHourChange(e,ifend){
    //let date = dateToString(e)
    e.setMinutes(0)
    if(ifend){
      this.setState({endHour:e, csvList:''})
    }else{
   
      if(this.state.product && this.state.product.includes('GFS')){
        let h = e.getHours()
        e.setHours(h-h%6)
      }  else if(this.state.product && this.state.product.includes('Forecast')){
        e.setHours(0)
      }

      this.setState({startHour:e, csvList:''})
    }
  }

  handleWeekChange(e,ifend){
    //let date = dateToString(e)
    if(ifend){
      this.setState({endWeek:e, csvList:''})
    }else{
      this.setState({startWeek:e, csvList:''})
    }
  }
  handleCropChange(e){
    this.setState({cropType:e})
  }

  getWeekList(type){
    const {VIWeekly,SMAPWeekly, ETList} = this.props.context.state
    const cState = this.props.context.state
    const indexDict = {             'SMAP-9KM-ANOMALY-WEEKLY-SUB':'SMAP9kmAnomalyWeekly',
      'SMAP-9KM-ANOMALY-WEEKLY-TOP':'SMAP9kmAnomalyWeekly',
      'SMAP-9KM-CATEGORY-WEEKLY-SUB':'SMAP9kmCategoricalWeekly',
      'SMAP-9KM-CATEGORY-WEEKLY-TOP':'SMAP9kmCategoricalWeekly',
      'SMAP-9KM-WEEKLY-SUB':'SMAP9kmWeekly',
      'SMAP-9KM-WEEKLY-TOP':'SMAP9kmWeekly',
      'SMAP-HYB-1KM-WEEKLY':'SMAP1kmWeekly',
      'SMAP-HYB-1KM-ANOMALY-WEEKLY':'SMAP1kmAnomalyWeekly',
      'SMAP-HYB-1KM-CATEGORY-WEEKLY':'SMAP1kmCategoricalWeekly',
      'NDVI-WEEKLY':'NDVIWeekly',
      'MVCI-WEEKLY':'MVCIWeekly',
      'VCI-WEEKLY':'VCIWeekly',
      //'ET':'ETList',
    }
    let weekList
    if(type && type.includes('WEEKLY')){
      weekList = cState[indexDict[type]].map(a=>a[1]+'_'+a[2])
      weekList && weekList.sort((a,b)=>b.split(/\_/)[0]*100+b.split(/\_/)[1]*1-a.split(/\_/)[0]*100-a.split(/\_/)[1]*1)
      weekList = [...new Set(weekList)]
    }else if (type === 'ET MODIS'){
      weekList = ETList.map(a=>a.slice(3,6).join('.'))
      weekList = [...new Set(weekList)]
      weekList.sort((a,b)=>stringToDate(b).getTime()-stringToDate(a).getTime())
    }

    return weekList
  }

  handlePeriodChange(e){
    this.setState({period:e, csvList:'', product: null})
  }
  handleProductChange(e){
    this.setState({product:e, startWeek: null, endWeek: null, })
  }

  getDateFromWeek(week, type){
    const cState = this.props.context.state
    const indexDict = {             'SMAP-9KM-ANOMALY-WEEKLY-SUB':'SMAP9kmAnomalyWeekly',
      'SMAP-9KM-ANOMALY-WEEKLY-TOP':'SMAP9kmAnomalyWeekly',
      'SMAP-9KM-CATEGORY-WEEKLY-SUB':'SMAP9kmCategoricalWeekly',
      'SMAP-9KM-CATEGORY-WEEKLY-TOP':'SMAP9kmCategoricalWeekly',
      'SMAP-9KM-WEEKLY-SUB':'SMAP9kmWeekly',
      'SMAP-9KM-WEEKLY-TOP':'SMAP9kmWeekly',
      'SMAP-HYB-1KM-WEEKLY':'SMAP1kmWeekly',
      'SMAP-HYB-1KM-ANOMALY-WEEKLY':'SMAP1kmAnomalyWeekly',
      'SMAP-HYB-1KM-CATEGORY-WEEKLY':'SMAP1kmCategoricalWeekly',
      'NDVI-WEEKLY':'NDVIWeekly',
      'MVCI-WEEKLY':'MVCIWeekly',
      'VCI-WEEKLY':'VCIWeekly',
      //'ET':'ETList',
    }
    let datestr = cState[indexDict[type]].filter(a=>a[1]+'_'+a[2] === week)[0][3]
    return stringToDate(datestr)

  }

  processCsv(csvStr,period,product){
    let csvList = csvStr.split(/\n/).map(a=>{
      let b=a.split(/[,]/)
      let c = [b[0]]
      c.push(...b.slice(1).map(a=>isNaN(a*1)?(a==='inf\r'?NaN:a):a*1))
      return c
    })
    csvList = csvList.slice(0,csvList.length-1)
    //console.log(csvList)
    //let firstWeekNum = csvList[1][0].split('week')[1]*1
    csvList = csvList.map(a=>{
      if(product.includes('NDVI')){
        //a[0] = dataType==='daily'?stringToDate(a[0]):a[0].split('week')[1]*1
        if (typeof a[1] !== 'string'){
            a = [
              period==='Daily'?stringToDate(a[0]):this.getDateFromWeek(a[0], product), 
              a[1]<=250?(a[1]-125)/125:NaN
            ]
        }
      }
      else if(product.includes('MVCI')){
        if (typeof a[1] !== 'string'){
          a = [
            period==='Daily'?stringToDate(a[0]):this.getDateFromWeek(a[0], product), 
            a[1]<=250?(a[1]-125)/100:NaN
          ]
        }
      }
      else if(product.includes('VCI')){
        if (typeof a[1] !== 'string'){
          a = [
            period==='Daily'?stringToDate(a[0]):this.getDateFromWeek(a[0], product), 
            a[1]/250
          ]
        }
      }
      else if(product.includes('1KM-WEEKLY')){
        if (typeof a[1] !== 'string'){
          a = [
            period==='Daily'?stringToDate(a[0]):this.getDateFromWeek(a[0], product), 
            a[1] === -9999?NaN:a[1]
          ]
        }
      }
      else {
        if (typeof a[1] !== 'string'){
          a = [
            period==='Daily'?stringToDate(a[0]):this.getDateFromWeek(a[0], product), 
            ...a.slice(1,a.length)
          ]
        }
      }
      return a
    })
    //console.log(csvList)
    return csvList
  }

  handleGenerate(){
    const { period, product, startWeek, endWeek } = this.state
    const { aoi } = this.props.context.state
    if(aoi.type!=='geom'||(aoi.type!=='geom'&&aoi.feature.type!=='Point')){
      alert("ERROR: Please first draw an interest point on the map using the top-center toolbar (AOI function -> Point).");
      return;
    }
    if((period === 'Daily' && !product)||(period === 'Weekly' && (!product || !startWeek || !endWeek))){
      alert('Error: You must select all required options first!')
      return; 
    }
    if((period === 'None' && !product)){
      alert('Error: You must select all required options first!')
      return; 
    }

    if(period && period === 'Hourly'){
      //this.getWeatherProfile()
      this.getHourlyProfile()
    }
    else if(period && period === 'None'){
      this.getSoilProfile()
    }
    else if(period && period === 'Daily' && product && [ 'ET','ET Forecast',
     'ET Machine Learning',
    //'ET Machine Learning(cloud free)',
    'ET Machine Learning(predict)',
    'Soil Moisture Machine Learning',
    //'Soil Moisture Machine Learning(cloud free)',
    'Soil Moisture',
    'Soil Moisture Forecast',
    'NWM Precipitation',
    'GFS Precipitation'].indexOf(product)!==-1){
      this.getDailyProfile()
    }
    // else if(product && (product === 'ET RF'||product === 'ET MODIS'||product === 'ET LDASOUT')){
    //   this.getETProfile()
    // }
    // else if(product && (product === 'SM LDASOUT')){
    //   this.getSMProfile()
    // }
    else{
      this.getSMAPProfile()
    }
  }
  countDown(){
    this.timeLeft =60
    clearInterval(this.timerID)
    this.timerID = setInterval(()=>{
     this.timeLeft === 0?this.timeLeft=0:this.timeLeft+=-1
      this.setState({loader:'Loading Chart in '+this.timeLeft+ ' seconds...'})
    }, 1000)
  }
  stopCountDown(){
    if(this.state.loader !== 'Loading Chart...'){
      clearInterval(this.timerID)
      this.setState({loader:'Loading Chart...'})
    }
  }

  getSMAPProfile(){
    const global_state = this.props.context.state
    const {aoi, SMAPWeekly, conusAEAProj} = global_state
    //const resource_prefix_url = global_state.nc_url_prefix
    const resource_prefix_url = 'https://nassgeo.csiss.gmu.edu'
    const { period, product, startDate, endDate, startWeek, endWeek} = this.state
    //const dataType = period.toLowerCase()
    // let p = dataType === 'daily' ? 1:7
    let oneHundred=1000*60*60*24*100

    if((period === 'Daily' )){
        if(endDate.getTime()-startDate.getTime()>oneHundred){
          alert("ERROR: Profile does not support processing over 100 layers! Please select shorter date period.")
          return;
        }
    }
    if(period === 'Weekly'){
      if( ((endWeek.split('_')[0]*1-startWeek.split('_')[0]*1))*52+endWeek.split('_')[0]*1-startWeek.split('_')[0]*1>100){
        alert("ERROR: Profile does not support processing over 100 layers! Please select shorter date period.")
        return;
      }
    }

  
    if (aoi.type==='geom'&&aoi.feature.type==='Point'){
      this.setState({isLoading:true})
      this.setState({modalVisible:true})
      this.setState({csvList:'' })
      this.setState({point:'Location: '+toStringHDMS(aoi.feature.coordinates.map(a=>a*1))})
      //this.setState({loader:'Loading Chart...'})
      this.countDown()
      let coor = fromLonLat(aoi.feature.coordinates.map(a=>a*1), conusAEAProj);
      let url = period === 'Daily'?resource_prefix_url + '/smap_service?service=WPS&version=1.0.0' + 
        '&request=Execute&identifier=GetProfileByDate&DataInputs=' + 
        'productId='+product+
        ';x='+coor[0]+';y='+coor[1] + 
        ';startDate='+dateToString(startDate)+';endDate='+dateToString(endDate)
        :
        resource_prefix_url + '/smap_service?service=WPS&version=1.0.0' + 
        '&request=Execute&identifier=GetProfileByWeek&DataInputs=' + 
        'productId='+product+
        ';x='+coor[0]+';y='+coor[1] + 
        ';startWeek='+startWeek+';endWeek='+endWeek

        console.log(url)

      if(global_state.istesting){

        url = "profile_resp.xml";

      }

      
      fetch(url, {
            mode: 'cors',
      })
      .then(res => res.text())
      .then(str => (new window.DOMParser()).parseFromString(str,'text/xml'))
      .then(data => {
        if(!data.getElementsByTagName("wps:LiteralData")[0]){
          alert("ERROR: Process failed, please check server error log")
        }else{
          let statUrl = data.getElementsByTagName("wps:LiteralData")[0].childNodes[0].nodeValue
          //let complexData = data.getElementsByTagName('wps:ComplexData')[0].childNodes[0].nodeValue
          statUrl = statUrl.replace('https://nassgeo.csiss.gmu.edu', resource_prefix_url)
          this.setState({statUrl,
            //complexData:JSON.parse(complexData.replace(/'/g,'"'))
          })

          if(global_state.istesting){

            statUrl = 
            //'Profile_SMAP-9KM-WEEKLY-SUB_2019_50_2020_10_-161531.05056761_2381729.3333333.csv'
            //"Profile_SMAP-9KM-ANOMALY-DAILY-SUB_2018.12.15_2019.01.15_-161531.05056761_2381729.3333333.csv";
            //'Profile_SMAP-HYB-1KM-ANOMALY-WEEKLY_2019_01_2019_48_138754.84939063597_2070404.2518096562.csv'
            'Profile_SMAP-HYB-1KM-WEEKLY_2019_01_2019_48_138754.84939063597_2070404.2518096562.csv'
          }
          fetch(statUrl)
          .then(res => res.text())
          .then(str =>{
              // console.log(str)
              let csvList = this.processCsv(str, period, product)
              this.setState({ csvList, })
              this.setState({isLoading:false})
          })
          .catch(e=>{
            console.log('error:',e)
            this.setState({isLoading:false})
          })
        }
      })
      .catch(e => {
        console.log('error:', e)
        this.setState({isLoading:false})
      })
      
    }else{
      alert("ERROR: Please select a point first...")
      this.setState({loader:'Please select a point first...'})
    }

  }
  getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  getProValue(param){
    const {soilUnits} = this.props.context.state
    
    //fetch(param.url, param.option)
    fetch( process.env.PUBLIC_URL + "/proxy?url=" + encodeURIComponent(param.url), param.option )
    .then(res => res.text())
    .then(str =>{
      if (str.includes("error")){
        this.setState({modalVisible:false})
        alert('Error: '+str)
      }else{
        let csvList = str.split(/\n/).map(a=>{
          let b=a.split(/[,]/)
          if(param.type && ['SM','PREP','Soil Moisture Machine Learning'].indexOf(param.type)!==-1){
            b = [b[0], ...b.slice(2).map(c=>{return isNaN(c*1)?c:c*1})]
          }
          else if(param.type &&['ET','ET Machine Learning'].indexOf(param.type)!==-1){
            b = [b[0], isNaN(b[1]*1)?b[1]:b[1]*1]
          }
          else{
            b = [b[0], ...b.slice(1).map(c=>{return isNaN(c*1)?c:c*1})]
          }
          return b
        })
        if(param.type && param.type==='SM'){
          csvList = [['time','0-10cm', '10-40cm', '40-100cm', '100-200cm'], ...csvList.slice(1)]
        }
        else if(param.type && param.type==='SP'){
          let profileTable =[]
          for (let i = 1; i < csvList.length; i++){
            profileTable = [...profileTable, [...csvList[i], soilUnits[csvList[i][0]]?soilUnits[csvList[i][0]]:'/']]
          }
          csvList = [['Soil Property', 'Value', 'Unit'], ...profileTable]
        }
        csvList = csvList.slice(0,csvList.length-1)
        this.setState({csvList})
      }
    })
    .catch(e=>{
      console.log('error:',e)
      this.setState({isLoading:false})
    })
  }

  getSoilProfile(){
    const {soilDict, aoi, conusAEAProj, nc_url_prefix} = this.props.context.state

    const propList = Object.values(soilDict).slice(4)

    if (aoi.type==='geom'&&aoi.feature.type==='Point'){
      this.setState({isLoading:true})
      this.setState({modalVisible:true})
      this.setState({csvList:'' })
      this.setState({point:'Location: '+toStringHDMS(aoi.feature.coordinates.map(a=>a*1))})
      //this.setState({loader:'Loading Chart...'})
      this.countDown()
      let coor = aoi.feature.coordinates
      let left = coor[0]-0.00005
      let bottom =  coor[1]-0.00005
      let right =  coor[0]+0.00005
      let top =  coor[1]+0.00005
      let wpUrl = this.props.context.state.nc_url_prefix +'/cgi-bin/mapserv?map=/var/www/html/ETC/wp_global.map'
      +'&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo&LAYERS=wp_global&BBOX='
      +bottom+','+left+','+top+','+right+'&HEIGHT=575&WIDTH=1001&FORMAT=image/png&INFO_FORMAT=text/plain&CRS=EPSG:4326&X=229&Y=280'
      +'&QUERY_LAYERS=wp_global'

      let rb = [{"longitude":[coor[0].toString()], "latitude":[coor[1].toString()]}]
      rb = JSON.stringify(rb)
      let param = {url:nc_url_prefix+'/et/profile_soil_property', option:{method:'POST', body:rb, headers:{'Content-Type':'application/json'}}, type:'SP', wpUrl:wpUrl}
      this.getProValue(param)

    }else{
      alert("ERROR: Please first draw an interest point on the map using the top-center toolbar (AOI function -> Point).");
      return;
    }
  }
  

  getHourlyProfile(){
    const { aoi, conusAEAProj, nc_url_prefix} = this.props.context.state
    const { product, startHour, endHour} = this.state

    if (aoi.type==='geom'&&aoi.feature.type==='Point'){
      this.setState({isLoading:true})
      this.setState({modalVisible:true})
      this.setState({csvList:'' })
      this.setState({point:'Location: '+toStringHDMS(aoi.feature.coordinates.map(a=>a*1))})
      //this.setState({loader:'Loading Chart...'})
      this.countDown()
      let coor = aoi.feature.coordinates
      let lon, lat, startH, endH, param
      lon = [coor[0].toString()]
      lat = [coor[1].toString()]
      startH = dateToString(startHour).replace(/\./g, '') + getNumString(startHour.getHours(), 2)
      endH = endHour?dateToString(endHour).replace(/\./g, '') + getNumString(endHour.getHours(), 2):null
      if(product && product==='ET'){
        let pname = 'ET'
        let rb = [{"longitude":lon, "latitude":lat, "starttime": startH, "endtime":endH, "paraname":pname}]
        rb = JSON.stringify(rb)
        param = {url:nc_url_prefix+'/et/profile_Hourly_HRLDAS_ANA_allR', 
          option:{method:'POST', body:rb, headers:{'Content-Type':'application/json'}}, type:'ET'}
      }
      else if(product && product==='Soil Moisture'){
        let rb = [{"longitude":lon, "latitude":lat, "starttime": startH, "endtime":endH}]
        rb = JSON.stringify(rb)
        param = {url:nc_url_prefix+'/et/profile_Hourly_HRLDAS_ANA_allR', 
          option:{method:'POST', body:rb, headers:{'Content-Type':'application/json'}}, type:'SM'}
      }
      //else if(product && product==='Total Amount of Available Water'){
      else if(product && product==='Available Water for Crop'){
          let rb = [{"longitude":lon, "latitude":lat, "starttime": startH, "endtime":endH}]
        rb = JSON.stringify(rb)
        param = {url:nc_url_prefix+'/et/profile_Hourly_AWCH_ANA_allR', 
          option:{method:'POST', body:rb, headers:{'Content-Type':'application/json'}}}
      }
      else if(product && product==='ET Forecast'){
        let pname =  'ET'
        let startT = new Date(startHour.getTime()+1000*3600)
        let endT = new Date(startHour.getTime()+1000*3600*24*5)
        let st = dateToString(startT).replace(/\./g, '') + getNumString(startT.getHours(), 2)
        let et = dateToString(endT).replace(/\./g, '') + getNumString(endT.getHours(), 2)
        let rb = [{"longitude":lon, "latitude":lat, "starttime": st, "endtime":et, "foldername":startH ,"paraname":pname}]
        rb = JSON.stringify(rb)
        param = {url:nc_url_prefix+'/et/profile_Hourly_HRLDAS_FCST_allR', 
          option:{method:'POST', body:rb, headers:{'Content-Type':'application/json'}}, type:'ET'}
      }
      else if(product && product==='Soil Moisture Forecast'){
        let startT = new Date(startHour.getTime()+1000*3600)
        let endT = new Date(startHour.getTime()+1000*3600*24*5)
        let st = dateToString(startT).replace(/\./g, '') + getNumString(startT.getHours(), 2)
        let et = dateToString(endT).replace(/\./g, '') + getNumString(endT.getHours(), 2)
        let rb = [{"longitude":lon, "latitude":lat, "starttime": st, "endtime":et, "foldername":startH}]
        rb = JSON.stringify(rb)
        param = {url:nc_url_prefix+'/et/profile_Hourly_HRLDAS_FCST_allR', 
          option:{method:'POST', body:rb, headers:{'Content-Type':'application/json'}}, type:'SM'}
      }
      else if(product && product.includes('NWM')){
        //let pname = "t2d_nwm"
        let rb = [{"longitude":lon, "latitude":lat, "starttime": startH, "endtime":endH,}]
        rb = JSON.stringify(rb)
        param = {url:nc_url_prefix+'/et/profile_hourly_NWM_allR', option:{method:'POST', body:rb, headers:{'Content-Type':'application/json'}}}
      }
      else if(product &&  product.includes('GFS')){
        //let pname = "t2d_gfs"
        let startT = new Date(startHour.getTime()+1000*3600)
        let endT = new Date(startHour.getTime()+1000*3600*24*5)
        let st = dateToString(startT).replace(/\./g, '') + getNumString(startT.getHours(), 2)
        let et = dateToString(endT).replace(/\./g, '') + getNumString(endT.getHours(), 2)
        let rb = [{"longitude":lon, "latitude":lat, "starttime": st, "endtime":et, "foldername":startH }]
        rb = JSON.stringify(rb)
        param = {url:nc_url_prefix+'/et/profile_hourly_GFS_allR', 
          option:{method:'POST', body:rb, headers:{'Content-Type':'application/json'}}}
      }

      this.getProValue(param)
    }else{
      alert("ERROR: Please first draw an interest point on the map using the top-center toolbar (AOI function -> Point).");
      return;
    }
  }

  getDailyProfile(){
    const { aoi, conusAEAProj, nc_url_prefix} = this.props.context.state
    const { product, startDate, endDate,cropType, plantDate, startHour} = this.state

    if (aoi.type==='geom'&&aoi.feature.type==='Point'){
      this.setState({isLoading:true})
      this.setState({modalVisible:true})
      this.setState({csvList:'' })
      this.setState({point:'Location: '+toStringHDMS(aoi.feature.coordinates.map(a=>a*1))})
      //this.setState({loader:'Loading Chart...'})
      this.countDown()
      let coor = aoi.feature.coordinates
      let lon, lat, startD, endD, param
      lon = [coor[0].toString()]
      lat = [coor[1].toString()]
      startD = dateToString(startDate).replace(/\./g, '')
      endD = endDate?dateToString(endDate).replace(/\./g, ''):null
      if(product && (product==='ET' || product==='NWM Precipitation')){
        let pname = product==='ET'?'ET':'PREP'
        let rb = [{"longitude":lon, "latitude":lat, "starttime": startD, "endtime":endD,}]
        rb = JSON.stringify(rb)
        param = {url:nc_url_prefix+'/et/profile_Daily_HRLDAS_ANA_allR', 
        option:{method:'POST', body:rb, headers:{'Content-Type':'application/json'}}, type:pname}
      }else if(product && product==='GFS Precipitation'){
        let startH = dateToString(startHour).replace(/\./g, '') + getNumString(startHour.getHours(), 2)
        startD = dateToString(startHour).replace(/\./g, '')
        let endT = new Date(startHour.getTime()+1000*3600*24*4)
        let et = dateToString(endT).replace(/\./g, '')
        let pname = 'PREP'
        let rb = [{"longitude":lon, "latitude":lat, "starttime": startD, "endtime":et,"foldername":startH, "paraname":pname}]
        rb = JSON.stringify(rb)
        param = {url:nc_url_prefix+'/et/profile_Daily_HRLDAS_FORE', 
        option:{method:'POST', body:rb, headers:{'Content-Type':'application/json'}}}
      }
      else if(product && product==='Soil Moisture'){
        let rb = [{"longitude":lon, "latitude":lat, "starttime": startD, "endtime":endD, "hour":"10"}]
        rb = JSON.stringify(rb)
        param = {url:nc_url_prefix+'/et/profile_Hourly_HRLDAS_ANA_CH', 
        option:{method:'POST', body:rb, headers:{'Content-Type':'application/json'}}, type:'SM'}
      }
      else if(product && product==='Soil Moisture Forecast'){
        let sD = dateToString(new Date(startDate.getTime()-1000*3600*24)).replace(/\./g, '')
        let startH = sD+"00"
        let endT = new Date(startDate.getTime()+1000*3600*24*4)
        let et = dateToString(endT).replace(/\./g, '')
        let rb = [{"longitude":lon, "latitude":lat, "starttime": startD, "endtime":et, "foldername":startH, "hour":"10"}]
        rb = JSON.stringify(rb)
        param = {url:nc_url_prefix+'/et/profile_Hourly_HRLDAS_FCST_CH', 
        option:{method:'POST', body:rb, headers:{'Content-Type':'application/json'}}, type:'SM'}
      }      
      else if(product && product==='ET Forecast'){
        let sD = dateToString(new Date(startDate.getTime()-1000*3600*24)).replace(/\./g, '')
        let startH = sD+"00"
        let endT = new Date(startDate.getTime()+1000*3600*24*4)
        let et = dateToString(endT).replace(/\./g, '')
        let rb = [{"longitude":lon, "latitude":lat, "starttime": startD, "endtime":et, "foldername":startH, "hour":"10"}]
        rb = JSON.stringify(rb)
        param = {url:nc_url_prefix+'/et/profile_Hourly_HRLDAS_FCST_CH', 
        option:{method:'POST', body:rb, headers:{'Content-Type':'application/json'}}, type:'ET'}
      }      
      else if(product && (product.includes('Machine Learning'))){
        // let plantTime = dateToString(plantDate).replace(/\./g, '')
        // let ct = cropType === 'Corn'?"1":cropType === "Soybean"?"5":"0"
        let rb = [{"longitude":lon[0], "latitude":lat[0], "starttime": startD, "endtime":endD,}]// "crop_type":ct, "planttime":plantTime}]
        rb = JSON.stringify(rb)
        param = {url:nc_url_prefix+'/et/profile_ETSM_ML', 
        option:{method:'POST', body:rb, headers:{'Content-Type':'application/json'}}, type:product}
      }
      // else if(product && (product==='ET Machine Learning(cloud free)')){
      //   let plantTime = dateToString(plantDate).replace(/\./g, '')
      //   let ct = cropType === 'Corn'?"1":cropType === "Soybean"?"5":"0"
      //   let rb = [{"longitude":lon[0], "latitude":lat[0], "starttime": startD, "endtime":endD, "crop_type":ct, "planttime":plantTime}]
      //   rb = JSON.stringify(rb)
      //   param = {url:nc_url_prefix+'/et/profile_ET_clear', option:{method:'POST', body:rb, headers:{'Content-Type':'application/json'}}}
      // }
      // else if(product && (product==='Soil Moisture Machine Learning')){
      //   let plantTime = dateToString(plantDate).replace(/\./g, '')
      //   let ct = cropType === 'Corn'?"1":cropType === "Soybean"?"5":"0"
      //   let rb = [{"longitude":lon[0], "latitude":lat[0], "starttime": startD, "endtime":endD, "crop_type":ct, "planttime":plantTime}]
      //   rb = JSON.stringify(rb)
      //   param = {url:nc_url_prefix+'/et/profile_SM_cloud', option:{method:'POST', body:rb, headers:{'Content-Type':'application/json'}}}
      // }
      // else if(product && (product==='Soil Moisture Machine Learning(cloud free)')){
      //   let plantTime = dateToString(plantDate).replace(/\./g, '')
      //   let ct = cropType === 'Corn'?"1":cropType === "Soybean"?"5":"0"
      //   let rb = [{"longitude":lon[0], "latitude":lat[0], "starttime": startD, "endtime":endD, "crop_type":ct, "planttime":plantTime}]
      //   rb = JSON.stringify(rb)
      //   param = {url:nc_url_prefix+'/et/profile_SM_clear', option:{method:'POST', body:rb, headers:{'Content-Type':'application/json'}}}
      // }
      // else if(product && (product==='ET Machine Learning(predict)')){
      //   let plantTime = dateToString(plantDate).replace(/\./g, '')
      //   let ct = cropType === 'Corn'?"1":cropType === "Soybean"?"5":"0"
      //   let rb = [{"longitude":lon[0], "latitude":lat[0], "starttime": startD, "endtime":endD, "crop_type":ct, "planttime":plantTime}]
      //   rb = JSON.stringify(rb)
      //   param = {url:nc_url_prefix+'/et/profile_ET_fcst', option:{method:'POST', body:rb, headers:{'Content-Type':'application/json'}}}
      // }
      
      this.getProValue(param)
    }else{
      alert("ERROR: Please first draw an interest point on the map using the top-center toolbar (AOI function -> Point).");
      return;
    }
  }

  handleExportProfile(){
      let a = document.createElement('a');
      const {csvList,product} = this.state
      const csvString = this.props.context.stringifyCsv(csvList)
      const data = new Blob([csvString], {type: 'text/csv'})
      let tableUrl = window.URL.createObjectURL(data)
      //a.download = statUrl.split('/').pop()
      a.download = product+'.csv'
      a.href = tableUrl;
      a.click()
  }

  componentDidMount() {
    let {aoi,latestNDVIDay, latestSMAPDay} = this.props.context.state
    if(aoi.type === 'fip'){
        this.setState({title:aoi.title})
    }else if(aoi.type === 'geom'){
        this.setState({title:'AOI map'})
    }
    // let latestDate = (stringToDate(latestSMAPDay))<(stringToDate(latestNDVIDay))?stringToDate(latestSMAPDay):stringToDate(latestNDVIDay)
    // this.setState({endDate:latestDate, startDate:new Date(latestDate.getTime()-1000*3600*24*30)})

  }


  render() {
    const labelStyle = { textTransform: "capitalize" , marginBottom:'0', color:'white'};
    const boxStyle={padding:'0.4em', margin: 'auto'};
    const buttonStyle = {background:'red', border:'none', outline:'none'}
    const {csvList, modalVisible, period, product, startDate, endDate, startWeek, endWeek, startHour, endHour, cropType, plantDate} = this.state
    const {latestNDVIDay, latestSMAPDay, latestSMAPDay1km, latestSMAPAnomalyDay, olmap, basicLayerList, descriVar, latestETDay,latestANATime,
      latestPREPNWMDay, latestGFSTime, latestNWMTime, latestFORECASTTime, latestPREPGFSTime} = this.props.context.state
    const weekList = this.getWeekList(product)
    const units={'ET':'mm', 'Soil Moisture':'m/m'}
    let step = product && product.includes('GFS')? 6:1
    let maxDate = product &&product.includes('NDVI')?stringToDate(latestNDVIDay):
      product &&product.includes('1KM-DAILY')?stringToDate(latestSMAPDay1km):
      product &&product.includes('9KM-ANOMALY-DAILY')?stringToDate(latestSMAPAnomalyDay):
      product &&product === 'ET RF'?stringToDate('2020.07.29'):
      product &&product === 'ET'?stringToDate(latestETDay):
      product &&product === 'Soil Moisture'?latestANATime:
      product &&product === 'Soil Moisture Forecast'?latestFORECASTTime:
      product &&product === 'NWM Precipitation'?stringToDate(latestPREPNWMDay):
      product &&product === 'GFS Precipitation'?latestPREPGFSTime:
      product &&product.includes('Machine Learning')?new Date():
      stringToDate(latestSMAPDay)
    //maxDate = new Date(maxDate.getTime()+1000*3600*23)
    let minDate = product && product === 'ET RF'?stringToDate('2020.07.11'):
      stringToDate('2000.04.01')

    let maxTime = product &&product.includes('NWM')?latestNWMTime:
      product &&product.includes('GFS')?latestGFSTime:
      product &&product.includes('Forecast')?latestFORECASTTime:
      latestANATime
    csvList && this.stopCountDown()
    return (
      <div className="container">
        <div className="row">
          <div className="col-8" >
        
          </div>
          <div className="col-4">
         
          </div>
        </div>
        
        <div style={boxStyle}>
          <label  style={labelStyle}>Choose Period &nbsp; </label>
            <DropdownList  
              id="period"
              data={['Daily','Weekly','Hourly', 'None']} 
              // disabled={['Weekly']}
              value={period}
              onChange={e=>this.handlePeriodChange(e)}
            />
         
        </div>
        <div style={boxStyle}>
          <label  style={labelStyle}>Choose Product &nbsp; </label>
            <DropdownList  
              id="product"
              data={period && (period === 'Daily'?[ //productId for url
                //'ET RF',
                'ET',
                'ET Forecast',
                'Soil Moisture',
                'Soil Moisture Forecast',
                'NWM Precipitation',
                'GFS Precipitation',
                'ET Machine Learning',
                //'ET Machine Learning(cloud free)',
                'Soil Moisture Machine Learning',
                //'Soil Moisture Machine Learning(cloud free)',
                //'ET Machine Learning(predict)',
                'SMAP-9KM-DAILY-SUB',
                'SMAP-9KM-DAILY-TOP',
                'SMAP-9KM-ANOMALY-DAILY-SUB',
                'SMAP-9KM-ANOMALY-DAILY-TOP',
                'NDVI-DAILY',
              ]:(period === 'Weekly'?
                [
                  //'ET MODIS',
                  'SMAP-9KM-ANOMALY-WEEKLY-SUB',
                  'SMAP-9KM-ANOMALY-WEEKLY-TOP',
                  'SMAP-9KM-CATEGORY-WEEKLY-SUB',
                  'SMAP-9KM-CATEGORY-WEEKLY-TOP',
                  'SMAP-9KM-WEEKLY-SUB',
                  'SMAP-9KM-WEEKLY-TOP',
                  'SMAP-HYB-1KM-WEEKLY',
                  'SMAP-HYB-1KM-ANOMALY-WEEKLY',
                  'SMAP-HYB-1KM-CATEGORY-WEEKLY',
                  'NDVI-WEEKLY',
                  'MVCI-WEEKLY',
                  'VCI-WEEKLY',
                ]:(period === 'Hourly'?
                [
                  // 'NWM',
                  // 'GFS',
                  'National Water Model (NWM)',
                  'Global Forecast System (GFS)',
                  'ET',
                  'Soil Moisture',
                  //'Total Amount of Available Water',
                  'Available Water for Crop',
                  'ET Forecast',
                  'Soil Moisture Forecast'

                ]:[
                  'Soil property'
                ])))} 
              // disabled={['Weekly']}
              value={product}
              onChange={e=>this.handleProductChange(e)}
            />
         
        </div>
        {period && period === 'Weekly'?
            <div>
              <div  style={boxStyle}>
                <label  style={labelStyle}>Choose Start Week &nbsp;
                </label>
                  <DropdownList
                    id="start_week"
                   
                    data={weekList && (weekList.slice(endWeek?weekList.indexOf(endWeek):0, weekList.length))
                      }
                    //min={new Date(this.state.endDate.getTime()-oneHundredDays)}
                    value={startWeek}
                    onChange={e=>this.handleWeekChange(e, 0)}/>
           
              </div>
              <div  style={boxStyle}>
                <label  style={labelStyle}>Choose End Week &nbsp;
                </label>
                  <DropdownList
                    id="end_week"
                    data={weekList && (weekList.slice(0,weekList.indexOf(startWeek)))}
                    //max={(stringToDate(latestSMAPDay))<(stringToDate(latestNDVIDay))?stringToDate(latestSMAPDay):stringToDate(latestNDVIDay)}
                    value={endWeek}
                    onChange={e=>this.handleWeekChange(e, 1)}/>
              
              </div>
            </div>
          :(
            period && period === 'Hourly')||product==='GFS Precipitation'?
                <div>
                <div  style={boxStyle}>
                  <label  style={labelStyle}>{product && (product.includes('Forecast')||product === "GFS Precipitation") ?
                    'Choose Initialize Time':'Choose Start Hour'}
                    <DateTimePicker
                      id="start_hour"
                      time={!(product && ['ET Forecast', 'Soil Moisture Forecast'].indexOf(product)!==-1)}
                      step={60*step}
                      disabled={!product}
                      max={endHour?endHour:maxTime}
                      format={(product && ['ET Forecast', 'Soil Moisture Forecast'].indexOf(product)!==-1)?'MM/DD/YYYY':'MM/DD/YYYY hh:mm A'}
                      min={product && (product.includes('NWM')?new Date('2018-11-14T22:00:00'):new Date('2019-01-01T00:00:00'))}
                      value={startHour}
                      onChange={e=>this.handleHourChange(e, 0)}></DateTimePicker>
                  </label>
                </div>
                {product && (product.includes('Forecast')||product === "GFS Precipitation")?null:
                <div  style={boxStyle}>
                  <label  style={labelStyle}>Choose End Hour &nbsp;
                    <DateTimePicker
                      id="end_hour"
                      time={true}
                      disabled={!product}
                      min={startHour}
                      step={60*step}
                      format={'MM/DD/YYYY hh:mm A'}
                      max={maxTime}
                      value={endHour}
                      onChange={e=>this.handleHourChange(e, 1)}></DateTimePicker>
                  </label>
                </div>}
              </div>
              :
           (
              ( period && period === 'Daily'?
              <div>
              <div  style={boxStyle}>
                <label  style={labelStyle}>{product && (product.includes('Forecast')) ?'Choose Initialize Time':'Choose Start Date'}
                  <DateTimePicker
                    id="start_date"
                    time={false}
                    disabled={!product}
                    max={endDate?endDate:maxDate}
                    min={minDate
                      //product && (product.includes("SMAP")?stringToDate('2015.03.31'):stringToDate('2000.04.29'))
                    }
                    value={startDate}
                    onChange={e=>this.handleDateChange(e, 0)}></DateTimePicker>
                </label>
              </div>
              {product && (product.includes('Forecast'))?null:
              <div  style={boxStyle}>
                <label  style={labelStyle}>Choose End Date &nbsp;
                  <DateTimePicker
                    id="end_date"
                    time={false}
                    disabled={!product}
                    min={startDate}
                    max={maxDate
                      //product && (product.includes("SMAP")?stringToDate(latestSMAPDay):stringToDate(latestNDVIDay))
                    }
                    value={endDate}
                    onChange={e=>this.handleDateChange(e, 1)}></DateTimePicker>
                </label>
              </div>}
            </div>
              :
             null
              )
          )

    
        }
        {/* {product && product.includes('Machine')?
        <div>

          <div  style={boxStyle}>
            <label  style={labelStyle}>Choose Plant Date
              <DateTimePicker
                id="plant_date"
                time={false}
                value={plantDate}
                onChange={e=>this.setState({plantDate:e})}></DateTimePicker>
            </label>
          </div>
          <div  style={boxStyle}>
            <label  style={labelStyle}>Choose Crop Type &nbsp;
            </label>
              <DropdownList
                id="crop_type"
                
                data={['Corn', 'Soybean', 'Other']
                  }
                //min={new Date(this.state.endDate.getTime()-oneHundredDays)}
                value={cropType}
                onChange={e=>this.handleCropChange(e, 0)}/>
        </div>
        </div>:null} */}
        <div style={{padding:'-0.4em', paddingTop:'1em'}}>
          <button 
            type="button" className="btn btn-danger btn-sm" 
            style={{ 'width': '100px', 'borderRadius': '0',outline:'none' }} 
            onClick={()=>this.handleGenerate()}
          >
              {this.state.isLoading ? "Loading..." : "Submit"}
          </button>
        </div>
        <ReactModal initWidth={650} initHeight={600} 
                // onFocus={() => {}}
                top={50}
                className={"my-modal-custom-class"}
                onRequestClose={this.closeModal} 
                // disableResize={'false'}
                isOpen={modalVisible}> 
          <h5 className='flexible-modal-title'>Profile of {product===null?"":product} for Selected Point </h5>
          <span>{this.state.point}</span>
          <br/>
              {csvList?(period === 'None' || ['National Water Model (NWM)',
                  'Global Forecast System (GFS)',].indexOf(product)!==-1
              // || product === 'SM LDASOUT' 
              ?
               <Chart
               chartType="Table"
               height={'450px'}
               loader={<div>Loading Chart</div>}
               data={csvList}
               options={{
                   showRowNumber: true,
                   // width: '95%',
                   // height: '95%' 
                   // chartArea: {width:'500',height:'500'}
                   cssClassNames:{tableCell:'table-cell'}
               }}
               rootProps={{'data-tested':'1'}}
               //style={{textTransform:'capitalize'}}
           />
              :
              <Chart
                chartType="LineChart"
                loader={<div>Loading Chart</div>}
                data={csvList}
                marginLeft="5px"
                // width="90%"
                getChartWrapper={chartWrapper => {
                  // get a reference to the chartWrapper
                  this.chartWrapper = chartWrapper
                        // Redraw when you want
                        chartWrapper.draw();;
                      }}
                options={{ 
                  width: '100%',
                  height: '90%',
                  chartArea:{
                    top:5,
                    //right: 5,
                    width:"80%",
                    // height:"80%",
            
                  },
                  // height: 500,
                  // width: 500,
                  hAxis:{ 
                    title: this.state.product,
                    gridlines:{color:'#333'},
                    format: 'M/d/yy',
                  }, 
                  vAxis: {
                    title: units[this.state.product],
                    ticks: product.includes('CATEGORY')?[{v:4, f:'Surplus'}, {v:3, f:'Adequate'}, {v:2, f:'Short'}, {v:1, f:'Very Short'}, {v:9, f:'No Data'}]:null,
                  },
                  legend: { position: 'bottom' }
                }}
                rootProps={{'data-tested':'2'}}
              />)
              :(<span style={{backgroundColor:'red', color:'white'}}>{this.state.loader}</span>)}

                <p style={{ bottom:'5px', left:'100px', position:'absolute'}}>{descriVar[product]}</p>
                <button className='flexible-modal-clsBtn'
                    onClick={this.closeModal}
                >&times;
                </button>
                <button className="btn btn-danger btn-sm"  style={{...buttonStyle, bottom:'5px', left:'10px', position:'absolute'}}
                    onClick={this.handleExportProfile} disabled={csvList?false:true}
                >
                    Download
                </button>
        </ReactModal>
      </div >
    )
  }
}

//export default SMAPProfileBar
export default props => (
  <WSConsumer>
    {context => <WSProfileBar {...props} context={context} />}
  </WSConsumer>
)