import React, { useState, useEffect } from 'react'

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';



// ===================================================
let InfoDialog = (props) => {

    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ confirmDialog, setConfirmDialog ] = useState(false);
    const [ closeButtonText, setCloseButtonText ] = useState("CLOSE");
    const [ yesButtonText, setYesButtonText ] = useState("OK");
    const [ thirdButtonText, setThirdButtonText ] = useState(null);

    // -------------------------------------
    useEffect( () => {
        
        if ( props.open ) {
            setDialogOpen(true);
            if ( props.onYes !== undefined ) {
              setConfirmDialog(true);
              if ( props.yesButtonText !== undefined )
                setYesButtonText( props.yesButtonText );
              if ( props.thirdButtonText !== undefined )
                setThirdButtonText( props.thirdButtonText );
              setCloseButtonText( (props.closeButtonText !== undefined) ? props.closeButtonText : "CANCEL");
            }
            else if (props.closeButtonText !== undefined)
              setCloseButtonText( props.closeButtonText );
          }

    }, [props]);


    // -------------------------------------
    return (

      <Dialog
          open={dialogOpen}
          maxWidth={(thirdButtonText)?'md':'sm'}
          onClose={()=>{
            setDialogOpen(false);
            if ( props.onClose !== undefined )
                props.onClose();
        }}
        //PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          { confirmDialog && thirdButtonText && (
          <Button
            autoFocus
            variant="contained"
            color="primary"
            style={{textTransform:'none'}}
            onClick={()=>{
              setDialogOpen(false);
              props.onThirdClicked && props.onThirdClicked();
            }}
          >{thirdButtonText}</Button>)}

          { confirmDialog && (
          <Button
            autoFocus
            variant="contained"
            color="primary"
            style={{textTransform:'none'}}
            onClick={()=>{
              setDialogOpen(false);
              props.onYes && props.onYes();
            }}
          >{yesButtonText}</Button>)}

          <Button
            autoFocus
            variant="outlined"
            style={{textTransform:'none'}}
            onClick={()=>{
              setDialogOpen(false);
              if ( props.onClose !== undefined )
                  props.onClose();
            }}
          >{closeButtonText}</Button>

        </DialogActions>
      </Dialog>
    );
}


export default InfoDialog;
