import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
//import { Cancel, CheckCircle, InfoRounded } from "@material-ui/icons";
import { Cancel, CheckCircle, InfoRounded } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";

const useStyles = makeStyles((theme) => ({
  validated: {
    color: theme.palette.success.main,
  },
  failed: {
    color: theme.palette.error.main,
  },
  info: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.grey[200],
  }
}));

export default function PasswordEditor(props) {

  const classes = useStyles();

  const { setPassword, userId } = props;

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEntered, setReEntered] = useState("");
  const [open, setOpen] = useState(false);
  const [validated, setValidated] = useState(null);
  const [match, setMatch] = useState(false);

  const [modified, setModified] = useState(false);

  useEffect(() => {
    if (reEntered === "") {
      setMatch(false);
      return;
    }
    if (newPassword !== reEntered) {
      setMatch(false);
    } else {
      setMatch(true);
    }
  }, [reEntered]);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
    setOldPassword("");
    setValidated(null);
    setNewPassword("");
    setMatch(false);
  };

  const handleValidate = async () => {
    if (oldPassword === "") {
      setValidated(false);
      return;
    }
    const response = await fetch(process.env.PUBLIC_URL + "/backend/users/" + userId + "/password", {
      method: "POST",
      headers: {
        "Content-Type": "application/text"
      },
      body: oldPassword,
    });
    const data = await response.json();
    if (data.status == "Success") {
      setValidated(true);
      setNewPassword("");
      setReEntered("");
    } else {
      setValidated(false);
    }
  };

  const handleCheckPasswordMatch = () => {
    if (newPassword !== reEntered || newPassword === "") {
      setMatch(false);
    } else  {
      setMatch(true);
    }
  }

  const handleConfirm = () => {
    if (!match) {
      alert("Please make sure the entered new passwords matches!")
    } else if (oldPassword === newPassword) {
      // new password should not be the same as old password
      alert("Please enter a different password from your old password")
    } else {
      setPassword(newPassword);
      setModified(true);
      handleClose();
    }
  }

  return (
    <div>
      <Grid container spacing={2} alignItems="center" >
        <Grid item>
          <Button variant="contained" className={classes.info} onClick={handleClickOpen}>
            Edit Password
          </Button>
        </Grid>
        {modified ?
        (<Grid item>
          <InfoRounded />
          <Typography variant="caption">Password not saved yet, please click Save button to save the change.</Typography>
        </Grid>
        ) : null}   
      </Grid>
      <div style={{display: open ? "block" : "none", padding: "8px"}}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography variant="body1">
              Please enter current password
            </Typography>
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              type="password"
              variant="standard"
              value={oldPassword}
              onChange={e => setOldPassword(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Button variant="contained" onClick={handleValidate} size="small">
              Validate Current Password
            </Button>
          </Grid>
          <Grid item>
            <ValidationIcon validated={validated}/>
          </Grid>
        </Grid>
        {validated ? (
          <React.Fragment>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography variant="body1">
                  Please enter new password
                </Typography>
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  id="input_new_password"
                  type="password"
                  variant="standard"
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                  onBlur={handleCheckPasswordMatch}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography variant="body1">
                  Please re-enter the new password
                </Typography>
              </Grid>
              <Grid item xs>
                <TextField
                  fullWidth
                  id="input_repeat_password"
                  type="password"
                  variant="standard"
                  error={!match}
                  onChange={e => {
                    setReEntered(e.target.value);
                  }}
                  onBlur={handleCheckPasswordMatch}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item>
                <Button disabled={!match} variant="contained" color="primary" onClick={handleConfirm} >Confirm</Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={handleClose} >Cancel</Button>
              </Grid>
            </Grid>

          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
}

const ValidationIcon = ({validated}) => {

  const classes = useStyles()

  if (validated === null) {
    return null;
  } else if (validated === false) {
    return <Cancel className={classes.failed} />
  } else {
    return <CheckCircle className={classes.validated} />
  }
}