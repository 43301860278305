import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

import InfoDialog from '../util/InfoDialog';
import { findCropType, findSoilType } from '../util/IdaData';

const columns: GridColDef[] = [
  { field: 'timestamp', headerName: 'Timestamp', width: 180,
    valueGetter: (value, row) => row.payload.timestamp.split(".")[0] },
  { field: 'id', headerName: 'Task ID', width: 70, align:'center',
    valueGetter: (value, row) => row.payload.id },
  { field: 'status', headerName: 'Status', width: 100,
    valueGetter: (value, row) => row.status==0?'Running':row.status==1?'Finished':'Error' },
  { field: 'user', headerName: 'User', width: 200,
    valueGetter: (value, row) => row.email },
  { field: 'title', headerName: 'Title', width: 200,
    valueGetter: (value, row) => row.payload.title },
  { field: 'result', headerName: 'Result', width: 200,
    valueGetter: (value, row) => (row.status==0)?'#':row.outputInfo.getresulturl,
    renderCell: (params) => (
      (params.row.status==0)?'Pending...':
      <a href={`${params.row.outputInfo.getresulturl}`} target="_blank">Download...</a>
    ) },
];


// ==============================================================
let TasksView = (props) => {

  const [rows, setRows] = useState([]);

  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [infoTitle, setInfoTitle] = useState('');
  const [infoMessage, setInfoMessage] = useState('');

  const navigate = useNavigate();

  // -----------------------------------------------
  useEffect( () => {
  
    console.log( "TasksView:", props );

    // if ( props.loginContext.userDetails == null || 
    //   props.loginContext.userDetails.roles.indexOf("ROLE_ADMIN") < 0 ) {
    
    //   console.log( "not admin account" );
    //   //navigate("/");
    // }

    fetchData();

  }, [props.viewName]);

  
  // -----------------------------------------------
  let openInfoDialog = ( title, message ) => {
    
    setInfoTitle(title);
    setInfoMessage(message);
    setInfoDialogOpen(true);
  };


  // -----------------------------------------------
  let fetchData = async () => {

    let urlStr = process.env.PUBLIC_URL + '/backend/subscriptions/ida/taskstatus';

    let response = await fetch(urlStr).catch( error => console.log(error) );
    if ( response === undefined ) {
      openInfoDialog( "Error', 'Cannot fetch tasks information" );
      return null;
    }

    let data = await response.json().catch( error => console.log(error) );

    console.log( "Data:", data );

    setRows( data );
  };



  // -----------------------------------------------
  return (

    <Box
      component="main"
      sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, '& p': {marginBottom:0} }}
    >
      <div style={{ marginTop:50, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true
            },
          }}          
          pageSizeOptions={[5, 10, 25, 50, 100]}
          //checkboxSelection
          hideFooterSelectedRowCount={true}
          //editMode={'row'}
          autoHeight={true}
          getRowId={(row)=> row.key}
          onRowClick={(e)=>{
            console.log(e);
            openInfoDialog( "Clicked", `id: ${e.id}` );
          }}
        />
      </div>

      <InfoDialog
        open={infoDialogOpen}
        title={infoTitle}
        message={infoMessage}
        onClose={()=>{
          setInfoDialogOpen(false);
        }}
      />

    </Box>
  );

}



export default TasksView;

