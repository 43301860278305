import {
  Button,
  Container,
  CssBaseline,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";

import Message from "../account/feedback-message";
import PasswordEditor from "../account/password-editor";
import LoginContext from "../account/login-context";

const successMessage = "The user information is successfully updated.";
const failureMessage = "Something went wrong, please try later."

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    // flexDirection: 'column',
  },
}));

export default function ProfilePage( props ) {
  const classes = useStyles();
  const loginContext = useContext(LoginContext);
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");

  const [messageOpen, setMessageOpen] = useState(false);
  const [success, setSuccess] = useState(true);
  const [message, setMessage] = useState(successMessage);

  console.log( "loginContext:", loginContext );

  useEffect(() => {

    // fetch(process.env.PUBLIC_URL + "/backend/users?id=" + loginContext.userId)
    //   .then((res) => res.json())
    //   .then((data) => {
    //     if (data.statusCode === 200) {
    //       setFirstName(data.body.firstName);
    //       setLastName(data.body.lastName);
    //       setPhoneNumber(data.body.phoneNumber);
    //     }
    //   });
    
    if ( loginContext.loggedIn ) {
      setFirstName( loginContext.userDetails.firstName != null ? loginContext.userDetails.firstName : "");
      setLastName( loginContext.userDetails.lastName != null ? loginContext.userDetails.lastName : "");
      setPhoneNumber( loginContext.userDetails.phoneNumber != null ? loginContext.userDetails.phoneNumber: "" );
    }
  }, []);

  const handleSave = async () => {
    const res = await fetch(process.env.PUBLIC_URL + "/backend/users/" + loginContext.userId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: loginContext.userId,
        active: loginContext.userDetails.active,
        email: loginContext.username,
        password: (password.length > 0) ? password : null,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        roles: loginContext.userDetails.roles,
        geofairyPushToken: loginContext.userDetails.geofairyPushToken
      }),
    });
    const data = await res.json();
    if (data.status == "Success" ) {
      setMessageOpen(true);
      setSuccess(true);
      setMessage(successMessage);
    } else {
      setMessageOpen(true);
      setSuccess(false);
      setMessage(failureMessage);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };


  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Toolbar />
      <div className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Login Credentials</Typography>
          </Grid>

          <FieldRow
            label="Username"
            control={
              <TextField
                fullWidth
                variant="standard"
                disabled
                value={loginContext.username}
              />
            }
          />

          <Grid item xs={12}>
            <PasswordEditor setPassword={setPassword} userId={loginContext.userId}/>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">User Info</Typography>
          </Grid>

          <Grid container item xs={12} spacing={2} alignItems={"center"}>
            <Grid item>
              <Typography variant="body1">Name</Typography>
            </Grid>
            <Grid item>
              <TextField
                variant="standard"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="standard"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
          </Grid>

          <FieldRow
            label="Phone Number"
            control={
              <TextField
                variant="standard"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            }
          />

          <Grid container item justifyContent="center">
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
            <Button style={{marginLeft:30}} variant="contained" color="secondary" onClick={handleBack}>
              Back
            </Button>
          </Grid>
        </Grid>
      </div>

      <Message 
        open={messageOpen} 
        setOpen={setMessageOpen} 
        success={success}
        duration={2000} 
        message={message}
      /> 

    </Container>
  );
}

const FieldRow = ({ label, control }) => (
  <Grid container item xs={12} spacing={2} alignItems={"center"}>
    <Grid item>
      <Typography variant="body1">{label}</Typography>
    </Grid>
    <Grid item>{control}</Grid>
  </Grid>
);
