import React, {Component} from 'react';
import '../style/WSAnimationModal.css';
import NewPortal from '../functions/newPortal';
import Transition from '../functions/transition';

class WSAnimationModal extends Component {
   constructor(props) {
     super(props)
     this.closeModal = this.closeModal.bind(this);
     this.maskClick = this.maskClick.bind(this)
     this.state = {visible:false}
   }

   componentDidMount() {
     this.setState({visible:this.props.visible})
   }
   //update 'visible' state when receive props from parent component
   componentWillReceiveProps(props) {
     this.setState({ visible: props.visible })
   }
   
   closeModal() {
     const {onClose} = this.props
     onClose && onClose()
     this.setState({ visible: false })
   }

   maskClick() {
    const {onClose} = this.props
    onClose && onClose()
     this.setState({visible:false})
   }

   render() {
     const { visible } = this.state;
     const {title, children, modalStyle } = this.props;
     return (<NewPortal>
      {/* <div className="modal-wrapper"> */}
      <Transition
        visible = {visible}
        transitionName = 'animationModal'
        enterActiveTimeout = {200}
        enterEndTimeout = {100}
        leaveActiveTimeout = {100}
        leaveEndTimeout = {200}
      >
        <div className='animationModal' style={modalStyle}>
          <div className="animationModal-title">{title}</div>
          <div className="animationModal-content">{children}</div>
       
        </div>
        </Transition>
     {visible && <div className="mask" onClick={this.maskClick}></div> }
    </NewPortal> 
     )}
}

export default WSAnimationModal;