import React, { Component } from 'react'
import { WSConsumer } from "../context/WSContext";
import {DropdownList , Multiselect} from "react-widgets";
import {stringToDate, getDayList} from '../functions/dateFunc'
import ToggleSwitch from './ToggleSwitch'

class WSFlyBar extends Component {
  constructor(props){
    super(props)

    this.state={
      dataType:'SMAPTop 9km',
      averOrMax:true,
      selectedDays:null,
    }

    this.products=this.props.context.state.products

    this.handleSwitch = this.handleSwitch.bind(this)

  }

  handleDataChange(e){
    this.setState({dataType:e})
  }

  handleGenerate(){
    const aoi= {type:'fip',feature:-1}
    const {selectedDays,dataType, averOrMax} = this.state
    const {SMAPDaily1km, SMAPDaily} = this.props.context.state
    const nameDict = {"SMAPTop 9km":"TOP", "SMAPSub 9km":"SUB"}
    const method = averOrMax?'AVERAGE':'MAX'
    if(!selectedDays || selectedDays.length<=1){
      alert("ERROR: At least two dates should be selected for compositing!")
      return;
    }
    //let layers = selectedDays.map(a=>nameDict[dataType]+a+method)
    let layers
    layers = selectedDays.map(a=>{
      if (dataType === 'SMAPTop 1km'){
        return SMAPDaily1km.filter(b=>b[1] === a)[0]
      }else{
        return SMAPDaily.filter(b=>b[1] === a && b[2].includes(method) && b[0].includes(nameDict[dataType]))[0]
      }
    })
    layers = layers.map(a=>a.join('_').replace('.map',''))
    //['SMAP-9KM-DAILY-TOP_2020.05.09_AVERAGE','SMAP-9KM-DAILY-TOP_2020.05.10_AVERAGE','SMAP-9KM-DAILY-TOP_2020.05.11_AVERAGE']
   
   this.props.context.onTheFlyCalc(aoi,layers,averOrMax, dataType)

    }

    handleSwitch(e){
        this.setState({averOrMax:e.target.checked})
    }

    getLatestDays(dayL){
      let days = dayL.map(a=>a[1])
      days = [...new Set(days)]
      days.sort((a,b)=>stringToDate(b).getTime()-stringToDate(a).getTime())
      days = days.slice(0,30)
      return days
    }

  render() {
    const labelStyle = { textTransform: "capitalize" , marginBottom:'0', color:'white'};
    const boxStyle={width:'200px', padding:'0.4em', margin: 'auto'};
    const buttonStyle = {background:'red', border:'none', outline:'none'}
    const {averOrMax, selectedDays, dataType} = this.state
    const {latestNDVIDay, latestSMAPDay,SMAPDaily, SMAPDaily1km} = this.props.context.state
    const text = ['Avg', 'Max']
    const dayNum = 14
    const dayList = dataType === 'SMAPTop 1km'?
      this.getLatestDays(SMAPDaily1km):this.getLatestDays(SMAPDaily)
    
    //getDayList(dataType.slice(0, 4) === 'SMAP'?stringToDate(latestSMAPDay):stringToDate(latestNDVIDay), dayNum)
    return (
      
      <div className="container">
        
        <div style={boxStyle}>
          <label  style={labelStyle}>Choose Layer Type &nbsp; </label>
            <DropdownList  
              id="period"
              data={[...new Set(this.products.filter(a=>a.id.includes('Daily')).map(a=>a.id.split('_')[0]))]} 
              disabled={['SMAPTop 9km-Anomaly', 'SMAPSub 9km-Anomaly','NDVI']}
              value={dataType}
              onChange={e=>this.handleDataChange(e)}
              //groupBy={product=>product.split('_')[0]}
            />
         
        </div>
        <div  style={boxStyle}>
          <label  style={labelStyle}>Choose Layer Dates &nbsp;</label>
            <Multiselect
                data={dayList}
                //defaultValue={}
                value={selectedDays}
                onChange={value => this.setState({selectedDays:value})}
                />
          
        </div>
        <ToggleSwitch id='averOrMax' onChange={this.handleSwitch} Text={text} currentValue={averOrMax} />
        <div style={{padding:'-0.4em', paddingTop:'1em'}}>
          <button 
            type="button" className="btn btn-danger btn-sm" 
            style={{ 'width': '100px', 'borderRadius': '0',outline:'none' }} 
            onClick={()=>this.handleGenerate()}
            //disabled = {true}
          >
              {"Calculate"}
          </button>
        </div>
      </div >
    )
  }
}

//export default SMAPProfileBar
export default props => (
  <WSConsumer>
    {context => <WSFlyBar {...props} context={context} />}
  </WSConsumer>
)