import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { DropdownList } from "react-widgets";

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

import InfoDialog from '../util/InfoDialog';
import { findCropType, findSoilType } from '../util/IdaData';

const columns: GridColDef[] = [
  { field: 'timestamp', headerName: 'Timestamp', width: 200, flex:1,
    valueGetter: (value, row) => row.timestamp },
  { field: 'category', headerName: 'Category', width: 200, flex:1,
    valueGetter: (value, row) => row.category },
  { field: 'user', headerName: 'User', width: 200, flex:1,
    valueGetter: (value, row) => row.user },  
  { field: 'message', headerName: 'Message', flex:3,
    valueGetter: (value, row) => row.message }
];


// ==============================================================
let LogsView = (props) => {

  const textareaRef = useRef(null);
  const [rows, setRows] = useState([]);

  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [infoTitle, setInfoTitle] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [logInfoList, setLogInfoList] = useState([]);
  const [logInfo, setLogInfo] = useState('');
  const [prettyFormat, setPrettyFormat] = useState(false);
  const [logRaw, setLogRaw] = useState('');

  const navigate = useNavigate();

  // -----------------------------------------------
  useEffect( () => {
  
    console.log( "TasksView:", props );

    fetchLogInfoList();

  }, [props.viewName]);

  // -----------------------------------------------
  useEffect( () => {

    if ( prettyFormat == false )
      fetchLogRaw( logInfo );
    else
      fetchLogJson( logInfo );

  }, [logInfo, prettyFormat]);

  // -----------------------------------------------
  useEffect( () => {

    if ( textareaRef.current != null )
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;

  }, [logRaw, prettyFormat]);
  
  
  // -----------------------------------------------
  let openInfoDialog = ( title, message ) => {
    
    setInfoTitle(title);
    setInfoMessage(message);
    setInfoDialogOpen(true);
  };


  // -----------------------------------------------
  let fetchLogInfoList = async () => {

    let urlStr = process.env.PUBLIC_URL + '/backend/admin/getlogs';

    let response = await fetch(urlStr).catch( error => console.log(error) );
    if ( response === undefined ) {
      openInfoDialog( "Error', 'Cannot fetch logs information" );
      return null;
    }

    let data = await response.json().catch( error => console.log(error) );

    console.log( "Data:", data );

    setLogInfoList(data);
    if ( data.length > 0 )
      setLogInfo( data[0] );
  };


  // -----------------------------------------------
  let fetchLogRaw = async (info) => {

    let urlStr = info.url;

    setLoading(true);
    let response = await fetch(urlStr).catch( error => console.log(error) );
    if ( response === undefined ) {
      openInfoDialog( "Error', 'Cannot fetch logs" );
      return null;
    }

    let data = await response.text().catch( error => console.log(error) );

    setLogRaw(data);
    setLoading(false);
  };


  // -----------------------------------------------
  let fetchLogJson = async (info) => {

    let urlStr = info.url + '?outputFormat=application/json';

    setLoading(true);
    let response = await fetch(urlStr).catch( error => console.log(error) );
    if ( response === undefined ) {
      openInfoDialog( "Error', 'Cannot fetch pretty format logs" );
      return null;
    }

    let data = await response.json().catch( error => console.log(error) );

    //console.log( "JSON:", data );
    setRows( data );
    setLoading(false);
  };
  
  // -----------------------------------------------
  const boxStyle = {display:"flex", width:500, padding:'0.4em', margin: 'auto'};
  const labelStyle = {textAlign:'right', width:100, marginRight:'10px', fontWeight:"bold"};

  return (

    <Box
      component="main"
      sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, '& p': {marginBottom:0} }}
    >
      <div style={{...boxStyle, marginTop:30, width: '100%'}}>
          <label style={{...labelStyle, paddingTop:10}}>Log Data:</label>
          <DropdownList
            style={{width:250, textAlign:'left' }}
            data={logInfoList}
            datakey={'date'}
            textField={'date'}
            value={logInfo}
            onChange={value=>{
              setLogInfo(value);
            }}
          />
          <Checkbox style={{marginLeft: 30}}
              checked={prettyFormat}
              onChange={ e => {
                setPrettyFormat( e.target.checked );
              }}
          />
          <span style={{ paddingTop:9}}>Pretty Format</span>
      </div>

      <div style={{ marginTop:10, width: '100%' }}>
        {!prettyFormat && <textarea
          readOnly
          //disabled
          value={logRaw}
          ref={textareaRef}
          style={{width:'100%', height:'calc(100vh - 150px)',
            borderColor:'#BFBFBF', backgroundColor:'#F0F0F0',
            fontFamily: 'monospace', fontSize:12
          }}
        />}

        {prettyFormat && <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true
            },
          }}          
          pageSizeOptions={[10, 25, 50, 100]}
          //checkboxSelection
          hideFooterSelectedRowCount={true}
          //editMode={'row'}
          autoHeight={true}
          density='compact'
          getRowId={(row)=> row.timestamp}
          onRowClick={(e)=>{
            console.log( e );
            openInfoDialog( "Message", e.row.message );
          }}
        />}
      </div>

      <InfoDialog
        open={infoDialogOpen}
        title={infoTitle}
        message={infoMessage}
        onClose={()=>{
          setInfoDialogOpen(false);
        }}
      />

      { loading && (
        <CircularProgress
          size={70}
          sx={{
            color: '#545a60',
            opacity: 0.5,
            position: 'fixed',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}      

    </Box>
  );

}



export default LogsView;

